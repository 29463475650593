import { memo, useState } from "react";
import useKeydown from "@/src/hooks/useKeydown";
import "./ColorButtons.scss";
import { useEffect } from "react";

const ColorButtons = () => {

    const [isShowing, setIsShowing] = useState(false);

    const [active, setActive] = useState(0);

    const [buttons] = useState([
        { color: "#ff0000", key: "red", keyCode: 403 },
        { color: "#00ff00", key: "green", keyCode: 404 },
        { color: "#ffff00", key: "yellow", keyCode: 405 },
        { color: "#0000ff", key: "blue", keyCode: 406 },
    ]);

    useEffect(() => {
        if (!isShowing) setActive(0);
    }, [isShowing]);

    useKeydown({
        isActive: isShowing,
        capture: true,
        keydown: (e) => {
            e.stopPropagation();
            e.preventDefault();
        },
        ok: () => {

            let { keyCode } = buttons[active];
            let data = { bubbles: true, cancelable: true, keyCode: keyCode };

            window.dispatchEvent(new KeyboardEvent("keydown", data));
            window.dispatchEvent(new KeyboardEvent("keyup", data));

            setIsShowing(false);

        },
        left: () => {
            if (active > 0) setActive(active - 1);
        },
        right: () => {
            if (active < buttons.length - 1) setActive(active + 1);
        },
        back: () => {
            setIsShowing(false);
        },
    });

    useEffect(() => {

        window.showColorButtons = () => {
            setIsShowing(true);
        }

    }, []);

    return (
        <div className={"color-buttons-parent " + (isShowing ? " visible" : "")}>
            <div className="color-buttons">
                {buttons.map((button, index) => (
                    <div
                        key={index}
                        className={`color-button ${ active == index ? "active" : "" }`}
                        onMouseEnter={() => setActive(index)}
                    >
                        <div style={{ backgroundColor: button.color }} className="circle" />
                    </div>
                ))}
            </div>

        </div>
    )

};

export default memo(ColorButtons);