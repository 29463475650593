import { memo, useCallback, useMemo } from "react";

import MenuItem from "./MenuItem";

import useStore from "@/src/hooks/useStore";
import useKeydown from "@/src/hooks/useKeydown";

import "./MenuPage.scss";
import FooterInfo from "@/src/components/footerInfo/FooterInfo";
import AppLogo from "@/src/branding/AppLogo";
import Config from "@/src/config";
import TimeLineEpg from "../liveTv/components/timeLineEpg/TimeLineEpg";
import Storege from "@/src/services/storage/storage";

const Menu = ({
    onBack = () => { },
    setSelectedPage = () => { },
    activePage = "TV",
    setActivePage = () => { }
}) => {

    const { Menu } = Config;

    const [playlist] = useStore("playlist");
   
    const rtlMode = Storege.getRtlMode();

    const onMouseEnter = useCallback((e, page) => {
        setActivePage(page);
    }, []);

    const onClick = useCallback((e, page) => {

        if (page == "RELOAD") return window.location.reload();

        setSelectedPage(page);

    }, [playlist]);

    const move = (direction) => {

        setActivePage(item => {

            if (Menu[item][direction]) return Menu[item][direction];

            return item;

        });

    };

    const keyDownOptions = useMemo(() => {
        return {
            isActive: true,
            debounce: 100,
            up: () => move("up"),
            down: () => move("down"),
            left: () => move(rtlMode ? "right" : "left"),
            right: () => move(rtlMode ? "left" : "right"),
            back: () => onBack(),
        }
    }, []);

    useKeydown(keyDownOptions);


  
    return (
        <div className="menu-parent">

            <AppLogo />

            <MenuItem
                name="TV"
                isActive={activePage == "TV"}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
            />

            <MenuItem
                name="MOVIES"
                isActive={activePage == "MOVIES"}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
            />

            <MenuItem
                name="SERIES"
                isActive={activePage == "SERIES"}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
            />

            <MenuItem
                name="SETTINGS"
                isActive={activePage == "SETTINGS"}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
            />

            <MenuItem
                name="RELOAD"
                isActive={activePage == "RELOAD"}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
            />

            <FooterInfo />

        </div>
    )

}

export default memo(Menu);