import AppLogo from "./logo.png"
import SplashScreenImg from "./splashscreen.jpg";
import AppBackground from "./bg-image.jpg";
import AppBackgroundCroped from "./bg-image-croped.png";

import tvIcon from "./menu/icons/tv.png";
import moviesIcon from "./menu/icons/movies.png";
import seriesIcon from "./menu/icons/series.png";
import settingsIcon from "./menu/icons/settings.png";
import playlistIcon from "./menu/icons/playlist.png";
// import videoIcon from "./menu/icons/video.png";
import { reloadIcon } from "../../assets/icons";

let Web_host = "https://beno4k.com";
let Backend_host = "https://api.beno4k.com";
let Api_host = Backend_host + "/api"
let Payment_host = Web_host + "/en/purchase";
let Add_playlist_host = Web_host + "/en/upload-playlist";

const Config = {
    Web_host,
    Backend_host,
    Api_host,
    Payment_host,
    Add_playlist_host,
    AppLogo,
    SplashScreenImg,
    AppBackground,
    AppBackgroundCroped,
    Menu: {
        TV: {
            icon: <img src={tvIcon} alt="TV" />,
            right: "MOVIES",
            down: "RELOAD",
        },
        MOVIES: {
            icon: <img src={moviesIcon} alt="Movies" />,
            left: "TV",
            right: "SERIES",
            down: "RELOAD",
        },
        SERIES: {
            icon: <img src={seriesIcon} alt="Series" />,
            left: "MOVIES",
            right: "SETTINGS",
            down: "RELOAD",
        },
        PLAYLISTS: {
            icon: <img src={playlistIcon} alt="Playlist" />,
            right: "SETTINGS",
            down: "RELOAD",
        },
        // VIDEOS: {
        //     icon: <img src={videoIcon} alt="Video" />,
        //     up: "MOVIES",
        //     left: "SERIES",
        //     down: "SETTINGS"
        // },
        SETTINGS: {
            icon: <img src={settingsIcon} alt="Settings" />,
            left: "SERIES",
            down: "RELOAD"
        },
        RELOAD: {
            icon: <img src={reloadIcon} />,
            up: "MOVIES",
        }
    }
};

export default Config;