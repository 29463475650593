import { memo, useState, useEffect } from "react";
import Text from "@/src/components/text/Text";
import useKeydown from "@/src/hooks/useKeydown";
import Storege from "@/src/services/storage/storage";
import "./SubtitleSettings.scss";
import useStore from "@/src/hooks/useStore";
import Button from "@/src/components/button/Button";
import { CheckedSVG } from "@/src/components/svgIcons";

const SubtitleSettings = ({ onBack = () => { } }) => {

    const [subtitleSettings, setSubtitleSettings] = useStore("subtitleSettings");

    const [active, setActive] = useState(0);

    const [size, setSize] = useState("medium");

    const [style, setStyle] = useState("drop-shadow");

    const rtlMode = Storege.getRtlMode();

    const left = () => {
        if (active == 0) return;
        setActive(active - 1);
    }

    const right = () => {
        if (active == 6) return;
        setActive(active + 1);
    }

    useKeydown({
        isActive: true,
        left: rtlMode ? right : left,
        right: rtlMode ? left : right,
        back: () => {

            Storege.setSubtitleSettings({ size, style });
            setSubtitleSettings({ size, style });

            onBack();

        }
    });

    useEffect(() => {

        let style = subtitleSettings || {};

        setSize(style.size || "medium");

        setStyle(style.style || "drop-shadow");

    }, []);

    let sizeText = size;

    if (active >= 0 & active <= 2) sizeText = ["small", "medium", "large"][active];

    let styleText = style;

    if (active >= 3 & active <= 6) styleText = ["drop-shadow", "dark", "light", "contrast"][active - 3];

    return (
        <div
            className="subtitle-settings"
            onMouseDown={e => e.stopPropagation()}
            onMouseEnter={e => e.stopPropagation()}
            onClick={e => e.stopPropagation()}
            onMouseMove={e => e.stopPropagation()}
        >
            <div className="example-container">
                <span className={`subtitle-text ${sizeText} ${styleText}`}>
                    <Text>your subtitles will look like this</Text>
                </span>
            </div>
            <div className="buttons-container">
                <div className="column">
                    <div className="column-title">
                        <Text>size</Text>: <Text>{sizeText}</Text>
                    </div>
                    <div className="column-buttons">
                        <Button
                            className="subtitle-settings-button small"
                            isActive={active == 0}
                            onClick={() => { setSize("small"); }}
                            onMouseEnter={() => { setActive(0); }}
                        >
                            {size == "small" ? <CheckedSVG /> : null}
                            <span>Aa</span>
                        </Button>
                        <Button
                            className="subtitle-settings-button medium"
                            isActive={active == 1}
                            onClick={() => { setSize("medium"); }}
                            onMouseEnter={() => { setActive(1); }}
                        >
                            {size == "medium" ? <CheckedSVG /> : null}
                            <span>Aa</span>
                        </Button>
                        <Button
                            className="subtitle-settings-button large"
                            isActive={active == 2}
                            onClick={() => { setSize("large"); }}
                            onMouseEnter={() => { setActive(2); }}
                        >
                            {size == "large" ? <CheckedSVG /> : null}
                            <span>Aa</span>
                        </Button>
                    </div>
                </div>
                <div className="column">
                    <div className="column-title">
                        <Text>style</Text>: <Text>{styleText}</Text>
                    </div>
                    <div className="column-buttons">
                        <Button
                            className="subtitle-settings-button drop-shadow"
                            isActive={active == 3}
                            onClick={() => { setStyle("drop-shadow"); }}
                            onMouseEnter={() => { setActive(3); }}
                        >
                            {style == "drop-shadow" ? <CheckedSVG /> : null}
                            <span>Aa</span>
                        </Button>
                        <Button
                            className="subtitle-settings-button dark"
                            isActive={active == 4}
                            onClick={() => { setStyle("dark"); }}
                            onMouseEnter={() => { setActive(4); }}
                        >
                            {style == "dark" ? <CheckedSVG /> : null}
                            <span>Aa</span>
                        </Button>
                        <Button
                            className="subtitle-settings-button light"
                            isActive={active == 5}
                            onClick={() => { setStyle("light"); }}
                            onMouseEnter={() => { setActive(5); }}
                        >
                            {style == "light" ? <CheckedSVG /> : null}
                            <span>Aa</span>
                        </Button>
                        <Button
                            className="subtitle-settings-button contrast"
                            isActive={active == 6}
                            onClick={() => { setStyle("contrast"); }}
                            onMouseEnter={() => { setActive(6); }}
                        >
                            {style == "contrast" ? <CheckedSVG /> : null}
                            <span>Aa</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default memo(SubtitleSettings);