import { memo, useCallback, useContext } from "react";
import "./MovieItem.scss";
import Button from "../../../../../components/button/Button";
import { MoviesPageContext } from "../../../MoviesPage";
import ResizeImage from "../../../../../components/resizeImage/ResizeImage";
import { LockedSVG } from "@/src/components/svgIcons";
import MarqueeText from "@/src/components/marqueeText/MarqueeText";
import { dotsIcon } from "@/src/assets/icons";

const MovieItem = ({ index, style, isActive, onMouseEnter }) => {

    const {
        moviesContent,
        currentCategoryId,
        clickMovie,
        isLockedMovie,
        currentSection,
        searchIsOpen,
        searchItems,
    } = useContext(MoviesPageContext);

    if (searchIsOpen) {
        var movieId = searchItems[index];
    } else {
        var movieId = moviesContent.categories_obj[currentCategoryId]?.items[index];
    }

    const mouseEnter = useCallback(() => {
        onMouseEnter(index);
    }, []);

    const onClick = useCallback(() => {
        clickMovie(movieId);
    }, [movieId]);

    if (movieId == undefined || movieId == null) return null;

    const movie = moviesContent.items_obj[movieId];

    const isLocked = isLockedMovie(movieId);

    return (
        <div className="movie-item-parent" style={style}>
            <Button
                isActive={isActive && currentSection == "moviesSection"}
                className="movie-item"
                onMouseEnter={mouseEnter}
                onClick={onClick}
            >
                <div className="icon item-bg" style={{ backgroundImage: `url(${dotsIcon})` }}>
                    <ResizeImage src={movie.poster} aspectRatio={false} quality={0.5} />
                    {isLocked ? <LockedSVG /> : null}
                </div>
                <div className="title">
                    <MarqueeText isActive={isActive && currentSection == "moviesSection"}>{movie.name}</MarqueeText>
                </div>
            </Button>
        </div>
    )

};

export default memo(MovieItem);