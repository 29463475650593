const AndroidRequestCallBack = {}
const AndroidRequestResponse = {}

// only get method
export const RequestWithAndroid = (url) => {

    delete AndroidRequestCallBack[url];

    let req = new Promise((resolve, reject) => {

        try {

            if (window.Android.getFromUrlB64Splitted) {
                window.Android.getFromUrlB64Splitted(url);
            } else if (window.Android.getFromUrlB64) {
                window.Android.getFromUrlB64(url);
            } else {
                window.Android.getFromUrl(url);
            }

            AndroidRequestResponse[url] = "";

            AndroidRequestCallBack[url] = (response, status) => {

                if (status >= 200 && status < 300) {

                    AndroidRequestResponse[url] += response;

                    if (status != 206) {

                        let responseString = AndroidRequestResponse[url];

                        try {
                            responseString = atob(responseString);
                        } catch (e) { }

                        try {
                            responseString = JSON.parse(responseString);
                        } catch (e) {
                            console.log(e);
                        }

                        resolve(responseString);

                        delete AndroidRequestCallBack[url];
                        delete AndroidRequestResponse[url];

                    }

                } else {
                    delete AndroidRequestCallBack[url];
                    delete AndroidRequestResponse[url];
                    reject(response);
                }

            };

        } catch (e) {
            console.log(e);
            delete AndroidRequestCallBack[url];
            delete AndroidRequestResponse[url];
            reject(e.message);
        }

    });

    req.abort = () => {
        delete AndroidRequestCallBack[url];
        delete AndroidRequestResponse[url];
    };

    return req;

};

if (window.Android) {

    function setRequestResult(url, status, response) {
        try {
            if (AndroidRequestCallBack[url]) AndroidRequestCallBack[url](response, status);
        } catch (e) {
            console.log(e);
        }
    }

    function setRequestResultSplitted(url, status, response) {
        try {
            if (AndroidRequestCallBack[url]) AndroidRequestCallBack[url](response, status);
        } catch (e) {
            console.log(e);
        }
    }

    // window.Android.setRequestResult = setRequestResult;
    window.setRequestResult = setRequestResult;

    // window.Android.setRequestResultSplitted = setRequestResultSplitted;
    window.setRequestResultSplitted = setRequestResultSplitted;

}