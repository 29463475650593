import { useEffect, useState } from "react";
import { DeviceDate, FormatDate } from "../../../utils/util";

const DaysHeader = () => {

    const [date, setDate] = useState(FormatDate(new DeviceDate(), "hh:mm"));

    useEffect(() => {
        const timer = setInterval(() => setDate(FormatDate(new DeviceDate(), "hh:mm")), 10000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div>{date}</div>
    )

};

export default DaysHeader;