import { useEffect, useState, forwardRef, memo, useImperativeHandle } from "react";

let duration = 0;
let audioTracks = [];
let textTracks = [];
let videoTracks = [];

const TizenPlayer = (props, ref) => {

    const {
        url = "",
        mediatype = "movie",
        loop = false,
        playing = false,
        onReady = () => { },
        onPlay = () => { },
        onPause = () => { },
        onEnded = () => { },
        onBuffer = () => { },
        onBufferEnd = () => { },
        onStart = () => { },
        onError = () => { },
        onDuration = () => { },
        onProgress = () => { },
        onSeek = () => { },
        onLoadedTracks = () => { },
        playerstyle = {},
    } = props;

    let { width, height, left, top } = playerstyle;

    const [playerState, setPlayerState] = useState("NONE");

    useImperativeHandle(ref, () => ({
        seekTo: (time) => {
            if (!time) return;
            seekTo(time);
        },
        getInternalPlayer: () => {
            return {
                audioTracks: audioTracks,
                tizenTextTracks: textTracks,
                videoTracks: videoTracks,
            }
        }
    }));

    // ex. "{"language":"fr","channels":"2","sample_rate":"48000","bit_rate":"128000","fourCC":"mpeg"}"
    // ex. "{"track_num":"0","track_lang":"","subtitle_type":"0","fourCC":""}"
    const getTracksLanguage = (track = {}) => {

        let { extra_info, type } = track;

        if (!extra_info) return "";

        let language = "";

        let names = {
            AUDIO: "Audio",
            TEXT: "Subtitle",
            VIDEO: "Video",
        }

        try {

            let info = JSON.parse(extra_info);

            if (info) language = info.language || info.track_lang || "";

        } catch (e) {
            console.log(e);
        }

        if (!language) language = names[type];

        return language;

    }

    const getTotalTrackInfo = () => {

        try {

            let tracks = webapis.avplay.getTotalTrackInfo();

            if (!tracks) return;

            let currentInfo = { AUDIO: 0, TEXT: 0, VIDEO: 0 };

            let currentTracks = webapis.avplay.getCurrentStreamInfo() || [];

            for (let i = 0; i < currentTracks.length; i++) {
                let { type, index } = currentTracks[i];
                currentInfo[type] = index;
            }

            audioTracks = [];
            textTracks = [];
            videoTracks = [];

            for (let i = 0; i < tracks.length; i++) {

                let track = tracks[i];

                let extra_info = null ;

                try{
                    extra_info =   JSON.parse(track.extra_info);
                }catch(e){
                    console.log(e);
                }

                let language = getTracksLanguage(track);

                if (track.type == "AUDIO") {

                    audioTracks.push({
                        language,
                        get enabled () {
                            return currentInfo.AUDIO == track.index;
                        },
                        set enabled (value) {

                            if (value == false) return;

                            try {
                                webapis.avplay.setSelectTrack("AUDIO", track.index);
                                currentInfo.AUDIO = track.index;
                            } catch (e) {
                                console.log(e);
                            }

                        },
                    });

                }

                if (track.type == "TEXT") {

                    textTracks.push({
                        lng: language,
                        file_id: track.index,
                        type: "tizenSubtitle",
                        get enabled () {
                            return currentInfo.TEXT == track.index;
                        },
                        set enabled (value) {

                            if (value == false) return;

                            try {
                                webapis.avplay.setSelectTrack("TEXT", track.index);
                                currentInfo.TEXT = track.index;
                                webapis.avplay.setSilentSubtitle(false);
                            } catch (e) {
                                console.log(e);
                            }

                        },
                    });

                }

                if(track.type == "VIDEO") {

                   if(!extra_info) continue
                    
                    videoTracks.push({
                        title:`${extra_info.Width}x${extra_info.Height}`,
                        resolution: `${extra_info.Width}x${extra_info.Height}`,
                        file_id: track.index,
                        type: "tizenVideo",
                        get enabled () {
                            return currentInfo.VIDEO == track.index;
                        },
                        set enabled (value) {

                            if (value == false) return;

                            try {
                                webapis.avplay.setSelectTrack("VIDEO", track.index);
                                currentInfo.VIDEO = track.index;
                            } catch (e) {
                                console.log(e);
                            }

                        },
                    });

                }

            }            
    
            
        } catch (e) {
            console.log(e);
        }

        onLoadedTracks();

    }

    const seekTo = (seconds) => {

        if (!seconds && seconds < 0) return;

        if (["IDLE", "READY", "PLAYING", "PAUSED"].indexOf(webapis.avplay.getState()) > -1) {

            webapis.avplay.seekTo(seconds * 1000, function () {
                onSeek();
            });

        }

    }

    const play = () => {

        if (["READY", "PLAYING", "PAUSED"].indexOf(webapis.avplay.getState()) > -1) {
            webapis.avplay.play();
        }

    }

    const pause = () => {

        if (["PLAYING", "PAUSED"].indexOf(webapis.avplay.getState()) > -1) {
            webapis.avplay.pause();
        }

    }

    const setDisplayRect = () => {

        if (!width) width = window.innerWidth;
        if (!height) height = window.innerHeight;
        if (!left) left = 0;
        if (!top) top = 0;

        width = parseInt(width);
        height = parseInt(height);
        left = parseInt(left);
        top = parseInt(top);

        if (["IDLE", "READY", "PLAYING", "PAUSED"].indexOf(webapis.avplay.getState()) > -1) {
            webapis.avplay.setDisplayRect(left, top, width, height);
        }

    }

    const initPlayer = (changeProtocol) => {

        try {

            duration = 0;
            audioTracks = [];
            textTracks = [];

            webapis.avplay.close();

            if (!url) return;

            let src = url;

            if (changeProtocol) {

                let protocol = src.split("://")[0];

                if (protocol == "http") {
                    src = src.replace("http://", "https://");
                } else {
                    src = src.replace("https://", "http://");
                }

            }

            console.log("initPlayer: ", src);
        
            webapis.avplay.open(src);
         
            let bitRateString = "BITRATES=5000~10000|STARTBITRATE=HIGHEST|SKIPBITRATE=LOWEST";
        
            webapis.avplay.setStreamingProperty('ADAPTIVE_INFO', bitRateString);

            webapis.avplay.prepareAsync(
                () => { // success
                    // console.log("prepareAsync: success");
                },
                (e) => { // error
                    console.log("prepareAsync: error", e);
                    if (!changeProtocol) return initPlayer(true);
                    onError();
                }
            );

        } catch (e) {
            console.log(e);
        }

    }

    const disableSubtitles = () => {

        try {
            webapis.avplay.setSilentSubtitle(true);
        } catch (e) {
            console.log(e);
        }

    }

    useEffect(() => {

        switch (playerState) {
            case "NONE":
                break;
            case "IDLE":
                break;
            case "READY":
                disableSubtitles();
                getTotalTrackInfo();
                setDisplayRect();
                onReady();
                playing ? play() : pause();
                break;
            case "PLAYING":
                if (!playing) return pause();
                onPlay();
                break;
            case "PAUSED":
                if (playing) return play();
                onPause();
                break;
        }

    }, [playerState, playing]);

    useEffect(() => {
        setDisplayRect();
    }, [width, height, left, top]);

    useEffect(() => {
        initPlayer();
    }, [url]);

    useEffect(() => {

        try {

            webapis.avplay.setListener({
                onbufferingstart: onBuffer,
                onbufferingcomplete: onBufferEnd,
                onstreamcompleted: () => {

                    if (mediatype == "live" || loop) {
                        initPlayer();
                    } else {
                        onEnded();
                    }

                },
                oncurrentplaytime: (time) => {

                    let playedSeconds = parseInt(time / 1000) || 0;

                    onProgress({ played: 0, playedSeconds, loaded: 0, loadedSeconds: 0 });

                    if (!duration && mediatype != "live") {

                        duration = parseInt(webapis.avplay.getDuration() / 1000) || 0;

                        if (duration) {
                            onDuration(duration);
                            onStart();
                        }

                    }

                },
                onerror: (type, data) => {
                    console.log("onerror: ", type, data);
                },
                onevent: (eventType, eventData) => {
                    // console.log("onevent: ", eventType, eventData);
                },
                onsubtitlechange: (duration, text) => {
                    window.dispatchEvent(new CustomEvent("onTizenSubtitlesChange", { detail: { duration, text } }));
                }

            });

        } catch (e) {
            console.log(e);
        }

    }, [url]);

    useEffect(() => {

        let interval = setInterval(() => {
            setPlayerState(webapis.avplay.getState());
        }, 200);

        window.addEventListener("disableTizenSubtitles", disableSubtitles);

        return () => {
            clearInterval(interval);
            webapis.avplay.close();
            window.removeEventListener("disableTizenSubtitles", disableSubtitles);
        };

    }, []);

    return <object style={{ width: "100%", height: "100%" }} type="application/avplayer"></object>

};

export default memo(forwardRef(TizenPlayer));