import { memo, useContext, useEffect, useState } from "react";

import ListView from "../../../components/listView/ListView";
import ColoredButton from "../components/coloredButton/ColoredButton";
import EpgItem from "../components/epgItem/EpgItem";
import { Context } from "../LiveTvPage";
import useKeydown from "../../../hooks/useKeydown";
import ChannelPlayer from "../components/channelPlayer/ChannelPlayer";
import { DeviceDate } from "@/src/utils/util";

const PlayerSection = () => {

    const {
        channelsContent,
        currentChannelId,
        currentCategoryId,
        moveItemIndex,
        currentSection,
        setCurrentSection,
        currentChannelsSection,
        epgList,
        currentDay
    } = useContext(Context);

    const isActive = currentSection == "playerSection";

    let { sort } = channelsContent;

    const [epgs, setEpgs] = useState([]);

    const [initialActiveIndex, setInitialActiveIndex] = useState(0);

    const clickButton = (keyCode) => () => {

        let data = { bubbles: true, cancelable: true, keyCode: keyCode }

        window.dispatchEvent(new KeyboardEvent("keydown", data));
        window.dispatchEvent(new KeyboardEvent("keyup", data));

    }

    useEffect(() => {

        if (epgList && epgList[currentDay]) {

            let now = new DeviceDate().getTime();

            let epgs = epgList[currentDay] || [];

            let index = 0;

            // find current epg
            for (let i = 0; i < epgs.length; i++) {

                let { start_unix, end_unix } = epgs[i];

                if (now > start_unix && now < end_unix) {
                    index = i;
                    break;
                }

            }

            setInitialActiveIndex(index);

            setEpgs(epgs);

        } else {
            setInitialActiveIndex(0);
            setEpgs([]);
        }

    }, [currentDay, epgList]);

    useKeydown({
        isActive,
        left: () => {
            setCurrentSection("channels");
        },
        right: () => {
            setCurrentSection("daysSection");
        },
        back: () => {
            setCurrentSection("channels");
        },
    });

    return (

        <div className="live-tv-section player-section">

            <div className="section-header focus-item">

                {currentSection == "channels" && currentSection == "channels" && <>

                    {currentCategoryId == "favorites" ?
                        <ColoredButton text={moveItemIndex == null ? "move" : "save"} color="red" onClick={clickButton(403)} /> :
                        <ColoredButton text="sort" icon={sort} color="red" onClick={clickButton(403)} />
                    }

                    <ColoredButton text="category" color="green" onClick={clickButton(404)} />
                    <ColoredButton text="favorites" color="yellow" onClick={clickButton(405)} />
                </>}

                <ColoredButton text="menu" color="blue" onClick={clickButton(406)} />
                <ColoredButton text="move" color="green" onClick={clickButton(404)} />
            </div>

            <div className="section-body">

                <div className="player-container">
                    <div className="player-parent">
                        <ChannelPlayer />
                    </div>
                </div>

                <div className="epg-parent">
                    <ListView
                        id={currentDay + "-" + currentChannelId + "-" + initialActiveIndex}
                        uniqueKey={"epg-list-view"}
                        listType="vertical"
                        itemsTotal={epgs.length || 4}
                        itemsCount={4}
                        buffer={2}
                        initialActiveIndex={initialActiveIndex}
                        startScrollIndex={1}
                        nativeControle={true}
                        debounce={100}
                        ItemRenderer={EpgItem}
                        itemWidth={79.5}
                        itemHeight={9.5}
                        isActive={isActive}
                    />

                </div>
            </div>

        </div>

    )

};

export default memo(PlayerSection);