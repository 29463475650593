import { memo, useContext } from "react";
import Button from "../../../../../../components/button/Button";
import { SearchContext } from "../Search";
import { backspaceBlack, backspaceWhite } from "../../../../../../assets/icons";
import { MoviesPageContext } from "../../../../MoviesPage";

import Text from "@/src/components/text/Text";

const KeyboardActions = () => {

    const {
        isActive,
        current,
        setCurrent,
        rowIndex,
        setRowIndex,
        parentMouseEnter
    } = useContext(SearchContext);

    const {
        searchQuery,
        setSearchQuery,
    } = useContext(MoviesPageContext);

    const isActiveActione = isActive && current == "actions";

    const items = ["space", "backspace", "clear"];

    const KeyItem = ({ item, index }) => {
        // const body = item === "backspace" ? <img src={isActive ? backspaceBlack : backspaceWhite} /> : item;

        const isActive = isActiveActione && rowIndex === index;

        switch (item) {
            case "backspace": return <img src={isActive ? backspaceBlack : backspaceWhite} />;
            case "space": return <Text>space</Text>
            case "clear": return <Text>clear</Text>
        }

        return item;
    }

    const onClick = (e, item) => {

        switch (item) {
            case "space":
                let query = searchQuery.trim();
                if (!query) return;
                setSearchQuery(query + " ");
                break;
            case "backspace":
                setSearchQuery(searchQuery.slice(0, -1));
                break;
            case "clear":
                setSearchQuery("");
                break;
        }

    };

    const mouseEnter = (e, item) => {
        parentMouseEnter();
        setRowIndex(items.indexOf(item));
        setCurrent("actions");
    };

    return (
        <div className="search-row">
            <div className="movies-search-items">

                {items.map((item, index) => {

                    const isActive = isActiveActione && rowIndex === index;

                    // const body = item === "backspace" ? <img src={isActive ? backspaceBlack : backspaceWhite} /> : item;

                    return (
                        <Button
                            key={"movies-action-item-" + index}
                            className="focus-item large "
                            isActive={isActive}
                            isSelected={false}
                            data={item}
                            onClick={onClick}
                            onMouseEnter={mouseEnter}
                        >
                            <KeyItem item={item} index={index} />
                        </Button>
                    )

                })}

            </div>
        </div>
    )

};

export default memo(KeyboardActions);