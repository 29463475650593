import xmltojson from "xmltojson";
import { Base64Decode, FormatDate, isAdultTitle } from "../../utils/util";
import XTREAM from "../api/xtream";
import Storege from "../storage/storage";

// import Worker from "./parser.worker.js";

// const ParesChannelsWithWorker = (data, callback) => {

//     const worker = new Worker();

//     worker.postMessage(["ParseChannelsContent", data]);

//     worker.onmessage = (event) => {

//         let [eventName, data] = event.data;

//         if (eventName == "ParseChannelsContent") {
//             callback(data);
//             worker.terminate();
//         }

//     };

//     worker.onerror = (event) => { console.log(event); };

// };

// const ParesMoviesWithWorker = (data, callback) => {

//     const worker = new Worker();

//     worker.postMessage(["ParseMoviesContent", data]);

//     worker.onmessage = (event) => {

//         let [eventName, data] = event.data;

//         if (eventName == "ParseMoviesContent") {
//             callback(data);
//             worker.terminate();
//         }

//     };

//     worker.onerror = (event) => { console.log(event); };

// };

// const ParesCategoriesWithWorker = (data, callback) => {

//     const worker = new Worker();

//     worker.postMessage(["ParseCategories", data]);

//     worker.onmessage = (event) => {

//         let [eventName, data] = event.data;

//         if (eventName == "ParseCategories") {
//             callback(data);
//             worker.terminate();
//         }

//     };

//     worker.onerror = (event) => { console.log(event); };

// };

// --------------------

// import TestWorker from "./test.worker.js";

// const RunWithWorker = (myFunction, argumnts) => {

//     return new Promise((resolve, reject) => {

//         const worker = new TestWorker();

//         worker.onmessage = ({ data }) => {
//             resolve(data);
//         };

//         worker.onerror = (err) => {
//             reject(err.message)
//         };

//         worker.postMessage([myFunction.toString(), argumnts]);

//     });

// }

// const TestFunction = (data) => {
//     for (let i = 0; i < 10000000000; i++) { }
//     return "JSON.stringify(data)";
// };

// const TestFunctionData = [];

// RunWithWorker(TestFunction, TestFunctionData)
//     .then((data) => {
//         console.log("data resived", data);
//     })
//     .catch((err) => {
//         console.log(err);
//     });

const ParesChannelsWithWorker = (data, callback) => {

    let { items_arr, items_obj, categories_arr, categories_obj, isXtream } = data;

    if (isXtream) {

        let max_num = 0;

        let radios = [];

        for (let i = 0; i < items_arr.length; i++) {

            let channel = items_arr[i] || {};

            if (channel.stream_type == "live") {
                if (channel.num > max_num) max_num = channel.num;
            } else if (channel.stream_type == "radio_streams") {
                radios.push(channel);
            }

        }

        for (let i = 0; i < radios.length; i++) {

            if (radios[i]) {
                max_num++;
                radios[i].num = max_num;
            }

        }

    }

    for (let i = 0; i < items_arr.length; i++) {

        let channel = items_arr[i] || {};

        let num = channel.num || i + 1;
        let name = channel.name || "Channel " + num;
        let id = isXtream ? "" + (channel.stream_id || i + 1) : "ch_" + num;
        let logo = channel.stream_icon || channel.logo || "";
        let epg_id = channel.epg_channel_id || "";
        let tv_archive = channel.tv_archive || 0;
        let category_id = "" + (channel.category_id || channel.group || "Other");
        let url = channel.url || "";
        let stream_type = channel.stream_type || "";

        channel = { id, num, name, url, isXtream, logo, epg_id, tv_archive, category_id, stream_type };

        if (!categories_obj[category_id]) {

            categories_obj[category_id] = { id: category_id, name: category_id, items: [] };

            categories_arr.push(category_id);

        }

        categories_obj[category_id].items.push(id);

        items_obj[id] = channel;
        items_arr[i] = id;

    }

    callback({ items_arr, items_obj, categories_arr, categories_obj });

};

const ParesMoviesWithWorker = (data, callback) => {

    let { items_arr, items_obj, categories_arr, categories_obj, isXtream, isSeries } = data;

    for (let i = 0; i < items_arr.length; i++) {

        let movie = items_arr[i] || {};

        let num = movie.num || i + 1;
        let name = movie.name || movie.title || "";
        if (!isXtream) name = name.replace("#EXTINF:0,", "");

        let id = isXtream ? "" + (movie.stream_id || movie.series_id) : "movie_" + num;
        let poster = movie.stream_icon || movie.cover || movie.logo || "";
        let category_id = "" + (movie.category_id || movie.group || "Other");

        let year = movie.year || movie.releaseDate || movie.releasedate || movie.release_date || "";
        if (year) year = year.split("-")[0];

        let added = movie.added || 0;
        let seasons = movie.seasons || {};

        let rating = parseInt(movie.rating || 0) || 0;

        if (!rating) {
            rating = parseInt(movie.rating_5based || 0) || 0;
            rating = rating * 2;
        }

        let url = movie.url || "";

        movie = { num, name, id, poster, category_id, added, year, rating, isXtream };

        if (isSeries && !isXtream) movie.seasons = seasons;

        if (!isSeries && !isXtream) movie.url = url;

        if (!categories_obj[category_id]) {

            categories_obj[category_id] = { id: category_id, name: category_id, items: [] };

            categories_arr.push(category_id);

        }

        categories_obj[category_id].items.push(id);

        items_obj[id] = movie;
        items_arr[i] = id;

    }

    callback({ items_arr, items_obj, categories_arr, categories_obj });
};

const ParesCategoriesWithWorker = (data, callback) => {

    let { categories_arr, categories_obj } = data;

    for (let i = 0; i < categories_arr.length; i++) {

        let num = i + 1;

        let name = categories_arr[i].category_name || categories_arr[i] || "Category " + num;

        let id = "" + (categories_arr[i].category_id || name);

        categories_arr[i] = id;

        categories_obj[id] = { id, name, num, items: [] };

    }

    // fic duplicate categories
    let filtered = [];

    for (let i = 0; i < categories_arr.length; i++) {

        if (filtered.indexOf(categories_arr[i]) == -1) filtered.push(categories_arr[i]);

    }

    categories_arr = filtered;

    callback({ categories_arr, categories_obj });

}

// --------------------

export const ParseChannelsContent = async (channels = [], categories = [], isXtream = true) => {

    return new Promise((resolve, reject) => {

        let items_arr = channels;
        let items_obj = {};
        let categories_arr = categories;
        let categories_obj = {};

        ParesCategoriesWithWorker({ categories_arr, categories_obj }, (payload) => {

            let { categories_arr, categories_obj } = payload;

            ParesChannelsWithWorker({ items_arr, items_obj, categories_arr, categories_obj, isXtream }, (payload) => {

                let { items_arr, items_obj, categories_arr, categories_obj } = payload;

                let favorites = Storege.getFavorites("channels");

                // remove favorites items that no longer exist
                for (let i = 0; i < favorites.length; i++) {

                    let id = favorites[i];

                    if (!items_obj[id]) {
                        favorites.splice(i, 1);
                        i--;
                    }

                }

                categories_obj["search"] = { id: "search", name: "Search", items: [], searchValue: "" };
                categories_obj["favorites"] = { id: "favorites", name: "Favorites", items: favorites };
                categories_obj["all"] = { id: "all", name: "All", items: items_arr };

                categories_arr.unshift("search");
                categories_arr.unshift("favorites");
                categories_arr.unshift("all");

                SetCategoryConfigs(categories_obj, categories_arr, "channels");

                resolve({ items_arr, items_obj, categories_arr, categories_obj });

            });

        });

    });

};

export const ParseMoviesContent = (movies = [], categories = [], isSeries = false, isXtream = true) => {

    return new Promise((resolve, reject) => {

        let items_arr = movies;
        let items_obj = {};
        let categories_arr = categories;
        let categories_obj = {};

        ParesCategoriesWithWorker({ categories_arr, categories_obj }, (payload) => {

            let { categories_arr, categories_obj } = payload;

            ParesMoviesWithWorker({ items_arr, items_obj, categories_arr, categories_obj, isSeries, isXtream }, (payload) => {

                let { items_arr, items_obj, categories_arr, categories_obj } = payload;

                const continueWatching = Storege.getContinueWatching(isSeries ? "series" : "movies");

                const favorites = Storege.getFavorites(isSeries ? "series" : "movies");

                // remove continueWatching items that no longer exist
                for (let i = 0; i < continueWatching.items.length; i++) {

                    let id = continueWatching.items[i];

                    if (!items_obj[id]) {
                        continueWatching.items.splice(i, 1);
                        delete continueWatching.items_obj[id];
                        i--;
                    }

                }

                // remove favorites items that no longer exist
                for (let i = 0; i < favorites.length; i++) {

                    let id = favorites[i];

                    if (!items_obj[id]) {
                        favorites.splice(i, 1);
                        i--;
                    }

                }

                categories_obj["continue"] = {
                    id: "continue",
                    name: "Continue Watching",
                    items: continueWatching.items,
                    items_obj: continueWatching.items_obj,
                };
                categories_obj["all"] = { id: "all", name: "All", items: items_arr };

                categories_obj["favorites"] = {
                    id: "favorites",
                    name: "Favorites",
                    items: favorites
                };

                categories_obj["recently_added"] = {id: "recently_added", name: "Recently Added", items: items_arr.slice(0, 10)};

                
                categories_arr.unshift("favorites");
                
                categories_arr.unshift("all");
                
                categories_arr.unshift("recently_added");
                
                categories_arr.unshift("continue");

                SetCategoryConfigs(categories_obj, categories_arr, isSeries ? "series" : "movies");

                resolve({ items_arr, items_obj, categories_arr, categories_obj });

            });

        });

    });

};

export const SaveCategoriesConfigs = (categories_arr, categories_obj, type) => {

    let config = {};

    for (let i = 0; i < categories_arr.length; i++) {

        let id = categories_arr[i];

        let category = categories_obj[id];

        let { isHidden, isLocked } = category;

        config[id] = { position: i, isHidden, isLocked };

    }

    Storege.setCategoriesConfigs(type, config);

}

const SetCategoryConfigs = (categories_obj, categories_arr, type) => {

    // console.time("SetCategoryConfigs")
    const configs = Storege.getCategoriesConfigs(type);

    for (let i = 0; i < categories_arr.length; i++) {

        let id = categories_arr[i];

        const config = configs[id] || { isLocked: isAdultTitle(categories_obj[id].name), isHidden: false, position: i };

       let position = config.position == undefined ? i : config.position;

        categories_obj[id].position = position;

        categories_obj[id].isLocked = config.isLocked || false;

        categories_obj[id].isHidden = config.isHidden || false;

    }

    
    // sort categories by position
    categories_arr.sort((a, b) => {

        let positionA = categories_obj[a].position;
        let positionB = categories_obj[b].position;
        
        if (positionA < positionB) {
            return -1;
        } else if (positionA > positionB) {
            return 1;
        } else {
            return 0;
        }

    });

    // console.timeEnd("SetCategoryConfigs");

}

export const ParseEpgList = (epgList = {}, channelId, isXtream) => {

    let epg_obj = {};

    let current = null;

    if (isXtream) {

        if (epgList.epg_listings) {

            let programmes = epgList.epg_listings;

            programmes.sort((a, b) => a.start_timestamp - b.start_timestamp);

            let prev = null;
            let merged = [];

            // merge duplicate programmes
            for (let i = 0; i < programmes.length; i++) {

                let prog = programmes[i];

                if (prev && prev.stop_timestamp > prog.start_timestamp) {

                    // console.log("merge Program");

                    prev.stop_timestamp = prog.stop_timestamp;
                    prev.title = prev.title || prog.title;
                    prev.desc = prev.desc || prog.desc;

                } else if (prev && prev.title == prog.title) {

                    // console.log("merge duplicate title Program");

                    prev.stop_timestamp = prog.stop_timestamp;

                } else {

                    prev = prog;
                    merged.push(prog);

                }

            }
            // end merge

            let prev_epg = null

            for (let i = 0; i < merged.length; i++) {

                try {

                    let {
                        title,
                        description,
                        start_timestamp,
                        stop_timestamp,
                        has_archive,
                        now_playing,
                    } = merged[i];

                    title = Base64Decode(title);
                    description = Base64Decode(description);

                    let start_unix = start_timestamp * 1000;
                    let end_unix = stop_timestamp * 1000;

                    let start = FormatDate(start_unix, "hh:mm");
                    let end = FormatDate(end_unix, "hh:mm");

                    let date = new Date(start_unix).getDate();

                    if (!epg_obj[date]) epg_obj[date] = [];

                    let url = "";

                    if (isXtream) {

                        let duration = parseInt((stop_timestamp - start_timestamp) / 60);

                        let offset = new Date().getTimezoneOffset() * 60000;

                        let startStr = FormatDate(start_unix + offset, "yyyy-MM-dd:hh-mm");

                        url = XTREAM.getEpgUrl(channelId, startStr, duration);

                    }

                    let epg = {
                        title, description,
                        start, end,
                        start_unix, end_unix,
                        has_archive,
                        prev_epg, next_epg: null,
                        url
                    }

                    if (prev_epg) prev_epg.next_epg = epg;

                    prev_epg = epg;

                    epg_obj[date].push(epg);

                    if (now_playing) current = epg;

                } catch (e) {
                    console.log(e)
                }

            }

        }

    }

    return [epg_obj, current];

};

export const getXMLEpg = async (url) => {

    return new Promise((resolve, reject) => {

        const req = new XMLHttpRequest();

        req.timeout = 120000;

        req.onreadystatechange = function () {

            if (req.readyState == 4) {

                try {

                    if (req.status == 200) {

                        return resolve(ParseXMLEpg(req.responseText));

                    } else {
                        return reject("network error");
                    }

                } catch (e) {
                    console.log(e);
                    return reject("request error");
                }

            }

        }

        req.onerror = function (err) {
            reject(err.message);
        }

        req.ontimeout = function () {
            reject("request timeout");
        }

        req.open("GET", url);

        req.send();

    });

};

export const ParseXMLEpg = (xmldata) => {

    function parseXMLEpgTime(str) {

        let year = str.substr(0, 4);
        let month = str.substr(4, 2);
        let day = str.substr(6, 2);
        let hour = str.substr(8, 2);
        let minute = str.substr(10, 2);
        let second = str.substr(12, 2);
        let timezone = str.substr(14, 5);

        return new Date(`${year}-${month}-${day} ${hour}:${minute}:${second} ${timezone}`);

    }

    let { tv } = xmltojson.parseString(xmldata);

    let { channel, programme } = tv[0];

    let channels = {};

    for (let i = 0; i < channel.length; i++) {

        try {

            let id = channel[i]["_attr"]["id"]["_value"];
            let name = channel[i]["display-name"][0]["_text"];

            channels[id] = { name, programmes: [] };

        } catch (e) {
            console.log(e)
        }

    }

    let min_unix = null;
    let max_unix = null;

    for (let i = 0; i < programme.length; i++) {

        try {

            let prog = programme[i];

            let chId = prog["_attr"]["channel"]["_value"];

            let start = parseXMLEpgTime(prog["_attr"]["start"]["_value"]);
            let end = parseXMLEpgTime(prog["_attr"]["stop"]["_value"]);

            let title = prog["title"][0]["_text"];
            let desc = prog["desc"][0]["_text"];

            if (Array.isArray(title)) title = title[0]?.["_text"] || title[0] || "";
            if (Array.isArray(desc)) desc = desc[0]?.["_text"] || desc[0] || "";

            let start_unix = start.getTime();
            let end_unix = end.getTime();

            if (!min_unix || start_unix < min_unix) min_unix = start_unix;
            if (!max_unix || end_unix > max_unix) max_unix = end_unix;

            channels[chId]?.programmes.push({ title, desc, start_unix, end_unix });

        } catch (e) {
            console.log(e)
        }

    }

    if (min_unix && max_unix) {
        min_unix = new Date(min_unix).setHours(0, 0, 0, 0);
        max_unix = new Date(max_unix).setHours(23, 59, 59, 999) + 1;
    } else {
        min_unix = new Date().setHours(0, 0, 0, 0);
        max_unix = new Date().setHours(23, 59, 59, 999) + 1;
    }

    let programs = {};

    for (let id in channels) {

        let { name, programmes } = channels[id];

        programmes.sort((a, b) => a.start_unix - b.start_unix);

        let prev = null;
        let merged = [];

        for (let i = 0; i < programmes.length; i++) {

            let prog = programmes[i];

            if (prev && prev.end_unix > prog.start_unix) {

                // console.log("merge Program");

                prev.end_unix = prog.end_unix;
                prev.title = prev.title || prog.title;
                prev.desc = prev.desc || prog.desc;

            } else if (prev && prev.title == prog.title) {

                // console.log("merge duplicate title Program");

                prev.end_unix = prog.end_unix;

            } else if (prev && prev.end_unix != prog.start_unix) {

                // console.log("fill empty Program");

                merged.push({
                    title: "No Program",
                    desc: "",
                    start_unix: prev.end_unix,
                    end_unix: prog.start_unix
                });

                prev = prog;
                merged.push(prog);

            } else {

                prev = prog;
                merged.push(prog);

            }

        }

        if (merged.length) {

            let first = merged[0];
            let last = merged[merged.length - 1];

            let fill_first = [];
            let fill_last = [];

            if (first.start_unix > min_unix) {

                for (let i = min_unix; i < first.start_unix; i += 3600000) {

                    let end_unix = i + 3600000;

                    if (end_unix > first.start_unix) end_unix = first.start_unix;

                    fill_first.push({
                        title: "No Program",
                        desc: "",
                        start_unix: i,
                        end_unix
                    });

                }

            }

            if (last.end_unix < max_unix) {

                for (let i = max_unix; i > last.end_unix; i -= 3600000) {

                    let start_unix = i - 3600000;

                    if (start_unix < last.end_unix) start_unix = last.end_unix;

                    fill_last.unshift({
                        title: "No Program",
                        desc: "",
                        start_unix,
                        end_unix: i
                    });

                }

            }

            merged = [...fill_first, ...merged, ...fill_last];

        } else {

            for (let i = min_unix; i < max_unix; i += 3600000) {

                merged.push({
                    title: "No Program",
                    desc: "",
                    start_unix: i,
                    end_unix: i + 3600000
                });

            }

        }

        programs[name] = merged;

    }

    return { programs, min_unix, max_unix };

};