import { memo, useCallback, useContext } from "react";
import Button from "../../../../components/button/Button";
import Text from "../../../../components/text/Text";
import { SettingsContext } from "../../SettingsPage";
import "./SettingsItem.scss";
import Toggle from "../../../../components/toggle/Toggle";

const SettingsItem = ({
    isActive,
    index,
    style,
    onMouseEnter = () => { }
}) => {

    const {
        items,
        selectedItem,
        selectItem
    } = useContext(SettingsContext);

    const item = items[index];

    const mouseEnter = useCallback((e, index) => {
        onMouseEnter(index);
    }, [selectItem]);

    const onClick = useCallback((e, index) => {
        selectItem(index);
    }, [selectItem]);

    return (
        <div className="settings-item-parent" style={style}>
            <Button
                className="settings-item focus-item"
                isActive={isActive}
                isSelected={selectedItem === index}
                onMouseEnter={mouseEnter}
                onClick={onClick}
                data={index}
            >
                <div className="title">
                    <Text>{item.name}</Text>
                </div>
                {item.checked !== undefined ? <Toggle isActive={isActive} checked={item.checked} /> : null}
            </Button>
        </div>
    )

}

export default memo(SettingsItem);