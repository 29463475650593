import { memo, useEffect, useState } from "react";
import useKeydown from "../../../../hooks/useKeydown";
import Button from "../../../../components/button/Button";
import Text from "../../../../components/text/Text";
import "./ClearStorage.scss";
import Storege from "../../../../services/storage/storage";
import useStore from "@/src/hooks/useStore";

const ClearStorage = ({
    isActive = false,
    onBack = () => { },
    onMouseEnter = () => { }
}) => {

    const [selectedItem, setSelectedItem] = useState(null);

    const [activeIndex, setActiveIndex] = useState(0);

    const [channelsContent, setChannelsContent] = useStore("channelsContent");
    const [moviesContent, setMoviesContent] = useStore("moviesContent");
    const [seriesContent, setSeriesContent] = useStore("seriesContent");

    let favChan = channelsContent?.categories_obj?.favorites?.items || [];
    let faveMov = moviesContent?.categories_obj?.favorites?.items || [];
    let favSer = seriesContent?.categories_obj?.favorites?.items || [];
    let watMov = moviesContent?.categories_obj?.continue?.items || [];
    let watSer = seriesContent?.categories_obj?.continue?.items || [];

    const items = [
        { title: "favorite channels", key: "favorite_channels", count: favChan.length },
        { title: "favorite movies", key: "favorite_movies", count: faveMov.length },
        { title: "favorite series", key: "favorite_series", count: favSer.length },
        { title: "watched movies", key: "watched_movies", count: watMov.length },
        { title: "watched series", key: "watched_series", count: watSer.length },
        { title: "clear all", key: "clear_all", count: favChan.length + faveMov.length + favSer.length + watMov.length + watSer.length },
    ];

    const onClick = (e, index) => {

        let { key, count } = items[index];

        if (!count) return;

        const clearFavChan = () => {
            Storege.setFavorites("channels", []);
            channelsContent.categories_obj.favorites.items = [];
            setChannelsContent({ ...channelsContent });
        }

        const clearFavMov = () => {
            Storege.setFavorites("movies", []);
            moviesContent.categories_obj.favorites.items = [];
            setMoviesContent({ ...moviesContent });
        }

        const clearFavSer = () => {
            Storege.setFavorites("series", []);
            seriesContent.categories_obj.favorites.items = [];
            setSeriesContent({ ...seriesContent });
        }

        const clearWatMov = () => {
            Storege.setContinueWatching("movies", { items: [], items_obj: {} });
            moviesContent.categories_obj.continue.items = [];
            moviesContent.categories_obj.continue.items_obj = {};
            setMoviesContent({ ...moviesContent });
        }

        const clearWatSer = () => {
            Storege.setContinueWatching("series", { items: [], items_obj: {} });
            seriesContent.categories_obj.continue.items = [];
            seriesContent.categories_obj.continue.items_obj = {};
            setSeriesContent({ ...seriesContent });
        }

        if (selectedItem == index) {

            switch (key) {
                case "favorite_channels":
                    clearFavChan();
                    break;
                case "favorite_movies":
                    clearFavMov();
                    break;
                case "favorite_series":
                    clearFavSer();
                    break;
                case "watched_movies":
                    clearWatMov();
                    break;
                case "watched_series":
                    clearWatSer();
                    break;
                case "clear_all":
                    clearFavChan();
                    clearFavMov();
                    clearFavSer();
                    clearWatMov();
                    clearWatSer();
                    break;
            }

            setSelectedItem(null);
        } else {
            setSelectedItem(index);
        }

    };

    const mouseEnter = (e, index) => {
        setActiveIndex(index);
        onMouseEnter();
    };

    useEffect(() => {
        setSelectedItem(null);
    }, [activeIndex]);

    useKeydown({
        isActive,
        up: () => {
            if (activeIndex > 0) setActiveIndex(activeIndex - 1);
        },
        down: () => {
            if (activeIndex < items.length - 1) setActiveIndex(activeIndex + 1);
        },
        left: () => {
            onBack();
        },
        back: () => {
            onBack();
        },
    });

    return (
        <div className="settings-content">
            {items.map((item, index) => {
                return (
                    <div className="settings-clear-storage-row" key={index}>
                        <div className="settings-clear-storage-title">
                            <Text>{item.title}</Text> {item.count ? item.count : ""}
                        </div>
                        <Button
                            className={"focus-item clear-storage-btn" + (item.count ? "" : " disabled")}
                            isActive={isActive && activeIndex == index}
                            activeClassName={selectedItem == index ? "active-danger" : "active"}
                            onClick={onClick}
                            data={index}
                            onMouseEnter={mouseEnter}
                        >
                            <Text>{selectedItem == index ? "confirm" : "clear"}</Text>
                        </Button>
                    </div>
                )
            })}
        </div>
    )

};

export default memo(ClearStorage);