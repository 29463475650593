const lng = {
    "aa": "Afar",
    "aar": "Afar",
    "ab": "Abkhazian",
    "abk": "Abkhazian",
    "af": "Afrikaans",
    "afr": "Afrikaans",
    "ak": "Akan",
    "aka": "Akan",
    "sq": "Albanian",
    "alb": "Albanian",
    "am": "Amharic",
    "amh": "Amharic",
    "ar": "Arabic",
    "ara": "Arabic",
    "an": "Aragonese",
    "arg": "Aragonese",
    "hy": "Armenian",
    "arm": "Armenian",
    "as": "Assamese",
    "asm": "Assamese",
    "av": "Avaric",
    "ava": "Avaric",
    "ae": "Avestan",
    "ave": "Avestan",
    "ay": "Aymara",
    "aym": "Aymara",
    "az": "Azerbaijani",
    "aze": "Azerbaijani",
    "ba": "Bashkir",
    "bak": "Bashkir",
    "bm": "Bambara",
    "bam": "Bambara",
    "eu": "Basque",
    "baq": "Basque",
    "be": "Belarusian",
    "bel": "Belarusian",
    "bn": "Bengali",
    "ben": "Bengali",
    "bh": "Bihari languages",
    "bih": "Bihari languages",
    "bi": "Bislama",
    "bis": "Bislama",
    "bs": "Bosnian",
    "bos": "Bosnian",
    "br": "Breton",
    "bre": "Breton",
    "bg": "Bulgarian",
    "bul": "Bulgarian",
    "my": "Burmese",
    "bur": "Burmese",
    "ca": "Catalan; Valencian",
    "cat": "Catalan; Valencian",
    "ch": "Chamorro",
    "cha": "Chamorro",
    "ce": "Chechen",
    "che": "Chechen",
    "zh": "Chinese",
    "zh-CN": "Chinese (Simplified)",
    "zh-TW": "Chinese (Traditional)",
    "chi": "Chinese",
    "cu": "Church Slavic",
    "chu": "Church Slavic",
    "cv": "Chuvash",
    "chv": "Chuvash",
    "kw": "Cornish",
    "cor": "Cornish",
    "co": "Corsican",
    "cos": "Corsican",
    "cr": "Cree",
    "cre": "Cree",
    "cs": "Czech",
    "cze": "Czech",
    "da": "Danish",
    "dan": "Danish",
    "dv": "Divehi; Dhivehi; Maldivian",
    "div": "Divehi; Dhivehi; Maldivian",
    "nl": "Dutch; Flemish",
    "dut": "Dutch; Flemish",
    "dz": "Dzongkha",
    "dzo": "Dzongkha",
    "en": "English",
    "eng": "English",
    "eo": "Esperanto",
    "epo": "Esperanto",
    "et": "Estonian",
    "est": "Estonian",
    "ee": "Ewe",
    "ewe": "Ewe",
    "fo": "Faroese",
    "fao": "Faroese",
    "fj": "Fijian",
    "fij": "Fijian",
    "fi": "Finnish",
    "fin": "Finnish",
    "fr": "French",
    "fre": "French",
    "fy": "Western Frisian",
    "fry": "Western Frisian",
    "ff": "Fulah",
    "ful": "Fulah",
    "ka": "Georgian",
    "geo": "Georgian",
    "de": "German",
    "ger": "German",
    "gd": "Gaelic; Scottish Gaelic",
    "gla": "Gaelic; Scottish Gaelic",
    "ga": "Irish",
    "gle": "Irish",
    "gl": "Galician",
    "glg": "Galician",
    "gv": "Manx",
    "glv": "Manx",
    "el": "Greek",
    "gre": "Greek",
    "gn": "Guarani",
    "grn": "Guarani",
    "gu": "Gujarati",
    "guj": "Gujarati",
    "ht": "Haitian; Haitian Creole",
    "hat": "Haitian; Haitian Creole",
    "ha": "Hausa",
    "hau": "Hausa",
    "he": "Hebrew",
    "heb": "Hebrew",
    "hz": "Herero",
    "her": "Herero",
    "hi": "Hindi",
    "hin": "Hindi",
    "ho": "Hiri Motu",
    "hmo": "Hiri Motu",
    "hr": "Croatian",
    "hrv": "Croatian",
    "hu": "Hungarian",
    "hun": "Hungarian",
    "ig": "Igbo",
    "ibo": "Igbo",
    "is": "Icelandic",
    "ice": "Icelandic",
    "io": "Ido",
    "ido": "Ido",
    "ii": "Sichuan Yi; Nuosu",
    "iii": "Sichuan Yi; Nuosu",
    "iu": "Inuktitut",
    "iku": "Inuktitut",
    "ie": "Interlingue; Occidental",
    "ile": "Interlingue; Occidental",
    "ia": "Interlingua",
    "ina": "Interlingua",
    "id": "Indonesian",
    "ind": "Indonesian",
    "ik": "Inupiaq",
    "ipk": "Inupiaq",
    "it": "Italian",
    "ita": "Italian",
    "jv": "Javanese",
    "jav": "Javanese",
    "ja": "Japanese",
    "jpn": "Japanese",
    "kl": "Kalaallisut; Greenlandic",
    "kal": "Kalaallisut; Greenlandic",
    "kn": "Kannada",
    "kan": "Kannada",
    "ks": "Kashmiri",
    "kas": "Kashmiri",
    "kr": "Kanuri",
    "kau": "Kanuri",
    "kk": "Kazakh",
    "kaz": "Kazakh",
    "km": "Central Khmer",
    "khm": "Central Khmer",
    "ki": "Kikuyu; Gikuyu",
    "kik": "Kikuyu; Gikuyu",
    "rw": "Kinyarwanda",
    "kin": "Kinyarwanda",
    "ky": "Kirghiz; Kyrgyz",
    "kir": "Kirghiz; Kyrgyz",
    "kv": "Komi",
    "kom": "Komi",
    "kg": "Kongo",
    "kon": "Kongo",
    "ko": "Korean",
    "kor": "Korean",
    "kj": "Kuanyama; Kwanyama",
    "kua": "Kuanyama; Kwanyama",
    "ku": "Kurdish",
    "kur": "Kurdish",
    "lo": "Lao",
    "lao": "Lao",
    "la": "Latin",
    "lat": "Latin",
    "lv": "Latvian",
    "lav": "Latvian",
    "li": "Limburgan",
    "lim": "Limburgan",
    "ln": "Lingala",
    "lin": "Lingala",
    "lt": "Lithuanian",
    "lit": "Lithuanian",
    "lb": "Luxembourgish; Letzeburgesch",
    "ltz": "Luxembourgish; Letzeburgesch",
    "lu": "Luba-Katanga",
    "lub": "Luba-Katanga",
    "lg": "Ganda",
    "lug": "Ganda",
    "mk": "Macedonian",
    "mac": "Macedonian",
    "mh": "Marshallese",
    "mah": "Marshallese",
    "ml": "Malayalam",
    "mal": "Malayalam",
    "mi": "Maori",
    "mao": "Maori",
    "mr": "Marathi",
    "mar": "Marathi",
    "ms": "Malay",
    "may": "Malay",
    "mg": "Malagasy",
    "mlg": "Malagasy",
    "mt": "Maltese",
    "mlt": "Maltese",
    "mn": "Mongolian",
    "mon": "Mongolian",
    "na": "Nauru",
    "nau": "Nauru",
    "nv": "Navajo; Navaho",
    "nav": "Navajo; Navaho",
    "nr": "Ndebele, South",
    "nbl": "Ndebele, South",
    "nd": "Ndebele, North",
    "nde": "Ndebele, North",
    "ng": "Ndonga",
    "ndo": "Ndonga",
    "ne": "Nepali",
    "nep": "Nepali",
    "nn": "Norwegian Nynorsk",
    "nno": "Norwegian Nynorsk",
    "nb": "Bokmål, Norwegian",
    "nob": "Bokmål, Norwegian",
    "no": "Norwegian",
    "nor": "Norwegian",
    "ny": "Chichewa; Chewa; Nyanja",
    "nya": "Chichewa; Chewa; Nyanja",
    "oc": "Occitan",
    "oci": "Occitan",
    "oj": "Ojibwa",
    "oji": "Ojibwa",
    "or": "Oriya",
    "ori": "Oriya",
    "om": "Oromo",
    "orm": "Oromo",
    "os": "Ossetian; Ossetic",
    "oss": "Ossetian; Ossetic",
    "pa": "Panjabi; Punjabi",
    "pan": "Panjabi; Punjabi",
    "fa": "Persian",
    "per": "Persian",
    "pi": "Pali",
    "pli": "Pali",
    "pl": "Polish",
    "pol": "Polish",
    "pt": "Portuguese",
    "por": "Portuguese",
    "pt-PT": "Portuguese",
    "pt-BR": "Portuguese (Brazil)",
    "ps": "Pushto; Pashto",
    "pus": "Pushto; Pashto",
    "qu": "Quechua",
    "que": "Quechua",
    "rm": "Romansh",
    "roh": "Romansh",
    "ro": "Romanian",
    "rum": "Romanian",
    "rn": "Rundi",
    "run": "Rundi",
    "ru": "Russian",
    "rus": "Russian",
    "sg": "Sango",
    "sag": "Sango",
    "sa": "Sanskrit",
    "san": "Sanskrit",
    "si": "Sinhala; Sinhalese",
    "sin": "Sinhala; Sinhalese",
    "sk": "Slovak",
    "slo": "Slovak",
    "sl": "Slovenian",
    "slv": "Slovenian",
    "se": "Northern Sami",
    "sme": "Northern Sami",
    "sm": "Samoan",
    "smo": "Samoan",
    "sn": "Shona",
    "sna": "Shona",
    "sd": "Sindhi",
    "snd": "Sindhi",
    "so": "Somali",
    "som": "Somali",
    "st": "Sotho, Southern",
    "sot": "Sotho, Southern",
    "es": "Spanish; Castilian",
    "spa": "Spanish; Castilian",
    "sc": "Sardinian",
    "srd": "Sardinian",
    "sr": "Serbian",
    "srp": "Serbian",
    "ss": "Swati",
    "ssw": "Swati",
    "su": "Sundanese",
    "sun": "Sundanese",
    "sw": "Swahili",
    "swa": "Swahili",
    "sv": "Swedish",
    "swe": "Swedish",
    "ty": "Tahitian",
    "tah": "Tahitian",
    "ta": "Tamil",
    "tam": "Tamil",
    "tt": "Tatar",
    "tat": "Tatar",
    "te": "Telugu",
    "tel": "Telugu",
    "tg": "Tajik",
    "tgk": "Tajik",
    "tl": "Tagalog",
    "tgl": "Tagalog",
    "th": "Thai",
    "tha": "Thai",
    "bo": "Tibetan",
    "tib": "Tibetan",
    "ti": "Tigrinya",
    "tir": "Tigrinya",
    "to": "Tonga",
    "ton": "Tonga",
    "tn": "Tswana",
    "tsn": "Tswana",
    "ts": "Tsonga",
    "tso": "Tsonga",
    "tk": "Turkmen",
    "tuk": "Turkmen",
    "tr": "Turkish",
    "tur": "Turkish",
    "tw": "Twi",
    "twi": "Twi",
    "ug": "Uighur",
    "uig": "Uighur",
    "uk": "Ukrainian",
    "ukr": "Ukrainian",
    "ur": "Urdu",
    "urd": "Urdu",
    "uz": "Uzbek",
    "uzb": "Uzbek",
    "ve": "Venda",
    "ven": "Venda",
    "vi": "Vietnamese",
    "vie": "Vietnamese",
    "vo": "Volapük",
    "vol": "Volapük",
    "cy": "Welsh",
    "wel": "Welsh",
    "wa": "Walloon",
    "wln": "Walloon",
    "wo": "Wolof",
    "wol": "Wolof",
    "xh": "Xhosa",
    "xho": "Xhosa",
    "yi": "Yiddish",
    "yid": "Yiddish",
    "yo": "Yoruba",
    "yor": "Yoruba",
    "za": "Zhuang; Chuang",
    "zha": "Zhuang; Chuang",
    "zu": "Zulu",
    "zul": "Zulu",
    "ea": "Ceuta, Melilla",
    "other": "Other"
}

export default lng;