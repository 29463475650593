import { memo, useCallback, useContext } from "react";
import Button from "../../../../../../components/button/Button";
import { SearchContext } from "../Search";
import { MoviesPageContext } from "../../../../MoviesPage";

const KeyboardItems = () => {

    const {
        isActive,
        types,
        type,
        current,
        setCurrent,
        itemsIndex,
        setItemsIndex,
        parentMouseEnter,
    } = useContext(SearchContext);

    const {
        searchQuery,
        setSearchQuery,
    } = useContext(MoviesPageContext);

    const isActiveItems = isActive && current == "items";

    const items = {
        "en": [
            ["a", "b", "c", "d", "e", "f"],
            ["g", "h", "i", "j", "k", "l"],
            ["m", "n", "o", "p", "q", "r"],
            ["s", "t", "u", "v", "w", "x"],
            ["y", "z", "0", "1", "2", "3"],
            ["4", "5", "6", "7", "8", "9"],
        ],
        "ar": [
            ["ض", "ص", "ث", "ق", "ف", "غ"],
            ["ع", "ه", "خ", "ح", "ج", "د"],
            ["ش", "س", "ي", "ب", "ل", "ا"],
            ["ت", "ن", "م", "ك", "ط", "ذ"],
            ["ئ", "ء", "ؤ", "ر", "لا", "ى"],
            ["ة", "و", "ز", "ظ", "[", "]"],
        ],
        "symbols": [
            ["!", "@", "#", "$", "%", "^"],
            ["&", "*", "(", ")", "-", "+"],
            ["_", "=", "{", "}", "[", "]"],
            [":", ";", "'", '"', "<", ">"],
            ["?", "/", "\\", "|", "~", "`"],
            [".", ",", "¢", "£", "€", "¥"],
        ]
    }

    let key = types[type].key;

    const onClick = useCallback((e, data) => {
        let [i, j] = data.split("-");
        let char = items[key][i][j];
        setSearchQuery(searchQuery + char);
    }, [searchQuery, key]);

    const mouseEnter = useCallback((e, data) => {
        let [i, j] = data.split("-");
        let index = (i * items[key][0].length) + parseInt(j);
        parentMouseEnter();
        setCurrent("items");
        setItemsIndex(index);
    }, []);

    return (
        <div className="search-row">

            {items[key].map((row, i) => {

                return (
                    <div
                        key={i}
                        className="movies-search-items"
                    >
                        {row.map((item, j) => {

                            let index = (i * row.length) + j;

                            return (
                                <Button
                                    key={"movies-keyboard-item-" + index}
                                    className="focus-item small"
                                    isActive={isActiveItems && itemsIndex === index}
                                    isSelected={false}
                                    data={i + "-" + j}
                                    onClick={onClick}
                                    onMouseEnter={mouseEnter}
                                >
                                    {item}
                                </Button>
                            )

                        })}
                    </div>
                )

            })}

        </div>
    )

}

export default memo(KeyboardItems);