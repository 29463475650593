

import { memo, useEffect, useState } from "react";
import "./PlayerSettings.scss";
import Storege from "../../../../services/storage/storage";
import useStore from "../../../../hooks/useStore";
import Button from "../../../../components/button/Button";
import useKeydown from "../../../../hooks/useKeydown";
import Text from "@/src/components/text/Text";

const PlayerSettings = ({
    isActive = false,
    onBack = () => { },
    onMouseEnter = () => { }
}) => {

    const [platform] = useStore("platform");

    const [channelPlayers, setChannelPlayers] = useState([]);
    const [vodPlayers, setVodPlayers] = useState([]);

    const [channelPlayer, setChannelPlayer] = useState(null);
    const [vodPlayer, setVodPlayer] = useState(null);

    const [activeIndex, setActiveIndex] = useState(0);

    const mouseEnter = (e, index) => {
        setActiveIndex(index);
        onMouseEnter();
    }

    useEffect(() => {

        if (platform?.model == "tizen") {

            let tizenPlayerSettings = Storege.getTizenPlayerSettings();

            let { channelPlayer, vodPlayer } = tizenPlayerSettings;

            let channelPlayers = [
                { title: "html5 player", value: "html5" },
                { title: "native player", value: "native" },
            ];

            let vodPlayers = [
                { title: "html5 player", value: "html5" },
                { title: "native player", value: "native" },
            ];

            setChannelPlayer(channelPlayer);
            setVodPlayer(vodPlayer);

            setChannelPlayers(channelPlayers);
            setVodPlayers(vodPlayers);

        } else if (platform?.model == "android") {

            let androidPlayerSettings = Storege.getAndroidPlayerSettings();

            let { channelPlayer, vodPlayer } = androidPlayerSettings;

            let channelPlayers = [
                { title: "exo player", value: "EXO" },
                { title: "vlc player", value: "VLC" },
            ];

            let vodPlayers = [
                { title: "exo player", value: "EXO" },
                { title: "vlc player", value: "VLC" },
            ];

            setChannelPlayer(channelPlayer);
            setVodPlayer(vodPlayer);

            setChannelPlayers(channelPlayers);
            setVodPlayers(vodPlayers);

        } else {
            onBack();
        }

    }, []);

    useEffect(() => {

        if (platform?.model == "tizen") {
            Storege.setTizenPlayerSettings({ channelPlayer, vodPlayer });
        } else if (platform?.model == "android") {
            Storege.setAndroidPlayerSettings({ channelPlayer, vodPlayer });
        }

    }, [vodPlayer, channelPlayer]);

    useKeydown({
        isActive,
        back: onBack,
        left: onBack,
        up: () => {
            if (activeIndex > 0) setActiveIndex(activeIndex - 1);
        },
        down: () => {
            let items = [...channelPlayers, ...vodPlayers];
            if (activeIndex < items.length - 1) setActiveIndex(activeIndex + 1);
        },
    })

    return (
        <div className="settings-content">

            <div className="player-settings-row">

                <div className="player-settings-title">
                    <Text>channel player</Text>
                </div>

                {
                    channelPlayers.map((item, index) => {

                        return (
                            <Button
                                key={index}
                                isActive={isActive && activeIndex === index}
                                isSelected={item.value == channelPlayer}
                                className="player-settings-item focus-item"
                                onClick={() => setChannelPlayer(item.value)}
                                data={index}
                                onMouseEnter={mouseEnter}
                            >
                                <Text>{item.title}</Text>
                            </Button>
                        )

                    })
                }
            </div>

            <div className="player-settings-row">

                <div className="player-settings-title">
                    <Text>vod player</Text>
                </div>

                {
                    vodPlayers.map((item, index) => {

                        return (
                            <Button
                                key={index}
                                isActive={isActive && activeIndex === index + channelPlayers.length}
                                isSelected={item.value == vodPlayer}
                                className="player-settings-item focus-item"
                                onClick={() => setVodPlayer(item.value)}
                                data={index + channelPlayers.length}
                                onMouseEnter={mouseEnter}
                            >
                                <Text>{item.title}</Text>
                            </Button>
                        )

                    })
                }

            </div>

        </div>
    );

};

export default memo(PlayerSettings);