import { memo, useCallback, useContext, useEffect, useState } from "react";
import { ManageCategoriesContext } from "./ManageCategoriesPopup";
import Button from "../../../../../components/button/Button";
import { EyeHiddenSVG, EyeSVG, LockedSVG, MoveVerticalSVG, UnLockedSVG } from "../../../../../components/svgIcons";
import useKeydown from "../../../../../hooks/useKeydown";
import Text from "../../../../../components/text/Text";
import MarqueeText from "@/src/components/marqueeText/MarqueeText";

const ManageCategoriesItem = ({
    isActive,
    style,
    index,
    onMouseEnter = () => { }
}) => {

    const {
        content,
        setContent,
        moveItemIndex,
        setMoveItemIndex,
    } = useContext(ManageCategoriesContext);

    const { categories_arr, categories_obj } = content;

    const categoryId = categories_arr[index];

    const category = categories_obj[categoryId];

    const [activeIndex, setActiveIndex] = useState(0);

    const isMove = moveItemIndex == index;

    const mouseEnter = useCallback((e, activeIndex) => {
        onMouseEnter(index);
        setActiveIndex(activeIndex);
    }, []);

    const infoButtons = [];
    const buttons = []

    if (category) {

        const { id, isHidden, isLocked } = category;

        buttons.push({
            key: "move",
            svg: <MoveVerticalSVG />,
            tooltip: "move"
        });

        if (["all", "search", "favorites", "continue"].indexOf(id) == -1) {

            buttons.push({
                key: "hide",
                svg: isHidden ? <EyeSVG /> : <EyeHiddenSVG />,
                tooltip: isHidden ? "show" : "hide"
            });

            buttons.push({
                key: "lock",
                svg: isLocked ? <UnLockedSVG /> : <LockedSVG />,
                tooltip: isLocked ? "unlock" : "lock"
            });

            infoButtons.push(isHidden ? <EyeHiddenSVG key="eye" /> : <EyeSVG key="eye" />);

            infoButtons.push(isLocked ? <LockedSVG key="lock" /> : <UnLockedSVG key="lock" />);

        }

    }

    const onClick = useCallback((e, activeIndex) => {

        if (!isMove) {

            if (activeIndex == 0) return;

            let _index = activeIndex - 1;

            let { key } = buttons[_index];

            switch (key) {
                case "move":
                    setMoveItemIndex(index);
                    break;
                case "hide":
                    let { isHidden } = category;
                    category.isHidden = !isHidden;
                    setContent({ ...content });
                    break;
                case "lock":
                    let { isLocked } = category;
                    category.isLocked = !isLocked;
                    setContent({ ...content });
                    break;
            }

        }

    }, [isMove]);

    useEffect(() => {

        if (!isActive) setActiveIndex(0);

    }, [activeIndex, isActive]);

    useKeydown({
        isActive,
        right: () => {
            if (activeIndex == buttons.length) return;
            setActiveIndex(activeIndex + 1);
        },
        left: () => {
            if (activeIndex == 0) return;
            setActiveIndex(activeIndex - 1);
        }
    });

    if (!category) return null;

    let parentClassName = "manage-categories-item-parent " + (index % 2 == 0 ? "even" : "odd");

    if (isActive) parentClassName += " active";

    if (isMove) parentClassName += " move";

    return (
        <div className={parentClassName} style={style}>

            <Button
                className="manage-categories-item focus-item"
                isActive={(isActive && activeIndex == 0) || isMove}
                isSelected={isActive && activeIndex != 0}
                data={0}
                onMouseEnter={mouseEnter}
                onClick={onClick}
            >

                <div className="manage-categories-item-title">
                    <MarqueeText isActive={(isActive && activeIndex == 0)}>
                        {category.name}
                    </MarqueeText>
                </div>

                {["all", "search", "favorites", "continue"].indexOf(category.id) == -1 ?
                    <div className="manage-categories-item-count">{category.items?.length || 0}</div>
                    : null}

                {infoButtons}

            </Button>

            {buttons.map((button, index) => {

                const { key, svg } = button;

                return (
                    <Button
                        key={index}
                        className={"manage-categories-item-actions focus-item " + key}
                        isActive={isActive && activeIndex == index + 1}
                        onMouseEnter={mouseEnter}
                        onClick={onClick}
                        data={index + 1}
                    >
                        {svg}
                    </Button>
                );
            })}


            {(activeIndex > 0 && isActive) ?
                <div className="manage-categories-item-tooltip">
                    <Text>{buttons[activeIndex - 1].tooltip}</Text>
                </div> : null}

            <div className="move-icon">
                <MoveVerticalSVG />
            </div>

        </div>

    );

}

export default memo(ManageCategoriesItem);