import { memo, forwardRef } from "react";
import ReactPlayer from "react-player";
import AndroidPlayer from "./AndroidPlayer";
import useStore from "@/src/hooks/useStore";
import TizenPlayer from "./TizenPlayer";
import VideoPlayer from "./VideoPlayer";
import Storege from "@/src/services/storage/storage";

const AppPlayer = (props, ref) => {

    if (props?.mediatype == "trailer") return <ReactPlayer {...props} ref={ref} />

    const [platform] = useStore("platform");

    switch (platform?.model) {
        case "android":

            var androidPlayerSettings = Storege.getAndroidPlayerSettings();

            var { channelPlayer, vodPlayer } = androidPlayerSettings;

            if (props?.mediatype == "live" || props?.mediatype == "epg") {
                var playerType = channelPlayer || "EXO";
            } else {
                var playerType = vodPlayer || "EXO";
            }

            return <AndroidPlayer {...props} playerType={playerType} ref={ref} />

        case "tizen":

            var tizenPlayerSettings = Storege.getTizenPlayerSettings();

            var { channelPlayer, vodPlayer } = tizenPlayerSettings;

            var Player = VideoPlayer;

            if (props?.mediatype == "live" || props?.mediatype == "epg") {
                if (channelPlayer == "native") Player = TizenPlayer;
            } else {
                if (vodPlayer == "native") Player = TizenPlayer;
            }

            return <Player {...props} ref={ref} />

    }

    return <VideoPlayer {...props} ref={ref} />

};

export default memo(forwardRef(AppPlayer));