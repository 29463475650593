
function Icon () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 32 32"
        >
            <path
                d="M26.17 12.37L9 2.45A3.23 3.23 0 007.38 2 3.38 3.38 0 004 5.38v21.29a3.33 3.33 0 005.1 2.82l17.19-10.86a3.65 3.65 0 00-.12-6.26z"
                data-name="01-Play"
                data-original="#000000"
            ></path>
        </svg>
    );
}

export default Icon;