import useConnection from "../../hooks/useConnection";
import { NoConnectionSVG } from "@/src/components/svgIcons";
import "./ConnectionPopup.scss";
import Text from "@/src/components/text/Text";

const ConnectionPopup = () => {

    const isOnline = useConnection();

    if (isOnline) return null;

    return (
        <div className="connection-popup bg-color">
            <NoConnectionSVG />
            <div className="title">
                <Text>connection lost</Text>
            </div>
        </div>
    )


};

export default ConnectionPopup;
