import { memo, useCallback, useContext, useEffect, useState } from "react";

import { categoryIcon } from "../../../assets/icons";

import CategoryItem from "../components/categoryItem/CategoryItem";
import ChannelItem from "../components/channelItem/ChannelItem";

import useKeydown from "../../../hooks/useKeydown";
import ListView from "../../../components/listView/ListView";

import { Context } from "../LiveTvPage";
import NoResult from "../components/NoResult";
import Storege from "@/src/services/storage/storage";
import Config from "@/src/config";
import { SaveCategoriesConfigs } from "@/src/services/content/parser";



const ChannelsSection = () => {

    const {
        onBack,
        setCurrentSection,
        currentSection,
        channelsContent,
        setChannelsContent,
        currentChannelId,
        currentChannelsSection,
        setCurrentChannelsSection,
        currentCategoryId,
        playerOptions,
        moveItemIndex,
        setMoveItemIndex
    } = useContext(Context);

    const [initialActiveIndex, setInitialActiveIndex] = useState(0);


    const [initialCategoryActiveIndex, setInitialCategoryActiveIndex] = useState(0);

    const isActive = (currentSection == "channels" && !playerOptions.isFullscreen) || currentSection == "categories";

    const currentCategory = channelsContent.categories_obj[currentCategoryId];

    const categoryName = currentCategory ? currentCategory.name : "";

    const channelsCount = currentCategory ? currentCategory.items.length : 0;

    const categoriesCount = channelsContent.categories_arr.length;

    const { sort } = channelsContent;

    const currentChannelIndex = currentCategory ? currentCategory.items.indexOf(currentChannelId) : null;

    useEffect(() => {
        setInitialActiveIndex(0);
    }, [currentCategoryId, sort, channelsCount]);// listen to changes channelsCount for searching 

    useEffect(() => {

        if (currentSection != "channels") return;

        if (!playerOptions.isFullscreen && currentCategory) {

            let index = currentCategory.items.indexOf(currentChannelId);

            if (index != -1) setInitialActiveIndex(index);

        }

    }, [playerOptions, currentSection]);

    useEffect(() => {

        if (currentSection != "categories") return;

        // let categoryIndex = channelsContent.categories_arr.indexOf(currentCategoryId);

        // if (categoryIndex != -1) {
        //     setInitialCategoryActiveIndex(categoryIndex);
        // }

    }, [currentSection]);

    const moveFavorites = (k) => {

        if (moveItemIndex == null) return;

        if (k == 1 && moveItemIndex == currentCategory.items.length - 1) return;

        if (k == -1 && moveItemIndex == 0) return;

        let favorites = channelsContent.categories_obj.favorites.items;

        favorites.splice(moveItemIndex + k, 0, favorites.splice(moveItemIndex, 1)[0]);

        channelsContent.categories_obj.favorites.items = favorites;

        setChannelsContent({ ...channelsContent });
        setMoveItemIndex(moveItemIndex + k);
        setInitialActiveIndex(moveItemIndex + k);

        Storege.setFavorites("channels", favorites);

    }

    const moveCategory = (k) => {

        if (moveItemIndex == null) return;

        if (k == 1 && moveItemIndex == channelsContent.categories_arr.length - 1) return;

        if (k == -1 && moveItemIndex == 0) return;

        channelsContent.categories_arr.splice(moveItemIndex + k, 0, channelsContent.categories_arr.splice(moveItemIndex, 1)[0]);

        setMoveItemIndex(moveItemIndex + k);

        setInitialCategoryActiveIndex(moveItemIndex + k);

        SaveCategoriesConfigs(channelsContent.categories_arr, channelsContent.categories_obj, "channels");

        requestAnimationFrame(() => {

            // console.log("channelsContent", channelsContent);
            
            setChannelsContent({ ...channelsContent });
        })

       

    }

    useKeydown({
        isActive: isActive && !playerOptions.isFullscreen,
        left: () => {
            setMoveItemIndex(null);
            // setCurrentChannelsSection("categories");
            setCurrentSection("categories");
        },
        right: () => {
            setMoveItemIndex(null);
            // if (currentChannelsSection == "categories") {
            //     // setCurrentChannelsSection("channels");
            //     setCurrentSection("channels");
            // } else {
            //     setCurrentSection("playerSection");
            // }
            if (currentSection == "categories") {
                // setCurrentChannelsSection("channels");
                setCurrentSection("channels");
            } else {
                setCurrentSection("playerSection");
            }
        },
        up: () => {
            if(currentSection == "categories"){
           
                moveCategory(-1);
           
            }else{
           
                moveFavorites(-1);
           
            }
        },
        down: () => {
          
            if(currentSection == "categories"){
           
                moveCategory(1);
           
            }else{
           
                moveFavorites(1);
           
            }
            
        },
        back: () => {
            setMoveItemIndex(null);
            // if (currentChannelsSection == "categories") {
            //     setCurrentChannelsSection("channels");
            // } else {
            //     onBack();
            // }
            if (currentSection == "channels") {
                // setCurrentChannelsSection("channels");
                setCurrentSection("categories");
            } else {
                onBack();
            }
        },
    });

    return (
        <div className="live-tv-section playlist-section">

            <div className="section-header focus-item">
                <img src={Config.AppLogo} />
                <div className="section-title">{categoryName}</div>
                <div className="section-count">{channelsCount}</div>
            </div>

            <div className="section-body">

                <div className={"section-content"}>

                    <div className="category-section">
                        <div className="parent">
                            <ListView
                                id={ "categories-"+initialCategoryActiveIndex}
                                uniqueKey={"channel-categories-view"}
                                listType="vertical"
                                itemsTotal={categoriesCount}
                                itemsCount={10}
                                buffer={2}
                                initialActiveIndex={initialCategoryActiveIndex}
                                startScrollIndex={5}
                                nativeControle={true}
                                debounce={100}
                                ItemRenderer={CategoryItem}
                                itemWidth={43}
                                itemHeight={9.5}
                                isActive={currentSection == "categories" && moveItemIndex == null && isActive}
                            />
                        </div>
                    </div>

                    <div className="channel-section">
                        <div className="parent">
                            {channelsCount == 0 && <NoResult />}
                            <ListView
                                id={ "channels-" + currentCategoryId + "-" + initialActiveIndex + "-" + sort} // 
                                uniqueKey={"channel-list-view"}
                                listType="vertical"
                                itemsTotal={channelsCount}
                                itemsCount={10}
                                buffer={2}
                                initialActiveIndex={initialActiveIndex}
                                onBackScrollIndex={currentChannelIndex}
                                startScrollIndex={5}
                                nativeControle={true}
                                debounce={100}
                                ItemRenderer={ChannelItem}
                                itemWidth={43}
                                itemHeight={9.5}
                                isActive={currentSection == "channels" && moveItemIndex == null && isActive }
                            />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )

};

export default memo(ChannelsSection);