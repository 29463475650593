import { memo, useCallback, useContext } from "react";
import Button from "../../../../../components/button/Button";
import { MovieInfoContext } from "../../MovieInfo";

const SeasonItem = ({ isActive, index, style, onMouseEnter }) => {

    const {
        seasons,
        selectedSeason,
        setSelectedSeason,
    } = useContext(MovieInfoContext);

    const seasonNum = seasons[index];

    const isSelected = seasonNum === selectedSeason;

    const onClick = useCallback(() => {
        setSelectedSeason(seasonNum);
    }, [isActive, isSelected, setSelectedSeason]);

    const mouseEnter = useCallback(() => {
        onMouseEnter(index);
    }, [isActive, isSelected, setSelectedSeason]);

    return (
        <div className="season-item-parent" style={style}>
            <Button
                className="season-item"
                data={index}
                isActive={isActive}
                isSelected={isSelected}
                onMouseEnter={mouseEnter}
                onClick={onClick}
            >
                Season {seasonNum}
            </Button>
        </div>

    )

}

export default memo(SeasonItem);