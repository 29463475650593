import { useCallback, useEffect, useRef, useState, memo } from "react";
import "./KeyboardLarge.scss";
import Button from "../button/Button";
import { createPortal } from "react-dom";
import useKeydown from "../../hooks/useKeydown";

import Text from "../text/Text";

import Storege from "@/src/services/storage/storage";
import { backspaceBlackIcon, backspaceWhiteIcon, shiftWhiteIcon, shiftBlackIcon, spaceWhiteIcon, spaceBlackIcon } from "@/src/assets/icons";

const Item = memo(({ item, uppercase, isActive }) => {

    switch (item) {
        case "bspace":
            return <img src={isActive ? backspaceBlackIcon : backspaceWhiteIcon} />
        // return <div className="bg-icon backspace"></div>
        case "shift":
            return <img src={isActive ? shiftBlackIcon : shiftWhiteIcon} />
        // return <div className="bg-icon shift"></div>
        case "space":
            return <img src={isActive ? spaceBlackIcon : spaceWhiteIcon} />
        // return <div className="bg-icon space"></div>
        case "EN":
        case "RU":
        case "AR":
            return item;
        case "Clean":
        case "Done":
            return <Text>{item}</Text>
        default:
            return uppercase ? item.toUpperCase() : item.toLowerCase();
    }

})

let ITEMS = [];

const KeyboardLarge = ({ options, className }) => {

    const {
        onChange = () => { },
        onBack = () => { },
        onTop = () => { },
        onBackspace = () => { },
        onDone = () => { },
        onClear = () => { },
        onClose = () => { },
        helpKeys = [],
        onMouseEnter = () => { },
    } = options;

    const isActive = options.isActive == undefined ? true : options.isActive;

    const parentRef = useRef();

    const [lang, setLang] = useState("en");
    const [uppercase, setSetUppercase] = useState(false);
    const [active, setActive] = useState([0, 0]);
    const [clicked, setClicked] = useState(false);

    const appLanguage = Storege.getLanguage();

    const keyboard = {
        en: [
            ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "/", "\\", "bspace"],
            ["a", "s", "d", "f", "g", "h", "j", "k", "l", ".", ":", "Done"],
            ["shift", "z", "x", "c", "v", "b", "n", "m", ",", "?", "Clean", "shift"],
            ["RU", "space", "123"],
        ],
        ru: [
            ["й", "ц", "у", "к", "е", "н", "г", "ш", "щ", "з", "х", "ъ", "bspace"],
            ["ф", "ы", "в", "а", "п", "р", "о", "л", "д", "ж", "э", "Done"],
            ["shift", "я", "ч", "с", "м", "и", "т", "ь", "б", "ю", "Clean", "shift"],
            ["AR", "space", "123"],
        ],
        ar: [
            ["ض", "ص", "ث", "ق", "ف", "غ", "ع", "ه", "خ", "ح", "ج", "د", "bspace"],
            ["ش", "س", "ي", "ب", "ل", "ا", "ت", "ن", "م", "ك", "ط", "Done"],
            ["shift", "ئ", "ء", "ؤ", "ر", "لا", "ى", "ة", "و", "ز", "ظ", "Clean"],
            ["EN", "space", "123"],
        ],
        char: [
            ["`", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "|", "bspace"],
            ["@", "#", "$", "_", "&", "-", "+", "(", ")", "/", "*", "Done"],
            ["shift", '"', "'", ":", ";", "!", ".", "<", ">", "=", "Clean", "shift"],
            ["EN", "space", "EN"],
        ],
    };

    const keyboardKeys = keyboard[lang];

    if (helpKeys.length > 0) keyboardKeys.unshift(helpKeys);

    useEffect(() => {

        let timer

        if (clicked) {

            timer = setTimeout(() => {

                setClicked(false);

            }, 10);

        }

        return () => {
            clearTimeout(timer);
        }

    }, [clicked]);

    const mouseEnter = useCallback((e, data) => {

        const [row, index] = data.split("-");

        setActive([parseInt(row), parseInt(index)]);

        onMouseEnter();

    }, []);

    const onClick = (e, data) => {

        const [row, index] = data.split("-");
        let item = keyboardKeys[parseInt(row)][parseInt(index)];

        setClicked(true);

        switch (item) {
            case "bspace":
                onBackspace();
                break;
            case "shift":
                setSetUppercase((prev) => !prev);
                break;
            case "space":
                onChange(" ");
                break;
            case "EN":
            case "RU":
            case "AR":
                setLang(item.toLowerCase());
                break;
            case "123":
                setLang("char");
                break;
            case "Clean":
                onClear();
                break;
            case "Done":
                onDone();
                break;
            default:
                onChange(uppercase ? item.toUpperCase() : item.toLowerCase());
                break;
        }

    };

    const findNear = (cur, i) => {

        if (!ITEMS[i]) return cur;

        for (let j = 0; j < ITEMS[i].length; j++) {

            let next = ITEMS[i][j];

            if (cur.x >= next.x && cur.x <= next.x1 || cur.x1 >= next.x && cur.x1 <= next.x1) {
                return [i, j];
            }

        }

        return [0, 0];

    }

    const up = () => {

        const [row, index] = active;

        if (row == 0) return onTop();

        const cur = ITEMS[row][index];

        setActive(findNear(cur, row - 1));

    };

    const down = () => {

        const [row, index] = active;

        if (row == ITEMS.length - 1) return

        const cur = ITEMS[row][index];

        setActive(findNear(cur, row + 1));

    };

    const left = () => {

        const [row, index] = active;

        if (index == 0 && options.onLeft) return options.onLeft();
        if (index == 0) return setActive([row, keyboardKeys[row].length - 1]);

        return setActive([row, index - 1]);

    };

    const right = () => {

        const [row, index] = active;

        if (index == keyboardKeys[row].length - 1) return setActive([row, 0]);

        return setActive([row, index + 1]);

    };

    const keydown = (e) => {

        let { keyCode, key, metaKey, ctrlKey } = e;

        if (metaKey || ctrlKey) return false

        if (keyCode > 47 && keyCode < 58 ||
            keyCode > 64 && keyCode < 91 ||
            keyCode > 96 && keyCode < 123 ||
            keyCode > 57 && keyCode < 65 ||
            keyCode > 122 && keyCode < 127 ||
            keyCode > 185 && keyCode < 193 ||
            keyCode > 218 && keyCode < 223 || keyCode == 32) {
            onChange(key);
            return true
        }

        return false

    };

    useEffect(() => {

        let row = parentRef.current.getElementsByClassName("keyboard-row");

        row = Array.from(row).map((row, rowIndex) => {

            let keys = row.getElementsByClassName("keyboard-key");

            return Array.from(keys).map((key, keyIndex) => {

                const rect = key.getBoundingClientRect();

                return { x: rect.left, x1: rect.left + rect.width }

            });

        });

        ITEMS = row

    }, []);

    const rtlMode = Storege.getRtlMode();

    useKeydown({
        isActive,
        debounce: 50,
        keydown,
        back: onBack,
        up,
        down,
        left: rtlMode ? right : left,
        right: rtlMode ? left : right,
    });

    return (

        createPortal(

            <div className={"keyboard-parent " + className} onClick={() => { requestAnimationFrame(onClose) }}>

                <div className="keyboard-large bg-color" onClick={(e) => e.stopPropagation()} ref={parentRef}>
                    {
                        keyboardKeys.map((row, rowIndex) => {

                            return (

                                <div className="keyboard-row" key={rowIndex} data={rowIndex}>

                                    {row.map((key, keyIndex) => {

                                        let className = "focus-item keyboard-key " + key;

                                        if (key == "shift" && uppercase) className += " enabled";

                                        if (rowIndex == 0 && helpKeys.length > 0) className += " help";

                                        return (
                                            <Button
                                                isActive={active[0] == rowIndex && active[1] == keyIndex && !clicked && isActive}
                                                className={className}
                                                key={rowIndex + "-" + keyIndex}
                                                data={rowIndex + "-" + keyIndex}
                                                onMouseEnter={mouseEnter}
                                                onClick={onClick}
                                            >
                                                <Item item={key} uppercase={uppercase} isActive={active[0] == rowIndex && active[1] == keyIndex && !clicked && isActive} />
                                            </Button>
                                        )

                                    })}

                                </div>
                            )

                        })
                    }
                </div>

            </div>,

            document.getElementById("root")
        )

    )


};

export default KeyboardLarge;