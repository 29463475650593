import { useContext } from "react";
import Button from "../../../../components/button/Button";
import "./DayItem.scss"
import { Context } from "../../LiveTvPage";
import { DeviceDate } from "@/src/utils/util";

const DayItem = ({ index, isActive, onMouseEnter, style }) => {

    const { setCurrentSection, currentDay, setCurrentDay } = useContext(Context);

    let week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    let date = new DeviceDate();
    date.setDate(date.getDate() + (index - 3));

    let day = date.getDate();
    let name = week[date.getDay()];

    const isSelected = currentDay == day;

    const mouseEnter = () => {
        onMouseEnter(index);
        setCurrentSection("daysSection");
    };

    const onClick = (e) => {
        if (window.Android && e.duration && e.duration > 500 && window.showColorButtons) {
            window.showColorButtons();
        } else {
            setCurrentDay(day);
        }
    };

    return (
        <div style={style}>
            <Button
                className="day-item focus-item"
                isActive={isActive}
                isSelected={isSelected}
                onClick={onClick}
                onMouseEnter={mouseEnter}
            >
                <div className="day">{day}</div>
                <div className="name">{name}</div>
            </Button>
        </div>
    );

};

export default DayItem;