import { useState, memo, useEffect } from "react";
import { FormatDate } from "@/src/utils/util";
import { useContext } from "react";
import { TimeLineEpgContext } from "../TimeLineEpg";

const CurrentTime = memo(() => {

    const [date, setDate] = useState(new Date());

    useEffect(() => {

        let interval = setInterval(() => {

            setDate(new Date());

        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <div className="current-time">
            {FormatDate(date, "hh:mm")}
        </div>
    )

});

const TimeLineBody = memo(({ timelineConfig }) => {

    const [items, setItems] = useState([]);

    useEffect(() => {

        let { min_unix, max_unix } = timelineConfig;

        if (min_unix && max_unix) {

            let items = [];

            for (let i = min_unix; i < max_unix; i += 3600000) {
                let hour = FormatDate(i, "dd/MM hh:mm");
                let left = ((i - min_unix) / 60000) + "rem";
                items.push(<div className="item" key={i} data-time={hour} style={{ left }}></div>);
            }

            setItems(items);

        }

    }, [timelineConfig]);


    return items;

})

const TimeLine = () => {

    let { timelineConfig } = useContext(TimeLineEpgContext);

    return (
        <div className="timeline-parent">

            <CurrentTime />

            <div className="timeline" id="timeline">

                <TimeLineBody timelineConfig={timelineConfig} />

            </div>

        </div>

    )
};

export default memo(TimeLine);