import { useContext } from "react";
import Storege from "../services/storage/storage";
import { MoviesPageContext } from "../pages/movies/MoviesPage";

/**
 * 
 * @param {int} movieId 
 * @returns {[boolean, () => void]} [isFavorite, toggelFavorite]
 */
const useFavoriteMovies = (movieId) => {

    const {
        contentType,
        moviesContent,
        setMoviesContent,
    } = useContext(MoviesPageContext);

    const favorites = moviesContent.categories_obj["favorites"]?.items || [];

    const favoriteIndex = favorites.indexOf(movieId);

    const isFavorite = favoriteIndex > -1;

    const toggelFavorite = () => {

        if (favoriteIndex > -1) {
            favorites.splice(favoriteIndex, 1);
        } else {
            favorites.unshift(movieId);
        }

        Storege.setFavorites(contentType, favorites);

        setMoviesContent({ ...moviesContent });

    }

    if (!movieId) return [false, () => { }];

    return [isFavorite, toggelFavorite];

};

export default useFavoriteMovies;