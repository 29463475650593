import { useEffect, useState, createContext, memo, useCallback } from "react";
import { createPortal } from "react-dom";
import Text from "@/src/components/text/Text";
import useKeydown from "@/src/hooks/useKeydown";
import SubtitleItem from "./SubtitleItem";
import ListView from "@/src/components/listView/ListView";
import "./SubtitlePopup.scss";

export const SubtitleContext = createContext();

const SubtitlePopup = ({
    subtitles,
    isVisible,
    subtitle,
    setSubtitle = () => { },
    onBack = () => { }
}) => {

    const [isShowing, setIsShowing] = useState(false);

    const [selectedGroup, setSelectedGroup] = useState(0);

    const [initialGroupIndex, setInitialGroupIndex] = useState(0);

    const [initialSubtitleIndex, setInitialSubtitleIndex] = useState(0);

    const [activeCol, setActiveCol] = useState(0);

    const renderGroupItem = useCallback((props) => {
        return <SubtitleItem {...props} type="subtitle-group" />
    }, [selectedGroup, subtitles]);

    const renderItem = useCallback((props) => {
        return <SubtitleItem  {...props} type="subtitle" />
    }, [selectedGroup, subtitles]);

    const setActiveColIndex = useCallback((index) => () => {
        setActiveCol(index);
    }, []);

    useEffect(() => {

        if (isVisible) {

            setTimeout(() => {
                setIsShowing(true);
            }, 0);

            if (!subtitle) {
                setSelectedGroup(0);
                setInitialGroupIndex(0);
                setInitialSubtitleIndex(0);
                setActiveCol(0);
            } else {

                for (let i = 0; i < subtitles.length; i++) {

                    for (let j = 0; j < subtitles[i].subtitles.length; j++) {

                        if (subtitles[i].subtitles[j].file_id == subtitle.file_id) {
                            setSelectedGroup(i);
                            setInitialGroupIndex(i);
                            setInitialSubtitleIndex(j);
                            setActiveCol(1);
                            return;
                        }

                    }

                }

            }

        } else {
            setIsShowing(false);
        }

    }, [isVisible]);

    useKeydown({
        isActive: isVisible,
        left: () => {
            setActiveCol(0);
        },
        right: () => {
            if (selectedGroup) setActiveCol(1);
        },
        back: () => {
            onBack();
        }
    });

    if (!isVisible) return null;

    return createPortal(
        <SubtitleContext.Provider
            value={{
                subtitles,
                selectedGroup,
                setSelectedGroup,
                subtitle,
                setSubtitle
            }}
        >
            <div
                className={"subtitle-popup " + (isShowing ? "show" : "")}
                onMouseDown={e => e.stopPropagation()}
                onMouseEnter={e => e.stopPropagation()}
                onClick={e => e.stopPropagation()}
                onMouseMove={e => e.stopPropagation()}
            >

                <div className="subtitle-popup-content">

                    <div className="subtitle-popup-title">
                        <Text>subtitle</Text>
                    </div>

                    <div className="subtitle-popup-list">

                        <div className="subtitle-popup-list-col">
                            <ListView
                                id={subtitles}
                                uniqueKey={"subtitle-popup-list-0"}
                                listType="vertical"
                                itemsTotal={subtitles.length}
                                itemsCount={10}
                                buffer={2}
                                initialActiveIndex={initialGroupIndex}
                                startScrollIndex={5}
                                nativeControle={true}
                                debounce={100}
                                ItemRenderer={renderGroupItem}
                                itemWidth={40}
                                itemHeight={7.7}
                                isActive={activeCol == 0}
                                onMouseEnter={setActiveColIndex(0)}
                            />
                        </div>

                        <div className="subtitle-popup-list-col">
                            <ListView
                                id={selectedGroup}
                                uniqueKey={"subtitle-popup-list-1"}
                                listType="vertical"
                                itemsTotal={subtitles[selectedGroup]?.subtitles?.length || 0}
                                itemsCount={10}
                                buffer={2}
                                initialActiveIndex={initialSubtitleIndex}
                                startScrollIndex={5}
                                nativeControle={true}
                                debounce={100}
                                ItemRenderer={renderItem}
                                itemWidth={40}
                                itemHeight={7.7}
                                isActive={activeCol == 1}
                                onMouseEnter={setActiveColIndex(1)}
                            />
                        </div>

                    </div>

                </div>

            </div>
        </SubtitleContext.Provider>
        , document.getElementById("root"))

};

export default memo(SubtitlePopup);