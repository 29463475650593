import { memo, useContext } from "react";
import { MovieInfoContext } from "../../MovieInfo";
import { timeIcon } from "../../../../../assets/icons";
import Rating from "./rating/Rating";

const MovieDetails = () => {

    const { movieInfo } = useContext(MovieInfoContext);

    if (!movieInfo) return null;

    let { name, rating, year, duration, genre, description } = movieInfo;

    return (
        <>
            <div className="movie-title">{name}</div>

            <div className="movie-details">

                {rating ? <Rating rating={rating} /> : null}

                {year ? <div className="movie-year">{year}</div> : null}

                {duration ? <div className="movie-duration">
                    <img src={timeIcon} />
                    <span>{duration}</span>
                </div> : null}

                <div className="movie-genre">
                    {genre}
                </div>

            </div>

            <div className="movie-desc">
                {description}
            </div>
        </>
    )

}

export default memo(MovieDetails);