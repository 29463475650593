import { createPortal } from "react-dom";
import "./PlaylistLoading.scss";
import Text from "../../../components/text/Text";
import useKeydown from "../../../hooks/useKeydown";

const PlaylistLoading = ({
    status = "loading", // 
    percent = 0,
    mb = 0,
    onBack = () => { },
}) => {

    useKeydown({
        isActive: true,
        capture: true,
        keydown: (e) => {
            e.stopPropagation();
            return false;
        },
        back: onBack,
    })

    return createPortal(
        <div className="playlist-loading bg-color">

            <div className="playlist-loading">

                {(status == "loading" || status == "parsing") ?
                    <div className="playlist-loader"></div>
                    :
                    <>
                        <div className="percent">{percent ? percent + "%" : mb ? mb + "MB" : ""}</div>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                            <circle cx="16" cy="16" r="15.9155" className="progress-bg" />
                            <circle cx="16" cy="16" r="15.9155" className="progress-bar svg-progeress-bg" strokeDashoffset={100 - percent} />
                        </svg>
                    </>}

            </div>

            <div className="title">
                <Text>{status}</Text>
            </div>

        </div>,
        document.getElementById("root")
    );

};

export default PlaylistLoading;