import { useContext } from "react";
import Storege from "../services/storage/storage";
import { MoviesPageContext } from "../pages/movies/MoviesPage";

/**
 * 
 * @param {int} movieId 
 * @returns {[object|null, () => void]}
 */
const useContinueWatching = (movieInfo) => {

    const {
        contentType,
        moviesContent,
        setMoviesContent,
    } = useContext(MoviesPageContext);

    const movieId = movieInfo?.id;

    if (!movieId) return [null, () => { }];

    const continueArray = moviesContent.categories_obj["continue"]?.items || [];

    const continueObject = moviesContent.categories_obj["continue"]?.items_obj || {};

    const continueData = continueObject[movieId] || null;

    const setContinue = (value, seasonNum, episodeNum) => {

        let prevContinueData = continueObject[movieId];

        let index = continueArray.indexOf(movieId);

        if (index > -1) {
            continueArray.splice(index, 1);
            delete continueObject[movieId];
        }

        if (contentType == "movies") {

            if (value && value.percent < 0.99) {

                continueArray.unshift(movieId);
                continueObject[movieId] = value;

            }

        } else {

            if (!prevContinueData) prevContinueData = {}

            prevContinueData.lastSeason = seasonNum;
            prevContinueData.lastEpisode = episodeNum;

            let key = `${ seasonNum }-${ episodeNum }`;

            prevContinueData[key] = value;

            let isWatchedAllEpisodes = true;
            let seasons = movieInfo?.seasons || {};

            for (const season in seasons) {

                for (const episode in seasons[season]) {

                    let key = `${ season }-${ episode }`;

                    if (!prevContinueData[key] || prevContinueData[key].percent < 0.99) {
                        isWatchedAllEpisodes = false;
                        break;
                    }

                }

            }

            if (!isWatchedAllEpisodes) {

                continueArray.unshift(movieId);
                continueObject[movieId] = prevContinueData;

            }

        }

        setMoviesContent({ ...moviesContent });

        Storege.setContinueWatching(contentType, {
            items: continueArray,
            items_obj: continueObject,
        });

    }

    return [continueData, setContinue];

};

export default useContinueWatching;