import PlayerButtons from "@/src/components/playerButtons/PlayerButtons";

import { memo } from "react";

const PlayerButtonsContainer = ({
    isActive,
    isPaused,
    seekTo,
    playPause,
    onMouseEnter,
    currentTime
}) => {

    return (
        <div className="player-buttons-container">

            <PlayerButtons
                isActive={isActive}
                isPaused={isPaused}
                playPause={playPause}
                currentTime={currentTime}
                onMouseEnter={onMouseEnter}
                seekTo={seekTo}
            />
       
        </div>
    )

}

export default memo(PlayerButtonsContainer);