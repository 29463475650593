import { memo, useContext, useEffect, useMemo } from "react";
import DaysHeader from "../components/DaysHeader";
import DayItem from "../components/dayItem/DayItem";
import useKeydown from "../../../hooks/useKeydown";
import { Context } from "../LiveTvPage";
import ListView from "../../../components/listView/ListView";

const DaysSection = () => {

    const { setCurrentSection, currentSection } = useContext(Context);

    const isActive = currentSection == "daysSection";

    useKeydown({
        isActive,
        left: () => {
            setCurrentSection("playerSection");
        },
        back: () => {
            setCurrentSection("playerSection");
        },
    });

    return (
        <div className="live-tv-section days-section">

            <div className="section-header focus-item">
                <DaysHeader />
            </div>

            <div className="section-body">
                <ListView
                    uniqueKey={"dey-item"}
                    listType="vertical"
                    itemsTotal={8}
                    itemsCount={8}
                    buffer={0}
                    initialActiveIndex={0}
                    startScrollIndex={0}
                    nativeControle={true}
                    debounce={200}
                    ItemRenderer={DayItem}
                    itemWidth={11.8}
                    itemHeight={11.8}
                    isActive={isActive}
                />
            </div>

        </div>
    )

};

export default memo(DaysSection);