import { useEffect } from "react";
import useStore from "./useStore";

const useVisibility = () => {

    const [_, setDocumentIsHidden] = useStore("documentIsHidden");

    useEffect(() => {

        const visibilitychange = () => {
            setDocumentIsHidden(document.hidden);
        }

        window.addEventListener('visibilitychange', visibilitychange);

        return () => {
            window.removeEventListener('visibilitychange', visibilitychange);
        };

    }, []);

};

export default useVisibility