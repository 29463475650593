import Config from "../../config";
import storeSubscribe from "../store/subscribe";
import { RequestWithAndroid } from "./android";

const Api_host = Config.Api_host;

let device_blocked = false;

let token = "";

storeSubscribe("token", (newToken) => {
    token = newToken;
});

const BACKEND = {

    requests: [],

    abortAll: function () {

        for (var i = 0; i < BACKEND.requests.length; i++) {
            try {
                BACKEND.requests[i].aborted = true;
                BACKEND.requests[i].abort();
            } catch (e) { }
        }

        BACKEND.requests = [];

    },

    registerDevice: function (data) {
        return BACKEND.request("post", Api_host + "/register_device", data);
    },

    getPlaylists: function () {
        return BACKEND.request("get", Api_host + "/playlist", {});
    },

    setSelectedPlaylist: function (data) {
        return BACKEND.request("put", Api_host + "/device/selected_playlists", data);
    },

    getPlaylistData: function (data) {
        return BACKEND.request("post", Api_host + "/get_playlist_data", data);
    },

    addPlaylist: function (data) {
        return BACKEND.request("post", Api_host + "/playlist", data);
    },

    deletePlaylist: function (data) {
        return BACKEND.request("post", Api_host + "/playlist/delete", data);
    },

    getNotifications: function () {
        return BACKEND.request("get", Api_host + "/notifications", {});
    },

    skipNotifications: function (data) {
        return BACKEND.request("put", Api_host + "/notifications", data);
    },

    parsePlaylist: function (data, progress) {
        return BACKEND.request("post", Api_host + "/parse_playlist", data, progress);
    },

    downloadSubtitle: function (file_id) {
        return BACKEND.request("get", Api_host + "/subtitles?file_id=" + file_id, {});
    },

    getVideos: function () {
        return BACKEND.request("get", Api_host + "/videos", {});
    },

    addVideo: function (data) {
        return BACKEND.request("post", Api_host + "/videos", data);
    },

    updateVideo: function (data) {
        return BACKEND.request("put", Api_host + "/videos", data);
    },

    deleteVideo: function (data) {
        return BACKEND.request("delete", Api_host + "/videos", data);
    },

    addFreeDnsStatistics: function (data) {
        return BACKEND.request("post", Api_host + "/free_dns_online", data);
    },

    getServers: function () {
        return BACKEND.request("get", Api_host + "/admin_servers", {});
    },

    request: function (method, url, data, progress = () => { }) {

        const req = new XMLHttpRequest();

        const promise = new Promise((resolve, reject) => {

            if (!window.navigator.onLine) return reject("network disconnected");

            if (device_blocked) return reject("Your device is blocked");

            // req.timeout = 120000;
            req.timeout = 30000;

            req.onreadystatechange = function () {

                if (req.aborted) return;

                if (device_blocked) return;

                if (req.readyState == 4) {

                    try {
                        var data = JSON.parse(req.responseText);
                    } catch (e) {
                        return reject("request error");
                    }

                    if (req.status == 200) {

                        if (data.error) {

                            if (data.code == 402) {
                                device_blocked = true;
                                reject("Your device is blocked");
                            } else if (data.code == 401) {
                                location.reload();
                            } else {
                                reject(data.message);
                            }

                        } else {
                            resolve(data.message);
                        }

                    } else {

                        var message = data ? data.message || "network error" : "network error";
                        reject(message);

                    }

                    BACKEND.requests = BACKEND.requests.filter(r => r != req);

                }

            }

            req.onerror = function (err) {

                if (req.aborted) return;

                reject(err.message);
                BACKEND.requests = BACKEND.requests.filter(r => r != req);

            }

            req.ontimeout = function () {

                if (req.aborted) return;

                reject("request timeout");
                BACKEND.requests = BACKEND.requests.filter(r => r != req);

            }

            req.upload.onprogress = function (e) {

                if (req.aborted) return;

                if (e.lengthComputable && e.loaded && e.total) {
                    progress(parseInt((e.loaded / e.total) * 100));
                }

            };

            req.open(method.toUpperCase(), url);

            if (data instanceof FormData) {
                // req.setRequestHeader("Content-Type", "multipart/form-data");
            } else {
                req.setRequestHeader("Content-Type", "application/json");
            }

            if (token) req.setRequestHeader("Authorization", token);

            if (method == "get") {
                req.send();
            } else {

                if (data instanceof FormData) {
                    req.send(data);
                } else {
                    req.send(JSON.stringify(data || {}));
                }

            }

        });

        BACKEND.requests.push(req);

        return promise;

    },

    downloadPlaylist: function (url, progress = () => { }, checkedProtocol = false) {

        return new Promise((resolve, reject) => {

            let request = BACKEND.downloadPlaylistXHR;

            if (window.Android) request = BACKEND.downloadPlaylistAndroid;

            request(url, progress)
                .then((response) => {
                    resolve(response);
                })
                .catch(() => {

                    if (checkedProtocol) {
                        reject("incorrect playlist url");
                    } else {

                        let protocol = url.split("://")[0];

                        if (protocol == "http") {
                            url = url.replace("http://", "https://");
                        } else if (protocol == "https") {
                            url = url.replace("https://", "http://");
                        }

                        BACKEND.downloadPlaylist(url, progress, true)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch(() => {
                                reject("incorrect playlist url");
                            });

                    }

                });

        });

    },

    downloadPlaylistXHR: function (url, progress = () => { }) {

        const req = new XMLHttpRequest();

        const promise = new Promise((resolve, reject) => {

            if (!window.navigator.onLine) return reject("network disconnected");

            req.timeout = 120000;

            req.onerror = function (err) {

                if (req.aborted) return;

                BACKEND.requests = BACKEND.requests.filter(r => r != req);
                reject(err.message);

            }

            req.ontimeout = function () {

                if (req.aborted) return;

                BACKEND.requests = BACKEND.requests.filter(r => r != req);
                reject("request timeout");

            }

            req.onreadystatechange = function (e) {

                if (req.aborted) return;

                if (req.readyState != 4) return;

                if (req.status == 200) {
                    resolve(req.responseText);
                } else {
                    reject("network error");
                }

                BACKEND.requests = BACKEND.requests.filter(r => r != req);

            }

            req.onprogress = function (e) {

                if (req.aborted) return;

                if (e.loaded && e.total) {
                    progress(parseInt((e.loaded / e.total) * 100));
                } else if (e.loaded) {
                    let mb = parseInt(e.loaded / 1024 / 1024);
                    progress(0, mb);
                } else {
                    progress(0, 0);
                }

            }

            if (url.indexOf("?") == -1) url += "?token=" + token;

            req.open("get", url);

            req.send();

        });

        BACKEND.requests.push(req);

        return promise;

    },

    downloadPlaylistAndroid: function (url, progress = () => { }) {

        let req = RequestWithAndroid(url);

        BACKEND.requests.push(req);

        return req;

    }

};

export default BACKEND;