import store from "./store";

window.globalState = () => {
    return store.getState().globalState;
}

export default function storeSubscribe (type, callback = () => { }) {

    let prevValue = store.getState().globalState[type];

    let unsubscribe = store.subscribe(() => {

        let curValue = store.getState().globalState[type];

        if (prevValue !== curValue) {
            prevValue = curValue;
            callback(curValue);
        }

    });

    const dispatch = (payload) => {
        store.dispatch({ type, payload });
    }

    return [dispatch, unsubscribe];

}