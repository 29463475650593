import { memo } from "react";
import useStore from "../../hooks/useStore";
import Words from "../../utils/words";

const Text = ({ children, replace = (t) => t }) => {

    const [language] = useStore('language');

    let text = children;

    if (!text) return text;

    if (typeof children === "string") {

        text = text.toLowerCase();

        if (Words[text] && Words[text][language]) {
            text = Words[text][language];
            text = replace(text);
        } else {
            console.log(`Missing translation for "${children}" in "${language}"`);
        }

    } else {
        console.warn("Text component only accepts string as children");
    }

    return text

};

export default memo(Text);