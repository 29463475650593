import { memo } from "react";
import Text from "../../../../components/text/Text";
import './ChangeLanguage.scss';
import Languages from "./Languages";
import LanguageItem from "./LanguageItem";
import ListView from "../../../../components/listView/ListView";
import useKeydown from "../../../../hooks/useKeydown";

const ChangeLanguage = ({
    isActive = false,
    onBack = () => { },
    onMouseEnter = () => { }
}) => {

    useKeydown({
        isActive,
        left: onBack,
    });

    return (
        <div className="settings-content">

            <ListView
                uniqueKey={"settings-languages-list"}
                listType="vertical"
                itemsTotal={Languages.length}
                itemsCount={10}
                buffer={0}
                initialActiveIndex={0}
                startScrollIndex={0}
                nativeControle={true}
                debounce={100}
                ItemRenderer={LanguageItem}
                itemWidth={70}
                itemHeight={9}
                isActive={isActive}
                onBack={onBack}
                onMouseEnter={onMouseEnter}
            />

        </div>
    )

};

export default memo(ChangeLanguage);