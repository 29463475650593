import { memo, useState, useCallback } from "react";
import QrCode from "@/src/components/qr/QrCode";
import useKeydown from "@/src/hooks/useKeydown";
import Text from "@/src/components/text/Text";
import Button from "@/src/components/button/Button";
import Input from "@/src/components/input/Input";
import Toast from "@/src/components/toast/Toast";
import "./AddVideoPopup.scss";
import Config from "@/src/config";
import useStore from "@/src/hooks/useStore";

const AddVideoPopup = ({
    onBack = () => { },
    addVideo = () => { },
}) => {

    const [current, setCurrent] = useState("inputs");

    const [activeInputIndex, setActiveInputIndex] = useState(0);

    const [activeBtnIndex, setActiveBtnIndex] = useState(0);

    const [focusedInput, setFocusedInput] = useState(null);

    const [deviceInfo] = useStore("deviceInfo");

    const [rtlMode] = useStore("rtlMode");

    const [inputs, setInputs] = useState([
        { label: "name", value: "", helpKeys: [] },
        { label: "url", value: "", helpKeys: ["http://", "https://", "https://www.youtube.com/watch?v="] },
    ]);

    const PopupInfoText = () => {
        return rtlMode ?
            <div className="popup-info-text">
                <span className="active-text-color">{Config.Web_host} </span>
                :<Text>to add video from our website visit</Text><br />
                <Text>or Scan the bar code in the left</Text>
            </div>
            :
            <div className="popup-info-text">
                <Text>to add video from our website visit</Text>
                :<span className="active-text-color"> {Config.Web_host}</span><br />
                <Text>or Scan the bar code in the right</Text>
            </div>
    }

    const onConfirm = () => {

        let name = inputs[0].value.trim();
        let url = inputs[1].value.trim();

        if (!name) return Toast.error({ msg: "name is required", id: "name is required", time: 3000 });

        if (!url) return Toast.error({ msg: "url is required", id: "url is required", time: 3000 });

        // check if url is valid
        let urlRegex = new RegExp("^(http|https)://", "i");

        if (!urlRegex.test(url)) return Toast.error({ msg: "invalid url", id: "invalid url", time: 3000 });

        addVideo({ name, url });

    };

    const onBlur = (value) => {

        setFocusedInput((index) => {

            inputs[index].value = value.trim();

            setInputs([...inputs]);

            return null

        });

    };

    // inputs
    const onClickInput = useCallback((e, index) => {
        setActiveInputIndex(index);
        setCurrent("inputs");
        setFocusedInput(index);
    }, []);

    const onMouseEnterInput = useCallback((e, index) => {
        setActiveInputIndex(index);
        setCurrent("inputs");
    }, []);

    // buttons
    const onMouseEnterBtn = useCallback((e, index) => {
        setActiveBtnIndex(index);
        setCurrent("buttons");
    }, []);

    useKeydown({
        isActive: focusedInput == null,
        debounce: 100,
        back: onBack,
        up: () => {

            setCurrent((current) => {

                switch (current) {
                    case "inputs":
                        if (activeInputIndex == 0) return "inputs";
                        setActiveInputIndex(activeInputIndex - 1);
                        return "inputs";
                    case "buttons":
                        setActiveInputIndex(inputs.length - 1);
                        return "inputs";
                }

            });

        },
        down: () => {

            setCurrent((current) => {

                switch (current) {
                    case "inputs":
                        if (activeInputIndex == inputs.length - 1) return "buttons";
                        setActiveInputIndex(activeInputIndex + 1);
                        return "inputs";
                    case "buttons":
                        return "buttons";
                }

            });

        },
        left: () => {

            switch (current) {
                case "buttons":
                    setActiveBtnIndex(0)
                    break;
            }

        },
        right: () => {

            switch (current) {
                case "buttons":
                    setActiveBtnIndex(1)
                    break;
            }

        }
    });

    let qrCodeValue = Config.Add_playlist_host + `?mac=${deviceInfo.mac}&type=upload_video`;

    return (
        <div className="add-video-popup">

            <div className="popup-title">
                <Text>add video</Text>
            </div>

            <div className="popup-content">

                {inputs.map((input, index) => {

                    return (
                        <Button
                            key={"Button-" + index}
                            isActive={activeInputIndex == index && current == "inputs"}
                            className={"button"}
                            activeClassName={focusedInput === index ? "focused" : "active"}
                            data={index}
                            onClick={onClickInput}
                            onMouseEnter={onMouseEnterInput}
                        >
                            <Input
                                key={"Input-" + index}
                                placeholder={input.label}
                                initialValue={input.value}
                                isFocused={focusedInput === index}
                                onBlur={onBlur}
                                onDone={onBlur}
                                onTop={onBlur}
                                onBack={onBlur}
                                helpKeys={input.helpKeys}
                            />
                        </Button>
                    )

                })}

            </div>

            <div className="popup-buttons">

                <Button
                    className="button action-btn"
                    isActive={activeBtnIndex == 0 && current == "buttons"}
                    data={0}
                    onClick={onBack}
                    onMouseEnter={onMouseEnterBtn}
                >
                    <Text>cancel</Text>
                </Button>

                <Button
                    className="button action-btn"
                    isActive={activeBtnIndex == 1 && current == "buttons"}
                    data={1}
                    onClick={onConfirm}
                    onMouseEnter={onMouseEnterBtn}
                >
                    <Text>ok</Text>
                </Button>

            </div>

            <div className="popup-info" style={{ opacity: (focusedInput == null) ? 1 : 0 }} >

                <PopupInfoText />

                <div className="popup-info-qr">
                    <QrCode
                        style={{ height: "26rem", width: "26rem" }}
                        value={qrCodeValue}
                    />
                </div>

            </div>

        </div>
    )

};

export default memo(AddVideoPopup);