import { memo } from "react";

import Config from "../../config";

import "./SplashLoading.scss";
import Text from "../../components/text/Text";

const SplashLoading = ({ isVisible = false }) => {

    if (!isVisible) return null;

    return (
        <div className="splash-loading">
            <img alt="splash-screen-img" className="splash-screen-img" src={Config.SplashScreenImg} />
            <div className="loading"></div>
            <div className="app-version"><Text>version</Text>: {Config.APP_VERSION}</div>
        </div>
    )
}

export default memo(SplashLoading);