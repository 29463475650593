import { memo, useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../../../components/button/Button";
import { MovieInfoContext } from "../../MovieInfo";
import ResizeImage from "../../../../../components/resizeImage/ResizeImage";
import Storege from "../../../../../services/storage/storage";
import TMDB from "../../../../../services/api/tmdb";
import CardProgress from "../../../../../components/cardProgress/CardProgress";
import { dotsIcon } from "@/src/assets/icons";

const EpisodePoster = memo(({ episode, tmdbId }) => {

    const [src, setSrc] = useState(null);

    const getTmdbInfo = async () => {

        try {

            if (!episode.tbdbInfo && tmdbId) {

                episode.tbdbInfo = true;

                let info = await TMDB.get_episode_info(tmdbId, episode.season, episode.num);

                if (info && info.still_path) {

                    let poster = TMDB.image_paths.w200 + info.still_path;

                    episode.posters.unshift(poster);

                }

            }

        } catch (e) {

        }

        checkPosters();

    };

    const checkPosters = () => {

        let index = 0;

        const check = () => {

            if (index >= episode.posters.length) return;

            let img = new Image();

            img.onload = () => {
                setSrc(img.src);
            }

            img.onerror = () => {
                index++;
                check();
            }

            img.src = episode.posters[index];

        }

        check();

    }

    useEffect(() => {

        setSrc(null);

        if (Storege.getUseTMDB()) {
            getTmdbInfo();
        } else {
            checkPosters();
        }

    }, [episode, tmdbId]);

    if (!src) return null;

    return <ResizeImage src={src} aspectRatio={true} quality={0.9} />

});

const EpisodeItem = ({ isActive, index, style, onMouseEnter }) => {

    const {
        playMovie,
        selectedSeason,
        episodes,
        movieInfo,
        continueData
    } = useContext(MovieInfoContext);

    const episodeNum = episodes[index];

    const episode = movieInfo.seasons[selectedSeason][episodeNum];

    const onClick = useCallback(() => {
        playMovie(selectedSeason, episodeNum);
    }, [isActive, selectedSeason]);

    const mouseEnter = useCallback(() => {
        onMouseEnter(index);
    }, [isActive]);

    let watchedPercent = 0;

    if (continueData && episode) {

        let key = `${episode.season}-${episode.num}`;

        if (continueData[key]) {

            let { percent, currentTime } = continueData[key];

            if (currentTime >= 60) watchedPercent = percent

        }

    }

    if (!episode || !movieInfo) return null;

    return (
        <div className="episode-item-parent" style={style}>
            <Button
                className="episode-item"
                data={index}
                isActive={isActive}
                onMouseEnter={mouseEnter}
                onClick={onClick}
            >
                <div className="icon item-bg" style={{ backgroundImage: `url(${dotsIcon})` }}>
                    <EpisodePoster episode={episode} tmdbId={movieInfo.tmdbId} />
                    <CardProgress percent={watchedPercent} />
                </div>
                <div className="title">
                    S{selectedSeason} E{episode.num}
                </div>
            </Button>
        </div>
    )
}

export default memo(EpisodeItem);