import { useCallback, useContext, useEffect, useState } from "react";
import ListView from "../../../../components/listView/ListView";
import { MoviesPageContext } from "../../MoviesPage";
import CategoryItem from "./categoryItem/CategoryItem";
import useKeydown from "../../../../hooks/useKeydown";
import SearchButton from "./search/SearchButton";
import Search from "./search/Search";
import { SaveCategoriesConfigs } from "@/src/services/content/parser";

const CategoriesSection = () => {

    const {
        contentType,
        currentSection,
        setCurrentSection,
        moviesContent,
        setMoviesContent,
        onBack,
        searchIsOpen,
        moveItemIndex,
        setMoveItemIndex,
    } = useContext(MoviesPageContext);

    const [searchIsActive, setSearchIsActive] = useState(false);

    const [initialActiveIndex, setInitialActiveIndex] = useState(0);

    const categoriesCount = moviesContent.categories_arr.length;

    const isActive = currentSection == "categoriesSection";

    const onUp = useCallback(() => {

        console.log(moveItemIndex);
        
        setSearchIsActive(true);
        setCurrentSection("categoriesSection");

    }, [moveItemIndex]);

    const onDown = useCallback(() => {
        setSearchIsActive(false);
        setCurrentSection("categoriesSection");
    }, []);

    const onRight = useCallback(() => {
        setCurrentSection("moviesSection");
    }, []);

    const moveCategory = (i) =>{

        let nextIndex = moveItemIndex + i;  
            
        if(moveItemIndex == null) return;

        if((moveItemIndex == 0 && i == -1 )|| moveItemIndex == moviesContent.categories_arr.length - 1) return;

        moviesContent.categories_arr.splice(nextIndex, 0, moviesContent.categories_arr.splice(moveItemIndex, 1)[0]);

        setMoveItemIndex(nextIndex);

        setInitialActiveIndex(nextIndex);

        SaveCategoriesConfigs(moviesContent.categories_arr, moviesContent.categories_obj, contentType);

        requestAnimationFrame(() => {
            setMoviesContent({ ...moviesContent });
        });

    }

    useEffect(()=>{

        if(currentSection != 'categoriesSection') setMoveItemIndex(null);

    },[currentSection])

    useKeydown({
        isActive: !searchIsActive && isActive,
        debounce: 100,
        up: ()=>{
            moveCategory(-1);  
        },
        down: ()=>{
            moveCategory(1);
        },
        right: () => {
            if (!searchIsOpen) onRight();
        },
    });

    useKeydown({
        isActive: searchIsActive && isActive,
        down: onDown,
        back: onBack,
        right: () => {
            onRight();
        },
    });

    return (
        <div className="categories-section">
            <div className="section-header">
                <SearchButton
                    isActive={searchIsActive && isActive}
                    onMouseEnter={onUp}
                />
            </div>
            <div className="categories-parent">

                {searchIsOpen ?

                    <Search
                        onUp={onUp}
                        onRight={onRight}
                        isActive={!searchIsActive && isActive}
                        onMouseEnter={onDown}
                    />

                    :

                    <ListView
                        id={initialActiveIndex}
                        uniqueKey={"movies-categories-view"}
                        listType="vertical"
                        itemsTotal={categoriesCount}
                        itemsCount={13}
                        buffer={2}
                        initialActiveIndex={initialActiveIndex}
                        startScrollIndex={5}
                        nativeControle={true}
                        debounce={100}
                        ItemRenderer={CategoryItem}
                        itemWidth={51.7}
                        itemHeight={7.5}
                        onUp={onUp}
                        isActive={!searchIsActive && isActive && moveItemIndex == null}
                        onBack={onBack}
                        onMouseEnter={onDown}
                    />

                }
            </div>
        </div>
    )

};

export default CategoriesSection;