import { memo, useCallback, useContext, useEffect, useState } from "react";
import "./Seasons.scss";
import ListView from "../../../../../components/listView/ListView";
import SeasonItem from "./SeasonItem";
import EpisodeItem from "./EpisodeItem";
import { MovieInfoContext } from "../../MovieInfo";
import useKeydown from "../../../../../hooks/useKeydown";
import Text from "@/src/components/text/Text";

import Storege from "@/src/services/storage/storage";

const Seasons = () => {

    const {
        seasons,
        episodes,
        selectedSeason,
        movieInfo,
        currentBlock,
        setCurrentBlock,
    } = useContext(MovieInfoContext);

    const isActive = currentBlock === "movie-seasons";

    const [currentRow, setCurrentRow] = useState(0);

    const mouseEnterSeasons = useCallback(() => {
        setCurrentRow(0);
        setCurrentBlock("movie-seasons");
    }, []);

    const mouseEnterEpisodes = useCallback(() => {
        setCurrentRow(1);
        setCurrentBlock("movie-seasons");
    }, [])

    useKeydown({
        isActive,
        debounce: 100,
        up: () => {
            if (currentRow === 0) {
                setCurrentBlock("movie-buttons");
            } else {
                setCurrentRow(0);
            }
        },
        down: () => {
            if (currentRow === 0) {
                setCurrentRow(1);
            } else {
                if (movieInfo && movieInfo.casts) {
                    setCurrentBlock("movie-casts");
                }
            }
        }
    });

    if (!seasons.length) return null;

    return (
        <div className="seasons-parent">
            <div className="seasons-title">
                <Text>seasons</Text>
            </div>
            <div className="seasons-list">
                <ListView
                    uniqueKey={"movies-seasons-list"}
                    listType="horizontal"
                    itemsTotal={seasons.length}
                    itemsCount={6}
                    buffer={3}
                    initialActiveIndex={0}
                    startScrollIndex={3}
                    nativeControle={true}
                    debounce={100}
                    ItemRenderer={SeasonItem}
                    itemWidth={29.3}
                    itemHeight={7}
                    isActive={isActive && currentRow === 0}
                    onMouseEnter={mouseEnterSeasons}
                    direction={Storege.getRtlMode() ? "rtl" : "ltr"}
                />
            </div>
            <div className="episodes-list">
                <ListView
                    id={selectedSeason}
                    uniqueKey={"movies-episodes-list"}
                    listType="horizontal"
                    itemsTotal={episodes.length}
                    itemsCount={4}
                    buffer={2}
                    initialActiveIndex={0}
                    startScrollIndex={2}
                    nativeControle={true}
                    debounce={100}
                    ItemRenderer={EpisodeItem}
                    itemWidth={44}
                    itemHeight={31.5}
                    isActive={isActive && currentRow === 1}
                    onMouseEnter={mouseEnterEpisodes}
                    direction={Storege.getRtlMode() ? "rtl" : "ltr"}
                />
            </div>
        </div>
    )
}

export default memo(Seasons);