import Text from "../../../../components/text/Text";
import "./ColoredButton.scss";

const ColoredButton = ({ text, color, icon, onClick = () => { } }) => {
    return (
        <div className="colored-button" onClick={onClick}>

            <div className={"color " + color}></div>

            <div className="title">
                <Text>{text}</Text>
            </div>

            {icon ? <div className="icon">{icon}</div> : null}

        </div>
    );
}

export default ColoredButton;