import { memo, useCallback, useEffect, useMemo, useState } from "react";
import "./PlaylistItem.scss";
import useKeydown from "../../../hooks/useKeydown";
import Button from "../../../components/button/Button";
import { binIcon } from "../../../assets/icons";
import ConfirmPopup from "../../../popups/confirm/ConfirmPopup";
import useStore from "../../../hooks/useStore";

import Storege from "@/src/services/storage/storage";

const PlaylistItem = ({
    style,
    isActive,
    index,
    onMouseEnter = () => { },
    onFocus = () => { },
    onDelete = () => { },
    onClick = () => { },
}) => {

    const [playlists] = useStore("playlists");

    const playlist = playlists[index];

    const [playlistStatus] = useState("");
    const [deleteFocused, setDeleteFocused] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const rtlMode = Storege.getRtlMode();

    const mouseEnter = useCallback(() => {
        setDeleteFocused(false);
        onMouseEnter(index);
        onFocus();
    }, []);

    const click = useCallback(() => {
        onClick(playlist);
    }, []);

    const left = () => {
        setDeleteFocused(false);
    }

    const right = () => {
        setDeleteFocused(true);
    }

    const keyDownOptions = useMemo(() => {
        return {
            isActive: isActive && !confirmDelete,
            debounce: 300,
            left: left,
            right: right,
            up: () => { },
            down: () => { },
        }
    }, [isActive]);

    useKeydown(keyDownOptions);

    useEffect(() => {
        if (!isActive) {
            setDeleteFocused(false);
        }
    }, [isActive]);

    if (!playlist) return null;

    return (
        <div style={style} className="playlist-item-parent">

            <Button
                className={"playlist-item focus-item"}
                isActive={isActive && !deleteFocused && !confirmDelete}
                isSelected={playlist.is_selected}
                onClick={click}
                onMouseEnter={mouseEnter}
            >

                <div className="number">{index + 1}</div>

                <div className={"playlist-info" + (playlist.hide_url ? " hide-url" : "")}>

                    <div className="title-row">
                        <div className="title">{playlist.name}</div>
                        <div className="date">{playlistStatus}</div>
                    </div>

                    <div className="url">
                        {/* {rtlMode ? playlist.url.split("").reverse().join("") : playlist.url} */}
                        {playlist.url}
                    </div>

                </div>

                <Button
                    className={"playlist-item-delete focus-item"}
                    isActive={isActive && deleteFocused && !confirmDelete}
                    activeClassName={"active-danger"}
                    onClick={() => { setConfirmDelete(true) }}
                    onMouseEnter={() => {
                        setDeleteFocused(true);
                        onFocus();
                    }}
                >
                    <img src={binIcon} />
                </Button>

            </Button>

            {confirmDelete && <ConfirmPopup
                title={"delete playlist popup title"}
                message={"delete playlist popup message"}
                confirmText={"yes"}
                cancelText={"no"}
                confirmFocused={true}
                onCancel={() => {
                    setConfirmDelete(false);
                }}
                onConfirm={() => {
                    onDelete(playlist);
                    setConfirmDelete(false);
                }}
            />}

        </div>
    )

}

export default memo(PlaylistItem);