import { memo, useState, useCallback, useEffect, useMemo } from "react";

import QrCode from "@/src/components/qr/QrCode";
import "./AddPlaylistPopup.scss";

import useKeydown from "../../hooks/useKeydown";
import useStore from "../../hooks/useStore";
import Config from "../../config";

import Text from "../../components/text/Text";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import Toast from "../../components/toast/Toast";

import XTREAM from "../../services/api/xtream";
import BACKEND from "../../services/api/backend";

import { isValidHost, isValidPlaylistUrl } from "../../utils/util";

let renderCount = 0;

const AddPlaylistPopup = ({
    onBack = () => { }
}) => {

    renderCount++

    const [deviceInfo] = useStore("deviceInfo");

    const [current, setCurrent] = useState("tabs");

    const [selectedTab, setSelectedTab] = useState("playlist");

    const [activeTab, setActiveTab] = useState("playlist");

    const [activeInputIndex, setActiveInputIndex] = useState(0);

    const [activeBtnIndex, setActiveBtnIndex] = useState(0);

    const [focusedInput, setFocusedInput] = useState(null);

    const [rtlMode] = useStore("rtlMode");

    const [inputs, setInputs] = useState({
        playlist: [
            { label: "name", value: "", helpKeys: [] },
            { label: "url", value: "", helpKeys: ["http://", "https://", "/get.php?", "username=", "&password=", "&type=", "&output="] },
        ],
        xtream: [
            { label: "host", value: "", helpKeys: ["http://", "https://", ".com", ".ru", ":80", ":8080"] },
            { label: "username", value: "", helpKeys: [] },
            { label: "password", value: "", helpKeys: [] },
        ]
    });

    const PopupInfoText = () => {

        return rtlMode ?
            <div className="popup-info-text">
                <span className="active-text-color">{Config.Web_host} </span>:
                <Text>to add playlist from our website visit</Text>
                <br />
                <Text>or Scan the bar code in the left</Text>
            </div>
            :
            <div className="popup-info-text">
                <Text>to add playlist from our website visit</Text>
                :<span className="active-text-color"> {Config.Web_host}</span><br />
                <Text>or Scan the bar code in the right</Text>
            </div>
    }

    const onCancel = () => {
        onBack();
    };

    const onConfirm = async () => {

        let xtream_account = null;

        let name = inputs["playlist"][0].value;
        let url = inputs["playlist"][1].value;

        let host = inputs["xtream"][0].value;
        let username = inputs["xtream"][1].value;
        let password = inputs["xtream"][2].value;

        if (selectedTab == "playlist") {

            if (name == "") return Toast.error({ msg: "Name is required", id: "add-playlist-name" });
            if (url == "") return Toast.error({ msg: "Url is required", id: "add-playlist-url" });

            if (!isValidPlaylistUrl(url)) return Toast.error({ msg: "Incorrect url", id: "add-playlist-url" });

            xtream_account = XTREAM.isXtreamPlaylist(url);

        } else {

            if (host == "") return Toast.error({ msg: "Host is required", id: "add-playlist-host" });
            if (!isValidHost(host)) return Toast.error({ msg: "Incorrect host", id: "add-playlist-host" });

            if (username == "") return Toast.error({ msg: "Username is required", id: "add-playlist-username" });
            if (password == "") return Toast.error({ msg: "Password is required", id: "add-playlist-password" });

            xtream_account = { host, username, password };

            name = username;
            url = `${host}/get.php?username=${username}&password=${password}&type=m3u_plus&output=ts`

        }

        if (xtream_account) {

            try {
                await XTREAM.login(xtream_account);
            } catch (msg) {
                return Toast.error({ msg, id: "add-playlist" });;
            }

        } else {

            // try to ping the url

        }

        try {
            await BACKEND.addPlaylist({ name, url, mac: deviceInfo.mac });
            Toast.success({ msg: "playlist added successfully", id: "add-playlist-success" });
            onBack();
        } catch (msg) {
            Toast.error({ msg, id: "add-playlist" });
        }

    };

    const onBlur = (value) => {

        console.log("blur input");

        setFocusedInput((index) => {

            inputs[selectedTab][index].value = value;

            setInputs({ ...inputs });

            return null

        });

    };

    // tabs
    const onClickTab = useCallback((e, tab) => {
        setSelectedTab(tab);
        setCurrent("tabs");
    }, []);

    const onMouseEnterTab = useCallback((e, tab) => {
        setActiveTab(tab);
        setCurrent("tabs");
    }, []);

    // inputs
    const onClickInput = useCallback((e, index) => {
        setActiveInputIndex(index);
        setCurrent("inputs");
        setFocusedInput(index);
    }, []);

    const onMouseEnterInput = useCallback((e, index) => {
        setActiveInputIndex(index);
        setCurrent("inputs");
    }, []);

    // buttons
    const onMouseEnterBtn = useCallback((e, index) => {
        setActiveBtnIndex(index);
        setCurrent("buttons");
    }, []);

    useKeydown({
        isActive: focusedInput == null,
        debounce: 100,
        back: () => {
            onBack();
        },
        up: () => {

            setCurrent((current) => {

                switch (current) {
                    case "tabs":
                        return "tabs";
                    case "inputs":
                        if (activeInputIndex == 0) return "tabs";
                        setActiveInputIndex(activeInputIndex - 1)
                        return "inputs";
                    case "buttons":
                        setActiveInputIndex(inputs[selectedTab].length - 1)
                        return "inputs";
                }

            });

        },
        down: () => {

            setCurrent((current) => {

                switch (current) {
                    case "tabs":
                        setActiveInputIndex(0)
                        return "inputs";
                    case "inputs":
                        if (activeInputIndex == inputs[selectedTab].length - 1) return "buttons";
                        setActiveInputIndex(activeInputIndex + 1);
                        return "inputs";
                    case "buttons":
                        return "buttons";
                }

            });

        },
        left: () => {

            switch (current) {
                case "tabs":
                    setActiveTab("playlist")
                    break;
                case "buttons":
                    setActiveBtnIndex(0)
                    break;
            }

        },
        right: () => {

            switch (current) {
                case "tabs":
                    setActiveTab("xtream")
                    break;
                case "buttons":
                    setActiveBtnIndex(1)
                    break;
            }

        }
    });

    let qrCodeValue = Config.Add_playlist_host + "?mac=" + deviceInfo.mac;

    if (selectedTab != "playlist") qrCodeValue += "&tab=xtream_account";

    return (
        <div className="add-playlist-popup">

            <div className="popup-title">
                <Text>add playlist</Text>
            </div>

            <div className="popup-buttons">

                <Button
                    isActive={activeTab == "playlist" && current == "tabs"}
                    isSelected={selectedTab == "playlist"}
                    onClick={onClickTab}
                    onMouseEnter={onMouseEnterTab}
                    data="playlist"
                    className="button tab-btn"
                >
                    <Text>playlist</Text>
                </Button>

                <Button
                    isActive={activeTab == "xtream" && current == "tabs"}
                    isSelected={selectedTab == "xtream"}
                    onClick={onClickTab}
                    onMouseEnter={onMouseEnterTab}
                    data="xtream"
                    className="button tab-btn"
                >
                    <Text>xtream account</Text>
                </Button>

            </div>

            <div className="popup-content">

                {inputs[selectedTab].map((input, index) => {
                    return (
                        <Button
                            key={selectedTab + "-" + index}
                            isActive={activeInputIndex == index && current == "inputs"}
                            className={"button"}
                            activeClassName={focusedInput === index ? "focused" : "active"}
                            data={index}
                            onClick={onClickInput}
                            onMouseEnter={onMouseEnterInput}
                        >
                            <Input
                                key={selectedTab + "-" + index}
                                placeholder={input.label}
                                initialValue={input.value}
                                isFocused={focusedInput === index}
                                onBlur={onBlur}
                                onDone={onBlur}
                                onTop={onBlur}
                                onBack={onBlur}
                                helpKeys={input.helpKeys}
                            />
                        </Button>
                    )

                })}

            </div>

            <div className="popup-buttons">

                <Button
                    className="button action-btn"
                    isActive={activeBtnIndex == 0 && current == "buttons"}
                    data={0}
                    onClick={onCancel}
                    onMouseEnter={onMouseEnterBtn}
                >
                    <Text>cancel</Text>
                </Button>

                <Button
                    className="button action-btn"
                    isActive={activeBtnIndex == 1 && current == "buttons"}
                    data={1}
                    onClick={onConfirm}
                    onMouseEnter={onMouseEnterBtn}
                >
                    <Text>ok</Text>
                </Button>

            </div>

            <div className="popup-info">

                <PopupInfoText />

                <div className="popup-info-qr">
                    <QrCode
                        style={{ height: "26rem", width: "26rem" }}
                        size={260}
                        value={qrCodeValue}
                    />
                </div>

            </div>

        </div >
    )

};

export default memo(AddPlaylistPopup);

