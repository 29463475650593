import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import useKeydown from "@/src/hooks/useKeydown";
import useStore from "@/src/hooks/useStore";
import PageLoading from "@/src/components/pageLoading/PageLoading";
import ProgressContainer from "./components/ProgressContainer";
import PlayerError from "@/src/components/playerError/PlayerError";
import AppPlayer from "@/src/components/appPlayer/AppPlayer";
import "./VideoPlayer.scss"

let controlesTimeout = null;
let seekToTimeout = null;

const VideoPlayer = ({
    videoInfo,
    onBack = () => { },
}) => {

    const isActive = true;

    const playerRef = useRef(null);

    const [documentIsHidden] = useStore("documentIsHidden");

    const [isOnline] = useStore("isOnline");

    const [currentTime, setCurrentTime] = useState(0);

    const [duration, setDuration] = useState(0);

    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(true);

    const [isPlaying, setIsPlaying] = useState(true);

    const [isPaused, setIsPaused] = useState(false);

    const [isSeeking, setIsSeeking] = useState(false);

    const [controlesVisible, setControlesVisible] = useState(false);

    const playerConfign = {
        controls: false,
        width: "100%",
        height: "100%",
        url: videoInfo?.url,
        mediatype: videoInfo?.type,
        playing: isPlaying && isOnline && !documentIsHidden,
        onReady: () => {
            setLoading(false);
        },
        onPlay: () => {
            if (!isOnline || documentIsHidden) return;
            setError(false);
            setLoading(false);
            setIsPaused(false);
        },
        onPause: () => {
            if (!isOnline || documentIsHidden) return;
            setLoading(false);
            setIsPaused(true);
        },
        onEnded: () => {
            onBack();
        },
        onBuffer: () => {
            // setLoading(true);
        },
        onBufferEnd: () => {
            setLoading(false);
        },
        onStart: () => {
            setLoading(false);
            setIsPaused(false);
        },
        onError: (e) => {
            setError(true);
            setLoading(false);
        },
        onDuration: (sec) => {
            setDuration(sec);
        },
        onProgress: ({ playedSeconds }) => {

            setError(false);

            setLoading(false);

            if (isSeeking) return;

            setCurrentTime(playedSeconds);

        },
        onSeek: () => {
            setLoading(false);
            setIsSeeking(false);
        },
        config: {
            youtube: {
                playerVars: {
                    showinfo: 1,
                    controls: 0,
                    loop: 0,
                    disablekb: 1,
                    autoplay: 1,
                }
            }
        }
    }

    const playPause = () => {
        toggleControls();
        setIsPlaying(!isPlaying);
    }

    const seekTo = (percent, seconds) => {

        toggleControls();

        let player = playerRef.current;

        if (!player || duration == 0) return;

        if (seconds != null && seconds != undefined) {
            var currentTime = seconds;
        } else {
            var currentTime = percent * duration;
        }

        if (currentTime < 0) currentTime = 0;

        if (currentTime > duration) currentTime = duration;

        if (currentTime == duration) currentTime -= 1;

        if (isNaN(currentTime)) return;

        setCurrentTime(currentTime);

        setIsSeeking(true);

        clearTimeout(seekToTimeout);

        seekToTimeout = setTimeout(() => {

            setLoading(true);

            player.seekTo(currentTime, "seconds");

        }, 1000);

    }

    const toggleControls = () => {

        clearTimeout(controlesTimeout);

        setControlesVisible(true);

        controlesTimeout = setTimeout(() => {
            setControlesVisible(false);
        }, 3000);

    }

    useKeydown({
        name: "video-player",
        isActive: isActive,
        keydown: (e, key) => {
            toggleControls();
        },
        up: () => { },
        down: () => { },
        back: () => {
            onBack();
        },
        play: () => {
            setIsPlaying(true);
        },
        pause: () => {
            setIsPlaying(false);
        },
        stop: () => {
            onBack();
        },
        play_pause: () => {
            setIsPlaying(!isPlaying);
        },
        next: () => {
            seekTo(null, currentTime + 10);
        },
        fast_next: () => {
            seekTo(null, currentTime + 30);
        },
        prev: () => {
            seekTo(null, currentTime - 10);
        },
        fast_prev: () => {
            seekTo(null, currentTime - 30);
        },
    });

    useEffect(() => {

        if (videoInfo) {
            document.body.classList.add("fullscreen");
        } else {
            document.body.classList.remove("fullscreen");
        }

        return () => {
            document.body.classList.remove("fullscreen");
        }

    }, [videoInfo]);

    if (!videoInfo || !isActive) return null

    const isShowingControles = controlesVisible || isPaused;

    return createPortal(<div className="video-player" onClick={playPause} onMouseMove={toggleControls}>

        <AppPlayer ref={playerRef} {...playerConfign} />

        <PlayerError isVisible={error} />

        <div className={"video-player-controller " + (isShowingControles ? "show" : "")}>

            <div className="video-player-title">
                {videoInfo?.name || ""}
            </div>

            <div className="video-player-footer">

                <ProgressContainer
                    isActive={isActive}
                    isPaused={isPaused}
                    currentTime={currentTime}
                    duration={duration}
                    seekTo={seekTo}
                    playPause={playPause}
                // onMouseEnter={() => { }}
                />

            </div>

        </div>

        <PageLoading isVisible={loading && !error && videoInfo?.type != "trailer"} />

    </div>, document.getElementById("root"));

}

export default memo(VideoPlayer);