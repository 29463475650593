import { memo, useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import { Context } from "../LiveTvPage";

const SearchSection = () => {

    const {
        setCurrentSection,
        channelsContent,
        setChannelsContent,
        setCurrentCategoryId
    } = useContext(Context);

    let defaultValue = channelsContent.categories_obj["search"].searchValue || "";

    const [value, setValue] = useState(defaultValue);

    const onBack = useCallback(() => {

        setCurrentSection("channels");

        let search_items = channelsContent.categories_obj["search"].items;

        if (search_items.length == 0) setCurrentCategoryId("all");

    }, [setCurrentSection]);

    useEffect(() => {

        if (value.length == 0) {

            channelsContent.categories_obj["search"].items = channelsContent.items_arr;

        } else {

            let search_items = [];

            let query = value.trim();
            query = query.toLowerCase();

            for (let i = 0; i < channelsContent.items_arr.length; i++) {

                let id = channelsContent.items_arr[i];

                let item = channelsContent.items_obj[id];

                if (item.name.toLowerCase().indexOf(query) > -1) {
                    search_items.push(id);
                }

            }

            // sort items by search query
            search_items.sort((a, b) => {

                let nameA = channelsContent.items_obj[a].name.toLowerCase();
                let nameB = channelsContent.items_obj[b].name.toLowerCase();

                let indexA = nameA.indexOf(query);
                let indexB = nameB.indexOf(query);

                if (indexA < indexB) {
                    return -1;
                } else if (indexA > indexB) {
                    return 1;
                } else {
                    return 0;
                }

            });

            channelsContent.categories_obj["search"].items = search_items;

        }

        channelsContent.categories_obj["search"].searchValue = value;

        setChannelsContent({ ...channelsContent });

    }, [value]);

    useEffect(() => {

        return () => {

            try {

                let search_items = channelsContent.categories_obj["search"].items;

                if (search_items.length == 0) {
                    channelsContent.categories_obj["search"].searchValue = "";
                }

            } catch (e) {
                console.log(e);
            }

        };

    }, []);

    return (

        <div className="live-tv-section search-section">

            <Button
                isActive={true}
                className={"button focus-item"}
                activeClassName={" "}
                onClick={() => { }}
                onMouseEnter={() => { }}
            >
                <Input
                    placeholder={"Search"}
                    initialValue={value}
                    isFocused={true}
                    onDone={onBack}
                    onChange={setValue}
                    onBack={onBack}
                    helpKeys={[]}
                    keyboardClassName={"live-tv-keyboard"}
                />
            </Button>

        </div>

    )

};

export default memo(SearchSection);