const useResize = () => {

    let fontSize = 10; // 1rem = 10px (default) 1920x1080

    let k = window.innerWidth / 1920;

    fontSize = fontSize * k;

    document.documentElement.style.fontSize = fontSize + "px";

}

window.addEventListener("load", useResize);

window.addEventListener("resize", useResize);

useResize();