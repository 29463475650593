import { useContext } from "react";
import { Context } from "../pages/liveTv/LiveTvPage";
import Storege from "../services/storage/storage";

/**
 * 
 * @param {string} channelId 
 * @returns {[boolean, () => void]} [isFavorite, toggelFavorite]
 */
const useFavoriteChannel = (channelId) => {

    const {
        channelsContent,
        setChannelsContent,
        currentCategoryId,
        setCurrentCategoryId
    } = useContext(Context);

    const favorites = channelsContent.categories_obj["favorites"]?.items || [];

    const favoriteIndex = favorites.indexOf(channelId);

    const isFavorite = favoriteIndex > -1;

    const toggelFavorite = () => {

        if (favoriteIndex > -1) {

            favorites.splice(favoriteIndex, 1);

            // if favorites is empty and current category is favorites then set current category to all
            if (favorites.length == 0 && currentCategoryId == "favorites") {
                setCurrentCategoryId("all");
            }

        } else {
            favorites.unshift(channelId);
        }

        Storege.setFavorites("channels", favorites);

        setChannelsContent({ ...channelsContent });

    }

    if (!channelId) return [false, () => { }];

    return [isFavorite, toggelFavorite];

};

export default useFavoriteChannel;