import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./services/store/store";
import Layout from './layout/Layout';
import "./hooks/useResize";
import "./assets/styles/index.scss";
import "./branding/index.scss";

import Config from './config';

const Root = document.getElementById('root');

Root.classList.add(Config.APP_NAME);

createRoot(Root).render(<Provider store={store}><Layout /></Provider>);