import { memo, useCallback, useState, createContext } from "react";
import Text from "../../components/text/Text";
import ListView from "../../components/listView/ListView";
import Button from "../../components/button/Button";
import FooterInfo from "../../components/footerInfo/FooterInfo";
import AddVideoPopup from "./addVideoPopup/AddVideoPopup";
import VideoItem from "./videoItem/VideoItem";
import useKeydown from "@/src/hooks/useKeydown";
import { useEffect } from "react";
import VideoPlayer from "@/src/components/vodeoPlayer/VideoPlayer";
import "./VideosPage.scss";
import BACKEND from "@/src/services/api/backend";
import socket from "@/src/services/socket/socket";
import Toast from "@/src/components/toast/Toast";
import { plusBlackIcon, plusWhiteIcon } from "@/src/assets/icons";

export const VideoPageContext = createContext();

const VideosPage = ({ onBack = () => { } }) => {

    const [current, setCurrent] = useState("videoList");

    const [videos, setVideos] = useState([]);

    const [videoInfo, setVideoInfo] = useState(null);

    const setFocuse = (current) => () => setCurrent(current);

    const getVideos = async () => {

        try {

            let videos = await BACKEND.getVideos();

            if (videos.length) {
                setVideos(videos);
                setCurrent("videoList");
            } else {
                setCurrent("addVideoPopup");
            }

        } catch (e) { }

    }

    const hodePopup = () => {

        if (videos.length) {
            setCurrent("addBtn");
        } else {
            onBack();
        }

    };

    const playVideo = useCallback((video) => {

        let type = video.url.includes("youtube") ? "trailer" : "video";

        setVideoInfo({ ...video, type });
        setCurrent("videoPlayer");

    }, []);

    const closePlayer = useCallback(() => {
        setVideoInfo(null);
        setCurrent("videoList");
    }, []);

    const addVideo = async (video) => {

        if (!video) return;

        try {

            let position = 0;

            if (videos.length) position = videos[videos.length - 1].position + 1;

            video.position = position;

            await BACKEND.addVideo(video);

            getVideos();

        } catch (e) {
            Toast.error({ msg: e.message || e, id: "addVideo", time: 3000 });
            console.log("addVideo error:", e);
        }

    }

    const deleteVideo = useCallback(async (index) => {

        let video = videos[index];

        if (!video) return;

        let ids = [video.id];

        try {

            await BACKEND.deleteVideo({ ids });

            videos.splice(index, 1);

            setVideos([...videos]);

            if (!videos.length) setCurrent("addVideoPopup");

        } catch (e) {
            console.log("deleteVideo error:", e);
        }

    }, [videos]);

    useEffect(() => {

        getVideos();

        let unsubscribeSocket = socket.on("update_videos", getVideos);

        return () => {
            unsubscribeSocket();
        }

    }, []);

    useKeydown({
        isActive: current == "addBtn",
        down: () => {
            if (videos.length) setCurrent("videoList");
        },
        back: onBack,
    });

    return (

        <VideoPageContext.Provider
            value={{
                current,
                setCurrent,
                //
                videos,
                setVideos,
                //
                playVideo,
                deleteVideo,
                //
            }}
        >

            <div className="video-page">

                {current == "addVideoPopup" && <AddVideoPopup onBack={hodePopup} addVideo={addVideo} />}

                {current != "addVideoPopup" && <div className="container">

                    <div className="header">
                        <div className="title">
                            <Text>videos</Text>
                        </div>
                        <Button
                            className="add-button focus-item"
                            isActive={current == "addBtn"}
                            onClick={setFocuse("addVideoPopup")}
                            onMouseEnter={setFocuse("addBtn")}
                        >
                            {/* <div className="bg-icon plus"></div> */}
                            <img src={current == "addBtn" ? plusBlackIcon : plusWhiteIcon} />
                        </Button>
                    </div>

                    <div className="video-list">
                        <ListView
                            uniqueKey={"video-list-view"}
                            listType="vertical"
                            itemsTotal={videos.length}
                            itemsCount={7}
                            buffer={2}
                            initialActiveIndex={0}
                            startScrollIndex={5}
                            nativeControle={true}
                            debounce={100}
                            ItemRenderer={VideoItem}
                            itemWidth={100}
                            itemHeight={12}
                            isActive={current == "videoList"}
                            onUp={setFocuse("addBtn")}
                            onDown={() => { }}
                            onLeft={() => { }}
                            onRight={() => { }}
                            onMouseEnter={setFocuse("videoList")}
                            onBack={onBack}
                        />
                    </div>

                </div>}

                <FooterInfo />

            </div>

            {videoInfo && <VideoPlayer videoInfo={videoInfo} onBack={closePlayer} />}

        </VideoPageContext.Provider>
    )

}

export default memo(VideosPage);