import { memo, useCallback, useEffect, useState } from "react";
import PlaylistItem from "./playlistItem/PlaylistItem";
import ListView from "../../components/listView/ListView";
import useKeydown from "../../hooks/useKeydown";
import Text from "../../components/text/Text";
import "./PlaylistPage.scss";
import useStore from "../../hooks/useStore";
import AddPlaylistPopup from "../../popups/addPlaylist/AddPlaylistPopup";
import Button from "../../components/button/Button";
import Toast from "../../components/toast/Toast";
import PlaylistLoading from "./playlistLoading/PlaylistLoading";
import BACKEND from "../../services/api/backend";
import XTREAM from "../../services/api/xtream";
import FooterInfo from "../../components/footerInfo/FooterInfo";

import { plusBlackIcon, plusWhiteIcon } from "@/src/assets/icons";

const PlaylistPage = ({ onBack = () => { } }) => {

    const [playlists, setPlaylists] = useStore("playlists");
    const [current, setCurrent] = useState("playlist");

    const [playlistLoading, setPlaylistLoading] = useState(false);
    const [loadedPercent, setLoadedPercent] = useState(0);
    const [loadedMB, setLoadedMB] = useState(0);
    const [playlistLoadingStatus, setPlaylistLoadingStatus] = useState("loading");

    const getPlaylists = async () => {

        try {
            let _playlits = await BACKEND.getPlaylists();
            setPlaylists(_playlits);
        } catch (msg) {
            Toast.error({ msg });
        }

    }

    const hideLoading = () => {
        XTREAM.abortAll();
        BACKEND.abortAll();
        setPlaylistLoading(false);
        setLoadedMB(0);
        setLoadedPercent(0);
    }

    const onFocusPlaylist = useCallback(() => {
        setCurrent("playlist");
    }, []);

    const onDeletePlaylist = useCallback(async (playlist) => {

        try {

            await BACKEND.deletePlaylist({ id: playlist.id });

            Toast.success({ msg: "playlist deleted successfully", time: 2000 });

            getPlaylists();

        } catch (e) {
            console.log(e);
        }

    }, [playlists]);

    const onSelectPlaylist = useCallback(async (playlist) => {

        if (playlistLoading) return;

        setPlaylistLoading(true);
        setPlaylistLoadingStatus("loading");

        let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);

        try {

            if (xtream_account) {

                await XTREAM.login(xtream_account);

                try {
                    await BACKEND.setSelectedPlaylist({ playlists: [playlist.id] });
                } catch (e) {
                    console.log(e);
                }

            } else {

                let blob = await BACKEND.downloadPlaylist(playlist.url, (percent, mb) => {
                    setPlaylistLoadingStatus("downloading");
                    setLoadedPercent(percent);
                    setLoadedMB(mb);
                });

                setLoadedPercent(0);

                let data = new FormData();
                data.append("id", playlist.id);
                data.append("data", new Blob([blob], { type: "plain/text" }), playlist.id + ".m3u");

                await BACKEND.parsePlaylist(data, (percent) => {

                    setLoadedPercent(percent);

                    if (percent == 100) {
                        setPlaylistLoadingStatus("parsing");
                    } else {
                        setPlaylistLoadingStatus("uploading");
                    }

                });

                try {
                    await BACKEND.setSelectedPlaylist({ playlists: [playlist.id] });
                } catch (e) {
                    console.log(e);
                }

            }

            hideLoading();
            getPlaylists();
            Toast.success({ msg: "playlist selected successfully", time: 1000 });

        } catch (msg) {
            hideLoading();
            Toast.error({ msg, id: "add-playlist" });
        }

    }, [playlists]);

    const playlistItem = useCallback((props) => {

        return <PlaylistItem
            {...props}
            onFocus={onFocusPlaylist}
            onDelete={onDeletePlaylist}
            onClick={onSelectPlaylist}
        />

    }, [playlists]);

    const showAddPlaylistPopup = useCallback(() => {
        setCurrent("addPlaylistPopup");
    }, []);

    const hideAddPlaylistPopup = useCallback(() => {

        if (playlists.length) {
            setCurrent("addBtn");
            getPlaylists();
        } else {
            onBack();
        }

    }, [playlists]);

    const playlistUp = useCallback(() => {
        requestAnimationFrame(() => {
            setCurrent("addBtn");
        });
    }, []);

    useKeydown({
        isActive: current == "addBtn",
        debounce: 100,
        back: () => {
            onBack();
        },
        down: () => {
            if (playlists.length) {
                setCurrent("playlist");
            }
        }
    });

    useEffect(() => {
        if (!playlists.length) {
            setCurrent("addPlaylistPopup");
        } else {
            if (current == "addPlaylistPopup") setCurrent("addBtn");
        }
    }, [playlists]);

    return (

        <div className="playlist-page">

            {playlistLoading && <PlaylistLoading
                percent={loadedPercent}
                mb={loadedMB}
                status={playlistLoadingStatus}
                onBack={() => { hideLoading(); }}
            />}

            {current == "addPlaylistPopup" && <AddPlaylistPopup onBack={hideAddPlaylistPopup} />}

            {current != "addPlaylistPopup" && <div className="container">

                <div className="header">
                    <div className="title">
                        <Text>playlists</Text>
                    </div>
                    <Button
                        className="add-button focus-item"
                        isActive={current == "addBtn"}
                        onClick={showAddPlaylistPopup}
                        onMouseEnter={() => { setCurrent("addBtn") }}
                    >
                        <img src={current == "addBtn" ? plusBlackIcon : plusWhiteIcon} />
                    </Button>
                </div>

                <div className="playlist-list">
                    <ListView
                        uniqueKey={"playlist-list-view"}
                        listType="vertical"
                        itemsTotal={playlists.length}
                        itemsCount={7}
                        buffer={2}
                        initialActiveIndex={0}
                        startScrollIndex={5}
                        nativeControle={true}
                        debounce={100}
                        ItemRenderer={playlistItem}
                        itemWidth={100}
                        itemHeight={12}
                        isActive={current == "playlist"}
                        onUp={playlistUp}
                        onDown={() => { }}
                        onLeft={() => { }}
                        onRight={() => { }}
                        onBack={onBack}
                    />
                </div>

            </div>}

            <FooterInfo />

        </div>
    )

}

export default memo(PlaylistPage);