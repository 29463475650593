import { useEffect, useState } from "react";

import AppBackground from "../branding/AppBackground";
import SplashLoading from "./splashLoading/SplashLoading";
import MenuPage from "../pages/menu/MenuPage";
import Toast from "../components/toast/Toast";
import Config from "../config";

import ConnectionPopup from "../popups/connection/ConnectionPopup";
import ExitPopup from "../popups/exitPopup/ExitPopup";

import useStore from "../hooks/useStore";
import useVisibility from "../hooks/useVisibility";

import Platform from "../services/platform/platform";
import Storege from "../services/storage/storage";
import Socket from "../services/socket/socket";
import BACKEND from "../services/api/backend";

import "../services/content/content";
import "../services/api/subtitles";
import "../assets/icons"

import ColorButtons from "../popups/colorButtons/ColorButtons";

export default function () {

    const [current, setCurrent] = useState("loading");
    const [prevExitState, setPrevExitState] = useState("");

    const [token, setToken] = useStore("token");
    const [platform, setPlatform] = useStore("platform");
    const [deviceInfo, setDeviceInfo] = useStore("deviceInfo");
    const [playlists, setPlaylists] = useStore("playlists");
    const [language, setLanguage] = useStore("language");
    const [subtitleSettings, setSubtitleSettings] = useStore("subtitleSettings");
    const [rtlMode, setRtlMode] = useStore("rtlMode");

    useEffect(() => {
        // set arabic direction
        if (rtlMode) {
            document.dir = "rtl";
            document.body.classList.remove("ltr");
            document.body.classList.add("rtl");
        } else {
            document.dir = "ltr";
            document.body.classList.remove("rtl");
        }
    }, [rtlMode])

    const registerDevice = async () => {

        var requestError = false;

        try {

            let { mac, model, version } = platform;

            let app_version = Config.APP_VERSION;

            var { device, token, playlists } = await BACKEND.registerDevice({ mac, model, os_version: version, app_version });

            Storege.setDevice(device);
            Storege.setToken(token);
            Storege.setPlaylists(playlists);

        } catch (e) {

            requestError = true;

            setTimeout(() => { registerDevice(); }, 10000);

        }

        if (requestError) {
            var device = Storege.getDevice();
            var token = Storege.getToken();
            var playlists = Storege.getPlaylists();
        }

        if (device && token && playlists) {

            console.log("setDeviceInfo ");

            setDeviceInfo(device);
            setToken(token);
            setPlaylists(playlists || []);

            setCurrent("menuPage");

        }

    }

    const initPlatform = async () => {

        try {

            let platform = new Platform();
            await platform.init();
            setPlatform(platform);

        } catch (e) {

            console.log("initPlatform error:", e);

            setTimeout(() => { initPlatform(); }, 1000);

        }

    }

    const exitApp = () => {
        hideExitPopup();
        platform.exit();
    }

    const hideExitPopup = () => {
        setCurrent(prevExitState);
        setPrevExitState("");
    }

    const showExitPopup = () => {

        if (platform.model == "android") return exitApp();

        if (current == "exit") return;

        setPrevExitState(current);

        setCurrent("exit");

    }

    // setup socket
    useEffect(() => {

        if (!deviceInfo) return;

        let { mac } = deviceInfo;

        Socket.connect(token, mac);

        const unsubscribeActiveEvent = Socket.on("activate", () => {
            setCurrent("loading");
            registerDevice();
        });

        const unsubscribeDeactivateEvent = Socket.on("deactivate", () => {
            setCurrent("loading");
            registerDevice();
        });

        return () => {
            Socket.disconnect();
            unsubscribeActiveEvent();
            unsubscribeDeactivateEvent();
        }

    }, [deviceInfo]);

    useEffect(() => {

        if (!platform) return;

        registerDevice();

    }, [platform]);

    useEffect(() => {

        initPlatform();

        setLanguage(Storege.getLanguage());

        setSubtitleSettings(Storege.getSubtitleSettings());
        setRtlMode(Storege.getRtlMode());

    }, []);

    useVisibility();

    return (
        <>
            <AppBackground />
            <SplashLoading isVisible={current == "loading"} />
            <MenuPage isVisible={current == "menuPage"} onBack={showExitPopup} />
            <ExitPopup isVisible={current == "exit"} onCancel={hideExitPopup} onExit={exitApp} />
            <ConnectionPopup />
            <Toast />
            <ColorButtons />
        </>
    )
}