import { memo, useContext } from "react";
import { MoviesPageContext } from "../../MoviesPage";
import Text from "../../../../components/text/Text";
import { searchWhite } from "../../../../assets/icons";
import ColoredButton from "@/src/pages/liveTv/components/coloredButton/ColoredButton";

const MoviesSectionTitle = () => {

    const {
        moviesContent,
        currentSection,
        currentCategoryId,
        searchIsOpen,
        searchQuery
    } = useContext(MoviesPageContext);

    const category = moviesContent.categories_obj[currentCategoryId];

    let title = category?.name;

    if (searchIsOpen) {

        if (searchQuery) {
            title = <span>{searchQuery}</span>;
        } else {
            title = <span><Text>search</Text></span>;
        }

        title = <> <img src={searchWhite} /> {title}</>

    }


    const clickButton = (keyCode) => () => {

        let data = { bubbles: true, cancelable: true, keyCode: keyCode }

        window.dispatchEvent(new KeyboardEvent("keydown", data));
        window.dispatchEvent(new KeyboardEvent("keyup", data));

    }

    return (
        <div className={"movies-section-title " + (searchIsOpen ? "search-is-open" : "")}>
            <div className="title">{title}</div>

            {
                currentSection == "categoriesSection" && !searchIsOpen &&  <ColoredButton text="move" color="green" onClick={clickButton(404)} />
            }
           
        </div>
    )

};

export default memo(MoviesSectionTitle);