
import { useRef, memo } from "react";
import "./Progress.scss";
import useKeydown from "../../hooks/useKeydown";

import Storege from "@/src/services/storage/storage";

let count = 0;
let prevDir = "right";
let timeout = null;

const Progress = ({
    isActive,
    value = 0,
    total = 0,
    onClick = () => { },
    onChange = () => { },
    onMouseEnter = () => { }
}) => {

    let percent = value / total;

    if (percent > 1) percent = 1;
    if (percent < 0) percent = 0;

    if (!percent || (value == 0 && total == 0)) percent = 0;

    const progressParent = useRef(null);

    const rtlMode = Storege.getRtlMode();

    const onMouseDown = (e) => {

        e.stopPropagation();
        e.preventDefault();

        const parent = progressParent.current;

        if (!parent) return

        const parentRect = parent.getBoundingClientRect();

        function mousemove(e) {

            var percent = (e.clientX - parentRect.left) / parentRect.width;

            if (percent > 1) percent = 1;
            if (percent < 0) percent = 0;

            onChange(percent)

        }

        mousemove(e);

        document.body.addEventListener("mousemove", mousemove);

        function mouseup(e) {
            document.body.removeEventListener("mousemove", mousemove);
            document.body.removeEventListener("mouseup", mouseup);
        }

        document.body.addEventListener("mouseup", mouseup);
        document.body.addEventListener("mouseleave", mouseup);

    }

    const change = (dir) => {

        let step = 10;

        if (dir != prevDir) count = 0;

        prevDir = dir;

        if (count > 100) {
            step = 60;
        } else if (count > 50) {
            step = 45;
        } else if (count > 30) {
            step = 30;
        } else if (count > 10) {
            step = 15;
        }

        count++;

        if (dir == "left") {
            value -= step;
        } else {
            value += step;
        }

        if (value > total) value = total;
        if (value < 0) value = 0;

        onChange(value / total);

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            count = 0;
        }, 2000);

    }

    useKeydown({
        name: "progress",
        isActive,
        right: () => { change(rtlMode ? "left" : "right") },
        left: () => { change(rtlMode ? "right" : "left") },
        ok: () => { onClick() },
    });

    return (
        <div
            ref={progressParent}
            className={"progress" + (isActive ? " active" : "")}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onClick={(e) => { e.stopPropagation() }}
        >
            <div className="progress-bar prograss-bg" style={{ width: percent * 100 + "%" }}>
            </div>
        </div>
    )

}

export default memo(Progress);