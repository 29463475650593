import { memo, useCallback, useContext, useRef } from "react";
import { MoviesPageContext } from "../../MoviesPage";
import GridView from "../../../../components/listView/GridView";
import MovieItem from "./movieItem/MovieItem";
import MoviesSectionTitle from "./MoviesSectionTitle";
import NoResult from "./NoResult";

const MoviesSection = () => {

    const {
        currentSection,
        setCurrentSection,
        moviesContent,
        currentCategoryId,
        searchIsOpen,
        searchItems,
    } = useContext(MoviesPageContext);

    const moviesSectionRef = useRef();

    if (searchIsOpen) {
        var categorieId = "search";
        var moviesCount = searchItems.length;
    } else {
        var categorieId = currentCategoryId;
        var moviesCount = moviesContent.categories_obj[currentCategoryId]?.items.length || 0;
    }

    const isActive = currentSection == "moviesSection";

    if (isActive && moviesCount == 0) setCurrentSection("categoriesSection");

    // show/hide title
    const onChangeRow = useCallback((index) => {

        if (moviesSectionRef.current) {

            if (index == 1) {
                moviesSectionRef.current.classList.remove("hide-title");
            } else {
                moviesSectionRef.current.classList.add("hide-title");
            }

        }

    }, []);

    const onBack = useCallback(() => {
        setCurrentSection("categoriesSection");
    }, []);

    const onMouseEnter = useCallback(() => {
        setCurrentSection("moviesSection");
    }, []);

    return (
        <div className="movies-section" ref={moviesSectionRef}>
            <MoviesSectionTitle />
            <div className="movies-parent">
                {moviesCount ? <GridView
                    id={categorieId + "-" + moviesCount}
                    uniqueKey={"movies-categories-view"}
                    listType="vertical"
                    itemsTotal={moviesCount}
                    rowItemsCount={4}
                    rowCount={2}
                    bufferStart={2}
                    bufferEnd={3}
                    nativeControle={true}
                    debounce={200}
                    scrollOffset={36}
                    ItemRenderer={MovieItem}
                    itemWidth={34.56}
                    itemHeight={56}
                    isActive={isActive}
                    onMouseEnter={onMouseEnter}
                    onChangeRow={onChangeRow}
                    onBack={onBack}
                // onLeft={onBack}
                /> : <NoResult />}
            </div>
        </div>
    )

};

export default memo(MoviesSection);