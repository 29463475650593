// iptv-player-io
// iptv-ott-player
// iptv-playlist-player
// iptv-stream-player
// iptv-play
// iptv-player
// iptv-plus
// iptv-pro
// iptv-next
// iptv-star

import versionJson from "../version.json";

const APP_VERSION = versionJson.version;
// const APP_NAME = process.env.APP_NAME || "beno";
const APP_NAME =  "beno";

console.clear();
console.log(APP_NAME, APP_VERSION);

let Config = require(`./branding/${APP_NAME}/Config`).default;

Config.APP_VERSION = APP_VERSION;
Config.APP_NAME = APP_NAME;

export default Config;
