import { memo, useCallback, useContext } from "react";
import "./Casts.scss";
import ListView from "../../../../../components/listView/ListView";
import CastItem from "./CastItem";
import { MovieInfoContext } from "../../MovieInfo";
import useKeydown from "../../../../../hooks/useKeydown";
import Text from "@/src/components/text/Text";

import Storege from "@/src/services/storage/storage";

const Casts = () => {

    const {
        movieInfo,
        currentBlock,
        setCurrentBlock,
    } = useContext(MovieInfoContext);

    const isActive = currentBlock === "movie-casts";

    useKeydown({
        isActive,
        debounce: 100,
        up: () => {
            if (movieInfo && movieInfo.seasons) {
                setCurrentBlock("movie-seasons");
            } else {
                setCurrentBlock("movie-buttons");
            }
        }
    });

    const onMouseEnter = useCallback(() => {
        setCurrentBlock("movie-casts");
    }, []);

    if (!movieInfo || !movieInfo.casts) return null;

    return (
        <div className="casts-parent">
            <div className="casts-title">
                <Text>cast</Text>
            </div>
            <div className="casts-list">
                <ListView
                    uniqueKey={"movies-cast-list"}
                    listType="horizontal"
                    itemsTotal={movieInfo.casts.length}
                    itemsCount={6}
                    buffer={3}
                    initialActiveIndex={0}
                    startScrollIndex={3}
                    nativeControle={true}
                    debounce={100}
                    ItemRenderer={CastItem}
                    itemWidth={29.3}
                    itemHeight={40}
                    isActive={isActive}
                    onMouseEnter={onMouseEnter}
                    direction={Storege.getRtlMode() ? "rtl" : "ltr"}
                />
            </div>
        </div>
    );
};

export default memo(Casts);