import { memo } from "react";
import Text from "../../components/text/Text";

import Button from "../../components/button/Button";
import Config from "@/src/config";

const MenuItems = {
    TV: { title: "live tv", className: "tv-item", icon: Config.Menu.TV.icon },
    MOVIES: { title: "movies", className: "movies-item", icon: Config.Menu.MOVIES.icon },
    SERIES: { title: "series", className: "series-item", icon: Config.Menu.SERIES.icon },
    SETTINGS: { title: "settings", className: "settings-item", icon: Config.Menu.SETTINGS.icon },
    // PLAYLISTS: { titlt: "playlists", className: "playlist-item", icon: Config.Menu.PLAYLISTS.icon },
    RELOAD: { title: "reload", className: "reload-item", icon: Config.Menu.RELOAD.icon },
}

const MenuItem = ({ name, isActive, onMouseEnter, onClick }) => {

    const { title, className, icon } = MenuItems[name];

    return (
        <Button
            isActive={isActive}
            className={className + " menu-item"}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            data={name}
        >

            <div className="menu-item-bg"></div>

            {icon}

            <div className="title"><Text>{title}</Text></div>

        </Button>
    )

};

export default memo(MenuItem);