function Icon () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 511.999 511.999"
        >
            <path
                d="M504.251 399.55L305.495 55.296C295.164 37.402 276.66 26.72 255.999 26.72s-39.165 10.683-49.496 28.576L7.748 399.55c-10.331 17.894-10.331 39.259 0 57.153 10.331 17.893 28.834 28.576 49.496 28.576h397.511c20.661 0 39.165-10.683 49.496-28.576 10.33-17.894 10.33-39.259 0-57.153zm-26 42.141c-4.905 8.494-13.688 13.565-23.496 13.565H57.244c-9.808 0-18.592-5.071-23.496-13.565s-4.904-18.637 0-27.131L232.503 70.306c4.905-8.494 13.688-13.565 23.496-13.565 9.808 0 18.592 5.071 23.496 13.565L478.251 414.56c4.904 8.494 4.904 18.637 0 27.131z"
                data-original="#000000"
            ></path>
            <path
                d="M255.999 355.183c-11.036 0-20.015 8.979-20.015 20.015 0 11.036 8.979 20.015 20.015 20.015 11.036 0 20.015-8.979 20.015-20.015 0-11.036-8.979-20.015-20.015-20.015zm-15.012-185.139h30.022v155.115h-30.022z"
                data-original="#000000"
            ></path>
        </svg>
    );
}

export default Icon;