import { io } from "socket.io-client";
import Config from "../../config";
import storeSubscribe from "../store/subscribe";
import BACKEND from "../api/backend";

const [setPlaylists] = storeSubscribe("playlists", (playlists) => { });

class Socket {

    constructor() {
        this.socket = null;
    }

    async getPlaylists () {

        try {
            let playlits = await BACKEND.getPlaylists();
            setPlaylists(playlits);
        } catch (e) { }

    }

    connect (token, mac) {

        if (!token && !mac) return;

        if (this.socket) this.socket.disconnect();

        this.socket = io(Config.Backend_host, { extraHeaders: { token, mac } });

        this.socket.on("connect", () => {
            console.log("Socket connected !!!");
            this.socket.emit("join", { mac });
        });

        this.socket.on("connect_error", (err) => {
            console.log("Socket connect error !!!", err.message);
        });

        this.socket.on("show_code", (message) => {
            // show code popup
        });

        this.socket.on("activate", () => {
            // show activation popup
        });

        this.socket.on("deactivate", () => {
            // remove token and redirect to index.html
        });

        this.socket.on("activate playlist", () => {
            this.getPlaylists();
        });

        this.socket.on("update playlist", () => {
            this.getPlaylists();
        });

        this.socket.on("deactivate playlist", () => {
            this.getPlaylists();
        });

        this.socket.on("notification", (data) => {
            // render notifications
        });

    }

    disconnect () {

        try {
            if (this.socket) this.socket.disconnect();
        } catch (e) {
            console.log(e);
        }

    }

    on (event = "", callback = () => { }) {

        try {

            this.socket.on(event, callback);

            return () => {
                this.socket.off(event, callback);
            };

        } catch (e) {

            console.log("Socket on error", e.message);

            return () => { };

        }

    }

    emit (event = "", data = {}) {

        try {
            this.socket.emit(event, data);
        } catch (e) {
            console.log(e);
        }

    }

}

export default new Socket();