import { useContext } from "react";
import { Context } from "../LiveTvPage";
import { sadIcon } from "@/src/assets/icons";
import Text from "@/src/components/text/Text";

const NoResult = () => {

    const { currentCategoryId } = useContext(Context);

    return (
        <div className="no-result-found">
            <img src={sadIcon} />
            <Text>{currentCategoryId == "search" ? "no result found" : "no channels in this category"}</Text>
        </div>
    )

};

export default NoResult;