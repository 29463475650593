
import Button from "../button/Button";
import { useState } from "react";
import { forwardIcon,backwardIcon,playWhiteIcon,pauseWhiteIcon } from "@/src/assets/icons";
import './PlayerButtons.scss';
import useKeydown from "@/src/hooks/useKeydown";

const items = ['backward','play','forward'];

const PlayerButtons = ({ 
    isActive, 
    isPaused,
    playPause, 
    onMouseEnter ,
    seekTo,
    currentTime
    }) => {

        const [activeIndex,setActiveIndex] = useState(1);


        const handleSetIcon = (item) => {

            if(item === 'play'){
                return isPaused ? playWhiteIcon :pauseWhiteIcon
            }

            return  item == 'forward' ? forwardIcon : backwardIcon
        }

        const handleMouseEnter = (e,data) => {

            let index = items.indexOf(data);

            if(index > -1)setActiveIndex(index);
            
            onMouseEnter();
        }
   

        const handleItemCklck = (e,data) => {

            if(data === 'play'){
               
                playPause();
           
            }else{
                let time = data == 'forward' ? 10 : -10;

                seekTo(null, currentTime +time);
            
            }
            
        }


        useKeydown({
            isActive,
            left: () => {
                setActiveIndex((prev ) =>  {
                    if(prev === 0) return 0;
                    return prev - 1;
                });
            },
            right: () => {
                setActiveIndex((prev ) => {
                    if(prev == items.length - 1) return items.length - 1;
                    return prev + 1;
                });
            },
        })
        
    return (
        <div className="player-buttons">

            {
                items.map((item,index) => {
                    return (
                        <Button 
                            key={`player-button-${index}`}
                            onClick={handleItemCklck}
                            onMouseEnter={handleMouseEnter}
                            className={`player-button ${item}`}
                            isActive={isActive && activeIndex === index}
                            data={item}
                        >
                           <img src={handleSetIcon(item)} alt={item} />
                        </Button>
                    )
                })
            }
               
          </div>
    )
      
    
}







export default PlayerButtons