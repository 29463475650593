import storeSubscribe from "../store/subscribe";

let current_playlist = null;

storeSubscribe("playlist", (playlist) => {
    current_playlist = playlist;
});

const Storege = {

    getTimeSettings: () => {
        return Storege.get("timeSettings") || { hours: 0, minutes: 0 };
    },

    setTimeSettings: (value) => {
        Storege.set("timeSettings", value);
    },

    getDevice: () => {
        return Storege.get("device") || null;
    },

    setDevice: (data) => {
        Storege.set("device", data);
    },

    getToken: () => {
        return Storege.get("token") || "";
    },

    setToken: (token) => {
        return Storege.set("token", token);
    },

    getPlaylists: () => {
        return Storege.get("playlists") || [];
    },

    setPlaylists: (playlists) => {
        return Storege.set("playlists", playlists);
    },

    getFavorites: (type) => {

        let playlistData = Storege.getPlaylistData();

        let favorites = []

        switch (type) {
            case "channels":
                favorites = playlistData.favoriteChannels;
                break;
            case "movies":
                favorites = playlistData.favoriteMovies;
                break;
            case "series":
                favorites = playlistData.favoriteSeries;
                break;

        }

        return favorites || [];

    },

    setFavorites: (type, value) => {

        let playlistData = Storege.getPlaylistData();

        switch (type) {
            case "channels":
                playlistData.favoriteChannels = value;
                break;
            case "movies":
                playlistData.favoriteMovies = value;
                break;
            case "series":
                playlistData.favoriteSeries = value;
                break;

        }

        Storege.setPlaylistData(playlistData);

    },

    getCategoriesConfigs: (type) => {

        let playlistData = Storege.getPlaylistData();

        let categoriesConfigs = null;

        switch (type) {
            case "movies":
                categoriesConfigs = playlistData.categoriesConfigsMovies;
                break;
            case "series":
                categoriesConfigs = playlistData.categoriesConfigsSeries;
                break;
            case "channels":
                categoriesConfigs = playlistData.categoriesConfigsChannels;
                break;
        }

        return categoriesConfigs || {};

    },

    setCategoriesConfigs: (type, value) => {

        let playlistData = Storege.getPlaylistData();

        switch (type) {
            case "movies":
                playlistData.categoriesConfigsMovies = value;
                break;
            case "series":
                playlistData.categoriesConfigsSeries = value;
                break;
            case "channels":
                playlistData.categoriesConfigsChannels = value;
                break;
        }
        console.log(playlistData);
        
        Storege.setPlaylistData(playlistData);

    },

    getContinueWatching: (type) => {

        let playlistData = Storege.getPlaylistData();

        let contineWatching = null;

        switch (type) {
            case "movies":
                contineWatching = playlistData.contineWatchingMovies;
                break;
            case "series":
                contineWatching = playlistData.contineWatchingSeries;
                break;
        }

        return contineWatching || { items: [], items_obj: {} };

    },

    setContinueWatching: (type, value) => {

        let playlistData = Storege.getPlaylistData();

        switch (type) {
            case "movies":
                playlistData.contineWatchingMovies = value;
                break;
            case "series":
                playlistData.contineWatchingSeries = value;
                break;

        }

        Storege.setPlaylistData(playlistData);

    },

    getSteamFormat: () => {

        let playlistData = Storege.getPlaylistData();

        return playlistData.steamFormat || "";

    },

    setSteamFormat: (value) => {

        let playlistData = Storege.getPlaylistData();

        playlistData.steamFormat = value;

        Storege.setPlaylistData(playlistData);

    },

    getChannelState: () => {

        let playlistData = Storege.getPlaylistData();

        return playlistData.channelState || null;

    },

    setChannelState: (value) => {

        let playlistData = Storege.getPlaylistData();

        playlistData.channelState = value;

        Storege.setPlaylistData(playlistData);

    },

    getPlaylistData: () => {

        if (!current_playlist) return {};

        let id = current_playlist.id;

        return Storege.get(`playlistData-${id}`) || {};

    },

    setPlaylistData: (value) => {

        if (!current_playlist) return;

        let id = current_playlist.id;

        Storege.set(`playlistData-${id}`, value);

    },

    syncPlaylistData: (ids = []) => {

        if (!ids?.length) return;

        let keys = Object.keys(localStorage);

        keys.forEach((key) => {

            if (key.indexOf("playlistData-") > -1) {

                let id = parseInt(key.split("playlistData-")[1]);

                if (ids.indexOf(id) == -1) localStorage.removeItem(key);

            }

        });

    },

    getPinCode: () => {
        return Storege.get("pinCode") || "0000";
    },

    setPinCode: (value) => {
        Storege.set("pinCode", value);
    },

    getUseTMDB: () => {
        return Storege.get("useTMDB") == false ? false : true;
    },

    setUseTMDB: (value) => {
        Storege.set("useTMDB", value);
    },

    getChannelsSort: () => {
        return Storege.get("channelsSort") || "";
    },

    setChannelsSort: (value) => {
        Storege.set("channelsSort", value);
    },

    getSubtitleSettings: () => {
        return Storege.get("subtitleSettings") || { size: "medium", style: "drop-shadow" };
    },

    setSubtitleSettings: (value) => {
        Storege.set("subtitleSettings", value);
    },

    getMac: () => {
        return Storege.get("mac") || localStorage.getItem("mac") || "";
    },

    setMac: (value) => {
        Storege.set("mac", value);
    },

    getDeviceId: () => {
        return Storege.get("deviceId") || localStorage.getItem("deviceId") || "";
    },

    setDeviceId: (value) => {
        Storege.set("deviceId", value);
    },

    setLanguage: (value) => {
        Storege.set("language", value);
    },

    getLanguage: () => {
        return Storege.get("language") || "en";
    },

    getTizenPlayerSettings: () => {
        return Storege.get("tizenPlayerSettings") || { channelPlayer: "native", vodPlayer: "native" };
    },

    setTizenPlayerSettings: (value) => {
        Storege.set("tizenPlayerSettings", value);
    },

    getAndroidPlayerSettings: () => {
        return Storege.get("androidPlayerSettings") || { channelPlayer: "EXO", vodPlayer: "EXO" };
    },

    setAndroidPlayerSettings: (value) => {
        Storege.set("androidPlayerSettings", value);
    },

    getRtlMode: () => {
        return Storege.get("rtlMode") || false;
    },

    setRtlMode: (value) => {
        Storege.set("rtlMode", value);
    },

    set: (key, value) => {

        if (!key) return console.error("Storege set key is empty");

        localStorage.setItem(key, JSON.stringify(value));

    },

    get: (key) => {

        if (!key) return console.error("Storege get key is empty");

        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            return null;
        }

    },

    remove: (key) => {

        if (!key) return console.error("Storege remove key is empty");

        localStorage.removeItem(key);

    }

};

export default Storege;