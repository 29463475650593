const TMDB = {

    API_HOST: "https://api.themoviedb.org/3",
    API_KEY: "30888e8f271f1698dcfb0228f589bec1",

    LANGUAGE: "en-US",

    req_timeout: 10000,

    image_paths: {
        original: "https://image.tmdb.org/t/p/original",
        w500: "https://image.tmdb.org/t/p/w500",
        w200: "https://image.tmdb.org/t/p/w200"
    },

    // { query: name, year: year }
    search_movies: (query) => {
        return TMDB.request("/search/movie", query);
    },

    // { query: name, first_air_date_year: year }
    search_series: (query) => {
        return TMDB.request("/search/tv", query);
    },

    // tmdbId
    get_movie_info: (id) => {
        return TMDB.request("/movie/" + id, { append_to_response: "videos,credits,translations" });
    },

    // tmdbId
    get_series_info: (id) => {
        return TMDB.request("/tv/" + id, { append_to_response: "videos,credits,translations" });
    },

    // tmdbId 
    get_episode_info: (id, season, episode) => {
        return TMDB.request(`/tv/${ id }/season/${ season }/episode/${ episode }`, {})
    },

    request: (path, params) => {

        return new Promise((resolve, reject) => {

            const req = new XMLHttpRequest();

            req.timeout = TMDB.req_timeout;

            req.onreadystatechange = function () {

                try {

                    if (req.readyState == 4) {

                        if (req.status == 200) {
                            resolve(JSON.parse(req.responseText));
                        } else {
                            reject(req.responseText);
                        }

                    }

                } catch (e) {
                    reject(e);
                }

            }

            req.onerror = reject;

            req.ontimeout = reject;

            let query = [];

            if (!params) params = {};

            params["api_key"] = TMDB.API_KEY;

            params["language"] = TMDB.LANGUAGE;

            for (let key in params) {
                if (params[key]) query.push(key + "=" + encodeURIComponent(params[key]));
            }

            let url = TMDB.API_HOST + path + "?" + query.join("&");

            req.open("get", url);
            req.send();

        });

    },

};


export default TMDB;        