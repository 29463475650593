import { memo, useCallback, useContext } from "react";
import Button from "@/src/components/button/Button";
import { VideoContext } from "./VideoPopup";
import Languages from "@/src/utils/languages";

const VideoItem = ({
    style,
    index,
    isActive,
    onMouseEnter,
}) => {

    const {
        videos,
        selected,
        setSelected
    } = useContext(VideoContext);

    let video = videos[index];

    const mouseEnter = useCallback((e) => {
        onMouseEnter(index);
    }, [index]);

    const onClickItem = (e) => {

        e.stopPropagation();
        setSelected(index);

        for (let i = 0; i < videos.length; i++) {
            videos[i].enabled = i == index;
        }

    };

    if (!video) return null;

    let title = video.title;

    if (video.language) {
        
        let length = video.language.length;

        if (length == 2 || length == 3) {
            title = Languages[video.language];
        } else if (length > 3) {
            title = video.language;
        }

    }

    if (!title || title == 'Video') title = "Video " + (index + 1);

    return (
        <div className="video-item-parent" style={style}>
            <Button
                className="video-item focus-item"
                onClick={onClickItem}
                onMouseEnter={mouseEnter}
                isActive={isActive}
                isSelected={index == selected}
            >
                {title}
            </Button>
        </div>
    )

};

export default memo(VideoItem);