import { createContext, memo, useState } from "react";
import "./Search.scss";
import KeyboardTypes from "./components/KeyboardTypes";
import KeyboardItems from "./components/KeyboardItems";
import KeyboardActions from "./components/KeyboardActions";
import useKeydown from "../../../../../hooks/useKeydown";
import SearchSort from "./components/SearchSort";
import SortTypes from "./components/SortTypes";

import Storege from "@/src/services/storage/storage";

export const SearchContext = createContext();

const Search = ({
    isActive,
    onUp = () => { },
    onRight = () => { },
    onMouseEnter = () => { }
}) => {

    const [rowIndex, setRowIndex] = useState(0);

    const [itemsIndex, setItemsIndex] = useState(0);

    const [current, setCurrent] = useState("types");

    const [sort, setSort] = useState("");

    const [type, setType] = useState(0);

    const rtlMode = Storege.getRtlMode();

    const types = [
        { name: "ENG", key: "en" },
        { name: "AR", key: "ar" },
        { name: "#+-", key: "symbols" }
    ];

    useKeydown({
        isActive: isActive && current == "items",
        up: () => {
            if (itemsIndex < 6) {
                setRowIndex(Math.floor(itemsIndex / 2));
                setCurrent("types");
                return;
            }
            setItemsIndex(itemsIndex - 6);
        },
        down: () => {
            if (itemsIndex > 29) {
                setRowIndex(Math.floor((itemsIndex - 30) / 2));
                setCurrent("actions");
                return
            }
            setItemsIndex(itemsIndex + 6);
        },
        right: () => {
            if (rtlMode) {
                if (itemsIndex % 6 == 0) return requestAnimationFrame(onRight);
                setItemsIndex(itemsIndex - 1);
            } else {
                if (itemsIndex % 6 == 5) return requestAnimationFrame(onRight);
                setItemsIndex(itemsIndex + 1);
            }
        },
        left: () => {
            if (rtlMode) {
                if (itemsIndex % 6 == 5) return;
                setItemsIndex(itemsIndex + 1);
            } else {
                if (itemsIndex % 6 == 0) return;
                setItemsIndex(itemsIndex - 1);
            }
        },
        back: () => {
            onUp();
        },
    });

    useKeydown({
        isActive: isActive && current != "items",
        up: () => {
            switch (current) {
                case "types":
                    onUp();
                    break;
                case "actions":
                    setItemsIndex((rowIndex * 2) + 30);
                    setCurrent("items");
                    break;
                case "sort":
                    setCurrent("actions");
                    break;
            }
        },
        down: () => {
            switch (current) {
                case "types":
                    setItemsIndex(rowIndex * 2);
                    setCurrent("items");
                    break;
                case "actions":
                    // setCurrent("sort");
                    break;
            }

        },
        right: () => {
            if (rtlMode) {
                if (rowIndex == 0) return;
                setRowIndex(rowIndex - 1);
            } else {
                if (rowIndex == 2) return requestAnimationFrame(onRight);
                setRowIndex(rowIndex + 1);
            }
        },
        left: () => {
            if (rtlMode) {
                if (rowIndex == 2) return requestAnimationFrame(onRight);
                setRowIndex(rowIndex + 1);
            } else {
                if (rowIndex == 0) return;
                setRowIndex(rowIndex - 1);
            }
        },
        back: () => {
            onUp();
        },
    });

    return (
        <SearchContext.Provider
            value={{
                current,
                setCurrent,
                //
                rowIndex,
                setRowIndex,
                //
                itemsIndex,
                setItemsIndex,
                //
                sort,
                setSort,
                //
                types,
                type,
                setType,
                //
                isActive,
                parentMouseEnter: onMouseEnter
            }}>
            <div className="movies-search-parent">
                <div className="movies-search">

                    {/* <SearchSort /> */}

                    <KeyboardTypes />

                    <KeyboardItems />

                    <KeyboardActions />

                    {/* <SortTypes /> */}

                </div>
            </div>
        </SearchContext.Provider>
    );

};

export default memo(Search);