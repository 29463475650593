import { memo, startTransition, useContext, useEffect } from "react";
import { MovieInfoContext } from "../../MovieInfo";
import { useState } from "react";

const Background = () => {

    const [backdrop, setBackdrop] = useState("");

    const { movieInfo } = useContext(MovieInfoContext);

    useEffect(() => {

        if (!movieInfo) return;

        const { backdrops } = movieInfo;

        if (!backdrops || backdrops.length == 0) return;

        let index = 0;

        const checkImage = () => {

            const img = new Image();

            img.onload = () => {
                startTransition(() => setBackdrop(backdrops[index]));
            }

            img.onerror = () => {
                index++;
                if (index < backdrops.length) {
                    checkImage();
                }
            }

            img.src = backdrops[index];

        };

        checkImage();

    }, [movieInfo]);

    if (!movieInfo || !backdrop) return null;

    return <div className="background" style={{ backgroundImage: `url(${ backdrop })` }}></div>

}

export default memo(Background);