import { memo, useCallback, useContext } from "react";
import Button from "@/src/components/button/Button";
import { SubtitleContext } from "./SubtitlePopup";

const SubtitleItem = ({
    style,
    index,
    isActive,
    onMouseEnter,
    type // type = "subtitle-group" || "subtitle"
}) => {

    const {
        subtitles,
        selectedGroup,
        setSelectedGroup,
        subtitle,
        setSubtitle
    } = useContext(SubtitleContext);

    const mouseEnter = useCallback((e) => {
        onMouseEnter(index);
    }, [index]);

    const onClickItem = useCallback((e) => {

        if (type == "subtitle-group") {
            setSelectedGroup(index);
            setSubtitle(subtitles[index]?.subtitles[0] || null);
        } else {
            setSubtitle(subtitles[selectedGroup]?.subtitles[index] || null);
        }

    }, [index, selectedGroup, subtitles]);

    let title = "";
    let isSelected = false;

    if (type == "subtitle-group") {
        if (!subtitles[index]) return null;
        title = subtitles[index].lng;
        isSelected = index == selectedGroup;
    } else {
        if (!subtitles[selectedGroup]?.subtitles[index]) return null;
        title = subtitles[selectedGroup].subtitles[index].lng + " " + (index + 1);
        let file_id = subtitles[selectedGroup].subtitles[index].file_id;
        isSelected = subtitle?.file_id == file_id;
    }

    return (
        <div className="subtitle-item-parent" style={style}>
            <Button
                className="subtitle-item focus-item"
                onClick={onClickItem}
                onMouseEnter={mouseEnter}
                isActive={isActive}
                isSelected={isSelected}
            >
                {title}
            </Button>
        </div>
    )

};

export default memo(SubtitleItem);