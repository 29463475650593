import useStore from "@/src/hooks/useStore";
import SUBTITLES from "@/src/services/api/subtitles";
import { useEffect } from "react";
import { memo, useState } from "react";

const SubtitleContainer = ({
    subtitle,
    currentTime,
    isSeeking,
    isShowingControles,
    isShowingSettings
}) => {

    const [subtitles, setSubtitles] = useState(null);

    const [text, setText] = useState("");

    const [subtitleSettings] = useStore("subtitleSettings");

    let { size, style } = subtitleSettings || {};

    if (!size) size = "medium";

    if (!style) style = "drop-shadow";

    useEffect(() => {

        if (!subtitles || !currentTime || isSeeking) return;

        let ms = parseInt(currentTime * 1000);
        let min = Math.ceil(ms / 60000);

        let subtitle_list = subtitles[min];

        if (!subtitle_list) return;

        let subtitle = subtitle_list.find((subtitle) => {
            return ms >= subtitle.start && ms <= subtitle.end;
        });

        setText(subtitle?.text || "");

    }, [currentTime, isSeeking]);

    useEffect(() => {

        let onTizenSubtitlesChange = (e) => {

            let { duration, text } = e.detail;

            setText(text);

            setTimeout(() => {
                setText("");
            }, duration);

        }

        if (subtitle) {

            if (subtitle.type == "tizenSubtitle") {

                subtitle.enabled = true;

                window.addEventListener("onTizenSubtitlesChange", onTizenSubtitlesChange);

            } else {

                SUBTITLES.download(subtitle.file_id, (obj) => {
                    setSubtitles(obj);
                });

            }

        }

        return () => {
            SUBTITLES.abort();
            setSubtitles(null);
            setText("");
            window.dispatchEvent(new CustomEvent("disableTizenSubtitles"));
            window.removeEventListener("onTizenSubtitlesChange", onTizenSubtitlesChange);
        }

    }, [subtitle]);

    if (isShowingSettings) return null;

    return (
        <div className={"subtitle-container" + (isShowingControles ? " move-up" : "")}>
            <span className={`subtitle-text ${ size } ${ style }`} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    )
};

export default memo(SubtitleContainer);