const Words = {
    "accept": {
        "en": "Accept and use free trial",
        "am": "Ընդունել և օգտագործել անվճար փորձարկումը",
        "ar": "قبول واستخدام التجربة المجانية",
        "de": "Akzeptieren und kostenlose Testversion verwenden",
        "fr": "Accepter et utiliser l'essai gratuit",
        "br": "Aceitar e usar teste gratuito",
        "es": "Aceptar y usar prueba gratuita",
        "tr": "Ücretsiz deneme sürümünü kabul edin ve kullanın",
        "ru": "Принять и использовать бесплатную пробную версию"
    },
    "lifetime": {
        "en": "Lifetime",
        "am": "Տարեկան",
        "ar": "مدى الحياة",
        "de": "Lebenszeit",
        "fr": "Durée de vie",
        "br": "Vida",
        "es": "Vida",
        "tr": "Ömür",
        "ru": "Продолжительность жизни"
    },
    "activated":{
        "en": "Activated",
        "am": "Ակտիվացված է",
        "ar": "تم التنشيط",
        "de": "Aktiviert",
        "fr": "Activé",
        "br": "Ativado",
        "es": "Activado",
        "tr": "Aktive",
        "ru": "Активированный"
    },
    "cancel": {
        "en": "Cancel",
        "am": "Չեղարկել",
        "ar": "يلغي",
        "de": "Absagen",
        "fr": "Annuler",
        "br": "Cancelar",
        "es": "Cancelar",
        "tr": "İptal et",
        "ru": "Отменить"
    },
    "password": {
        "en": "Password",
        "am": "Գաղտնաբառ",
        "ar": "كلمة المرور",
        "de": "Passwort",
        "fr": "Password",
        "br": "Senha",
        "es": "Contraseña",
        "tr": "Parola",
        "ru": "Пароль"
    },
    "categories": {
        "en": "Categories",
        "am": "Կատեգորիաներ",
        "ar": "التصنيفات",
        "de": "Kategorien",
        "fr": "Catégories",
        "br": "Categorias",
        "es": "Categorías",
        "tr": "Kategoriler",
        "ru": "Категории"
    },
    "search": {
        "en": "Search",
        "am": "Որոնել",
        "ar": "بحث",
        "de": "Suche",
        "fr": "Recherche",
        "br": "Pesquisar",
        "es": "Buscar",
        "tr": "Arama",
        "ru": "Поиск"
    },
    "sort by": {
        "en": "Sort By",
        "am": "Դասավորել ըստ",
        "ar": "صنف حسب",
        "de": "Sortieren nach",
        "fr": "Trier par",
        "br": "Ordenar por",
        "es": "Ordenar por",
        "tr": "Göre sırala",
        "ru": "Сортировать по"
    },
    "ok": {
        "en": "Ok",
        "am": "Լավ",
        "ar": "نعم",
        "de": "D'accord",
        "fr": "Ok",
        "br": "Ok",
        "es": "Ok",
        "tr": "Tamam",
        "ru": "Хорошо"
    },
    "mac address": {
        "en": "Mac Address",
        "am": "Mac հասցե",
        "ar": "عنوان ماك",
        "de": "Mac-Adresse",
        "fr": "Adresse Mac",
        "br": "Endereço Mac",
        "es": "Dirección Mac",
        "tr": "Mac adresi",
        "ru": "Mac адрес"
    },
    "live tv": {
        "en": "Live Tv",
        "am": "Ալիքներ",
        "ar": "تلفزيون\t",
        "de": "Live Tv",
        "fr": "En direct",
        "br": "TV ao vivo",
        "es": "TV en vivo",
        "tr": "Canlı Tv",
        "ru": "Прямой эфир"
    },
    "movies": {
        "en": "Movies",
        "am": "Ֆիլմեր",
        "ar": "أفلام",
        "de": "Filme ",
        "fr": "Films",
        "br": "Filmes",
        "es": "Películas",
        "tr": "Filmler",
        "ru": "Фильмы"
    },
    "series": {
        "en": "Series",
        "am": "Սերիալներ",
        "ar": "مسلسل",
        "de": "Serie",
        "fr": "Séries",
        "br": "Séries",
        "es": "Serie",
        "tr": "Dizi",
        "ru": "Сериалы"
    },
    "settings": {
        "en": "Settings",
        "am": "Կարգավորումներ",
        "ar": "إعدادات",
        "de": "Einstellungen",
        "fr": "Paramètres",
        "br": "Configurações",
        "es": "Ajustes",
        "tr": "Ayarlar",
        "ru": "Настройки"
    },
    "support email": {
        "en": "Support Email",
        "am": "Սպասարկման Էլ. հասցե",
        "ar": "البريد الإلكتروني للدعم",
        "de": "Support Email",
        "fr": "Support Email",
        "br": "E-mail de suporte",
        "es": "Soporte de correo electrónico",
        "tr": "Destek E-posta",
        "ru": "Поддержка по электронной почте"
    },
    "web page": {
        "en": "Web Page",
        "am": "Վեբ Էջ",
        "ar": "صفحة ويب",
        "de": "Webseite",
        "fr": "Page Web",
        "br": "Página da web",
        "es": "Página web",
        "tr": "Web Sayfası",
        "ru": "Веб-страница"
    },
    "status": {
        "en": "Status",
        "am": "Կարգավիճակ",
        "ar": "الحالة",
        "de": "Status",
        "fr": "État",
        "br": "Status",
        "es": "Estado",
        "tr": "Durum",
        "ru": "Статус"
    },
    "activated": {
        "en": "Activated",
        "am": "Ակտիվացված է",
        "ar": "مفعل",
        "de": "Aktiviert",
        "fr": "Activé",
        "br": "Ativado",
        "es": "Activado",
        "tr": "Aktive",
        "ru": "Активированный"
    },
    "free trial": {
        "en": "Free trial",
        "am": "Անվճար փորձարկում",
        "ar": "تجربة مجانية",
        "de": "Kostenlose Testversion",
        "fr": "Essai gratuit",
        "br": "Teste gratuito",
        "es": "Prueba gratuita",
        "tr": "Ücretsiz deneme",
        "ru": "Бесплатная пробная версия"
    },
    "free trial expired": {
        "en": "Free trial expired",
        "am": "Անվճար փորձարկումը ավարտվում է",
        "ar": "انتهت صلاحية التجربة المجانية",
        "de": "Kostenlose Testversion abgelaufen",
        "fr": "Essai gratuit expiré",
        "br": "Teste gratuito expirado",
        "es": "Prueba gratuita caducada",
        "tr": "Ücretsiz deneme süresi doldu",
        "ru": "Истек срок бесплатной пробной версии"
    },
    "use tmdb api": {
        "en": "Use TMDB API",
        "am": "Օգտագործեք TMDB API",
        "ar": "استخدم واجهة برمجة تطبيقات TMDB",
        "de": "Verwenden Sie die TMDB-API",
        "fr": "Utiliser l'API TMDB",
        "br": "Usar API TMDB",
        "es": "Usar la API de TMDB",
        "tr": "TMDB API kullanın",
        "ru": "Используйте TMDB API"
    },
    "version": {
        "en": "Version",
        "am": "Տարբերակ",
        "ar": "الإصدار",
        "de": "Version",
        "fr": "Version",
        "br": "Versão",
        "es": "Versión",
        "tr": "Versiyon",
        "ru": "Версия"
    },
    "playlists": {
        "en": "Playlists",
        "am": "Երգացանկեր",
        "ar": "قوائم التشغيل",
        "de": "Listen",
        "fr": "Listes",
        "br": "Listas",
        "es": "Listas",
        "tr": "Listeleri",
        "ru": "Плейлисты"
    },
    "playlist": {
        "en": "Playlist",
        "am": "Երգացանկ",
        "ar": "قائمة التشغيل",
        "de": "Playlist",
        "fr": "Playlist",
        "br": "Lista de reprodução",
        "es": "Lista de reproducción",
        "tr": "Oynatma listesi",
        "ru": "Плейлист"
    },
    "add playlist": {
        "en": "Add playlist",
        "am": "Ավելացնել երգացանկ",
        "ar": "أضف قائمة التشغيل",
        "de": "Playlist hinzufügen",
        "fr": "Ajouter une liste de lecture",
        "br": "Adicionar lista de reprodução",
        "es": "Agregar lista de reproducción",
        "tr": "Oynatma listesi ekle",
        "ru": "Добавить плейлист"
    },
    "xtream account": {
        "en": "Xtream Account",
        "am": "Xtream հաշիվ",
        "ar": "حساب Xtream",
        "de": "Xtream-Konto",
        "fr": "Compte Xtream",
        "br": "Conta Xtream",
        "es": "Cuenta Xtream",
        "tr": "Xtream Hesabı",
        "ru": "Xtream аккаунт"
    },
    "name": {
        "en": "Name",
        "am": "Անուն",
        "ar": "اسم",
        "de": "Name",
        "fr": "Nom",
        "br": "Nome",
        "es": "Nombre",
        "tr": "İsim",
        "ru": "Имя"
    },
    "url": {
        "en": "Url",
        "am": "Url",
        "ar": "عنوان Url",
        "de": "Url",
        "fr": "Url",
        "br": "URL",
        "es": "Url",
        "tr": "Url",
        "ru": "Url"
    },
    "host": {
        "en": "Host",
        "am": "Host",
        "ar": "يستضيف",
        "de": "Gastgeber",
        "fr": "Hôte",
        "br": "Hospedar",
        "es": "Anfitrión",
        "tr": "Ev sahibi",
        "ru": "Хост"
    },
    "username": {
        "en": "Username",
        "am": "Օգտագործող",
        "ar": "اسم المستخدم",
        "de": "Benutzername",
        "fr": "Nom d'utilisateur ",
        "br": "Nome de usuário",
        "es": "Nombre de usuario",
        "tr": "Kullanıcı adı",
        "ru": "Имя пользователя"
    },
    "to add playlist from our website visit": {
        "en": "To add playlist from our website visit",
        "am": "Մեր կայքից երգացանկ ավելացնելու համար այցելեք",
        "ar": "لإضافة قائمة تشغيل من موقعنا قم بزيارة الموقع",
        "de": "Um eine Playlist von unserer Website hinzuzufügen, besuchen Sie",
        "fr": "Pour ajouter une liste de lecture à partir de notre site Web, visitez",
        "br": "Para adicionar lista de reprodução do nosso site, visite",
        "es": "Para agregar una lista de reproducción desde nuestro sitio web, visite",
        "tr": "Web sitemizden oynatma listesi eklemek için ziyaret edin",
        "ru": "Чтобы добавить плейлист с нашего сайта, посетите"
    },
    "to add video from our website visit": {
        "en": "To add video from our website visit",
        "am": "Մեր կայքից տեսանյութ ավելացնելու համար այցելեք",
        "ar": "لإضافة فيديو من موقعنا قم بزيارة الموقع",
        "de": "Um ein Video von unserer Website hinzuzufügen, besuchen Sie",
        "fr": "Pour ajouter une vidéo à partir de notre site Web, visitez",
        "br": "Para adicionar vídeo do nosso site, visite",
        "es": "Para agregar un video desde nuestro sitio web, visite",
        "tr": "Web sitemizden video eklemek için ziyaret edin",
        "ru": "Чтобы добавить видео с нашего сайта, посетите"
    },
    "or scan the bar code in the right": {
        "en": "or Scan the QR code in the right",
        "am": "կամ սկանավորեք QR կոդը աջ կողմում",
        "ar": "أو امسح الرمز الشريطي في اليمين",
        "de": "Oder scannen Sie den Barcode rechts",
        "fr": "Ou scannez le code-barres à droite",
        "br": "ou Digitalize o código de barras à direita",
        "es": "o Escanea el código de barras a la derecha",
        "tr": "veya sağdaki QR kodunu tarayın",
        "ru": "или отсканируйте QR-код справа"
    },
    "or scan the bar code in the left": {
        "en": "or Scan the QR code in the left",
        "am": "կամ սկանավորեք QR կոդը ձախ կողմում",
        "ar": "أو امسح الرمز الشريطي في اليسار",
        "de": "Oder scannen Sie den Barcode links",
        "fr": "Ou scannez le code-barres à gauche",
        "br": "ou Digitalize o código de barras à esquerda",
        "es": "o Escanea el código de barras a la izquierda",
        "tr": "veya soldaki QR kodunu tarayın",
        "ru": "или отсканируйте QR-код слева"
    },
    "delete playlist": {
        "en": "Delete playlist",
        "am": "Ջնջել փլեյլիստը",
        "ar": "حذف قائمة التشغيل",
        "de": "Delete playlist",
        "fr": "Delete playlist",
        "br": "Excluir lista de reprodução",
        "es": "Eliminar lista de reproducción",
        "tr": "Oynatma listesini sil",
        "ru": "Удалить плейлист"
    },
    "playlist is empty": {
        "en": "Playlist is empty",
        "am": "փլեյլիստը դատարկ է",
        "ar": "قائمة التشغيل فارغة",
        "de": "Wiedergabeliste ist leer",
        "fr": "La liste de lecture est vide",
        "br": "A lista de reprodução está vazia",
        "es": "La lista de reproducción está vacía.",
        "tr": "Oynatma listesi boş",
        "ru": "Плейлист пуст"
    },
    "downloaded": {
        "en": "Downloaded",
        "am": "Ներբեռնված",
        "ar": "تم التنزيل",
        "de": "Downloaded",
        "fr": "Downloaded",
        "br": "Baixado",
        "es": "Descargada",
        "tr": "İndirilen",
        "ru": "Скачанный"
    },
    "downloading": {
        "en": "Downloading",
        "am": "Ներբեռնում",
        "ar": "جارى التحميل",
        "de": "Downloading",
        "fr": "Downloading",
        "br": "Baixando",
        "es": "Descargando",
        "tr": "İndiriliyor",
        "ru": "Загрузка"
    },
    "parsing": {
        "en": "Parsing",
        "am": "Վերլուծություն",
        "ar": "تفسير",
        "de": "Parsing",
        "fr": "Parsing",
        "br": "Análise",
        "es": "Análisis",
        "tr": "Ayrıştırma",
        "ru": "Анализ"
    },
    "name is required": {
        "en": "Name is required",
        "am": "Անունը պարտադիր է",
        "ar": "مطلوب اسم",
        "de": "Name is required",
        "fr": "Name is required",
        "br": "O nome é obrigatório",
        "es": "Name is required",
        "tr": "İsim gerekli",
        "ru": "Имя обязательно"
    },
    "url is required": {
        "en": "Url is required",
        "am": "Url-ը պարտադիր է",
        "ar": "مطلوب عنوان Url",
        "de": "Url is required",
        "fr": "Url is required",
        "br": "URL é obrigatório",
        "es": "Url is required",
        "tr": "Url gerekli",
        "ru": "Url обязателен"
    },
    "host is required": {
        "en": "Host is required",
        "am": "Host-ը պարտադիր է",
        "ar": "المضيف مطلوب",
        "de": "Host is required",
        "fr": "Host is required",
        "br": "O host é obrigatório",
        "es": "Host is required",
        "tr": "Host gerekli",
        "ru": "Хост обязателен"
    },
    "username is required": {
        "en": "Username is required",
        "am": "Օգտագործողը պարտադիր է",
        "ar": "اسم المستخدم مطلوب",
        "de": "Username is required",
        "fr": "Username is required",
        "br": "Nome de usuário é requerido",
        "es": "Username is required",
        "tr": "Kullanıcı adı gerekli",
        "ru": "Имя пользователя обязательно"
    },
    "password is required": {
        "en": "Password is required",
        "am": "Գաղտնաբառը պարտադիր է",
        "ar": "كلمة المرور مطلوبة",
        "de": "Password is required",
        "fr": "Password is required",
        "br": "Senha requerida",
        "es": "Password is required",
        "tr": "Parola gerekli",
        "ru": "Пароль обязателен"
    },
    "invalid account": {
        "en": "Invalid account",
        "am": "Անվավեր հաշիվ",
        "ar": "حساب غير صالح",
        "de": "Invalid account",
        "fr": "Invalid account",
        "br": "Conta inválida",
        "es": "Cuenta inválida",
        "tr": "Geçersiz hesap",
        "ru": "Недействительный аккаунт"
    },
    "youtube id is required": {
        "en": "Youtube id is required",
        "am": "Youtube id-ն պարտադիր է",
        "ar": "مطلوب معرف Youtube",
        "de": "Youtube id is required",
        "fr": "Youtube id is required",
        "br": "O ID do Youtube é obrigatório",
        "es": "Youtube id is required",
        "tr": "Youtube kimliği gerekli",
        "ru": "Требуется идентификатор Youtube"
    },
    "invalid url": {
        "en": "Invalid url",
        "am": "Անվավեր url",
        "ar": "عنوان Url غير صالح",
        "de": "Invalid url",
        "fr": "Invalid url",
        "br": "Url inválido",
        "es": "Url inválido",
        "tr": "Geçersiz url",
        "ru": "Недействительный URL"
    },
    "program not found": {
        "en": "Program not found",
        "am": "Ծրագրի չի գտնվել",
        "ar": "البرنامج غير موجود",
        "de": "Programm nicht gefunden",
        "fr": "Programme introuvable",
        "br": "Programa não encontrado",
        "es": "programa no encontrado",
        "tr": "Program bulunamadı",
        "ru": "Программа не найдена"
    },
    "category": {
        "en": "Category",
        "am": "Կատեգորիա",
        "ar": "فئة",
        "de": "Kategorie",
        "fr": "Catégorie",
        "br": "Categoria",
        "es": "Categoría",
        "tr": "Kategori",
        "ru": "Категория"
    },
    "favorites": {
        "en": "Favorites",
        "am": "Նախընտրածներ",
        "ar": "المفضلة",
        "de": "Favoriten",
        "fr": "Favoris",
        "br": "Favoritos",
        "es": "Favoritos",
        "tr": "Favoriler",
        "ru": "Избранное"
    },
    "sort": {
        "en": "Sort",
        "am": "Դասավորել",
        "ar": "نوع",
        "de": "Sortieren",
        "fr": "Trier",
        "br": "Organizar",
        "es": "Clasificar",
        "tr": "Sırala",
        "ru": "Сортировать"
    },
    "menu": {
        "en": "Menu",
        "am": "Մենյու",
        "ar": "قائمة طعام",
        "de": "Menü",
        "fr": "Menu",
        "br": "Cardápio",
        "es": "Menú",
        "tr": "Menü",
        "ru": "Меню"
    },
    "all": {
        "en": "All",
        "am": "Բոլորը",
        "ar": "الجميع",
        "de": "Alle",
        "fr": "Tous",
        "br": "Todos",
        "es": "Todos",
        "tr": "Hepsi",
        "ru": "Все"
    },
    "play": {
        "en": "Play",
        "am": "Սկսել",
        "ar": "يلعب",
        "de": "Abspielen",
        "fr": "Lecture",
        "br": "Jogar",
        "es": "Jugar",
        "tr": "Oynat",
        "ru": "Играть"
    },
    "resolutions": {
        "en": "Resolutions",
        "am": "Չափսերը",
        "ar": "القرارات",
        "de": "Auflösung",
        "fr": "Résolution",
        "br": "Resoluções",
        "es": "Resoluciones",
        "tr": "Çözünürlükler",
        "ru": "Разрешения"
    },
    "audio": {
        "en": "Audio",
        "am": "Աուդիո",
        "ar": "صوت",
        "de": "Audio",
        "fr": "Audio",
        "br": "Áudio",
        "es": "Audio",
        "tr": "Ses",
        "ru": "Аудио"
    },
    "video":{
        "en": "Video",
        "am": "Տեսանյութ",
        "ar": "فيديو",
        "de": "Video",
        "fr": "Vidéo",
        "br": "Vídeo",
        "es": "Vídeo",
        "tr": "Video",
        "ru": "Видео"
    },
    "subtitle": {
        "en": "Subtitle",
        "am": "Ենթագրեր",
        "ar": "العنوان الفرعي",
        "de": "Subtitle",
        "fr": "Subtitle",
        "br": "Legenda",
        "es": "Subtitle",
        "tr": "Altyazı",
        "ru": "Субтитры"
    },
    "display mode": {
        "en": "Display Mode",
        "am": "Ցուցադրման ռեժիմ",
        "ar": "وضع العرض",
        "de": "Anzeigemodus",
        "fr": "Mode d'affichage",
        "br": "Modo de exibição",
        "es": "Modo de visualización",
        "tr": "Görüntüleme modu",
        "ru": "Режим отображения"
    },
    "not found": {
        "en": "Not found",
        "am": "Չի գտնվել",
        "ar": "غير معثور عليه",
        "de": "Not found",
        "fr": "Not found",
        "br": "Não encontrado",
        "es": "Extraviado",
        "tr": "Bulunamadı",
        "ru": "Не найдено"
    },
    "cast": {
        "en": "Cast",
        "am": "Դերերում",
        "ar": "يقذف",
        "de": "Cast",
        "fr": "Cast",
        "br": "Elenco",
        "es": "Emitir",
        "tr": "Cast",
        "ru": "Каст"
    },
    "seasons": {
        "en": "Seasons",
        "am": "Սեզոններ",
        "ar": "مواسم",
        "de": "Seasons",
        "fr": "Seasons",
        "br": "Temporadas",
        "es": "Seasons",
        "tr": "Sezonlar",
        "ru": "Сезоны"
    },
    "genres": {
        "en": "Genres",
        "am": "Ժանրեր",
        "ar": "الأنواع",
        "de": "Genres",
        "fr": "Genres",
        "br": "Gêneros",
        "es": "Genres",
        "tr": "Türler",
        "ru": "Жанры"
    },
    "season": {
        "en": "Season",
        "am": "Սեզոն",
        "ar": "موسم",
        "de": "Season",
        "fr": "Season",
        "br": "Temporada",
        "es": "Season",
        "tr": "Sezon",
        "ru": "Сезон"
    },
    "change language": {
        "en": "Change Language",
        "am": "Փոխել լեզուն",
        "ar": "تغيير اللغة",
        "de": "Ändern Sie die Sprache",
        "fr": "Changer de langue",
        "br": "Mudar idioma",
        "es": "Cambiar idioma",
        "tr": "Dili değiştir",
        "ru": "Изменить язык"
    },
    "languages": {
        "en": "Languages",
        "am": "Լեզուներ",
        "ar": "اللغات",
        "de": "Sprachen",
        "fr": "Langages",
        "br": "línguas",
        "es": "Idiomas",
        "tr": "Diller",
        "ru": "Языки"
    },
    "watched movies": {
        "en": "Watched Movies",
        "am": "Դիտված Ֆիլմեր",
        "ar": "الأفلام التي شاهدتها",
        "de": "Watched Movies",
        "fr": "Watched Movies",
        "br": "Filmes assistidos",
        "es": "Watched Movies",
        "tr": "İzlenen Filmler",
        "ru": "Просмотренные фильмы"
    },
    "watched series": {
        "en": "Watched Series",
        "am": "Դիտված Սերիալներ",
        "ar": "مسلسل شاهد",
        "de": "Watched Series",
        "fr": "Watched Series",
        "br": "Séries assistidas",
        "es": "Watched Series",
        "tr": "İzlenen Diziler",
        "ru": "Просмотренные сериалы"
    },
    "others": {
        "en": "Others",
        "am": "Այլ",
        "ar": "آخرين",
        "de": "Andere",
        "fr": "Autres",
        "br": "Outros",
        "es": "Otros",
        "tr": "Diğerleri",
        "ru": "Другие"
    },
    "continue watching": {
        "en": "Continue Watching",
        "am": "Շարունակել Դիտել",
        "ar": "متابعة المشاهدة",
        "de": "Weiter schauen",
        "fr": "Continuer à regarder",
        "br": "Continue assistindo",
        "es": "Continuar viendo",
        "tr": "İzlemeye devam et",
        "ru": "Продолжить просмотр"
    },
    "please check with your playlist provider": {
        "en": "Please check with your playlist provider",
        "am": "Ստուգեք ձեր երգացանկի մատակարարի հետ",
        "ar": "يرجى التحقق من مزود قائمة التشغيل الخاص بك",
        "de": "Please check with your playlist provider",
        "fr": "Please check with your playlist provider",
        "br": "Por favor, verifique com seu provedor de lista de reprodução",
        "es": "Por favor, consulte con su proveedor de listas de reproducción",
        "tr": "Lütfen çalma listesi sağlayıcınızla kontrol edin",
        "ru": "Пожалуйста, проверьте с вашим поставщиком плейлистов"
    },
    "not selected playlist": {
        "en": "Not selected playlist",
        "am": "Երգացանկ ընտրված չէ",
        "ar": "قائمة التشغيل غير المحددة",
        "de": "Not selected playlist",
        "fr": "Not selected playlist",
        "br": "lista de reprodução não selecionada",
        "es": "Not selected playlist",
        "tr": "Seçilmemiş çalma listesi",
        "ru": "Плейлист не выбран"
    },
    "no movies available": {
        "en": "No movies available",
        "am": "Ֆիլմեր չկան",
        "ar": "لا توجد أفلام متاحة",
        "de": "No movies available",
        "fr": "No movies available",
        "br": "Nenhum filme disponível",
        "es": "No hay películas disponibles",
        "tr": "Mevcut film yok",
        "ru": "Фильмы недоступны"
    },
    "no series available": {
        "en": "No series available",
        "am": "Սերիալներ չկան",
        "ar": "لا توجد سلسلة متاحة",
        "de": "No series available",
        "fr": "No series available",
        "br": "Nenhuma série disponível",
        "es": "Ninguna serie disponible",
        "tr": "Mevcut seri yok",
        "ru": "Сериалы недоступны"
    },
    "favorites is empty": {
        "en": "Favorites is empty",
        "am": "Նախընտրածները դատարկ են",
        "ar": "المفضلة فارغة",
        "de": "Favorites is empty",
        "fr": "Favorites is empty",
        "br": "Favoritos está vazio",
        "es": "Favoritos está vacía",
        "tr": "Favoriler boş",
        "ru": "Избранное пусто"
    },
    "something went wrong. please try again": {
        "en": "Something went wrong. Please try again",
        "am": "Ինչ-որ բան այնպես չի աշխատում. Փորձեք նորից",
        "ar": "هناك خطأ ما. حاول مرة اخرى",
        "de": "Something went wrong. Please try again",
        "fr": "Something went wrong. Please try again",
        "br": "Algo deu errado. Por favor, tente novamente",
        "es": "Something went wrong. Please try again",
        "tr": "Bir şeyler yanlış gitti. Lütfen tekrar deneyin",
        "ru": "Что-то пошло не так. Пожалуйста, попробуйте снова"
    },
    "recently added": {
        "en": "Recently added",
        "am": "Վերջին ավելացված",
        "ar": "أضيف مؤخرا",
        "de": "Recently added",
        "fr": "Recently added",
        "br": "Adicionado recentemente",
        "es": "Recently added",
        "tr": "Son eklenen",
        "ru": "Недавно добавлено"
    },
    "are you sure want to exit ?": {
        "en": "Are you sure want to exit ?",
        "am": "Իսկապե՞ս ուզում եք դուրս գալ",
        "ar": "هل أنت متأكد من أنك تريد الخروج؟",
        "de": "Möchten Sie wirklich beenden?",
        "fr": "Êtes-vous sûr de vouloir quitter?",
        "br": "Tem certeza de que deseja sair?",
        "es": "¿Estás seguro de que quieres salir?",
        "tr": "Çıkmak istediğinizden emin misiniz?",
        "ru": "Вы уверены, что хотите выйти?"
    },
    "privacy policy": {
        "en": "Privacy policy",
        "am": "Գաղտնիության քաղաքականություն",
        "ar": "سياسة الخصوصية",
        "de": "Datenschutz-Bestimmungen",
        "fr": "Politique de confidentialité",
        "br": "Política de Privacidade",
        "es": "Política de privacidad",
        "tr": "Gizlilik Politikası",
        "ru": "Политика конфиденциальности"
    },
    "device": {
        "en": "Device",
        "am": "Սարք",
        "ar": "جهاز",
        "de": "Gerät",
        "fr": "Dispositif",
        "br": "Dispositivo",
        "es": "Dispositivo",
        "tr": "Araç",
        "ru": "Устройство"
    },
    "enter pin": {
        "en": "Enter pin",
        "am": "Մուտքագրեք պին",
        "ar": "أدخل دبوس",
        "de": "PIN eingeben",
        "fr": "Entrez le code PIN",
        "br": "Digite o código pin",
        "es": "Ingrese el código pin",
        "tr": "Pin gir",
        "ru": "Введите пин-код"
    },
    "enter new pin": {
        "en": "Enter new pin",
        "am": "Մուտքագրեք նոր պին",
        "ar": "أدخل دبوس جديد",
        "de": "Neue PIN eingeben",
        "fr": "Entrez un nouveau code PIN",
        "br": "Digite o novo pino",
        "es": "Ingrese el nuevo código pin",
        "tr": "Yeni pin girin",
        "ru": "Введите новый пин-код"
    },
    "enter old pin": {
        "en": "Enter old pin",
        "am": "Մուտքագրեք հին պինը",
        "ar": "أدخل دبوس قديم",
        "de": "Alte PIN eingeben",
        "fr": "Entrez l'ancien code PIN",
        "br": "Digite o pino antigo",
        "es": "Ingrese el pin antiguo",
        "tr": "Eski pin girin",
        "ru": "Введите старый пин-код"
    },
    "confirm new pin": {
        "en": "Confirm new pin",
        "am": "Հաստատեք նոր պինը",
        "ar": "تأكيد دبوس جديد",
        "de": "Bestätigen Sie die neue PIN",
        "fr": "Confirmer le nouveau code PIN",
        "br": "Confirme o novo pino",
        "es": "Confirmar nuevo pin",
        "tr": "Yeni pin onayla",
        "ru": "Подтвердите новый пин-код"
    },
    "delete": {
        "en": "Delete",
        "am": "Ջնջել",
        "ar": "حذف",
        "de": "Löschen",
        "fr": "Effacer",
        "br": "Excluir",
        "es": "Eliminar",
        "tr": "Sil",
        "ru": "Удалить"
    },
    "exit": {
        "en": "Exit",
        "am": "Ելք",
        "ar": "خروج",
        "de": "Beenden",
        "fr": "Sortie",
        "br": "Saída",
        "es": "Salida",
        "tr": "Çıkış",
        "ru": "Выход"
    },
    "pin code saved": {
        "en": "Pin code saved",
        "am": "Pin կոդը պահպանվել է",
        "ar": "تم حفظ رمز التعريف الشخصي",
        "de": "Pin code saved",
        "fr": "Pin code saved",
        "br": "Código PIN salvo",
        "es": "Código PIN guardado",
        "tr": "Pin kodu kaydedildi",
        "ru": "Пин-код сохранен"
    },
    "wrong pin": {
        "en": "wrong pin",
        "am": "Սխալ պին",
        "ar": "دبوس الخطأ",
        "de": "Falsche PIN",
        "fr": "Wrong pin",
        "br": "Pino errado",
        "es": "pin erróneo",
        "tr": "yanlış pin",
        "ru": "неверный пин-код"
    },
    "play trailer": {
        "en": "Play Trailer",
        "am": "Նվագարկել տրայլերը",
        "ar": "تشغيل العرض التقديمي",
        "de": "Play Trailer",
        "fr": "Play Trailer",
        "br": "Reproduzir Trailer",
        "es": "Reproducir trailer",
        "tr": "Fragmanı oyna",
        "ru": "Воспроизвести трейлер"
    },
    "restore account": {
        "en": "Restore account",
        "am": "Վերականգնել հաշիվը",
        "ar": "استعادة الحساب",
        "de": "Restore account",
        "fr": "Restore account",
        "br": "Restaurar conta",
        "es": "Restaurar cuenta",
        "tr": "Hesabı geri yükle",
        "ru": "Восстановить аккаунт"
    },
    "restore key": {
        "en": "Restore key",
        "am": "Վերականգնման բանալի",
        "ar": "مفتاح الاستعادة",
        "de": "Restore key",
        "fr": "Restore key",
        "br": "Chave de restauração",
        "es": "Clave de restauración",
        "tr": "Geri yükleme anahtarı",
        "ru": "Ключ восстановления"
    },
    "confirm": {
        "en": "Confirm",
        "am": "Հաստատել",
        "ar": "تؤكد",
        "de": "Bestätigen",
        "fr": "Confirmer",
        "br": "Confirmar",
        "es": "Confirmar",
        "tr": "Onaylamak",
        "ru": "Подтвердить"
    },
    "enter restore key": {
        "en": "Enter restore key",
        "am": "Մուտքագրեք վերականգնման բանալին",
        "ar": "أدخل مفتاح الاستعادة",
        "de": "Enter restore key",
        "fr": "Enter restore key",
        "br": "Digite a chave de restauração",
        "es": "Ingrese la clave de restauración",
        "tr": "Geri yükleme anahtarını girin",
        "ru": "Введите ключ восстановления"
    },
    "important": {
        "en": "Important",
        "am": "Կարևոր",
        "ar": "مهم",
        "de": "Wichtig",
        "fr": "Important",
        "br": "Importante",
        "es": "Importante",
        "tr": "Önemli",
        "ru": "Важно"
    },
    "restore popup title": {
        "en": "Save your device key.",
        "am": "Պահպանեք ձեր սարքի բանալին:",
        "ar": "احفظ مفتاح جهازك.",
        "de": "Save your device key.",
        "fr": "Save your device key.",
        "br": "Salve sua chave de dispositivo.",
        "es": "Guarde su clave de dispositivo.",
        "tr": "Cihaz anahtarınızı kaydedin.",
        "ru": "Сохраните ключ вашего устройства."
    },
    "restore popup text": {
        "en": "Device key is for migrating your account from one to other device or reactivate your app playlist and payment if your device is reseted. After migrating your account to other device your main device will be deactivated.",
        "am": "Սարքի բանալին նախատեսված է ձեր հաշիվը մեկից այլ սարք տեղափոխելու կամ ձեր հավելվածի երգացանկը և վճարումը վերագործարկելու համար, եթե ձեր սարքը ապաակտիվացված է: Ձեր հաշիվը այլ սարք տեղափոխելուց հետո ձեր հիմնական սարքը կանջատվի:",
        "ar": "مفتاح الجهاز مخصص لترحيل حسابك من جهاز إلى جهاز آخر أو إعادة تنشيط قائمة تشغيل التطبيق والدفع إذا تمت إعادة ضبط جهازك. بعد ترحيل حسابك إلى جهاز آخر، سيتم إلغاء تنشيط جهازك الرئيسي.",
        "de": "Mit dem Geräteschlüssel können Sie Ihr Konto von einem auf ein anderes Gerät migrieren oder Ihre App-Playlist und Zahlung reaktivieren, wenn Ihr Gerät zurückgesetzt wird. Nach der Migration Ihres Kontos auf ein anderes Gerät wird Ihr Hauptgerät deaktiviert.",
        "fr": "La clé de l'appareil permet de migrer votre compte d'un appareil à un autre ou de réactiver la liste de lecture de votre application et votre paiement si votre appareil est réinitialisé. Après avoir migré votre compte vers un autre appareil, votre appareil principal sera désactivé.",
        "br": "A chave do dispositivo serve para migrar sua conta de um para outro dispositivo ou reativar a lista de reprodução do aplicativo e o pagamento se o dispositivo for reiniciado. Após migrar sua conta para outro dispositivo, seu dispositivo principal será desativado.",
        "es": "La clave del dispositivo es para migrar su cuenta de un dispositivo a otro o reactivar la lista de reproducción de su aplicación y el pago si se reinicia su dispositivo. Después de migrar su cuenta a otro dispositivo, su dispositivo principal se desactivará.",
        "tr": "Cihaz anahtarı, hesabınızı bir cihazdan diğerine taşımak veya cihazınız sıfırlanırsa uygulama çalma listenizi ve ödemeyi yeniden etkinleştirmek içindir. Hesabınızı başka bir cihaza taşıdıktan sonra ana cihazınız devre dışı bırakılacaktır.",
        "ru": "Ключ устройства предназначен для миграции вашей учетной записи с одного устройства на другое или повторной активации плейлиста приложения и оплаты, если ваше устройство сброшено. После миграции вашей учетной записи на другое устройство ваше основное устройство будет деактивировано."
    },
    "your restore key": {
        "en": "Your restore key",
        "am": "ձեր վերականգնման բանալին",
        "ar": "مفتاح الاستعادة الخاص بك",
        "de": "Your restore key",
        "fr": "Your restore key",
        "br": "Sua chave de restauração",
        "es": "Tu clave de restauración",
        "tr": "Geri yükleme anahtarınız",
        "ru": "Ваш ключ восстановления"
    },
    "you have successfully restore your account": {
        "en": "You have successfully restore your account",
        "am": "Դուք հաջողությամբ վերականգնել եք ձեր հաշիվը",
        "ar": "لقد استعدت حسابك بنجاح",
        "de": "You have successfully restore your account",
        "fr": "You have successfully restore your account",
        "br": "Você restaurou sua conta com sucesso",
        "es": "Has restaurado tu cuenta con éxito",
        "tr": "Hesabınızı başarıyla geri yüklediniz",
        "ru": "Вы успешно восстановили свою учетную запись"
    },
    "prev": {
        "en": "Prev",
        "am": "Նախորդ",
        "ar": "السابق",
        "de": "Prev",
        "fr": "Prev",
        "br": "Anterior",
        "es": "Prev",
        "tr": "Önceki",
        "ru": "Предыдущий"
    },
    "next": {
        "en": "Next",
        "am": "Հաջորդ",
        "ar": "التالى",
        "de": "Next",
        "fr": "Next",
        "br": "Próximo",
        "es": "Próximo",
        "tr": "Sonraki",
        "ru": "Следующий"
    },
    "go live": {
        "en": "Go live",
        "am": "ուղիղ եթեր",
        "ar": "يعيش",
        "de": "Go live",
        "fr": "Go live",
        "br": "Viver",
        "es": "Vivir",
        "tr": "Canlı git",
        "ru": "Идти в прямом эфире"
    },
    "delete playlist popup title": {
        "en": "Delete playlist",
        "am": "Ջնջել փլեյլիստը",
        "ar": "حذف قائمة التشغيل",
        "de": "Delete playlist",
        "fr": "Delete playlist",
        "br": "Excluir lista de reprodução",
        "es": "Eliminar lista de reproducción",
        "tr": "Çalma listesini sil",
        "ru": "Удалить плейлист"
    },
    "delete playlist popup message": {
        "en": "Are you sure you want to delete this playlist?",
        "am": "Դուք համոզված եք, որ ցանկանում եք ջնջել այս փլեյլիստը?",
        "ar": "هل أنت متأكد أنك تريد حذف هذه القائمة؟",
        "de": "Are you sure you want to delete this playlist?",
        "fr": "Are you sure you want to delete this playlist?",
        "br": "Tem certeza de que deseja excluir esta lista de reprodução?",
        "es": "¿Estás seguro de que quieres eliminar esta lista de reproducción?",
        "tr": "Bu çalma listesini silmek istediğinizden emin misiniz?",
        "ru": "Вы уверены, что хотите удалить этот плейлист?"
    },
    "delete video popup title": {
        "en": "Delete video",
        "am": "Ջնջել տեսանյութը",
        "ar": "حذف الفيديو",
        "de": "Delete video",
        "fr": "Delete video",
        "br": "Excluir vídeo",
        "es": "Eliminar video",
        "tr": "Videoyu sil",
        "ru": "Удалить видео"
    },
    "delete video popup message": {
        "en": "Are you sure you want to delete this video?",
        "am": "Դուք համոզված եք, որ ցանկանում եք ջնջել այս տեսանյութը?",
        "ar": "هل أنت متأكد أنك تريد حذف هذا الفيديو؟",
        "de": "Are you sure you want to delete this video?",
        "fr": "Are you sure you want to delete this video?",
        "br": "Tem certeza de que deseja excluir este vídeo?",
        "es": "¿Estás seguro de que quieres eliminar este video?",
        "tr": "Bu videoyu silmek istediğinizden emin misiniz?",
        "ru": "Вы уверены, что хотите удалить это видео?"
    },
    "yes": {
        "en": "Yes",
        "am": "Այո",
        "ar": "نعم",
        "de": "Yes",
        "fr": "Yes",
        "br": "Sim",
        "es": "Sí",
        "tr": "Evet",
        "ru": "Да"
    },
    "no": {
        "en": "No",
        "am": "Ոչ",
        "ar": "لا",
        "de": "No",
        "fr": "No",
        "br": "Não",
        "es": "No",
        "tr": "Hayır",
        "ru": "Нет"
    },
    "loading": {
        "en": "Loading",
        "am": "Բեռնում",
        "ar": "جار التحميل",
        "de": "Loading",
        "fr": "Loading",
        "br": "Carregando",
        "es": "Cargando",
        "tr": "Yükleniyor",
        "ru": "Загрузка"
    },
    "uploading": {
        "en": "Uploading",
        "am": "Վերբեռնում",
        "ar": "تحميل",
        "de": "Uploading",
        "fr": "Uploading",
        "br": "Carregando",
        "es": "Subiendo",
        "tr": "Yükleniyor",
        "ru": "Загрузка"
    },
    "do you want to continue watching": {
        "en": "Do you want to continue watching?",
        "am": "Ցանկանու՞մ եք շարունակել դիտումը",
        "ar": "هل تريد الاستمرار في المشاهدة؟",
        "de": "Do you want to continue watching?",
        "fr": "Do you want to continue watching?",
        "br": "Você quer continuar assistindo?",
        "es": "¿Quieres seguir viendo?",
        "tr": "İzlemeye devam etmek ister misiniz?",
        "ru": "Хотите продолжить просмотр?"
    },
    "continue from": {
        "en": "Continue from",
        "am": "Շարունակել",
        "ar": "استمر من",
        "de": "Continue from",
        "fr": "Continue from",
        "br": "Continue de",
        "es": "Continuar desde",
        "tr": "Devam et",
        "ru": "Продолжить с"
    },
    "start from the beginning": {
        "en": "Start from the beginning",
        "am": "Սկսել սկզբից",
        "ar": "ابدأ من البداية",
        "de": "Start from the beginning",
        "fr": "Start from the beginning",
        "br": "Comece do começo",
        "es": "Comienza desde el principio",
        "tr": "Baştan başla",
        "ru": "Начать сначала"
    },
    "play next episode": {
        "en": "Play next episode",
        "am": "Նվագարկել հաջորդը",
        "ar": "تشغيل الحلقة التالية",
        "de": "Play next episode",
        "fr": "Play next episode",
        "br": "Reproduzir próximo episódio",
        "es": "Reproducir siguiente episodio",
        "tr": "Sonraki bölümü oyna",
        "ru": "Воспроизвести следующую серию"
    },
    "press back to cancel": {
        "en": "Press back to cancel",
        "am": "Սեղմեք հետադարձ չեղարկելու համար",
        "ar": "اضغط على الخلف للإلغاء",
        "de": "Press back to cancel",
        "fr": "Press back to cancel",
        "br": "Pressione voltar para cancelar",
        "es": "Presione atrás para cancelar",
        "tr": "İptal etmek için geri basın",
        "ru": "Нажмите назад, чтобы отменить"
    },
    "no result found": {
        "en": "No result found",
        "am": "Արդյունք չի գտնվել",
        "ar": "لم يتم العثور على نتيجة",
        "de": "No result found",
        "fr": "No result found",
        "br": "Nenhum resultado encontrado",
        "es": "No se encontraron resultados",
        "tr": "Sonuç bulunamadı",
        "ru": "Результат не найден"
    },
    "no movies in this category": {
        "en": "No movies in this category",
        "am": "Այս կատեգորիայում չկան ֆիլմեր",
        "ar": "لا توجد أفلام في هذه الفئة",
        "de": "No movies in this category",
        "fr": "No movies in this category",
        "br": "Sem filmes nesta categoria",
        "es": "No hay películas en esta categoría",
        "tr": "Bu kategoride film yok",
        "ru": "В этой категории нет фильмов"
    },
    "no series in this category": {
        "en": "No series in this category",
        "am": "Այս կատեգորիայում չկան սերիալներ",
        "ar": "لا توجد مسلسلات في هذه الفئة",
        "de": "No series in this category",
        "fr": "No series in this category",
        "br": "Sem séries nesta categoria",
        "es": "No hay series en esta categoría",
        "tr": "Bu kategoride dizi yok",
        "ru": "В этой категории нет сериалов"
    },
    "change pin code": {
        "en": "Change Pin Code",
        "am": "Փոխել պին կոդը",
        "ar": "تغيير رمز التعريف الشخصي",
        "de": "Pin-Code ändern",
        "fr": "Changer le code PIN",
        "br": "Alterar código pin",
        "es": "Cambiar código pin",
        "tr": "Pin kodunu değiştir",
        "ru": "Изменить пин-код"
    },
    "manage categories": {
        "en": "Manage Categories",
        "am": "Կառավարել կատեգորիաները",
        "ar": "إدارة الفئات",
        "de": "Kategorien verwalten",
        "fr": "Gérer les catégories",
        "br": "Gerenciar categorias",
        "es": "Administrar categorías",
        "tr": "Kategorileri yönet",
        "ru": "Управление категориями"
    },
    "clear storage": {
        "en": "Clear Storage",
        "am": "Մաքրել հիշողությունը",
        "ar": "مسح التخزين",
        "de": "Speicher löschen",
        "fr": "Effacer le stockage",
        "br": "Limpar armazenamento",
        "es": "Borrar almacenamiento",
        "tr": "Depolamayı temizle",
        "ru": "Очистить хранилище"
    },
    "change stream format": {
        "en": "Change Stream Format",
        "am": "Փոխել տեսականի ձևը",
        "ar": "تغيير تنسيق التيار",
        "de": "Stream-Format ändern",
        "fr": "Changer le format du flux",
        "br": "Alterar formato de fluxo",
        "es": "Cambiar formato de flujo",
        "tr": "Akış Formatını Değiştir",
        "ru": "Изменить формат потока"
    },
    "general info": {
        "en": "General Info",
        "am": "Ընդհանուր տվյալներ",
        "ar": "معلومات عامة",
        "de": "Allgemeine Information",
        "fr": "Information générale",
        "br": "Informação geral",
        "es": "Información general",
        "tr": "Genel Bilgi",
        "ru": "Общая информация"
    },
    "account info": {
        "en": "Account Info",
        "am": "Հաշիվի տվյալներ",
        "ar": "معلومات الحساب",
        "de": "Kontoinformation",
        "fr": "Informations de compte",
        "br": "Informações da Conta",
        "es": "Informacion de Cuenta",
        "tr": "Hesap Bilgisi",
        "ru": "Информация об аккаунте"
    },
    "device info": {
        "en": "Device Info",
        "am": "Սարքի տվյալներ",
        "ar": "معلومات الجهاز",
        "de": "Geräteinformationen",
        "fr": "Info appareil",
        "br": "Informação do dispositivo",
        "es": "Información del dispositivo",
        "tr": "Cihaz Bilgisi",
        "ru": "Информация об устройстве"
    },
    "laguages": {
        "en": "Laguages",
        "am": "Լեզուներ",
        "ar": "لغات",
        "de": "Sprachen",
        "fr": "Langues",
        "br": "línguas",
        "es": "Idiomas",
        "tr": "Diller",
        "ru": "Языки"
    },
    "position": {
        "en": "Position",
        "am": "Դիրք",
        "ar": "موضع",
        "de": "Position",
        "fr": "Position",
        "br": "Posição",
        "es": "Posición",
        "tr": "Pozisyon",
        "ru": "Позиция"
    },
    "top": {
        "en": "Top",
        "am": "Վերև",
        "ar": "أعلى",
        "de": "Top",
        "fr": "Top",
        "br": "Topo",
        "es": "Superior",
        "tr": "Üst",
        "ru": "Верх"
    },
    "bottom": {
        "en": "Bottom",
        "am": "Ներքև",
        "ar": "قاع",
        "de": "Bottom",
        "fr": "Bottom",
        "br": "Fundo",
        "es": "Fondo",
        "tr": "Alt",
        "ru": "Дно"
    },
    "size": {
        "en": "Size",
        "am": "Չափ",
        "ar": "بحجم",
        "de": "Size",
        "fr": "Size",
        "br": "Tamanho",
        "es": "Talla",
        "tr": "Boyut",
        "ru": "Размер"
    },
    "small": {
        "en": "Small",
        "am": "Փոքր",
        "ar": "صغير",
        "de": "Small",
        "fr": "Small",
        "br": "Pequeno",
        "es": "Pequeña",
        "tr": "Küçük",
        "ru": "Маленький"
    },
    "medium": {
        "en": "Medium",
        "am": "Միջին",
        "ar": "متوسط",
        "de": "Medium",
        "fr": "Medium",
        "br": "Médio",
        "es": "Medio",
        "tr": "Orta",
        "ru": "Средний"
    },
    "large": {
        "en": "Large",
        "am": "Մեծ",
        "ar": "كبير",
        "de": "Large",
        "fr": "Large",
        "br": "Grande",
        "es": "Grande",
        "tr": "Büyük",
        "ru": "Большой"
    },
    "style": {
        "en": "Style",
        "am": "Տեսակ",
        "ar": "نمط",
        "de": "Style",
        "fr": "Style",
        "br": "Estilo",
        "es": "Estilo",
        "tr": "Stil",
        "ru": "Стиль"
    },
    "light": {
        "en": "Light",
        "am": "Լույս",
        "ar": "ضوء",
        "de": "Light",
        "fr": "Light",
        "br": "Leve",
        "es": "Ligero",
        "tr": "Işık",
        "ru": "Светлый"
    },
    "dark": {
        "en": "Dark",
        "am": "Ամպային",
        "ar": "داكن",
        "de": "Dark",
        "fr": "Dark",
        "br": "Escuro",
        "es": "Oscuro",
        "tr": "Karanlık",
        "ru": "Темный"
    },
    "contrast": {
        "en": "Contrast",
        "am": "Կոնտրաստ",
        "ar": "تباين",
        "de": "Contrast",
        "fr": "Contrast",
        "br": "Contraste",
        "es": "Contraste",
        "tr": "Kontrast",
        "ru": "Контраст"
    },
    "drop-shadow": {
        "en": "Drop Shadow",
        "am": "Անկյունագծված տեսակ",
        "ar": "إسقاط الظل",
        "de": "Drop Shadow",
        "fr": "Drop Shadow",
        "br": "Sombra de gota",
        "es": "Sombra de gota",
        "tr": "Gölge bırak",
        "ru": "Тень"
    },
    "enter old pin code": {
        "en": "Enter old pin code",
        "am": "Մուտքագրեք հին պին կոդը",
        "ar": "أدخل رمز التعريف الشخصي القديم",
        "de": "Enter old pin code",
        "fr": "Enter old pin code",
        "br": "Digite o código pin antigo",
        "es": "Ingrese el código pin antiguo",
        "tr": "Eski pin kodunu girin",
        "ru": "Введите старый пин-код"
    },
    "enter new pin code": {
        "en": "Enter new pin code",
        "am": "Մուտքագրեք նոր պին կոդը",
        "ar": "أدخل رمز التعريف الشخصي الجديد",
        "de": "Enter new pin code",
        "fr": "Enter new pin code",
        "br": "Digite o novo código pin",
        "es": "Ingrese el nuevo código pin",
        "tr": "Yeni pin kodunu girin",
        "ru": "Введите новый пин-код"
    },
    "confirm new pin code": {
        "en": "Confirm new pin code",
        "am": "Հաստատեք նոր պին կոդը",
        "ar": "تأكيد رمز التعريف الشخصي الجديد",
        "de": "Confirm new pin code",
        "fr": "Confirm new pin code",
        "br": "Confirme o novo código pin",
        "es": "Confirmar nuevo código pin",
        "tr": "Yeni pin kodunu onayla",
        "ru": "Подтвердите новый пин-код"
    },
    "wrong pin code": {
        "en": "Wrong pin code",
        "am": "Սխալ պին կոդ",
        "ar": "رمز التعريف الشخصي الخاطئ",
        "de": "Wrong pin code",
        "fr": "Wrong pin code",
        "br": "Código pin errado",
        "es": "Código pin erróneo",
        "tr": "Yanlış pin kodu",
        "ru": "Неправильный пин-код"
    },
    "pin code changed": {
        "en": "Pin code changed",
        "am": "Pin կոդը փոխվեց",
        "ar": "تم تغيير رمز التعريف الشخصي",
        "de": "Pin code changed",
        "fr": "Pin code changed",
        "br": "Código pin alterado",
        "es": "Código pin cambiado",
        "tr": "Pin kodu değiştirildi",
        "ru": "Пин-код изменен"
    },
    "pin codes do not match": {
        "en": "Pin codes do not match",
        "am": "Pin կոդերը չեն համընկնում",
        "ar": "رموز التعريف الشخصي لا تتطابق",
        "de": "Pin codes do not match",
        "fr": "Pin codes do not match",
        "br": "Os códigos pin não correspondem",
        "es": "Los códigos pin no coinciden",
        "tr": "Pin kodları eşleşmiyor",
        "ru": "Пин-коды не совпадают"
    },
    "favorite channels": {
        "en": "Favorite channels",
        "am": "Նախընտրած ալիքները",
        "ar": "القنوات المفضلة",
        "de": "Favorite channels",
        "fr": "Favorite channels",
        "br": "Canais favoritos",
        "es": "Canales favoritos",
        "tr": "Favori kanallar",
        "ru": "Любимые каналы"
    },
    "favorite movies": {
        "en": "Favorite movies",
        "am": "Նախընտրած ֆիլմերը",
        "ar": "الأفلام المفضلة",
        "de": "Favorite movies",
        "fr": "Favorite movies",
        "br": "Filmes favoritos",
        "es": "Películas favoritas",
        "tr": "Favori filmler",
        "ru": "Любимые фильмы"
    },
    "favorite series": {
        "en": "Favorite series",
        "am": "Նախընտրած սերիալները",
        "ar": "السلسلة المفضلة",
        "de": "Favorite series",
        "fr": "Favorite series",
        "br": "Séries favoritas",
        "es": "Series favoritas",
        "tr": "Favori dizi",
        "ru": "Любимые сериалы"
    },
    "watched movies": {
        "en": "Watched movies",
        "am": "Դիտված ֆիլմերը",
        "ar": "الأفلام التي شاهدتها",
        "de": "Watched movies",
        "fr": "Watched movies",
        "br": "Filmes assistidos",
        "es": "Películas vistas",
        "tr": "İzlenen filmler",
        "ru": "Просмотренные фильмы"
    },
    "watched series": {
        "en": "Watched series",
        "am": "Դիտված սերիալները",
        "ar": "السلسلة التي شاهدتها",
        "de": "Watched series",
        "fr": "Watched series",
        "br": "Séries assistidas",
        "es": "Series vistas",
        "tr": "İzlenen dizi",
        "ru": "Просмотренные сериалы"
    },
    "clear all": {
        "en": "Clear all",
        "am": "Մաքրել բոլորը",
        "ar": "امسح الكل",
        "de": "Clear all",
        "fr": "Clear all",
        "br": "Limpar tudo",
        "es": "Limpiar todo",
        "tr": "Hepsini temizle",
        "ru": "Очистить все"
    },
    "clear": {
        "en": "Clear",
        "am": "Մաքրել",
        "ar": "واضح",
        "de": "Clear",
        "fr": "Clear",
        "br": "Limpar",
        "es": "Claro",
        "tr": "Temizle",
        "ru": "Очистить"
    },
    "the restore key is incorrect": {
        "en": "The restore key is incorrect",
        "am": "Վերականգնման բանալին սխալ է",
        "ar": "مفتاح الاستعادة غير صحيح",
        "de": "The restore key is incorrect",
        "fr": "The restore key is incorrect",
        "br": "A chave de restauração está incorreta",
        "es": "La clave de restauración es incorrecta",
        "tr": "Geri yükleme anahtarı yanlış",
        "ru": "Ключ восстановления неверен"
    },
    "device is not paid": {
        "en": "Device is not paid",
        "am": "Սարքը վճարված չէ",
        "ar": "الجهاز غير مدفوع",
        "de": "Device is not paid",
        "fr": "Device is not paid",
        "br": "O dispositivo não é pago",
        "es": "El dispositivo no está pagado",
        "tr": "Cihaz ödenmedi",
        "ru": "Устройство не оплачено"
    },
    "already restored": {
        "en": "Already restored",
        "am": "Վերականգնված է",
        "ar": "تم استعادته بالفعل",
        "de": "Already restored",
        "fr": "Already restored",
        "br": "Já restaurado",
        "es": "Ya restaurado",
        "tr": "Zaten geri yüklendi",
        "ru": "Уже восстановлен"
    },
    "active": {
        "en": "Active",
        "am": "Ակտիվ",
        "ar": "نشيط",
        "de": "Active",
        "fr": "Active",
        "br": "Ativo",
        "es": "Activo",
        "tr": "Aktif",
        "ru": "Активный"
    },
    "registration date": {
        "en": "Registration date",
        "am": "Գրանցման ամսաթիվ",
        "ar": "تاريخ التسجيل",
        "de": "Registration date",
        "fr": "Registration date",
        "br": "Data de registro",
        "es": "Fecha de registro",
        "tr": "Kayıt tarihi",
        "ru": "Дата регистрации"
    },
    "expired date": {
        "en": "Expired date",
        "am": "ժամկետի ավարտը",
        "ar": "تاريخ الانتهاء",
        "de": "Expired date",
        "fr": "Expired date",
        "br": "Data de validade",
        "es": "Fecha de vencimiento",
        "tr": "Son kullanma tarihi",
        "ru": "Дата окончания"
    },
    "lifetime": {
        "en": "Lifetime",
        "am": "Անժամկետ",
        "ar": "مدى الحياة",
        "de": "Lifetime",
        "fr": "Lifetime",
        "br": "Vida",
        "es": "Vida",
        "tr": "Ömür boyu",
        "ru": "Пожизненно"
    },
    "app version": {
        "en": "App version",
        "am": "App տարբերակը",
        "ar": "إصدار التطبيق",
        "de": "App version",
        "fr": "App version",
        "br": "Versão do aplicativo",
        "es": "Versión de la aplicación",
        "tr": "Uygulama sürümü",
        "ru": "Версия приложения"
    },
    "device key": {
        "en": "Device key",
        "am": "Սարքի բանալին",
        "ar": "مفتاح الجهاز",
        "de": "Device key",
        "fr": "Device key",
        "br": "Chave do dispositivo",
        "es": "Clave del dispositivo",
        "tr": "Cihaz anahtarı",
        "ru": "Ключ устройства"
    },
    "active connections": {
        "en": "Active connections",
        "am": "Ակտիվ կապեր",
        "ar": "اتصالات نشطة",
        "de": "Active connections",
        "fr": "Active connections",
        "br": "Conexões ativas",
        "es": "Conexiones activas",
        "tr": "Aktif bağlantılar",
        "ru": "Активные соединения"
    },
    "expired": {
        "en": "Expired",
        "am": "ժամկետանց",
        "ar": "منتهية الصلاحية",
        "de": "Expired",
        "fr": "Expired",
        "br": "Expirado",
        "es": "Expirado",
        "tr": "Süresi doldu",
        "ru": "Истекший"
    },
    "default pin code: 0000": {
        "en": "Default pin code: 0000",
        "am": "Սկզբնական պին կոդը: 0000",
        "ar": "رمز التعريف الشخصي الافتراضي: 0000",
        "de": "Default pin code: 0000",
        "fr": "Default pin code: 0000",
        "br": "Código pin padrão: 0000",
        "es": "Código pin predeterminado: 0000",
        "tr": "Varsayılan pin kodu: 0000",
        "ru": "Пин-код по умолчанию: 0000"
    },
    "show": {
        "en": "Show",
        "am": "Ցուցադրել",
        "ar": "تبين",
        "de": "Show",
        "fr": "Show",
        "br": "Mostrar",
        "es": "Mostrar",
        "tr": "Göster",
        "ru": "Показать"
    },
    "hide": {
        "en": "Hide",
        "am": "Թաքցնել",
        "ar": "إخفاء",
        "de": "Hide",
        "fr": "Hide",
        "br": "Esconder",
        "es": "Esconder",
        "tr": "Gizle",
        "ru": "Скрыть"
    },
    "lock": {
        "en": "Lock",
        "am": "Արգելափակել",
        "ar": "قفل",
        "de": "Lock",
        "fr": "Lock",
        "br": "Trancar",
        "es": "Bloquear",
        "tr": "Kilitle",
        "ru": "Замок"
    },
    "unlock": {
        "en": "Unlock",
        "am": "Բացել",
        "ar": "فتح",
        "de": "Unlock",
        "fr": "Unlock",
        "br": "Destrancar",
        "es": "Desbloquear",
        "tr": "Kilidi aç",
        "ru": "Отпереть"
    },
    "move": {
        "en": "Move",
        "am": "Տեղափոխել",
        "ar": "تحرك",
        "de": "Move",
        "fr": "Move",
        "br": "Mover",
        "es": "Mover",
        "tr": "Hareket et",
        "ru": "Двигаться"
    },
    "channels categories": {
        "en": "Channels categories",
        "am": "Ալիքների կատեգորիաներ",
        "ar": "فئات القنوات",
        "de": "Channels categories",
        "fr": "Channels categories",
        "br": "Categorias de canais",
        "es": "Categorías de canales",
        "tr": "Kanal kategorileri",
        "ru": "Категории каналов"
    },
    "movies categories": {
        "en": "Movies categories",
        "am": "Ֆիլմերի կատեգորիաներ",
        "ar": "فئات الأفلام",
        "de": "Movies categories",
        "fr": "Movies categories",
        "br": "Categorias de filmes",
        "es": "Categorías de películas",
        "tr": "Film kategorileri",
        "ru": "Категории фильмов"
    },
    "series categories": {
        "en": "Series categories",
        "am": "Սերիալների կատեգորիաներ",
        "ar": "فئات السلسلة",
        "de": "Series categories",
        "fr": "Series categories",
        "br": "Categorias de séries",
        "es": "Categorías de series",
        "tr": "Dizi kategorileri",
        "ru": "Категории сериалов"
    },
    "enter pin code": {
        "en": "Enter pin code",
        "am": "Մուտքագրեք պին կոդը",
        "ar": "أدخل رمز التعريف الشخصي",
        "de": "Enter pin code",
        "fr": "Enter pin code",
        "br": "Digite o código pin",
        "es": "Ingrese el código pin",
        "tr": "Pin kodunu girin",
        "ru": "Введите пин-код"
    },
    "close": {
        "en": "Close",
        "am": "Փակել",
        "ar": "أغلق",
        "de": "Close",
        "fr": "Close",
        "br": "Fechar",
        "es": "Cerrar",
        "tr": "Kapat",
        "ru": "Закрыть"
    },
    "your subtitles will look like this": {
        "en": "Your subtitles will look like this",
        "am": "Ձեր ենթագրերը կտեսնեն այսպես",
        "ar": "سيبدو ترجماتك مثل هذا",
        "de": "Your subtitles will look like this",
        "fr": "Your subtitles will look like this",
        "br": "Suas legendas ficarão assim",
        "es": "Tus subtítulos se verán así",
        "tr": "Altyazılarınız böyle görünecek",
        "ru": "Ваши субтитры будут выглядеть так"
    },
    "sorry, there was an error loading the player.": {
        "en": "Sorry, there was an error loading the player.",
        "am": "Ներողություն, պլայերը բեռնելիս սխալ տեղի ունեցավ",
        "ar": "عذرًا ، حدث خطأ أثناء تحميل اللاعب.",
        "de": "Sorry, there was an error loading the player.",
        "fr": "Sorry, there was an error loading the player.",
        "br": "Desculpe, ocorreu um erro ao carregar o player.",
        "es": "Lo sentimos, se produjo un error al cargar el reproductor.",
        "tr": "Üzgünüz, oynatıcı yüklenirken bir hata oluştu.",
        "ru": "Извините, произошла ошибка при загрузке плеера."
    },
    "videos": {
        "en": "Videos",
        "am": "Տեսանյութեր",
        "ar": "أشرطة فيديو",
        "de": "Videos",
        "fr": "Vidéos",
        "br": "Vídeos",
        "es": "Videos",
        "tr": "Videolar",
        "ru": "Видео"
    },
    "add video": {
        "en": "Add video",
        "am": "Ավելացնել տեսանյութ",
        "ar": "أضف فيديو",
        "de": "Add video",
        "fr": "Add video",
        "br": "Adicionar vídeo",
        "es": "Agregar video",
        "tr": "Video ekle",
        "ru": "Добавить видео"
    },
    "connection lost": {
        "en": "Connection Lost",
        "am": "Կապը կորցրած է",
        "ar": "اتصال مفقود",
        "de": "Verbindung verloren",
        "fr": "Connexion perdue",
        "br": "Conexão perdida",
        "es": "Conexión perdida",
        "tr": "Bağlantı kayboldu",
        "ru": "Соединение потеряно"
    },
    "error loading channels": {
        "en": "Error loading channels",
        "am": "Ալիքները բեռնելիս սխալ տեղի ունեցավ",
        "ar": "خطأ في تحميل القنوات",
        "de": "Fehler beim Laden von Kanälen",
        "fr": "Erreur de chargement des chaînes",
        "br": "Erro ao carregar canais",
        "es": "Error al cargar los canales",
        "tr": "Kanallar yüklenirken hata oluştu",
        "ru": "Ошибка загрузки каналов"
    },
    "no channels found": {
        "en": "No channels found",
        "am": "Ալիքներ չի գտնվել",
        "ar": "لم يتم العثور على قنوات",
        "de": "Keine Kanäle gefunden",
        "fr": "Aucune chaîne trouvée",
        "br": "Nenhum canal encontrado",
        "es": "No se encontraron canales",
        "tr": "Kanal bulunamadı",
        "ru": "Каналы не найдены"
    },
    "error loading movies": {
        "en": "Error loading movies",
        "am": "Ֆիլմերը բեռնելիս սխալ տեղի ունեցավ",
        "ar": "خطأ في تحميل الأفلام",
        "de": "Fehler beim Laden von Filmen",
        "fr": "Erreur de chargement des films",
        "br": "Erro ao carregar filmes",
        "es": "Error al cargar las películas",
        "tr": "Filmler yüklenirken hata oluştu",
        "ru": "Ошибка загрузки фильмов"
    },
    "error loading series": {
        "en": "Error loading series",
        "am": "Սերիալները բեռնելիս սխալ տեղի ունեցավ",
        "ar": "خطأ في تحميل السلسلة",
        "de": "Fehler beim Laden von Serien",
        "fr": "Erreur de chargement de la série",
        "br": "Erro ao carregar séries",
        "es": "Error al cargar series",
        "tr": "Dizi yüklenirken hata oluştu",
        "ru": "Ошибка загрузки сериалов"
    },
    "no movies found": {
        "en": "No movies found",
        "am": "Ֆիլմեր չի գտնվել",
        "ar": "لم يتم العثور على أفلام",
        "de": "Keine Filme gefunden",
        "fr": "Aucun film trouvé",
        "br": "Nenhum filme encontrado",
        "es": "No se encontraron películas",
        "tr": "Film bulunamadı",
        "ru": "Фильмы не найдены"
    },
    "no series found": {
        "en": "No series found",
        "am": "Սերիալներ չի գտնվել",
        "ar": "لم يتم العثور على سلسلة",
        "de": "Keine Serie gefunden",
        "fr": "Aucune série trouvée",
        "br": "Nenhuma série encontrada",
        "es": "No se encontraron series",
        "tr": "Dizi bulunamadı",
        "ru": "Сериалы не найдены"
    },
    "selected playlist is demo": {
        "en": "Selected playlist is demo",
        "am": "Ընտրված երգացանկը դեմո է",
        "ar": "قائمة التشغيل المحددة هي تجريبية",
        "de": "Selected playlist is demo",
        "fr": "Selected playlist is demo",
        "br": "A lista de reprodução selecionada é demo",
        "es": "La lista de reproducción seleccionada es demo",
        "tr": "Seçilen çalma listesi demo",
        "ru": "Выбранный плейлист демо"
    },
    "playlist selected successfully": {
        "en": "Playlist selected successfully",
        "am": "Երգացանկը ընտրված է",
        "ar": "تم تحديد قائمة التشغيل بنجاح",
        "de": "Playlist selected successfully",
        "fr": "Playlist selected successfully",
        "br": "Lista de reprodução selecionada com sucesso",
        "es": "Lista de reproducción seleccionada correctamente",
        "tr": "Çalma listesi başarıyla seçildi",
        "ru": "Плейлист успешно выбран"
    },
    "no channels in this category": {
        "en": "No channels in this category",
        "am": "Այս կատեգորիայում ալիքներ չկան",
        "ar": "لا توجد قنوات في هذه الفئة",
        "de": "Keine Kanäle in dieser Kategorie",
        "fr": "Aucune chaîne dans cette catégorie",
        "br": "Nenhum canal nesta categoria",
        "es": "No hay canales en esta categoría",
        "tr": "Bu kategoride kanal yok",
        "ru": "В этой категории нет каналов"
    },
    "html5 player": {
        "en": "HTML5 Player",
        "am": "HTML5 պլայեր",
        "ar": "مشغل HTML5",
        "de": "HTML5 Player",
        "fr": "HTML5 Player",
        "br": "Player HTML5",
        "es": "Reproductor HTML5",
        "tr": "HTML5 Oynatıcı",
        "ru": "HTML5 плеер"
    },
    "native player": {
        "en": "Native Player",
        "am": "Ներկառուցված պլայեր",
        "ar": "مشغل",
        "de": "Native Player",
        "fr": "Native Player",
        "br": "Player nativo",
        "es": "Reproductor nativo",
        "tr": "Yerel Oynatıcı",
        "ru": "Основной плеер"
    },
    "player settings": {
        "en": "Player settings",
        "am": "Պլայերի կարգավորումներ",
        "ar": "إعدادات اللاعب",
        "de": "Player settings",
        "fr": "Player settings",
        "br": "Configurações do player",
        "es": "Configuración del reproductor",
        "tr": "Oynatıcı ayarları",
        "ru": "Настройки плеера"
    },
    "channel player": {
        "en": "Channel player",
        "am": "Ալիքի պլայեր",
        "ar": "مشغل القناة",
        "de": "Channel player",
        "fr": "Channel player",
        "br": "Player de canal",
        "es": "Reproductor de canal",
        "tr": "Kanal oynatıcı",
        "ru": "Плеер канала"
    },
    "vod player": {
        "en": "VOD player",
        "am": "VOD պլայեր",
        "ar": "مشغل VOD",
        "de": "VOD player",
        "fr": "VOD player",
        "br": "Player VOD",
        "es": "Reproductor VOD",
        "tr": "VOD oynatıcı",
        "ru": "VOD плеер"
    },
    "your free trail has expired. activate the app to use it.": {
        "en": "Your free trail has expired. Activate the app to use it.",
        "am": "Ձեր անվճար փորձաշրջանի ժամկետը սպառվել է: Ակտիվացրեք հավելվածը՝ այն օգտագործելու համար:",
        "ar": "الفترة المجانية انتهت. قم بتنشيط التطبيق لاستخدامه.",
        "de": "Ihre kostenlose Testversion ist abgelaufen. Aktivieren Sie die App, um sie zu verwenden.",
        "fr": "Votre essai gratuit a expiré. Activez l'application pour l'utiliser.",
        "br": "Seu teste gratuito expirou. Ative o aplicativo para usá-lo.",
        "es": "Su prueba gratuita ha expirado. Activa la aplicación para usarla.",
        "tr": "Ücretsiz deneme süreniz sona erdi. Kullanmak için uygulamayı etkinleştirin.",
        "ru": "Ваш бесплатный пробный период истек. Активируйте приложение, чтобы использовать его."
    },
    "how to activate?": {
        "en": "How to activate?",
        "am": "Ինչպե՞ս ակտիվացնել",
        "ar": "كيف تفعل؟",
        "de": "How to activate?",
        "fr": "How to activate?",
        "br": "Como ativar?",
        "es": "¿Cómo activar?",
        "tr": "Nasıl aktive edilir?",
        "ru": "Как активировать?"
    },
    "scan qr code or visit": {
        "en": "Scan QR code or visit",
        "am": "Սկանավորեք QR կոդը կամ այցելեք",
        "ar": "مسح رمز الاستجابة السريعة أو زيارة",
        "de": "Scan QR code or visit",
        "fr": "Scan QR code or visit",
        "br": "Digitalize o código QR ou visite",
        "es": "Escanee el código QR o visite",
        "tr": "QR kodunu tarayın veya ziyaret edin",
        "ru": "Отсканируйте QR-код или посетите"
    },
    "choose your preferred payment method and make the payment": {
        "en": "Choose your preferred payment method and make the payment",
        "am": "Ընտրեք ձեր նախընտրած վճարման եղանակը և կատարեք վճարումը",
        "ar": "اختر طريقة الدفع المفضلة لديك وقم بالدفع",
        "de": "Choose your preferred payment method and make the payment",
        "fr": "Choose your preferred payment method and make the payment",
        "br": "Escolha seu método de pagamento preferido e faça o pagamento",
        "es": "Elija su método de pago preferido y realice el pago",
        "tr": "Tercih ettiğiniz ödeme yöntemini seçin ve ödemeyi yapın",
        "ru": "Выберите предпочтительный способ оплаты и произведите оплату"
    },
    "check your tv screen it can take 1-2 minutes to be activated": {
        "en": "Check your tv screen it can take 1-2 minutes to be activated",
        "am": "Ստուգեք ձեր հեռուստատեսակի էկրանը, ակտիվացումը կարող է տևել 1-2 րոպե",
        "ar": "تحقق من شاشة التلفزيون الخاصة بك يمكن أن يستغرق التنشيط من 1 إلى 2 دقيقة",
        "de": "Check your tv screen it can take 1-2 minutes to be activated",
        "fr": "Check your tv screen it can take 1-2 minutes to be activated",
        "br": "Verifique sua tela de TV, pode levar de 1 a 2 minutos para ser ativada",
        "es": "Verifique su pantalla de televisión, puede tardar de 1 a 2 minutos en activarse",
        "tr": "TV ekranınızı kontrol edin, etkinleştirilmesi 1-2 dakika sürebilir",
        "ru": "Проверьте свой телевизор, активация может занять 1-2 минуты"
    },
    "reload": {
        "en": "Reload",
        "am": "Թարմացնել",
        "ar": "إعادة تحميل",
        "de": "Reload",
        "fr": "Reload",
        "br": "Recarregar",
        "es": "Recargar",
        "tr": "Yeniden yükle",
        "ru": "Перезагрузить"
    },
    "save": {
        "en": "Save",
        "am": "Պահպանել",
        "ar": "حفظ",
        "de": "Save",
        "fr": "Save",
        "br": "Salvar",
        "es": "Guardar",
        "tr": "Kaydet",
        "ru": "Сохранить"
    },
    "playlist added successfully": {
        "en": "Playlist added successfully",
        "am": "Երգացանկը հաջողությամբ ավելացվեց",
        "ar": "تمت إضافة قائمة التشغيل بنجاح",
        "de": "Playlist added successfully",
        "fr": "Playlist added successfully",
        "br": "Lista de reprodução adicionada com sucesso",
        "es": "Lista de reproducción agregada correctamente",
        "tr": "Çalma listesi başarıyla eklendi",
        "ru": "Плейлист успешно добавлен"
    },
    "playlist deleted successfully": {
        "en": "Playlist deleted successfully",
        "am": "Երգացանկը հաջողությամբ ջնջվեց",
        "ar": "تم حذف قائمة التشغيل بنجاح",
        "de": "Playlist deleted successfully",
        "fr": "Playlist deleted successfully",
        "br": "Lista de reprodução excluída com sucesso",
        "es": "Lista de reproducción eliminada correctamente",
        "tr": "Çalma listesi başarıyla silindi",
        "ru": "Плейлист успешно удален"
    },
    "time settings": {
        "en": "Time settings",
        "am": "Ժամի կարգավորումներ",
        "ar": "إعدادات الوقت",
        "de": "Zeiteinstellungen",
        "fr": "Paramètres de temps",
        "br": "Configurações de tempo",
        "es": "Configuración de tiempo",
        "tr": "Zaman ayarları",
        "ru": "Настройки времени"
    },
    "incorrect playlist url": {
        "en": "Incorrect playlist url",
        "am": "Սխալ երգացանկի URL",
        "ar": "عنوان URL لقائمة التشغيل غير صحيح",
        "de": "Falsche Wiedergabeliste URL",
        "fr": "URL de liste de lecture incorrecte",
        "br": "URL da lista de reprodução incorreta",
        "es": "URL de lista de reproducción incorrecta",
        "tr": "Yanlış çalma listesi URL'si",
        "ru": "Неверный URL плейлиста"
    },
    "long press ok to show color buttons": {
        "en": "Long press OK to show color buttons",
        "am": "Երկար սեղմեք OK՝ գունավոր կոճակները ցուցադրելու համար",
        "ar": "اضغط لفترة طويلة على موافق لإظهار أزرار الألوان",
        "de": "Drücken Sie lange auf OK, um die Farbschaltflächen anzuzeigen",
        "fr": "Appuyez longuement sur OK pour afficher les boutons de couleur",
        "br": "Pressione longamente OK para mostrar os botões coloridos",
        "es": "Mantenga presionado OK para mostrar los botones de color",
        "tr": "Renkli düğmeleri göstermek için Tamam'a uzun basın",
        "ru": "Удерживайте OK для отображения цветных кнопок"
    },
    "if you log out of the application, you will lose your action history (move, favorites, continue watching)":{
        "en": "If you log out of the application, you will lose your action history (move, favorites, continue watching)",
        "am": "Եթե դուք դուրս գաք ծրագրից, դուք կկորցնեք ձեր գործողության պատմությունը (տեղափոխել, ընտրվածներ, շարունակել դիտելը)",
        "ar": "إذا قمت بتسجيل الخروج من التطبيق ، فستفقد تاريخ الإجراءات الخاص بك (التحرك ، المفضلات ، متابعة المشاهدة)",
        "de": "Wenn Sie sich aus der Anwendung abmelden, verlieren Sie Ihren Aktionsverlauf (verschieben, Favoriten, weiter ansehen)",
        "fr": "Si vous vous déconnectez de l'application, vous perdrez votre historique d'actions (déplacer, favoris, continuer à regarder)",
        "br": "Se você sair do aplicativo, perderá seu histórico de ações (mover, favoritos, continuar assistindo)",
        "es": "Si cierra la sesión de la aplicación, perderá su historial de acciones (mover, favoritos, continuar viendo)",
        "tr": "Uygulamadan çıkarsanız, eylem geçmişinizi kaybedersiniz (taşı, favoriler, izlemeye devam et)",
        "ru": "Если вы выйдете из приложения, вы потеряете историю действий (перемещение, избранное, продолжить просмотр)"
    },
    "exo player": {
        "en": "Exo player",
        "am": "Exo պլայեր",
        "ar": "مشغل Exo",
        "de": "Exo player",
        "fr": "Exo player",
        "br": "Player Exo",
        "es": "Reproductor Exo",
        "tr": "Exo oynatıcı",
        "ru": "Exo плеер"
    },
    "vlc player": {
        "en": "VLC player",
        "am": "VLC պլայեր",
        "ar": "مشغل VLC",
        "de": "VLC player",
        "fr": "VLC player",
        "br": "Player VLC",
        "es": "Reproductor VLC",
        "tr": "VLC oynatıcı",
        "ru": "VLC плеер"
    },

    "arabic layout": {
        "en": "Arabic layout",
        "am": "Արաբական դասավորություն",
        "ar": "تخطيط عربي",
        "de": "Arabic layout",
        "fr": "Arabic layout",
        "br": "Layout árabe",
        "es": "Diseño árabe",
        "tr": "Arapça düzen",
        "ru": "Арабский макет"
    },

    "rtl mode (for arabic)": {
        "en": "RTL mode (for Arabic)",
        "am": "RTL ռեժիմ (արաբերեն)",
        "ar": "وضع RTL (للعربية)",
        "de": "RTL mode (for Arabic)",
        "fr": "RTL mode (for Arabic)",
        "br": "Modo RTL (para árabe)",
        "es": "Modo RTL (para árabe)",
        "tr": "RTL modu (Arapça için)",
        "ru": "RTL режим (для арабского)"
    },

    "done": {
        "en": "Done",
        "am": "Ավարտ",
        "ar": "تم",
        "de": "Done",
        "fr": "Done",
        "br": "Feito",
        "es": "Hecho",
        "tr": "Tamamlandı",
        "ru": "Готово"
    },

    "clean": {
        "en": "Clean",
        "am": "Մաքրել",
        "ar": "نظيف",
        "de": "Clean",
        "fr": "Clean",
        "br": "Limpo",
        "es": "Limpio",
        "tr": "Temiz",
        "ru": "Чистый"
    },

    "space": {
        "en": "Space",
        "am": "Տարածք",
        "ar": "مساحة",
        "de": "Space",
        "fr": "Space",
        "br": "Espaço",
        "es": "Espacio",
        "tr": "Uzay",
        "ru": "Пробел"
    },

    "invalid username or password": {
        "en": "Invalid username or password",
        "am": "Անվավեր օգտանուն կամ գաղտնաբառ",
        "ar": "اسم مستخدم أو كلمة مرور غير صالحة",
        "de": "Ungültiger Benutzername oder Passwort",
        "fr": "Nom d'utilisateur ou mot de passe incorrect",
        "br": "Nome de usuário ou senha inválidos",
        "es": "Nombre de usuario o contraseña no válidos",
        "tr": "Geçersiz kullanıcı adı veya şifre",
        "ru": "Неверное имя пользователя или пароль"
    },

    "log out": {
        "en": "Log out",
        "am": "Դուրս գալ",
        "ar": "تسجيل الخروج",
        "de": "Log out",
        "fr": "Log out",
        "br": "Sair",
        "es": "Cerrar sesión",
        "tr": "Çıkış yap",
        "ru": "Выйти"
    },

    "are you sure you want to logout ?": {
        "en": "Are you sure you want to logout ?",
        "am": "Համոզվեք, որ դուք ուզում եք դուրս գալ",
        "ar": "هل أنت متأكد أنك تريد تسجيل الخروج؟",
        "de": "Are you sure you want to logout ?",
        "fr": "Are you sure you want to logout ?",
        "br": "Tem certeza de que deseja sair?",
        "es": "¿Estás seguro de que quieres cerrar sesión?",
        "tr": "Çıkış yapmak istediğinizden emin misiniz?",
        "ru": "Вы уверены, что хотите выйти?"
    },

    "username or password must be filled out": {
        "en": "Username or password must be filled out",
        "am": "Օգտանունը կամ գաղտնաբառը պետք է լրացվեն",
        "ar": "يجب ملء اسم المستخدم أو كلمة المرور",
        "de": "Benutzername oder Passwort müssen ausgefüllt werden",
        "fr": "Le nom d'utilisateur ou le mot de passe doit être rempli",
        "br": "Nome de usuário ou senha devem ser preenchidos",
        "es": "El nombre de usuario o la contraseña deben completarse",
        "tr": "Kullanıcı adı veya şifre doldurulmalıdır",
        "ru": "Имя пользователя или пароль должны быть заполнены"
    },

    "login": {
        "en": "Login",
        "am": "Մուտք",
        "ar": "تسجيل الدخول",
        "de": "Login",
        "fr": "Login",
        "br": "Entrar",
        "es": "Iniciar sesión",
        "tr": "Giriş",
        "ru": "Войти"
    },

}

export default Words;