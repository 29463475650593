import { memo, useCallback, useState, useEffect } from "react";

import Menu from "./Menu";
import LiveTvPage from "../liveTv/LiveTvPage";
import MoviesPage from "../movies/MoviesPage";
import PlaylistPage from "../playlist/PlaylistPage";
import SettingsPage from "../settings/SettingsPage";
import VideosPage from "../videos/VideosPage";
import ActivationPopup from "@/src/popups/activation/ActivationPopup";

import "./MenuPage.scss";
import useStore from "@/src/hooks/useStore";
import LoginPopup from "@/src/popups/login/LoginPopup";
import BACKEND from "@/src/services/api/backend";
// import TimeLineEpg from "../liveTv/components/timeLineEpg/TimeLineEpg";

const MenuPage = ({ isVisible, onBack = () => { } }) => {

    const [_, setAppBackground] = useStore("appBackground");
    const [selectedPage, setSelectedPage] = useState("MENU");

    const [playlists] = useStore("playlists");
    // menu active page needs to be parent state for save previous page on back
    const [activePage, setActivePage] = useState("TV");

    useEffect(() => {
        if (!playlists.length) {
            setSelectedPage("LOGIN");
        } else {
            setSelectedPage("MENU");
        }
    }, [playlists])

    const showMenu = useCallback(() => {
        requestAnimationFrame(() => {
            setAppBackground("fullscreen");
        });
        setSelectedPage("MENU");
    }, []);

    if (!isVisible) return null;

    return (
        <>
            {selectedPage == "MENU" && <Menu
                onBack={onBack}
                setSelectedPage={setSelectedPage}
                activePage={activePage}
                setActivePage={setActivePage}
            />}
            {selectedPage == "TV" && <LiveTvPage onBack={showMenu} />}
            {selectedPage == "MOVIES" && <MoviesPage onBack={showMenu} type="movies" />}
            {selectedPage == "SERIES" && <MoviesPage onBack={showMenu} type="series" />}
            {selectedPage == "PLAYLISTS" && <PlaylistPage onBack={showMenu} />}
            {selectedPage == "VIDEOS" && <VideosPage onBack={showMenu} />}
            {selectedPage == "SETTINGS" && <SettingsPage onBack={showMenu} />}
            {/* {selectedPage == "ACTIVATION" && <ActivationPopup isVisible={true} onBack={showMenu} />} */}
            {selectedPage == "LOGIN" && <LoginPopup isVisible={true} onBack={showMenu} />}
            {/* <TimeLineEpg /> */}
        </>
    )
}

export default memo(MenuPage);