import { memo, useCallback } from "react";
import Button from "../../../../components/button/Button"
import useStore from "../../../../hooks/useStore";
import Languages from "./Languages"
import Storege from "@/src/services/storage/storage";

const LanguageItem = ({ index, isActive, style, onMouseEnter }) => {

    const [language, setLanguage] = useStore('language');

    const isSelected = language === Languages[index].code;

    const onClick = useCallback(() => {
        setLanguage(Languages[index].code);
        Storege.setLanguage(Languages[index].code);
    }, [index]);

    const mouseEnter = useCallback(() => {
        onMouseEnter(index);
    }, [index]);

    return (
        <div className="language-item-parent" style={style}>
            <Button
                isActive={isActive}
                className="language-item focus-item"
                onClick={onClick}
                isSelected={isSelected}
                onMouseEnter={mouseEnter}
            >
                {Languages[index].name}
                <img src={Languages[index].icon} />
            </Button>
        </div>
    )

}

export default memo(LanguageItem);