import usFlag from "./flags/us.png";
import amFlag from "./flags/am.png";
import saFlag from "./flags/sa.png";
import deFlag from "./flags/de.png";
import frFlag from "./flags/fr.png";
import brFlag from "./flags/br.png";
import esFlag from "./flags/es.png";
import trFlag from "./flags/tr.png";
import ruFlag from "./flags/ru.png";

const Languages = [
    { name: "English", code: "en", icon: usFlag },
    { name: "Հայերեն", code: "am", icon: amFlag },
    { name: "العربية", code: "ar", icon: saFlag },
    { name: "Deutsch", code: "de", icon: deFlag },
    { name: "Français", code: "fr", icon: frFlag },
    { name: "Português - Brasil", code: "br", icon: brFlag },
    { name: "Español", code: "es", icon: esFlag },
    { name: "Türkçe", code: "tr", icon: trFlag },
    { name: "Русский", code: "ru", icon: ruFlag }
];

export default Languages;