
import { memo, useCallback, useContext } from "react";
import Button from "../../../../../components/button/Button";
import ResizeImage from "../../../../../components/resizeImage/ResizeImage";
import { MovieInfoContext } from "../../MovieInfo";
import MarqueeText from "@/src/components/marqueeText/MarqueeText";
import { dotsIcon } from "@/src/assets/icons";

const CastItem = ({ isActive, index, style, onMouseEnter }) => {

    const {
        movieInfo,
    } = useContext(MovieInfoContext);

    const mouseEnter = useCallback(() => {
        onMouseEnter(index);
    }, []);

    const onClick = useCallback(() => {
    }, []);

    if (!movieInfo) return null;

    const cast = movieInfo.casts[index];

    if (!cast) return null;

    return (
        <div className="cast-item-parent" style={style}>
            <Button
                className="cast-item"
                isActive={isActive}
                onMouseEnter={mouseEnter}
                onClick={onClick}
            >
                <div className="icon item-bg" style={{ backgroundImage: `url(${dotsIcon})` }}>
                    <ResizeImage src={cast.image} aspectRatio={true} tagname="div" quality={0.9} />
                </div>
                <div className="title">
                    <MarqueeText isActive={isActive}>
                        {cast.name}
                    </MarqueeText>
                </div>
            </Button>
        </div>

    )
}

export default memo(CastItem);