import { memo, useEffect, useState } from "react";
import Button from "../button/Button";
import { backspaceBlack, backspaceWhite } from "../../assets/icons";
import useKeydown from "../../hooks/useKeydown";
import "./NumberKeyboard.scss";

const NumberKeyboard = ({
    isActive,
    capture = false,
    onMouseEnter = () => { },
    onBack = () => { },
    onLeft = () => { },
    onChange = () => { },
    onBackspace = () => { },
}) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const onClick = (e, data) => {

        if (data == 10) return onBackspace();
        onChange(data);

    };

    const mouseEnter = (e, data) => {
        onMouseEnter(e, data);
        setActiveIndex(parseInt(data));
    };

    useEffect(() => {
        if (isActive) setActiveIndex(0);
    }, [isActive]);

    useKeydown({
        isActive,
        capture,
        keydown: (e, key) => {
            if (capture) e.stopPropagation();
            if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].indexOf(key) != -1) onChange(key);
        },
        right: () => {
            if (activeIndex < 10) setActiveIndex(activeIndex + 1);
        },
        left: () => {
            if (activeIndex == 0) return onLeft();
            setActiveIndex(activeIndex - 1);
        },
        back: onBack,
        ok: () => {
            if (!capture) return;
            if (activeIndex == 10) return onBackspace();
            onChange(activeIndex);
        },
    })

    return (
        <div className="number-keyboard">

            {Array(10).fill(0).map((_, i) => {

                return (
                    <Button
                        key={i}
                        isActive={activeIndex == i && isActive}
                        className="number-keyboard-item focus-item"
                        data={i}
                        onClick={onClick}
                        onMouseEnter={mouseEnter}
                    > {i} </Button>
                )

            })}

            <Button
                isActive={activeIndex == 10 && isActive}
                className="number-keyboard-item focus-item"
                data={10}
                onClick={onClick}
                onMouseEnter={mouseEnter}
            >
                <img src={activeIndex == 10 ? backspaceBlack : backspaceWhite} />
            </Button>

        </div>
    )

};

export default NumberKeyboard;