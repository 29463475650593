import { createContext, memo, useEffect, useState } from "react"
import CategoriesSection from "./sections/categories/CategoriesSection";
import MoviesSection from "./sections/movies/MoviesSection";
import MovieInfo from "./movieInfo/MovieInfo";
import useContent from "../../hooks/useContent";
import "./MoviesPage.scss";
import { useCallback } from "react";
import CheckPinPopup from "@/src/popups/checkPin/CheckPinPopup";
import PageLoading from "@/src/components/pageLoading/PageLoading";
import Toast from "@/src/components/toast/Toast";
import { GetPlaylistMovies, GetPlaylistSeries } from "@/src/services/content/content";

export const MoviesPageContext = createContext();

const MoviesPage = ({ onBack = () => { }, type }) => {

    const [moviesContent, setMoviesContent] = useContent(`${type}Content`); // moviesContent or seriesContent

    const [currentSection, setCurrentSection] = useState("categoriesSection"); // categoriesSection, moviesSection, movieInfo

    const [currentCategoryId, setCurrentCategoryId] = useState(null);

    const [currentMovieId, setCurrentMovieId] = useState(null);

    const [searchIsOpen, setSearchIsOpen] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");

    const [searchItems, setSearchItems] = useState([]);

    const [lockedMovieId, setLockedMovieId] = useState(null);

    const [moveItemIndex, setMoveItemIndex] = useState(null);

    const isLockedMovie = useCallback((movieId) => {

        const { category_id } = moviesContent.items_obj[movieId];

        const { isLocked } = moviesContent.categories_obj[category_id];

        return isLocked;

    }, [moviesContent]);

    const closePinPopup = useCallback(() => {
        setLockedMovieId(null);
    }, []);

    const pinComplate = useCallback(() => {
        showMovieInfo(lockedMovieId);
        setLockedMovieId(null);
    }, [lockedMovieId]);

    const showMovieInfo = (movieId) => {
        setCurrentMovieId(movieId);
        setCurrentSection("movieInfo");
    };

    const clickMovie = useCallback((movieId) => {

        if (isLockedMovie(movieId)) {
            setLockedMovieId(movieId);
        } else {
            showMovieInfo(movieId);
        }

    }, [moviesContent]);

    useEffect(() => {

        if (searchQuery) {

            let searchItems = [];

            let query = searchQuery.trim();
            query = query.toLowerCase();

            for (let i = 0; i < moviesContent.items_arr.length; i++) {

                let id = moviesContent.items_arr[i];

                let item = moviesContent.items_obj[id];

                if (item.name.toLowerCase().indexOf(query) > -1) {
                    searchItems.push(id);
                }

            }

            // sort items by search query
            searchItems.sort((a, b) => {

                let nameA = moviesContent.items_obj[a].name.toLowerCase();
                let nameB = moviesContent.items_obj[b].name.toLowerCase();

                let indexA = nameA.indexOf(query);
                let indexB = nameB.indexOf(query);

                if (indexA < indexB) {
                    return -1;
                } else if (indexA > indexB) {
                    return 1;
                } else {
                    return 0;
                }

            });

            setSearchItems(searchItems);

        } else {
            setSearchItems(moviesContent.items_arr);
        }


    }, [searchQuery, searchIsOpen]);

    useEffect(() => {

        if (moviesContent.status != "success") return;

        if (!currentCategoryId && moviesContent.categories_arr.length > 0) {

            for (let i = 0; i < moviesContent.categories_arr.length; i++) {

                let id = moviesContent.categories_arr[i];

                let category = moviesContent.categories_obj[id];

                if (category && !category.isLocked && category.items.length > 0) {
                    setCurrentCategoryId(id);
                    break;
                }

            }

        }

    }, [moviesContent, currentCategoryId]);

    useEffect(() => {

        if (moviesContent.status == "error") {
            Toast.error({ msg: `error loading ${type}`, id: `error loading ${type}`, time: 3000 });
            onBack();
        } else if (moviesContent.status == "none") {

            if (type == "movies") {
                GetPlaylistMovies();
            } else {
                GetPlaylistSeries();
            }

        }

    }, [moviesContent]);

    useEffect(() => {
        window.dispatchEvent(new CustomEvent("cleanImageResizer"));

        return () => {
            window.dispatchEvent(new CustomEvent("cleanImageResizer"));
        }
    }, []);

    return (

        <MoviesPageContext.Provider
            value={{
                onBack,
                contentType: type,
                clickMovie,
                isLockedMovie,
                //
                moviesContent,
                setMoviesContent,
                //
                currentSection,
                setCurrentSection,
                //
                currentCategoryId,
                setCurrentCategoryId,
                //
                currentMovieId,
                setCurrentMovieId,
                //
                searchIsOpen,
                setSearchIsOpen,
                //
                searchQuery,
                setSearchQuery,
                //
                searchItems,
                setSearchItems,
                //
                moveItemIndex,
                setMoveItemIndex
            }}
        >
            <div className={`movie-page ${currentSection}`}>
                <CategoriesSection />
                <MoviesSection />
                <PageLoading className="bg-color" isVisible={moviesContent.status != "success"} />
            </div>

            {currentMovieId ? <MovieInfo /> : null}

            <CheckPinPopup
                onBack={closePinPopup}
                onComplate={pinComplate}
                isVisiable={lockedMovieId != null}
            />

        </MoviesPageContext.Provider>
    )

}

export default memo(MoviesPage);