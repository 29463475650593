import { useDispatch, useSelector } from "react-redux";

/**
 * 
 * @param {string} type 
 * @returns {[any, () => void]} [state, setState]
 */
export default function useStore (type) {

    const dispatch = useDispatch();

    const state = useSelector((state) => state.globalState[type]);

    const setState = (payload) => { dispatch({ type, payload }) }

    return [state, setState];

}