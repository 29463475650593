import { useEffect, useState, memo, useContext } from "react";
import Button from "../../../../../components/button/Button";
import Text from "../../../../../components/text/Text";
import useKeydown from "../../../../../hooks/useKeydown";
import { MovieInfoContext } from "../../../movieInfo/MovieInfo";
import { FormatPlayerTime } from "../../../../../utils/util";
import "./ContinuePopup.scss";

const ContinuePopup = () => {

    const {
        movieInfo,
        playerOptions,
        setPlayerOptions,
        continueData,
    } = useContext(MovieInfoContext);

    const minimumTime = 60;

    const [isVisible, setIsVisible] = useState(false);

    const [title, setTitle] = useState("");

    const [activeIndex, setActiveIndex] = useState(0);

    const [confirmOptions, setConfirmOptions] = useState({ ...playerOptions });

    const [cancelOptions, setCancelOptions] = useState({ ...playerOptions });

    const getMovieFirstEpisode = () => {

        let { seasons } = movieInfo;

        if (!seasons) return null;

        let first = Object.keys(seasons)[0];

        if (!first) return null;

        let episode = Object.keys(seasons[first])[0];

        if (!episode) return null;

        return seasons[first][episode];

    };

    let confirm = () => {
        setPlayerOptions(confirmOptions);
    };

    let cancel = () => {
        setPlayerOptions(cancelOptions);
    };

    useEffect(() => {

        const playWithoutContinue = () => {

            if (playerOptions.type === "movie") {

                setPlayerOptions({
                    ...playerOptions,
                    askToContinue: false,
                    percent: 0,
                });

            } else {

                let { episode } = playerOptions;

                if (!episode) episode = getMovieFirstEpisode();

                setPlayerOptions({
                    ...playerOptions,
                    askToContinue: false,
                    percent: 0,
                    episode,
                });

            }

        }

        if (continueData) {

            let { type, episode } = playerOptions;

            if (type === "movie") {

                // if type is movie, get continueData from movie
                // this called when user click on play button from movieInfo page

                let { currentTime, percent, duration } = continueData;

                if (currentTime < minimumTime || duration - currentTime < minimumTime) return playWithoutContinue();

                setCancelOptions({
                    ...playerOptions,
                    askToContinue: false,
                    percent: 0,
                });

                setConfirmOptions({
                    ...playerOptions,
                    askToContinue: false,
                    percent,
                });

                setTitle(" " + FormatPlayerTime(currentTime));

            } else {

                if (episode) {

                    // if episode is set, get continueData from episode
                    // this called when user click on episode item from movieInfo page

                    let key = `${ episode.season }-${ episode.num }`;

                    if (!continueData[key]) return playWithoutContinue();

                    let { currentTime, percent, duration } = continueData[key];

                    if (currentTime < minimumTime || duration - currentTime < minimumTime) return playWithoutContinue();

                    setCancelOptions({
                        ...playerOptions,
                        askToContinue: false,
                        percent: 0,
                    });

                    setConfirmOptions({
                        ...playerOptions,
                        askToContinue: false,
                        percent,
                    });

                    setTitle(" " + FormatPlayerTime(currentTime));

                } else {

                    // if episode is not set, get last episode from continueData, 
                    // this caled when user click on play button from movieInfo page

                    let { lastEpisode, lastSeason } = continueData;

                    episode = movieInfo.seasons[lastSeason][lastEpisode];

                    let key = `${ lastSeason }-${ lastEpisode }`;

                    let { percent, currentTime, duration } = continueData[key];

                    let firstEpisode = getMovieFirstEpisode();

                    if (currentTime < minimumTime && episode == firstEpisode) {
                        return playWithoutContinue();
                    } else if (duration - currentTime < minimumTime) {

                        if (episode.next_episode) {

                            episode = episode.next_episode;

                            percent = 0;
                            currentTime = 0;
                            duration = 0;

                        } else {
                            return playWithoutContinue();
                        }

                    }

                    setConfirmOptions({
                        ...playerOptions,
                        askToContinue: false,
                        episode,
                        percent
                    });

                    setCancelOptions({
                        ...playerOptions,
                        askToContinue: false,
                        percent: 0,
                        episode: firstEpisode,
                    });

                    setTitle(` S${ episode.season } E${ episode.num } ${ FormatPlayerTime(currentTime) }`);

                }

            }

            setIsVisible(true);

        } else {
            playWithoutContinue();
        }

    }, [continueData, playerOptions]);

    useKeydown({
        isActive: true,
        back: () => {
            cancel();
        },
        up: () => {
            setActiveIndex(0);
        },
        down: () => {
            setActiveIndex(1);
        },
    });

    if (!isVisible) return null;

    return (
        <div
            className="continue-popup"
            onMouseDown={e => e.stopPropagation()}
            onMouseEnter={e => e.stopPropagation()}
            onClick={e => e.stopPropagation()}
            onMouseMove={e => e.stopPropagation()}
        >

            <div className="continue-popup-title">
                <Text>do you want to continue watching</Text>
            </div>

            <div className="continue-popup-buttons">

                <Button
                    className="continue-popup-button"
                    isActive={activeIndex == 0}
                    onClick={confirm}
                    onMouseEnter={() => setActiveIndex(0)}
                >
                    <Text>continue from</Text> {title}
                </Button>

                <Button
                    className="continue-popup-button"
                    isActive={activeIndex == 1}
                    onClick={cancel}
                    onMouseEnter={() => setActiveIndex(1)}
                >
                    <Text>start from the beginning</Text>
                </Button>

            </div>

        </div>
    )

};

export default memo(ContinuePopup);