import { memo, useState, useCallback, useEffect } from "react";
import { SettingsSVG } from "@/src/components/svgIcons";
import Button from "@/src/components/button/Button";
import Text from "@/src/components/text/Text";
import useKeydown from "@/src/hooks/useKeydown";

import Storege from "@/src/services/storage/storage";

const ButtonsContainer = ({
    isActive,
    onMouseEnter,
    openAudioPopup,
    openVideoPopup,
    openSubtitlesPopup,
    openSettingsPopup,
    subtitles,
    audios,
    videos,
}) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const [buttons, setButtons] = useState([]);

    const rtlMode = Storege.getRtlMode();

    const mouseEnter = useCallback((e, index) => {
        setActiveIndex(index);
        onMouseEnter(e);
    }, []);

    useEffect(() => {

        let buttons = [];

        if (audios.length > 1) {

            buttons.push({
                content: <Text>audio</Text>,
                className: "movie-player-button",
                onClick: openAudioPopup
            });

        }

        if(videos.length > 1){
            buttons.push({
                content: <Text>video</Text>,
                className: "movie-player-button",
                onClick: openVideoPopup
            });
        }

        if (subtitles.length > 0) {

            buttons.push({
                content: <Text>subtitle</Text>,
                className: "movie-player-button",
                onClick: openSubtitlesPopup
            });

            buttons.push({
                content: <SettingsSVG />,
                className: "movie-player-button settings",
                onClick: openSettingsPopup
            });

        }

        setButtons(buttons);

    }, [subtitles, audios,videos]);


    const left = () => {
        if (activeIndex == 0) return;
        setActiveIndex(activeIndex - 1);
    }

    const right = () => {
        if (activeIndex == buttons.length - 1) return;
        setActiveIndex(activeIndex + 1);
    }

    useKeydown({
        isActive,
        left: rtlMode ? right : left,
        right: rtlMode ? left : right,
    });

    return (
        <div className="buttons-container">

            {buttons.map((button, index) => (

                <Button
                    key={index}
                    className={button.className}
                    onClick={button.onClick}
                    onMouseEnter={mouseEnter}
                    data={index}
                    isActive={isActive && activeIndex == index}
                >
                    {button.content}
                </Button>
            ))}

        </div>
    )

}

export default memo(ButtonsContainer);