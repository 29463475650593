import { useState } from "react";
import NumberKeyboard from "../keyboard/NumberKeyboard";
import PinInputs from "../pinInputs/PinInputs";
import Text from "../text/Text";
import "./CheckPin.scss";
import { useEffect } from "react";
import Storege from "@/src/services/storage/storage";

const CheckPin = ({
    onBack,
    onComplate,
    onLeft,
    isActive,
    onMouseEnter,
    capture = false
}) => {

    const [isError, setIsError] = useState(false);

    const [pin, setPin] = useState("");

    const onChange = (val) => {
        setIsError(false);
        if (pin.length < 4) setPin(pin + val);
    }

    const onBackspace = () => {
        setIsError(false);
        if (pin.length > 0) setPin(pin.slice(0, pin.length - 1));
    }

    useEffect(() => {

        let timer = setTimeout(() => {

            if (pin.length == 4) {

                if (pin == Storege.getPinCode()) {
                    onComplate();
                } else {
                    setIsError(true);
                    setPin("");
                }

            }

        }, 500);

        return () => clearTimeout(timer);

    }, [pin]);

    return (
        <div className="check-pin-content">

            <div className="check-pin-title">
                <Text>enter pin code</Text>
            </div>

            <PinInputs
                isActive={isActive}
                pin={pin}
                setPin={setPin}
                onMouseEnter={onMouseEnter}
            />

            <div className="check-pin-error">
                {isError ? <Text>wrong pin code</Text> : null}
            </div>

            <NumberKeyboard
                isActive={isActive}
                onMouseEnter={onMouseEnter}
                onBack={onBack}
                onLeft={onLeft}
                onChange={onChange}
                onBackspace={onBackspace}
                capture={capture}
            />

            <div className="default-pin-text">
                <Text>default pin code: 0000</Text>
            </div>

        </div>
    )

}

export default CheckPin;