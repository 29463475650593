import "./ActivationPopup.scss"
import Config from "../../config";
import QrCode from "@/src/components/qr/QrCode";
import useStore from "../../hooks/useStore";
import AppLogo from "@/src/branding/AppLogo";
import useKeydown from "@/src/hooks/useKeydown";
import Text from "@/src/components/text/Text";
import Button from "@/src/components/button/Button";

const ActivationPopup = ({ isVisible, onBack }) => {

    const [deviceInfo] = useStore("deviceInfo");

    useKeydown({
        isActive: isVisible && deviceInfo,
        back: onBack,
    })

    if (!isVisible || !deviceInfo) return null;

    let qrCodeValue = Config.Payment_host + "?mac=" + deviceInfo.mac;

    return (
        <div className="activation-popup">

            <div className="container bg-linear-gradient">

                <AppLogo />

                <div className="title">
                    <Text>your free trail has expired. activate the app to use it.</Text>
                </div>

                <div className="body">

                    <div className="info">

                        <div className="info-title">
                            <Text>how to activate?</Text>
                        </div>

                        <div className="step">
                            <div className="step-number">1</div>
                            <div className="step-text">
                                <Text>scan qr code or visit</Text> <span>{Config.Payment_host}</span>
                            </div>
                        </div>

                        <div className="step">
                            <div className="step-number">2</div>
                            <div className="step-text">
                                <Text>choose your preferred payment method and make the payment</Text>
                            </div>
                        </div>

                        <div className="step">
                            <div className="step-number">3</div>
                            <div className="step-text">
                                <Text>check your tv screen it can take 1-2 minutes to be activated</Text>
                            </div>
                        </div>

                        <div className="mac">MAC: {deviceInfo.mac}</div>

                    </div>

                    <div className="qr">

                        <div className="qr-block">
                            <QrCode
                                style={{ width: "48rem", height: "48rem" }}
                                value={qrCodeValue}
                            />
                        </div>

                    </div>

                </div>

                <Button
                    onClick={onBack}
                    className="focus-item button"
                    isActive={true}
                    onMouseEnter={() => { }}
                >
                    <Text>close</Text>
                </Button>

            </div>

        </div>
    )

}

export default ActivationPopup;