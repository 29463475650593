
import { memo } from "react"
import Config from "../config"
import useStore from "../hooks/useStore"

const AppBackground = () => {

    const [appBackground] = useStore("appBackground");

    if (window.Android) {

        switch (appBackground) {
            case "fullscreen":
                return <img alt="app-background" className="app-background" src={Config.AppBackground} />
            case "croped":
                return <img alt="app-background" className="app-background" src={Config.AppBackgroundCroped} />
        }

        return null;

    } else {
        return <img alt="app-background" className="app-background" src={Config.AppBackground} />
    }

}

export default memo(AppBackground)