import { memo, useEffect, useState } from "react";
import Text from "../../../../components/text/Text";
import PinInputs from "../../../../components/pinInputs/PinInputs";
import NumberKeyboard from "../../../../components/keyboard/NumberKeyboard";
import "./ChangePin.scss";
import Storege from "../../../../services/storage/storage";
import useKeydown from "@/src/hooks/useKeydown";

const ChangePin = ({
    isActive = false,
    onBack = () => { },
    onMouseEnter = () => { }
}) => {

    const titles = ["enter old pin code", "enter new pin code", "confirm new pin code"];

    const [step, setStep] = useState(0); // 0 -> old, 1 -> new, 2 -> confirm

    const [pin, setPin] = useState("");

    const [newPin, setNewPin] = useState("");

    const [isError, setIsError] = useState(false);

    const [message, setMessage] = useState("");

    const onChange = (val) => {
        setIsError(false);
        setMessage("");
        if (pin.length < 4) setPin(pin + val);
    };

    const onBackspace = () => {
        setIsError(false);
        setMessage("");
        if (pin.length > 0) setPin(pin.slice(0, pin.length - 1));
    };

    useEffect(() => {

        let timer = setTimeout(() => {

            if (pin.length == 4) {

                if (step == 0) { // check old pin

                    let oldPin = Storege.getPinCode();

                    if (pin == oldPin) {
                        setPin("");
                        setStep(1);
                    } else {
                        setIsError(true);
                        setMessage("wrong pin code");
                        setPin("");
                    }

                } else if (step == 1) { // set new pin

                    setNewPin(pin);
                    setPin("");
                    setStep(2);

                } else if (step == 2) { // check confirm new pin

                    if (pin == newPin) {
                        Storege.setPinCode(pin);
                        setPin("");
                        setStep(0);
                        onBack();
                        setMessage("pin code changed");
                    } else {
                        setIsError(true);
                        setMessage("pin codes do not match");
                        setPin("");
                        setStep(1);
                    }

                }

            }

        }, 500);

        return () => clearTimeout(timer);

    }, [pin]);

    return (
        <div className="settings-content change-pin">

            <div className="change-pin-title">
                <Text>{titles[step]}</Text>
            </div>

            <PinInputs
                isActive={isActive}
                pin={pin}
                setPin={setPin}
                onMouseEnter={onMouseEnter}
            />

            <div className={"change-pin-message" + (isError ? " error" : "")}>
                <Text>{message}</Text>
            </div>

            <NumberKeyboard
                isActive={isActive}
                onMouseEnter={onMouseEnter}
                onBack={onBack}
                onLeft={onBack}
                onChange={onChange}
                onBackspace={onBackspace}
            />

            <div className="default-pin-text">
                <Text>default pin code: 0000</Text>
            </div>

        </div>
    )

};

export default memo(ChangePin);