import { useContext } from "react";
import { MoviesPageContext } from "../../MoviesPage";
import { sadIcon } from "../../../../assets/icons";
import Text from "../../../../components/text/Text";

const NoResult = () => {

    const { searchIsOpen, contentType } = useContext(MoviesPageContext);

    return (
        <div className="no-result-found">
            <img src={sadIcon} />
            <Text>{searchIsOpen ? "no result found" : `no ${ contentType } in this category`}</Text>
        </div>
    )

};

export default NoResult;