import { memo, useCallback, useEffect, useMemo, useState } from "react"

import Text from "../../components/text/Text";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import Toast from "../../components/toast/Toast";

import useStore from "@/src/hooks/useStore";

import "./LoginPopup.scss";
import useKeydown from "@/src/hooks/useKeydown";
import XTREAM from "@/src/services/api/xtream";

import PlaylistLoading from "@/src/pages/playlist/playlistLoading/PlaylistLoading";
import BACKEND from "@/src/services/api/backend";

import ExitPopup from "../exitPopup/ExitPopup";
import Config from "@/src/config";

function LoginPopup({ onBack }) {

    const [activeIndex, setActiveIndex] = useState(0)
    const [focusedInput, setFocusedInput] = useState(null)
    const [loading, setLoading] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);
    const [exitPopup, setExitPopup] = useState(false);
    const [platform] = useStore("platform");

    const [deviceInfo] = useStore("deviceInfo");

    const [rtlMode] = useStore("rtlMode");

    const [servers, setServers] = useState(null);

    const [inputs, setInputs] = useState([
        { label: "username", value: "" },
        { label: "password", value: "" }
    ])

    const getServers = useCallback(async () => {
        const data = await BACKEND.getServers();

        console.log("Servers ", data)

        setServers(data);
    }, [servers])

    const onBlur = (value) => {
        setFocusedInput(null);
        inputs[activeIndex].value = value.trim();
        setInputs([...inputs])
    }

    const login = useCallback(async () => {
        const username = inputs[0].value;
        const password = inputs[1].value;

        if (!username || !password) return Toast.error({ msg: "username or password must be filled out", id: "login_form" });

        setLoading(true);

        const data = servers.rows;

        for (let i = 0; i < data.length; i++) {

            if (isCanceled) return setIsCanceled(false);

            const xtream_account = {
                host: data[i].host,
                username,
                password
            }

            try {
                await XTREAM.login(xtream_account)
                confirm(xtream_account);
                return;
            } catch (e) {
                console.log(e);
                if (i == data.length - 1) error("invalid username or password");
            }

        }

    }, [servers])

    const confirm = useCallback(async (xtream_account) => {

        const { host, username, password } = xtream_account;

        const url = `${host}/get.php?username=${username}&password=${password}&type=m3u_plus&output=ts`

        try {
            await BACKEND.addPlaylist({ name: username, url, mac: deviceInfo.mac });
            window.location.reload();
        } catch (e) {
            error(e);
        }

    }, [])

    const error = useCallback((msg) => {
        Toast.error({ msg, id: "login_form" });
        setLoading(false);
    }, [])

    const cancel = useCallback(() => {
        setLoading(false);
        XTREAM.abortAll();
        setIsCanceled(true);
    }, [])

    const onExit = useCallback(() => {
        platform.exit();
    }, [platform])

    const MacAddress = () => {
        // return <div><Text>mac address</Text>: <span>{deviceInfo?.mac || ""}</span></div>

        return rtlMode ? <div>{deviceInfo?.mac || ""} :<Text>mac address</Text></div> :
            <div><Text>mac address</Text>: <span>{deviceInfo?.mac || ""}</span></div>
    };

    const keydown = useMemo(() => {
        return {
            isActive: !exitPopup && focusedInput == null,

            keydown: (e, key) => {
                const keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

                if (activeIndex < 2 && keys.indexOf(key) > -1) {
                    inputs[activeIndex].value += key;
                    setInputs([...inputs]);

                    return true
                }
            },

            down: () => {
                if (activeIndex > 1) return;
                setActiveIndex(activeIndex + 1)
            },

            up: () => {
                if (activeIndex === 0) return;
                setActiveIndex(activeIndex - 1)
            },

            back: () => {
                if (loading) return;

                if (window.Android) {
                    onExit();
                } else {
                    setExitPopup(true);
                }
            }
        }
    }, [activeIndex, exitPopup, focusedInput, inputs])

    useKeydown(keydown);

    useEffect(() => {
        getServers();
    }, [])

    return (

        <>
            {servers && <div className="login_popup">
                <div className="login_popup_content">

                    <div className="logo">
                        <img src={Config.AppLogo} />
                    </div>

                    <div className="title"><Text>Login</Text></div>

                    <div className="inputs">

                        {inputs.map((input, index) => {
                            return (
                                <Button
                                    className={"button"}
                                    key={index}
                                    data={index}
                                    isActive={activeIndex === index && !exitPopup}
                                    onMouseEnter={() => setActiveIndex(index)}
                                    onClick={() => setFocusedInput(index)}
                                >
                                    <Input
                                        key={index}
                                        placeholder={input.label}
                                        initialValue={input.value}
                                        isFocused={focusedInput === index}
                                        onBlur={onBlur}
                                        onDone={onBlur}
                                        onTop={onBlur}
                                        onBack={onBlur}
                                    />
                                </Button>
                            )
                        })}

                    </div>

                    <div className="buttons_parent">
                        <Button
                            className={"button"}
                            isActive={activeIndex === 2 && !exitPopup}
                            data={2}
                            onMouseEnter={() => setActiveIndex(2)}
                            onClick={login}
                        >
                            <Text>Login</Text>
                        </Button>
                    </div>

                    <div className="login-footer-info">
                    <MacAddress />
                </div>
                </div>

                {loading && <PlaylistLoading status="loading" onBack={cancel} />}
            </div>}

             
                      

            {exitPopup && <ExitPopup isVisible={exitPopup} onExit={onExit} onCancel={() => setExitPopup(false)} />}

        </>

    )
}

export default memo(LoginPopup);