function Icon () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
        >
            <path
                d="M436.193 169.626a453.083 453.083 0 00-16.589-13.59l-64.701 64.701A104.558 104.558 0 01361 256c0 57.897-47.103 105-105 105a104.534 104.534 0 01-35.263-6.098l-48.558 48.558C200.535 415.092 228.66 421 256 421c32.657 0 66.432-8.396 100.384-24.955 26.662-13.005 53.514-31.063 79.809-53.671 44.455-38.226 71.841-76.024 72.984-77.615a14.997 14.997 0 000-17.518c-1.144-1.591-28.529-39.389-72.984-77.615z"
                data-original="#000000"
            ></path>
            <path
                d="M256 331c41.355 0 75-33.645 75-75 0-3.598-.27-7.134-.763-10.598l-84.835 84.835c3.465.493 7 .763 10.598.763zM507.607 4.394c-5.857-5.857-15.355-5.857-21.213 0L368.546 122.242a305.247 305.247 0 00-12.162-6.286C322.432 99.396 288.657 91 256 91s-66.432 8.396-100.384 24.955c-26.662 13.005-53.514 31.063-79.809 53.671-44.454 38.226-71.84 76.024-72.984 77.615a14.997 14.997 0 000 17.518c1.144 1.591 28.529 39.39 72.984 77.615 13.623 11.713 27.396 22.192 41.214 31.391L4.394 486.394c-5.858 5.857-5.858 15.355 0 21.213C7.323 510.535 11.161 512 15 512s7.678-1.465 10.606-4.394l482-482c5.859-5.857 5.859-15.355.001-21.212zM151 256c0-57.897 47.103-105 105-105 23.551 0 45.315 7.794 62.85 20.938l-21.52 21.52C285.471 185.594 271.265 181 256 181c-41.355 0-75 33.645-75 75 0 15.264 4.594 29.47 12.458 41.33l-21.52 21.52C158.794 301.315 151 279.551 151 256z"
                data-original="#000000"
            ></path>
        </svg>
    );
}

export default Icon;