import { memo, useContext } from "react";
import Button from "../../../../../components/button/Button";
import { MoviesPageContext } from "../../../MoviesPage";
import "./CategoryItem.scss";
import { LockedSVG } from "@/src/components/svgIcons";
import MarqueeText from "@/src/components/marqueeText/MarqueeText";
import useKeydown from "@/src/hooks/useKeydown";
import { moveBlackIcon } from "@/src/assets/icons";

const CategoryItem = ({
    isActive,
    index,
    style = {},
    onMouseEnter = () => { },
}) => {

    const {
        moviesContent,
        currentCategoryId,
        setCurrentCategoryId,
        moveItemIndex,
        setMoveItemIndex
    } = useContext(MoviesPageContext);

    const categoryId = moviesContent.categories_arr[index];

    const category = moviesContent.categories_obj[categoryId];

    const mouseEnter = () => {
        onMouseEnter(index);
    };

    const onClick = (e) => {
        e.stopPropagation();

        if(moveItemIndex || moveItemIndex == 0) return setMoveItemIndex(null);

        if (window.Android && e.duration && e.duration > 500 && window.showColorButtons) {
            window.showColorButtons();
        } else {
            setCurrentCategoryId(categoryId);
        }

 
    };


    useKeydown({
        isActive,
        green: ()=>{         

            setMoveItemIndex(index);
        },
    })


    let isMove = moveItemIndex == index;

    if(isMove) isActive = true;

    return (
        <div className={"movie-category-parent"} style={style}>
            <Button
                className={`category-item focus-item ${isMove && isActive ? "move" : ""}`}
                isActive={isActive}
                onMouseEnter={mouseEnter}
                onClick={onClick}
                isSelected={currentCategoryId == categoryId}
                data={index}
                style={isMove && isActive? {backgroundImage:`url(${moveBlackIcon})` }: {}}
            >
                <div className="name">
                    <MarqueeText isActive={isActive}>{category?.name}</MarqueeText>
                </div>
                <div className="count">{category?.items.length}</div>
                <div className="lock-icon">{category?.isLocked ? <LockedSVG /> : null}</div>
            </Button>
        </div>
    )

}

export default memo(CategoryItem);