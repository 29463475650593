import { memo } from "react";
import "./CardProgress.scss";

const CardProgress = ({ percent }) => {

    if (percent > 1) percent = 1;
    if (percent < 0) percent = 0;

    if (percent == 0) return null;

    return (
        <div className="card-progress">
            <div className="card-progress-bar" style={{ width: `${ percent * 100 }%` }}></div>
        </div>
    );
}

export default memo(CardProgress);