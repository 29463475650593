import { asteriskIcon } from "../../assets/icons";
import "./PinInputs.scss"

const PinInputs = ({
    isActive,
    pin = "",
    setPin = () => { },
    onMouseEnter = () => { },
}) => {

    return (
        <div className="pin-inputs">
            {Array(4).fill(0).map((_, index) => {
                return (
                    <div
                        key={index}
                        className={"pin-input" + (index == pin.length && isActive ? " active" : "")}
                        onClick={() => {
                            setPin(pin.slice(0, index));
                        }}
                        onMouseEnter={onMouseEnter}
                    >
                        <div className="pin-input-body focus-item">
                            {index < pin.length ? <img src={asteriskIcon} /> : null}
                        </div>
                    </div>
                )
            })}
        </div>
    )

};

export default PinInputs;