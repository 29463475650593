import Toast from "../../components/toast/Toast";
import BACKEND from "../api/backend";
import XTREAM from "../api/xtream";
import Storege from "../storage/storage";
import storeSubscribe from "../store/subscribe";
import { ParseChannelsContent, ParseMoviesContent } from "./parser";
import { emptyContent } from "./emptyContent";

const [setChannelsContent] = storeSubscribe("channelsContent", (channelsContent) => { });

const [setMoviesContent] = storeSubscribe("moviesContent", (moviesContent) => { });

const [setSeriesContent] = storeSubscribe("seriesContent", (seriesContent) => { });

const [setStreamFormats] = storeSubscribe("streamFormats", (streamFormats) => { });

// get random number from 21-50 for demo user
const getRandomDemoUser = () => {
    return Math.floor(Math.random() * 30 + 21);
}

const demoUser = getRandomDemoUser();

const demoPlaylist = {
    id: "demo",
    name: "Demo",
    url: `http://79.143.180.88:25461/get.php?username=${demoUser}&password=${demoUser}`,
};

let curPlaylist = null;

const [setPlaylist] = storeSubscribe("playlist", async (playlist) => {

    if (!curPlaylist || curPlaylist.url !== playlist?.url) {
        curPlaylist = playlist;
        GetPlaylistContent();
    }

});

const [setPlaylists] = storeSubscribe("playlists", async (playlists) => {

    // sync playlist data
    let playlist_ids = [];

    for (let i = 0; i < playlists.length; i++) {
        playlist_ids.push(playlists[i].id);
    }

    Storege.syncPlaylistData(playlist_ids);
    // 

    if (playlists.length) Storege.setPlaylists(playlists);

    const updatePlaylist = (playlist) => {

        // if (!playlist) playlist = { ...demoPlaylist };

        setPlaylist(playlist);

    }

    let selectedPlaylist = null;

    // find selected playlist
    for (let i = 0; i < playlists.length; i++) {

        if (playlists[i].is_selected) {
            selectedPlaylist = playlists[i];
            break;
        }

    }

    if (!selectedPlaylist && playlists.length > 0) {

        // find xtream playlist
        const findXtreamPlaylist = async (i) => {

            let playlist = playlists[i];

            if (!playlist) return updatePlaylist(null);

            let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);

            if (xtream_account) {

                try {
                    await XTREAM.login(xtream_account);
                } catch (msg) {
                    findXtreamPlaylist(i + 1);
                    return
                }

                try {
                    await BACKEND.setSelectedPlaylist({ playlists: [playlist.id] });
                } catch (e) {
                    console.log(e);
                }

                playlist.is_selected = true;
                setPlaylists([...playlists]);
                updatePlaylist(playlist);

            } else {
                findXtreamPlaylist(i + 1);
            }

        }

        findXtreamPlaylist(0);

    } else {
        updatePlaylist(selectedPlaylist);
    }

});

let retryCount = 3;
let retryTimeMS = 3000;

let xtreamLoginRetryTimeout = null;
let channelsRetryTimeout = null;
let moviesRetryTimeout = null;
let seriesRetryTimeout = null;

// get channels
export const GetPlaylistChannels = () => {

    console.log("Get Playlist Channels");

    let playlist = curPlaylist;

    if (!playlist) return;

    clearTimeout(channelsRetryTimeout);

    let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);

    if (xtream_account) {

        const getXtreamChannels = async (retry) => {

            try {

                let channels = await XTREAM.getChannels();
                let categories = await XTREAM.getChannelCategories();
                let payload = await ParseChannelsContent(channels, categories, true);
                
               
                
                setChannelsContent({ ...payload, status: "success" });

            } catch (e) {

                console.log(e);

                if (retry < retryCount) {

                    console.log("channels retry", retry);

                    channelsRetryTimeout = setTimeout(() => {
                        getXtreamChannels(retry + 1);
                    }, retryTimeMS);

                } else {
                    setChannelsContent({ ...emptyContent, status: "error" });
                }

            }

        }

        getXtreamChannels(0);

    } else {

        BACKEND.getPlaylistData({ id: playlist.id, type: "tv" })
            .then(async ({ data, groups }) => {
                let payload = await ParseChannelsContent(data, groups, false);
                setChannelsContent({ ...payload, status: "success" });
            })
            .catch((e) => {
                console.log(e);
                setChannelsContent({ ...emptyContent, status: "error" });
            });

    }

}

// get movies
export const GetPlaylistMovies = () => {

    console.log("Get Playlist Movies");

    let playlist = curPlaylist;

    if (!playlist) return;

    clearTimeout(moviesRetryTimeout);

    let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);

    if (xtream_account) {

        const getXtreamMovies = async (retry) => {

            try {

                let movies = await XTREAM.getMovies();

                let categories = await XTREAM.getMovieCategories();

                movies.sort((a, b) => {
                    return a.added > b.added ? -1 : 1;
                });

                // movies, categories, isSeries, isXtream
                let payload = await ParseMoviesContent(movies, categories, false, true);

                setMoviesContent({ ...payload, status: "success" });

            } catch (e) {

                console.log(e);

                if (retry < retryCount) {

                    console.log("movies retry", retry);

                    moviesRetryTimeout = setTimeout(() => {
                        getXtreamMovies(retry + 1);
                    }, retryTimeMS);

                } else {
                    setMoviesContent({ ...emptyContent, status: "error" });
                }

            }

        }

        getXtreamMovies(0);

    } else {

        BACKEND.getPlaylistData({ id: playlist.id, type: "movie" })
            .then(async ({ data, groups }) => {
                let payload = await ParseMoviesContent(data, groups, false, false);// movies, categories, isSeries, isXtream
                setMoviesContent({ ...payload, status: "success" });
            })
            .catch((e) => {
                console.log(e);
                setMoviesContent({ ...emptyContent, status: "error" });
            });

    }

}

// get series
export const GetPlaylistSeries = () => {

    console.log("Get Playlist Series");

    let playlist = curPlaylist;

    if (!playlist) return;

    clearTimeout(seriesRetryTimeout);

    let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);

    if (xtream_account) {

        const getXtreamSeries = async (retry) => {

            try {

                let series = await XTREAM.getSeries();

                let categories = await XTREAM.getSeriesCategories();

                series.sort((a, b) => {
                    return a.last_modified > b.last_modified ? -1 : 1;
                });

                // movies, categories, isSeries, isXtream
                let payload = await ParseMoviesContent(series, categories, true, true);
                setSeriesContent({ ...payload, status: "success" });

            } catch (e) {

                console.log(e);

                if (retry < retryCount) {

                    console.log("series retry", retry);

                    seriesRetryTimeout = setTimeout(() => {
                        getXtreamSeries(retry + 1);
                    }, retryTimeMS);

                } else {
                    setSeriesContent({ ...emptyContent, status: "error" });
                }

            }

        }

        getXtreamSeries(0);

    } else {

        BACKEND.getPlaylistData({ id: playlist.id, type: "serie" })
            .then(async ({ data, groups }) => {
                let payload = await ParseMoviesContent(data, groups, true, false);// movies, categories, isSeries, isXtream
                setSeriesContent({ ...payload, status: "success" });
            })
            .catch((e) => {
                console.log(e);
                setSeriesContent({ ...emptyContent, status: "error" });
            });

    }

}

const GetPlaylistContent = async () => {

    let playlist = curPlaylist;

    if (!playlist) return;

    XTREAM.abortAll();
    XTREAM.resetInfo();
    BACKEND.abortAll();
    setStreamFormats([]);

    setChannelsContent({ ...emptyContent, status: "loading" });
    setMoviesContent({ ...emptyContent, status: "loading" });
    setSeriesContent({ ...emptyContent, status: "loading" });

    clearTimeout(xtreamLoginRetryTimeout);

    let xtream_account = XTREAM.isXtreamPlaylist(playlist.url);

    if (xtream_account) {

        try {

            const xtream_login = async () => {

                return new Promise((resolve, reject) => {

                    const login = async (retry) => {

                        try {

                            let account = await XTREAM.login(xtream_account);
                            resolve(account);

                        } catch (msg) {

                            if (retry < retryCount) {

                                console.log("xtream login retry", retry);

                                xtreamLoginRetryTimeout = setTimeout(() => {
                                    login(retry + 1);
                                }, retryTimeMS);

                            } else {
                                reject(msg);
                            }

                        }

                    };

                    login(0);

                });

            };

            var account = await xtream_login();

            if (playlist.id == "demo") {
                Toast.warning({ msg: "selected playlist is demo", id: "demo-playlist", time: 3000 });
            }

            if (playlist.free_dns_id) {

                BACKEND.addFreeDnsStatistics({ playlist_id: playlist.id })
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((e) => {
                        console.log(e);
                    });

            }

        } catch (msg) {

            setChannelsContent({ ...emptyContent, status: "error" });
            setMoviesContent({ ...emptyContent, status: "error" });
            setSeriesContent({ ...emptyContent, status: "error" });

            return Toast.error({ msg, time: 3000 });

        }

        if (account?.user_info?.allowed_output_formats) {

            let formats = account.user_info.allowed_output_formats;

            if (Array.isArray(formats) && formats.length > 0) {
                setStreamFormats(formats);
            }

        }

        XTREAM.init(xtream_account, account);
        XTREAM.stream_format = Storege.getSteamFormat();

    }

    GetPlaylistChannels();
    GetPlaylistMovies();
    GetPlaylistSeries();

}