import { useEffect, useState, createContext, memo } from "react";
import { createPortal } from "react-dom";
import Text from "@/src/components/text/Text";
import useKeydown from "@/src/hooks/useKeydown";
import ListView from "@/src/components/listView/ListView";
import "./VideoPopup.scss";
import VideoItem from "./VideoItem";

export const VideoContext = createContext();

const VideoPopup = ({
    videos,
    isVisible,
    className = "",
    onBack = () => { }
}) => {

    const [isShowing, setIsShowing] = useState(false);

    const [selected, setSelected] = useState(0);

    useEffect(() => {

        if (isVisible) {

            for (let i = 0; i < videos.length; i++) {

                if (videos[i].enabled) {
                    setSelected(i);
                    break;
                }

            }

            setTimeout(() => {
                setIsShowing(true);
            }, 0);

        } else {
            setIsShowing(false);
        }

    }, [isVisible]);

    useKeydown({
        isActive: isVisible,
        back: () => {
            onBack();
        }
    });

    if (!isVisible) return null;
   

    return createPortal(
        <VideoContext.Provider
            value={{
                videos,
                selected,
                setSelected,
            }}
        >
            <div
                className={`video-popup ${(isShowing ? "show" : "")} ${className}` }
                onMouseDown={e => e.stopPropagation()}
                onMouseEnter={e => e.stopPropagation()}
                onClick={e => e.stopPropagation()}
                onMouseMove={e => e.stopPropagation()}
            >

                <div className={`video-popup-content ${className}`}>

                    <div className="video-popup-title">
                        <Text>video</Text>
                    </div>

                    <div className="video-popup-list">

                        <div className="video-popup-list-col">
                            <ListView
                                id={videos}
                                uniqueKey={"video-popup-list"}
                                listType="vertical"
                                itemsTotal={videos.length}
                                itemsCount={10}
                                buffer={2}
                                initialActiveIndex={0}
                                startScrollIndex={5}
                                nativeControle={true}
                                debounce={100}
                                ItemRenderer={VideoItem}
                                itemWidth={40}
                                itemHeight={7.7}
                                isActive={true}
                            />
                        </div>

                    </div>

                </div>

            </div>
        </VideoContext.Provider>
        , document.getElementById("root"))

};

export default memo(VideoPopup);