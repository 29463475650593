import { memo, useContext } from 'react';
import Button from '@/src/components/button/Button';
import useKeydown from '@/src/hooks/useKeydown';
import useFavoriteChannel from '@/src/hooks/useFavoriteChannel';
import { Context } from '../../LiveTvPage';
import { dotsIcon, favoriteIcon, recIcon } from '@/src/assets/icons';
import ResizeImage from '@/src/components/resizeImage/ResizeImage';
import './ChannelItem.scss';
import MarqueeText from '@/src/components/marqueeText/MarqueeText';

const ChannelItem = ({
    isActive,
    index,
    style = {},
    onMouseEnter = () => { },
}) => {

    const {
        channelsContent,
        setCurrentSection,
        currentChannelId,
        currentCategoryId,
        clickChannel,
        moveItemIndex,
        setMoveItemIndex,
        setCurrentChannelsSection,
        currentChannelsSection
    } = useContext(Context);

    const channelId = channelsContent.categories_obj[currentCategoryId]?.items[index];

    const [isFavorite, toggelFavorite] = useFavoriteChannel(channelId);

    const isMovieng = moveItemIndex == index && isActive;

    if (isMovieng) isActive = true;

    useKeydown({
        isActive: isActive,
        yellow: () => {

            if (moveItemIndex != null) return setMoveItemIndex(null);

            toggelFavorite();

        },
        red: () => {

            if (currentCategoryId != "favorites") return;

            if (moveItemIndex != null) {
                setMoveItemIndex(null);
            } else {
                setMoveItemIndex(index);
            }

        }
    });

    const channel = channelsContent.items_obj[channelId];

    const isLocked = channelsContent.categories_obj[channel?.category_id]?.isLocked || false;

    const isSelected = currentChannelId == channelId;

    const mouseEnter = () => {
        if (moveItemIndex != null) return setMoveItemIndex(null);
        // setCurrentSection("channelsSection");
        // setCurrentChannelsSection("channels");
        setCurrentSection("channels");
        onMouseEnter(index);
    }

    // do not use useCallback here
    // When using useCallback, the pip mode playback channel does not work
    // when before that the channel changes from full screen mode up/down
    const click = (e) => {

        if (moveItemIndex != null) return setMoveItemIndex(null);

        if (window.Android && e.duration && e.duration > 500 && window.showColorButtons) {
            window.showColorButtons();
        } else {
            clickChannel(channelId);
        }

    };

    if (!channel) return null;

    let className = "channel-item focus-item";

    if (isMovieng) {
        className += " move";
    } else {

        if (isLocked) className += " locked";

        // if (isFavorite) className += " favorite";

        // if (channel.tv_archive) className += " record";

    }

    return (
        <div className={"channel-item-parent"} style={style}>
            <Button
                className={className}
                isActive={isActive}
                activeClassName={isMovieng ? "active move" : "active"}
                isSelected={isSelected}
                data={index}
                onMouseEnter={mouseEnter}
                onClick={click}
            >

                <div className='number'>{channel.num}</div>

                <div className='logo'>
                    <ResizeImage
                        format="png"
                        src={channel.logo}
                        placeholder={dotsIcon}
                        aspectRatio={true}
                        quality={0.5}
                    />
                </div>

                <div className='name'>
                    <MarqueeText isActive={isActive}>
                        {channel.name}
                    </MarqueeText>
                </div>

                {!!channel.tv_archive && <img className="record_icon" src={recIcon} />}
                {isFavorite && <img className="favorite_icon" src={favoriteIcon} />}

            </Button>
        </div>
    )

}

export default memo(ChannelItem);