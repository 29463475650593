function Icon () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="128"
            height="128"
            enableBackground="new 0 0 128 128"
            viewBox="0 0 36 36"
        >
            <path
                fillRule="evenodd"
                d="M16.86 6.57A4.57 4.57 0 0012.29 2H6.57A4.57 4.57 0 002 6.57v5.71a4.57 4.57 0 004.57 4.57h8a2.29 2.29 0 002.29-2.29v-8zm2.28 0A4.57 4.57 0 0123.71 2h5.71A4.57 4.57 0 0134 6.57v5.71a4.57 4.57 0 01-4.57 4.57h-8a2.29 2.29 0 01-2.29-2.29v-8zm-2.28 22.86A4.57 4.57 0 0112.29 34H6.57A4.57 4.57 0 012 29.43v-5.72a4.57 4.57 0 014.57-4.57h8a2.29 2.29 0 012.29 2.29zm2.28 0A4.57 4.57 0 0023.71 34h5.71A4.57 4.57 0 0034 29.43v-5.72a4.57 4.57 0 00-4.57-4.57h-8a2.29 2.29 0 00-2.29 2.29z"
                data-original="#000000"
            ></path>
        </svg>
    );
}

export default Icon;