import { useState } from "react";
import { useEffect } from "react";
import { memo, useRef } from "react";
import "./MarqueeText.scss";

const MarqueeText = ({ children, isActive }) => {

    const textRef = useRef(null);

    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {

        if (!textRef?.current) return;

        let offsetWidth = textRef?.current?.offsetWidth;
        let parentOffsetWidth = textRef?.current?.parentElement?.offsetWidth;

        setIsScrolling(offsetWidth > parentOffsetWidth - 5);

        let time = offsetWidth / 60; // 60px per second

        textRef.current.style["animation-duration"] = `${ time }s`;
        textRef.current.style["-webkit-animation-duration"] = `${ time }s`;

    }, [isActive, children]);

    if (!isActive) return children;

    return (
        <div className={`marquee-text ${ isScrolling ? "scroll" : "" }`} ref={textRef}>
            {isScrolling ? <>{children} {children}</> : children}
        </div>
    )

};

export default memo(MarqueeText);