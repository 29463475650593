import { useState } from "react";
import "./PageLoading.scss"
import { useEffect } from "react";

const PageLoading = ({ className = "", isVisible = false }) => {

    const [isShow, setIsShow] = useState(isVisible);

    useEffect(() => {

        if (isVisible) {
            setIsShow(true);
        } else {

            setTimeout(() => {

                requestAnimationFrame(() => {
                    setIsShow(false);
                });

            }, 50);

        }

    }, [isVisible]);

    if (!isShow) return null;

    return (
        <div className={"page-loading " + className}>
            <div className="page-loading-loader"></div>
        </div>
    )

}

export default PageLoading;