import Progress from "@/src/components/progress/Progress";
import { PauseSVG, PlaySVG } from "@/src/components/svgIcons";
import { FormatPlayerTime } from "@/src/utils/util";
import { memo } from "react";

const ProgressContainer = ({
    isActive,
    isPaused,
    currentTime,
    duration,
    seekTo,
    playPause,
    onMouseEnter,
}) => {

    return (
        <div className="progress-container">

            <div className="play-pause-btn">
                {isPaused ? <PlaySVG /> : <PauseSVG />}
            </div>

            <div className="time">{FormatPlayerTime(currentTime, "hh:mm:ss")}</div>

            <div className="progress-parent">
                <Progress
                    isActive={isActive}
                    value={currentTime}
                    total={duration}
                    onChange={seekTo}
                    onMouseEnter={onMouseEnter}
                    onClick={playPause}
                />
            </div>

            <div className="time">{FormatPlayerTime(duration, "hh:mm:ss")}</div>

        </div>
    )

}

export default memo(ProgressContainer);