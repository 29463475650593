import { memo, useEffect, useState } from "react";
import Text from "../../../../components/text/Text";
import "./Info.scss";
import useStore from "../../../../hooks/useStore";
import { FormatDate } from "../../../../utils/util";
import Config from "../../../../config";

const Info = () => {

    const [deviceInfo] = useStore("deviceInfo");

    const [items, setItems] = useState([]);

    useEffect(() => {

        let items = [];

        if (deviceInfo) {

            let { mac, key, payed, free_trial_expired, activation_expired, createdAt } = deviceInfo;

            let status = payed ? "active" : "free trial";

            let accountInfo = {
                title: <Text>account info</Text>,
                items: [
                    { title: <Text>status</Text>, value: <Text>{status}</Text> },
                    { title: <Text>registration date</Text>, value: FormatDate(createdAt, "dd.MM.yyyy") },
                ]
            };

            if (payed) {

                if (new Date(activation_expired).getFullYear() - new Date().getFullYear() > 10) {
                    activation_expired = <Text>lifetime</Text>;
                } else {
                    activation_expired = FormatDate(activation_expired, "dd.MM.yyyy");
                }

                accountInfo.items.push({
                    title: <Text>expired date</Text>,
                    value: activation_expired
                });

            } else {

                accountInfo.items.push({
                    title: <Text>free trial expired</Text>,
                    value: FormatDate(free_trial_expired, "dd.MM.yyyy")
                });

            }

            items.push(accountInfo);

            let device_info = {
                title: <Text>device info</Text>,
                items: [
                    { title: <Text>mac address</Text>, value: mac },
                    { title: <Text>app version</Text>, value: Config.APP_VERSION },
                    { title: <Text>device key</Text>, value: key },
                    // { title: <Text>restore key</Text>, value: restore_key },
                ]
            };

            items.push(device_info);

        }

        setItems(items);

    }, []);

    return (
        <div className="settings-content">
            {
                items.map((item, index) => (
                    <div key={index} className="settings-info-item">
                        <div className="settings-info-item-title">
                            {item.title}
                        </div>
                        {
                            item.items.map((item, index) => (
                                <div key={index} className="settings-item-list">
                                    <div>{item.title}</div>
                                    <div>{item.value}</div>
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    );

};

export default memo(Info);