import { memo, useEffect, useState, useRef, useCallback, useMemo, startTransition } from "react";
import "./ListView.scss";
import useKeydown from "../../hooks/useKeydown";

import Storege from "@/src/services/storage/storage";

let styles = {};
let transformTimeout = null;

let rtlMode;

function GridView({
    id,
    uniqueKey = "list-",
    nativeControle = false,
    debounce = 300,
    scrollOffset = 0,
    rowItemsCount,
    rowCount,
    bufferStart = 0,
    bufferEnd = 0,
    itemsTotal,
    itemWidth,
    itemHeight,
    isActive,
    ItemRenderer = () => { },
    onMouseEnter = () => { },
    onChangeRow = () => { },
    onUp = () => { },
    onDown = () => { },
    onLeft = () => { },
    onRight = () => { },
    onBack = () => { }
}) {

    if (rtlMode != Storege.getRtlMode()) styles = {};
    rtlMode = Storege.getRtlMode();

    if (!styles[uniqueKey]) styles[uniqueKey] = {};

    const scrollViewRef = useRef();

    const [items, setItems] = useState([]);

    const [activeIndex, setActiveIndex] = useState(0);

    const [startRow, setStartRow] = useState(0);

    const changeStartRow = (index) => {

        let startScrollRow = 2;

        let currentRow = Math.ceil((index + 1) / rowItemsCount);

        let row = currentRow - startScrollRow;

        if (row < 0) row = 0;

        setStartRow(row);

    }

    useEffect(() => {
        setActiveIndex(0);
        setStartRow(0);
        changeStartRow(0);
    }, [id]);

    const left = useCallback(() => {

        setActiveIndex((prev) => {

            if (prev % rowItemsCount == 0) {
                requestAnimationFrame(onBack);
            } else {
                prev--;
            }

            return prev;

        });

    }, [itemsTotal]);

    const right = useCallback(() => {

        setActiveIndex((prev) => {

            if (prev % rowItemsCount == rowItemsCount - 1 || prev == itemsTotal - 1) {
                // requestAnimationFrame(onBack);
            } else {
                prev++;
            }

            return prev;

        });

    }, [itemsTotal]);

    const up = useCallback(() => {

        setActiveIndex((prev) => {

            if (prev < rowItemsCount) {
                requestAnimationFrame(onUp);
            } else {
                prev -= rowItemsCount;
            }

            changeStartRow(prev);

            return prev;

        });

    }, [itemsTotal]);

    const down = useCallback(() => {

        setActiveIndex((prev) => {

            if (Math.ceil((prev + 1) / rowItemsCount) == Math.ceil(itemsTotal / rowItemsCount)) {
                requestAnimationFrame(onDown);
            } else {
                prev += rowItemsCount;
                if (prev > itemsTotal - 1) prev = itemsTotal - 1;
            }

            changeStartRow(prev);

            return prev;

        });

    }, [itemsTotal]);

    const back = useCallback(() => {
        onBack();
    }, [itemsTotal]);

    const onMouseEnterItem = useCallback((index) => {
        setActiveIndex(index);
        onMouseEnter(index);
    }, [itemsTotal]);

    const RenderItem = (index) => {

        if (!styles[uniqueKey][index]) {

            let vIndex = Math.floor(index / rowItemsCount);
            let hIndex = index % rowItemsCount;

            let style = {
                position: "absolute",
                width: itemWidth + "rem",
                height: itemHeight + "rem",
                top: vIndex * itemHeight + "rem",
                left: hIndex * itemWidth + "rem",
            };

            if (rtlMode) {
                style.right = hIndex * itemWidth + "rem";
                delete style.left;
            }

            styles[uniqueKey][index] = style;

        }

        return <ItemRenderer
            key={uniqueKey + index}
            index={index}
            style={styles[uniqueKey][index]}
            isActive={(index == activeIndex)}
            onMouseEnter={onMouseEnterItem}
        />;

    }

    useEffect(() => {

        const items = [];

        const start = (startRow * rowItemsCount) - (rowItemsCount * bufferStart);

        const end = (startRow * rowItemsCount) + (rowItemsCount * rowCount) + (rowItemsCount * bufferEnd);

        for (let i = start; i < end; i++) {

            if (i < 0 || i > itemsTotal - 1) continue;

            items.push(RenderItem(i));

        }

        setItems(items);

        startTransition(() => {

            if (!scrollViewRef.current) return;

            window.transforming = true;
            window.dispatchEvent(new Event("transformstart"));
            clearTimeout(transformTimeout);

            transformTimeout = setTimeout(() => {
                window.transforming = false;
                window.dispatchEvent(new Event("transformend"));
            }, 800);

            let offset = startRow * itemHeight;

            let currentRow = Math.ceil((activeIndex + 1) / rowItemsCount);

            if (currentRow > 1) {
                offset += scrollOffset
            }

            let transform = `translate3d(0, -${offset}rem, 0)`

            scrollViewRef.current.style["transform"] = transform;
            scrollViewRef.current.style["-webkit-transform"] = transform;
            scrollViewRef.current.style["-ms-transform"] = transform;

            onChangeRow(currentRow);

        });

    }, [activeIndex, startRow, itemsTotal, id]);

    const keyDownOptions = useMemo(() => {
        return {
            isActive: isActive && nativeControle,
            debounce,
            left,
            right,
            up,
            down,
            back
        }
    }, [isActive, nativeControle, itemsTotal, id]);

    useKeydown(keyDownOptions);

    return (
        <div className={"scroll-view-parent"} style={{ width: "100%", height: "100%" }}>
            <div className="scroll-view grid-view" ref={scrollViewRef}>
                {items}
            </div>
        </div>
    )
}

export default memo(GridView);