import { memo } from "react";
import Button from "../../../../../components/button/Button";
import Text from "../../../../../components/text/Text";
import useKeydown from "../../../../../hooks/useKeydown";
import "./PlayNextEpisode.scss";

const PlayNextEpisode = ({
    currentTime = 0,
    duration = 0,
    confirm = () => { },
    cancel = () => { }
}) => {

    useKeydown({
        isActive: true,
        back: () => {
            cancel();
        }
    });

    if (!duration || !currentTime) return null;

    let time = parseInt(duration - currentTime);

    if (time <= 1) return requestAnimationFrame(confirm);

    return (
        <div className="play-next-episode-popup">

            <div className="play-next-episode-container">
                <Button
                    className="play-next-episode-button"
                    onClick={confirm}
                    onMouseEnter={() => { }}
                    isActive={true}
                >
                    <Text>play next episode</Text>
                    <span>{time}</span>
                </Button>

                <div className="play-next-episode-cancel">
                    <Text>press back to cancel</Text>
                </div>

            </div>
        </div>
    )

};

export default memo(PlayNextEpisode);