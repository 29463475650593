
import { memo } from "react";
import QRCode from "qrcode.react";

function QrCode({ style, size, value }) {
    return <QRCode
        style={style}
        size={size}
        value={value}
    />
}

export default memo(QrCode);