import { memo, useEffect, useState } from "react";
import Text from "../../../../components/text/Text";
import "./StreamFormat.scss";
import Storege from "../../../../services/storage/storage";
import useStore from "../../../../hooks/useStore";
import Button from "../../../../components/button/Button";
import useKeydown from "../../../../hooks/useKeydown";
import XTREAM from "../../../../services/api/xtream";

const StreamFormat = ({
    isActive = false,
    onBack = () => { },
    onMouseEnter = () => { }
}) => {

    const [streamFormats] = useStore("streamFormats");

    const [streamFormat, setSteamFormat] = useState(Storege.getSteamFormat());

    const [items, setItems] = useState([]);

    const [activeIndex, setActiveIndex] = useState(0);

    const onClick = (e, index) => {

        let item = items[index];

        if (!item) return;

        Storege.setSteamFormat(item.value);

        XTREAM.stream_format = item.value;

        setSteamFormat(item.value);

        setItems([...items]);

    }

    const mouseEnter = (e, index) => {
        setActiveIndex(index);
        onMouseEnter();
    }

    useEffect(() => {

        let items = [
            { title: "Default", value: "" },
        ];

        for (let i = 0; i < streamFormats.length; i++) {

            if (streamFormats[i] != "" && streamFormats[i] != "rtmp") {
                items.push({ title: streamFormats[i], value: streamFormats[i] });
            }

        }

        setItems(items);

    }, []);

    useKeydown({
        isActive,
        back: onBack,
        left: onBack,
        up: () => {
            if (activeIndex > 0) setActiveIndex(activeIndex - 1);
        },
        down: () => {
            if (activeIndex < items.length - 1) setActiveIndex(activeIndex + 1);
        },
    })

    return (
        <div className="settings-content">

            {
                items.map((item, index) => {

                    return (
                        <Button
                            key={index}
                            isActive={isActive && activeIndex === index}
                            isSelected={streamFormat === item.value}
                            className="stream-format-item focus-item"
                            onClick={onClick}
                            data={index}
                            onMouseEnter={mouseEnter}
                        >
                            {item.title}
                        </Button>
                    )

                })
            }

        </div>
    );

};

export default memo(StreamFormat);