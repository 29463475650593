import { createContext, memo, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import Channels from "./components/Channels";
import Programms from "./components/Programms";
import TimeLine from "./components/TimeLine";
import useKeydown from "@/src/hooks/useKeydown";
import { RemToPixel, animation, transformElement } from "@/src/utils/util";
import "./TimeLineEpg.scss";
import { getXMLEpg } from "@/src/services/content/parser";

export const TimeLineEpgContext = createContext();

const TimeLineBody = memo(() => {

    return (
        <div className="timeline-epg-parent">

            <TimeLine />

            <Channels />

            <Programms />

        </div>
    )

});

const TimeLineEpg = () => {

    const [channels, setChannels] = useState([]);
    const [programms, setProgramms] = useState([]);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [activeRow, setActiveRow] = useState(0);
    const [timelineConfig, setTimelineConfig] = useState({});
    const rowHeight = RemToPixel(10); // Ex. 10rem = 100px

    let k = 100;

    useKeydown({
        isActive: true,
        up: () => {
            let row = activeRow - 1;
            if (row < 0) row = 0;
            setActiveRow(row);
        },
        down: () => {
            let row = activeRow + 1;
            if (row > channels.length - 1) row = channels.length - 1;
            setActiveRow(row);
        },
        left: () => {
            let x = positionX + k;
            if (x > 0) x = 0;
            setPositionX(x);
        },
        right: () => {
            let x = positionX - k;
            setPositionX(x);
        },
    });

    useEffect(() => {

        if (activeRow > 4 && activeRow < channels.length - 6) {
            let y = (activeRow - 4) * rowHeight;
            setPositionY(-y);
        }

    }, [activeRow]);

    useEffect(() => {

        let timeline = document.querySelector("#timeline");
        let channels = document.querySelector("#channels");
        let programms = document.querySelector("#programms");

        let anim2 = requestAnimationFrame(() => {
            transformElement(timeline, positionX, 0, 1);
            transformElement(channels, 0, positionY, 1);
            transformElement(programms, positionX, positionY, 1);
        });

        // let anim2 = animation((progress) => {

        //     transformElement(timeline, positionX, 0, progress);
        //     transformElement(channels, 0, positionY, progress);
        //     transformElement(programms, positionX, positionY, progress);

        // }, 60);

        return () => {
            cancelAnimationFrame(anim2);
        }

    }, [positionX, positionY]);

    useEffect(() => {

        (async () => {

            let { programs, min_unix, max_unix } = await getXMLEpg("http://vivatvpro.xyz:8080/xmltv.php?username=tomtom00531&password=tomtom00532");

            let channels = Object.keys(programs);

            setChannels(channels);
            setProgramms(programs);
            setTimelineConfig({ min_unix, max_unix });

            let date = new Date().getTime();

            let left = RemToPixel(((date - min_unix) / 60000) - 30); // 30 minutes offset

            setPositionX(-left);

        })();

    }, []);

    return createPortal(
        <TimeLineEpgContext.Provider
            value={{
                channels,
                programms,
                rowHeight,
                //
                positionX,
                positionY,
                //
                activeRow,
                setActiveRow,
                //
                timelineConfig
            }}
        >
            <TimeLineBody />
        </TimeLineEpgContext.Provider>
        , document.getElementById("root"));

}

export default memo(TimeLineEpg);