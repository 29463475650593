import { memo, useCallback, useEffect, useMemo, useState, useContext } from "react";
import useKeydown from "../../../hooks/useKeydown";
import Button from "../../../components/button/Button";
import { binIcon } from "../../../assets/icons";
import ConfirmPopup from "../../../popups/confirm/ConfirmPopup";
import { VideoPageContext } from "../VideosPage";
import "./VideoItem.scss";
import { PlaySVG } from "@/src/components/svgIcons";

import Storege from "@/src/services/storage/storage";

const VideoItem = ({
    style,
    isActive,
    index,
    onMouseEnter = () => { },
}) => {

    const {
        videos = [],
        playVideo,
        deleteVideo,
    } = useContext(VideoPageContext);

    const video = videos[index];

    const [deleteFocused, setDeleteFocused] = useState(false);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const rtlMode = Storege.getRtlMode();

    const mouseEnter = useCallback(() => {
        setDeleteFocused(false);
        onMouseEnter(index);
    }, []);

    const click = useCallback(() => {
        playVideo(video);
    }, []);

    const left = () => {
        setDeleteFocused(false);
    }

    const right = () => {
        setDeleteFocused(true);
    }

    const keyDownOptions = useMemo(() => {
        return {
            isActive: isActive && !confirmDelete,
            debounce: 300,
            left,
            right,
            up: () => { },
            down: () => { },
        }
    }, [isActive]);

    useKeydown(keyDownOptions);

    useEffect(() => {
        if (!isActive) setDeleteFocused(false);
    }, [isActive]);

    if (!video) return null;

    return (
        <div style={style} className="video-item-parent">

            <Button
                className={"video-item focus-item"}
                isActive={isActive && !deleteFocused && !confirmDelete}
                onClick={click}
                onMouseEnter={mouseEnter}
            >

                <div className="number">{index + 1}</div>

                <div className="video-info">

                    <div className="title-row">
                        <div className="title">{video.name}</div>
                    </div>

                    <div className="url">
                        {video.url}
                    </div>

                </div>

                <div className="play-icon">
                    <PlaySVG />
                </div>

                <Button
                    className={"video-item-delete focus-item"}
                    isActive={isActive && deleteFocused && !confirmDelete}
                    activeClassName={"active-danger"}
                    onClick={() => { setConfirmDelete(true) }}
                    onMouseEnter={() => {
                        setDeleteFocused(true);
                    }}
                >
                    <img src={binIcon} />
                </Button>

            </Button>

            {confirmDelete && <ConfirmPopup
                title={"delete video popup title"}
                message={"delete video popup message"}
                confirmText={"yes"}
                cancelText={"no"}
                confirmFocused={true}
                onCancel={() => {
                    setConfirmDelete(false);
                }}
                onConfirm={() => {
                    deleteVideo(index);
                    setConfirmDelete(false);
                }}
            />}

        </div>
    )

}

export default memo(VideoItem);