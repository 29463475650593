import { memo, useState, createContext, useEffect, useCallback } from "react";
import "./LiveTvPage.scss";

import ChannelsSection from "./sections/ChannelsSection";
import PLayerSection from "./sections/PLayerSection";
import DaysSection from "./sections/DaysSection";
import SearchSection from "./sections/SearchSection";

import useKeydown from "../../hooks/useKeydown";
import useContent from "../../hooks/useContent";

import XTREAM from "../../services/api/xtream";
import { ParseEpgList } from "../../services/content/parser";

import CheckPinPopup from "@/src/popups/checkPin/CheckPinPopup";
import PageLoading from "@/src/components/pageLoading/PageLoading";
import useStore from "@/src/hooks/useStore";
import Storege from "@/src/services/storage/storage";
import Toast from "@/src/components/toast/Toast";
import { DeviceDate } from "@/src/utils/util";
import { GetPlaylistChannels } from "@/src/services/content/content";

export const Context = createContext();

const LiveTvPage = ({ onBack = () => { } }) => {

    const [appBackground, setAppBackground] = useStore("appBackground");

    const [channelsContent, setChannelsContent] = useContent("channelsContent");

    // const [currentSection, setCurrentSection] = useState("channelsSection");
    const [currentSection, setCurrentSection] = useState("channels");

    const [currentChannelsSection, setCurrentChannelsSection] = useState("channels");

    const [currentChannelId, setCurrentChannelId] = useState(null);

    const [currentCategoryId, setCurrentCategoryId] = useState("all");

    const [currentDay, setCurrentDay] = useState(new DeviceDate().getDate());

    const [epgList, setEpgList] = useState(null);

    const [lockedChannelId, setLockedChannelId] = useState(null);

    const [sort, setSort] = useState(Storege.getChannelsSort()); // A-Z, Z-A

    const [moveItemIndex, setMoveItemIndex] = useState(null);

    const [playerOptions, setPlayerOptions] = useState({
        channel: null,
        epg: null,
        isLive: true,
        isFullscreen: false
    });

    const playChannel = (channelId) => {

        if (channelId == null || channelId == undefined) return

        let channel = channelsContent.items_obj[channelId];

        if (!channel) return

        setCurrentChannelId(channelId);

        setPlayerOptions({ ...playerOptions, isLive: true, channel, epg: null, });

    };

    const clickChannel = useCallback((channelId) => {

        if (currentChannelId == channelId) {
            setPlayerOptions({ ...playerOptions, isFullscreen: true });
        } else {

            const channel = channelsContent.items_obj[channelId];

            if (!channel) return

            const { isLocked } = channelsContent.categories_obj[channel.category_id];

            if (isLocked) {
                setLockedChannelId(channelId);
            } else {
                Storege.setChannelState({ channelId, categoryId: currentCategoryId });
                playChannel(channelId);
            }

        }

    }, [currentChannelId, playerOptions, channelsContent, currentCategoryId]);

    const pinOnComplate = useCallback(() => {
        playChannel(lockedChannelId);
        setLockedChannelId(null);
    }, [lockedChannelId]);

    const pinOnBack = useCallback(() => {
        setLockedChannelId(null);
    }, []);

    const goToMenu = () => {
        setCurrentChannelId(null);
        setAppBackground("fullscreen");
        onBack();
    }

    useEffect(() => {

        if (currentCategoryId == "favorites") return;

        let category = channelsContent.categories_obj[currentCategoryId];

        if (!category) return;

        let { items } = category

        if (category.sort == sort) return;

        category.sort = sort;
        channelsContent.sort = sort;

        let channels = channelsContent.items_obj;

        switch (sort) {

            case "A-Z":
                items.sort((a, b) => {
                    return channels[a].name > channels[b].name ? 1 : -1;
                });
                break;
            case "Z-A":
                items.sort((a, b) => {
                    return channels[a].name < channels[b].name ? 1 : -1;
                });
                break;
            default:

                items.sort((a, b) => {
                    return channels[a].num > channels[b].num ? 1 : -1;
                });

                break;
        }

        Storege.setChannelsSort(sort);

        setChannelsContent({ ...channelsContent });

    }, [sort, channelsContent, currentCategoryId]);

    useEffect(() => {

        setMoveItemIndex(null);

        if (currentSection == "searchSection") {
            setAppBackground("fullscreen");
        } else {
            setAppBackground("croped");
        }

    }, [currentSection]);

    // getting epg list for current channel
    const getEpgList = async (callback) => {

        try {

            let channel = channelsContent.items_obj[currentChannelId];

            if (channel && channel.isXtream) {

                let epgList = await XTREAM.getChannelEpg(channel.id);

                const data = ParseEpgList(epgList, channel.id, channel.isXtream);

                callback(data);

            }

        } catch (e) {
            console.log(e);
        }

    }

    // getting epg list for current channel
    useEffect(() => {

        let callback = ([epgList, currentEpg]) => {
            setEpgList(epgList);
            setPlayerOptions((options) => {
                return {
                    ...options,
                    epg: currentEpg
                }
            });
            setCurrentDay(new DeviceDate().getDate());
        };

        setEpgList(null);

        let timer = setTimeout(() => {
            getEpgList(callback);
        }, 1000);

        return () => {
            callback = () => { };
            clearTimeout(timer);
        }

    }, [currentChannelId]);

    // playing first channel or last channel
    useEffect(() => {

        if (channelsContent.status != "success") return;

        if (!currentChannelId) {

            let channelState = Storege.getChannelState();

            let lastChannel = channelState?.channelId;

            let lastCategory = channelState?.categoryId || "all";

            if (lastChannel && channelsContent.items_obj[lastChannel]) {

                clickChannel(lastChannel);

                let category = channelsContent.categories_obj[lastCategory];

                if (category && category.items.indexOf(lastChannel) > -1) {
                    setCurrentCategoryId(lastCategory);
                }

            } else {

                let [firstChannel] = channelsContent.items_arr;

                if (firstChannel && channelsContent.items_obj[firstChannel]) {
                    clickChannel(firstChannel);
                }

            }

        }

    }, [channelsContent, currentChannelId]);

    useEffect(() => {

        if (channelsContent.status == "error") {
            Toast.error({ msg: "error loading channels", id: "channels", time: 3000 });
            goToMenu();
        } else if (channelsContent.status == "none") {
            GetPlaylistChannels();
        }

    }, [channelsContent]);

    useEffect(() => {

        if (currentChannelId) {

            if (playerOptions.isFullscreen) {
                document.body.classList.add("fullscreen");
                setAppBackground("none");
            } else {
                document.body.classList.remove("fullscreen");
                setAppBackground("croped");
            }

        } else {
            setAppBackground("fullscreen");
        }

    }, [playerOptions, currentChannelId]);

    useEffect(() => {


        try {

            if (window.Android) {

                let hide_toast = Toast.success({ msg: "long press ok to show color buttons", time: 5000 });

                return () => hide_toast();

            }

        } catch (e) {
            console.log(e);
        }

        window.dispatchEvent(new CustomEvent("cleanImageResizer"));

        return () => {
            window.dispatchEvent(new CustomEvent("cleanImageResizer"));
        }

    }, []);

    useKeydown({
        isActive: !playerOptions.isFullscreen,
        red: () => {

            // if (currentSection != "channelsSection") return;
            // if (currentChannelsSection != "channels") return;
            if (currentSection != "channels") return;
            if (currentCategoryId == "favorites") return;

            switch (sort) {
                case "":
                    setSort("A-Z");
                    break;
                case "A-Z":
                    setSort("Z-A");
                    break;
                case "Z-A":
                    setSort("");
                    break;
            }

        },
        green: () => {

            console.log("green", currentSection)

            // if (currentSection != "channelsSection") return;
            if (currentSection == "channels") setCurrentSection("categories");

        },
        blue: () => {
            goToMenu();
        },
    });

    return (
        <Context.Provider
            value={{
                onBack: goToMenu,
                clickChannel,
                //
                channelsContent,
                setChannelsContent,
                //
                currentSection,
                setCurrentSection,
                //
                currentChannelId,
                //
                currentCategoryId,
                setCurrentCategoryId,
                //
                currentDay,
                setCurrentDay,
                //
                currentChannelsSection,
                setCurrentChannelsSection,
                //
                epgList,
                //
                playerOptions,
                setPlayerOptions,
                //
                moveItemIndex,
                setMoveItemIndex,
                //
            }}
        >
            <div className="live-tv-page">

                <ChannelsSection />

                {currentSection == "searchSection" ?
                    <SearchSection />
                    :
                    <>
                        <PLayerSection />
                        <DaysSection />
                    </>
                }

                <CheckPinPopup
                    isVisiable={lockedChannelId != null}
                    onComplate={pinOnComplate}
                    onBack={pinOnBack}
                />

                <PageLoading className="bg-color" isVisible={channelsContent.status != "success"} />

            </div>
        </Context.Provider>
    )

}

export default memo(LiveTvPage);