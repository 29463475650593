import { createContext, memo, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import useStore from "../../../../../hooks/useStore";
import "./ManageCategoriesPopup.scss";
import ManageCategoriesItem from "./ManageCategoriesItem";
import ListView from "../../../../../components/listView/ListView";
import useKeydown from "../../../../../hooks/useKeydown";
import Text from "../../../../../components/text/Text";

export const ManageCategoriesContext = createContext();

const ManageCategoriesPopup = ({
    type,
    title,
    onBack = () => { }
}) => {

    const [content, setContent] = useStore(type);

    const [moveItemIndex, setMoveItemIndex] = useState(null);

    const [initialActiveIndex, setInitialActiveIndex] = useState(0);

    const { categories_arr } = content;

    useKeydown({
        isActive: moveItemIndex != null,
        debounce: 200,
        up: () => {
          
            if (moveItemIndex == 0) return;
          
            categories_arr.splice(moveItemIndex - 1, 0, categories_arr.splice(moveItemIndex, 1)[0]);
          
            setContent({ ...content, categories_arr });
          
            setMoveItemIndex(moveItemIndex - 1);
          
            setInitialActiveIndex(moveItemIndex - 1);
        
        },
        down: () => {
           
            
            if (moveItemIndex == categories_arr.length - 1) return;
          
            categories_arr.splice(moveItemIndex + 1, 0, categories_arr.splice(moveItemIndex, 1)[0]);
          
            setContent({ ...content, categories_arr });
          
            setMoveItemIndex(moveItemIndex + 1);
          
            setInitialActiveIndex(moveItemIndex + 1);
        
        },
        back: () => {
            setMoveItemIndex(null);
        },
        ok: () => {
            setMoveItemIndex(null);
        },
    });

    return (
        createPortal(
            <ManageCategoriesContext.Provider
                value={{
                    content,
                    setContent,
                    //
                    moveItemIndex,
                    setMoveItemIndex,
                }}
            >
                <div className="manage-categories-popup bg-color">

                    <div className="manage-categories-popup-title">
                        <Text>{title}</Text>
                    </div>

                    <div className="manage-categories-popup-content">

                        <div className={"manage-categories-popup-list" + (moveItemIndex != null ? " move" : "")}>

                            <ListView
                                id={initialActiveIndex}
                                uniqueKey={"manage-categories-list"}
                                listType="vertical"
                                itemsTotal={categories_arr.length}
                                itemsCount={11}
                                buffer={10}
                                initialActiveIndex={initialActiveIndex}
                                startScrollIndex={5}
                                nativeControle={true}
                                debounce={100}
                                ItemRenderer={ManageCategoriesItem}
                                itemWidth={120}
                                itemHeight={8}
                                isActive={moveItemIndex == null}
                                onBack={onBack}
                            />

                        </div>

                    </div>

                </div>
            </ManageCategoriesContext.Provider>,
            document.getElementById("root"))
    );

};

export default memo(ManageCategoriesPopup);