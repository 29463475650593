import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";

import useKeydown from "@/src/hooks/useKeydown";
import useStore from "@/src/hooks/useStore";

import Button from "@/src/components/button/Button";
import Progress from "@/src/components/progress/Progress";
import Text from "@/src/components/text/Text";
import PlayerError from "@/src/components/playerError/PlayerError";
import { PauseSVG, PlaySVG } from "@/src/components/svgIcons";

import { FormatDate } from "@/src/utils/util";
import { arrowLeftWhite, dotsIcon } from "@/src/assets/icons";
import { Context } from "../../LiveTvPage";

import "./ChannelPlayer.scss";
import AppPlayer from "@/src/components/appPlayer/AppPlayer";
import ResizeImage from "@/src/components/resizeImage/ResizeImage";
import AudioPopup from "@/src/pages/movies/moviePlayer/popups/audio/AudioPopup";
import VideoPopup from "@/src/pages/movies/moviePlayer/popups/video/VideoPopup";
import XTREAM from "@/src/services/api/xtream";

let changeNumberTimeout = null;
let controlesTimeout = null;
let seekToTimeout = null;
let retryPlayerTimeout = null;

const Player = () => {

    const {
        currentChannelId,
        channelsContent,
        currentCategoryId,
        setCurrentCategoryId,
        playerOptions,
        setPlayerOptions,
        clickChannel,
    } = useContext(Context);

    const allChannelsObj = channelsContent.items_obj;

    const { epg, isLive } = playerOptions;

    const { next_epg, prev_epg } = epg || {};

    const playerRef = useRef(null);

    const [platform] = useStore("platform");

    const [documentIsHidden] = useStore("documentIsHidden");

    const [isOnline] = useStore("isOnline");

    const [hidden, setHidden] = useState(false);

    const [error, setError] = useState(null);

    const [current, setCurrent] = useState("progress"); // live, next, prev

    const [inputNumber, setInputNumber] = useState(null);

    const [playerStyle, setPlayerStyle] = useState({ display: "none" });

    const [currentTime, setCurrentTime] = useState(0);

    const [duration, setDuration] = useState(0);

    const [isPlaying, setIsPlaying] = useState(true);

    const [isPaused, setIsPaused] = useState(false);

    const [isSeeking, setIsSeeking] = useState(false);

    const [playerCurrentBlock, setPlayerCurrentBlock] = useState('fullscreen');

    const [audios, setAudios] = useState([]);
    const [video, setVideo] = useState([]);

    const [changeChannelId, setChangeChannelId] = useState(null);

    const [rtlMode] = useStore("rtlMode");

    const channel = channelsContent.items_obj[changeChannelId || currentChannelId];

    const changedChannelNumber = channelsContent.items_obj[changeChannelId]?.num || "";

    // change channel by up or down
    const upDownChannel = (num) => {

        setInputNumber(null);

        // selected category channels
        const channelIds = channelsContent.categories_obj[currentCategoryId]?.items || [];

        let index = channelIds.indexOf(changeChannelId || currentChannelId);

        index += num;

        if (index < 0) index = channelIds.length - 1;

        if (index > channelIds.length - 1) index = 0;

        setChangeChannelId(channelIds[index]);

    }

    // change channel by number
    const changeChannel = (num) => {

        setChangeChannelId(null);

        let number = inputNumber || "";

        if (num == "0" && number == "") return;

        number += num;

        for (let id in allChannelsObj) {

            let channel = allChannelsObj[id];

            if (channel.num == number) {
                setChangeChannelId(id);
                setInputNumber(number);
                return;
            }

        }

        setInputNumber(null);
        setChangeChannelId(null);

    }

    const EpgInfo = ({ epg }) => {

        if (changedChannelNumber) return null; // don't show epg info when changing channel

        if (!epg) return null;

        let { title, start } = epg;

        let time = "--:--";

        if (start) time = `${start}`;

        if (!title) title = <Text>program not found</Text>;

        return (
            <div className="epg-info">
                <div className="time">{time}</div>
                <div className="title">{title}</div>
            </div>
        )

    }

    const goLive = () => {
        reset();
        setPlayerOptions({ ...playerOptions, isLive: true, });
    }

    const seekTo = (percent, currentTime) => {

        let player = playerRef.current;

        if (!player) return;

        if (duration == Infinity) {
            console.log("duration is infinity");
            return;
        }

        if (currentTime == undefined) currentTime = percent * duration;

        if (currentTime < 0) currentTime = 0;

        if (currentTime > duration) currentTime = duration;

        if (currentTime == duration) currentTime -= 1;

        if (isNaN(currentTime)) return;

        setCurrentTime(currentTime);

        setIsSeeking(true);

        clearTimeout(seekToTimeout);

        seekToTimeout = setTimeout(() => {
            player.seekTo(currentTime, "seconds");
        }, 1000);

    }

    

    const loadTracks = () => {

        try {
          
            if(playerRef.current){

                setAudios(playerRef.current.getInternalPlayer().audioTracks|| []);
                setVideo(playerRef.current.getInternalPlayer().videoTracks|| []);        
                
                    
                
            }

        } catch (e) { 
            console.log(e)
        }

    }

 

    // retry to play when error
    useEffect(() => {

        let timeout = setTimeout(() => {
           
            if (isLive && !isPlaying) setIsPlaying(true);

        }, 100);

        return () => clearTimeout(timeout);

    }, [isPlaying, isLive,audios]);

    const playerConfign = useMemo(() => {

        let url = ""

        if (isLive) {
            if (channel && isPlaying) url = channel.isXtream ? XTREAM.getChannelUrl(channel) : channel.url;
        } else {
            url = epg?.url;
        }

        const onEnded = () => {

            if (isLive) return setIsPlaying(false);

            if (next_epg && next_epg.has_archive) {
                playNextPrevEpg(next_epg);
            } else {
                goLive();
            }

        };

        clearTimeout(retryPlayerTimeout);

        let playerConfign = {
            controls: false,
            width: "100%",
            height: "100%",
            url,
            mediatype: isLive ? "live" : "epg",
            loop: false,
            playing: isPlaying && isOnline && !documentIsHidden,
            onReady: () => {
            },
            onError: (e) => {

                clearTimeout(retryPlayerTimeout);

                // if live channel is not playing then retry again
                if (isLive) {
                    retryPlayerTimeout = setTimeout(() => {
                        setIsPlaying(false);
                    }, 2000);
                } else {
                    setError(true);
                }

            },
            onEnded: () => {
                onEnded();
            },
            onPlay: () => {
                if (!isOnline || documentIsHidden) return;
                setError(false);
                setIsPaused(false);
                setIsPlaying(true);
                

            },
            onPause: () => {
                if (!isOnline || documentIsHidden) return;
                setIsPlaying(false);
                setIsPaused(true);
            },
            onProgress: () => {
                setError(false);
                loadTracks();
            },

        }

        if (!isLive) {

            playerConfign = {
                ...playerConfign,
                onProgress: ({ playedSeconds }) => {
                    if (isSeeking) return;
                    setCurrentTime(playedSeconds);
                    if (playedSeconds && duration && playedSeconds >= duration) onEnded();
                },
                onDuration: (sec) => {
                    setError(false);
                    setDuration(sec);
                   
                },
                onSeek: () => {
                    setIsSeeking(false);
                }
            }

        }

        return playerConfign;

    }, [playerOptions, isPlaying, isOnline, isSeeking, documentIsHidden]);

    const hideControlesTimeout = () => {

        clearTimeout(controlesTimeout);

        controlesTimeout = setTimeout(() => {
            setHidden(true);
        }, 5000);

    }

    useEffect(() => {

        setHidden(false);

        clearTimeout(changeNumberTimeout);

        changeNumberTimeout = setTimeout(() => {

            setChangeChannelId(null);
            setInputNumber(null);

            let channel = allChannelsObj[changeChannelId];

            setVideo([]);
            setAudios([]);

            if (channel) {

                // selected category channels
                const channels = channelsContent.categories_obj[currentCategoryId]?.items || [];

                if (channels.indexOf(changeChannelId) == -1) {
                    // if channel is not in selected category then set current category to all
                    setCurrentCategoryId("all");
                }

                clickChannel(changeChannelId);

            }

        }, 1500);

        return () => clearTimeout(changeNumberTimeout);

    }, [changeChannelId]);

    useEffect(() => {

        if (!hidden) hideControlesTimeout();

        return () => {
            clearTimeout(controlesTimeout);
        }

    }, [hidden]);

    useEffect(() => {

        setHidden(false);
        hideControlesTimeout();

        // setting player style to fullscreen
        // don't remove this requestAnimationFrame
        requestAnimationFrame(() => {

            let playerParent = document.querySelector(".player-parent");

            if (playerParent) {

                let rect = playerParent.getBoundingClientRect();

                let { width, height, left, top } = rect;

                setPlayerStyle({
                    position: "absolute",
                    backgroundColor: window.Android ? "transparent" : "black",
                    width: width + "px",
                    height: height + "px",
                    left: left + "px",
                    top: top + "px",
                });

            }

        });
        
    }, [playerOptions]);

    useEffect(() => {
        setError(false);
    }, [currentChannelId]);

    useEffect(() => {
        return () => {
            clearTimeout(retryPlayerTimeout);
        }
    }, []);

    const reset = () => {
        clearTimeout(seekToTimeout);
        clearTimeout(changeNumberTimeout);
        clearTimeout(controlesTimeout);
        setChangeChannelId(null);
        setInputNumber(null);
        setIsPlaying(true);
        setIsPaused(false);
        setIsSeeking(false);
    }

    const up = () => {

        if (playerOptions.isLive) return upDownChannel(1);

        setCurrent("progress");

    };

    const down = () => {

        if (playerOptions.isLive) return upDownChannel(-1);

        if (current == "progress") setCurrent("live");

    };

    const left = () => {

        if (playerOptions.isLive) return;

        switch (current) {
            case "next":
                if (prev_epg && prev_epg.has_archive) {
                    setCurrent("prev");
                } else {
                    setCurrent("live");
                }
                break;
            case "prev":
                setCurrent("live");
                break;
            case "progress":
                seekTo(null, currentTime - 10);
                break;
        }

    };

    const right = () => {

        if (playerOptions.isLive) return;

        switch (current) {
            case "live":
                if (prev_epg && prev_epg.has_archive) {
                    setCurrent("prev");
                } else if (next_epg && next_epg.has_archive) {
                    setCurrent("next");
                }
                break;
            case "prev":
                if (next_epg && next_epg.has_archive) {
                    setCurrent("next");
                }
                break;
            case "progress":
                seekTo(null, currentTime + 10);
                break;
        }

    };

    const playNextPrevEpg = (epg) => {

        if (!epg) return;

        setPlayerOptions({
            ...playerOptions,
            isLive: false,
            epg,
            isFullscreen: true
        });

        setError(false);

        if (current == "prev") {

            if (!epg.prev_epg || !epg.prev_epg.has_archive) {
                setCurrent("live");
            }

        } else if (current == "next") {

            if (!epg.next_epg || !epg.next_epg.has_archive) {

                if (epg.prev_epg && epg.prev_epg.has_archive) {
                    setCurrent("prev");
                } else {
                    setCurrent("live");
                }

            }

        }

    }

    const ok = () => {

        if (playerOptions.isLive) return back();

        switch (current) {
            case "live":
                goLive();
                break;
            case "prev":
                if (prev_epg && prev_epg.has_archive) playNextPrevEpg(prev_epg);
                break;
            case "next":
                if (next_epg && next_epg.has_archive) playNextPrevEpg(next_epg);
                break;
            case "progress":
                setIsPlaying(!isPlaying);
                break;
        }

    }

    const hidePopup = () => {
        
        setPlayerCurrentBlock('fullscreen');
    
    }


    const back = () => {   
        reset();
        setPlayerOptions({
            ...playerOptions,
            isLive: true,
            isFullscreen: false
        });
    }

    useKeydown({
        isActive: playerOptions.isFullscreen && playerCurrentBlock == 'fullscreen',
        capture: true,
        keydown: (e, key) => {
          
            
            e.stopPropagation();

            setHidden(false);
            hideControlesTimeout();

            if (["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].indexOf(key) > -1) {
                changeChannel(key);
                return true;
            }

            return false
        },
        ok,
        back,
        up,
        down,
        left: ()=>{

            if (playerOptions.isLive ) {

                if(audios.length)
                setPlayerCurrentBlock('audioPopup');  
                
            }else{

                rtlMode ? right() : left()

            }

          
        },
        right: ()=>{

            if (playerOptions.isLive ) {

                if(video.length)
                setPlayerCurrentBlock('videoPopup');

            }else{
                    
                    rtlMode ? left() : right()
            }

        },
        channel_up: () => upDownChannel(1),
        channel_down: () => upDownChannel(-1),
        play: () => {
            if (playerOptions.isLive) return;
            setIsPlaying(true);
        },
        pause: () => {
            if (playerOptions.isLive) return;
            setIsPlaying(false);
        },
        stop: () => {
            if (playerOptions.isLive) return;
            back();
        },
        play_pause: () => {
            if (playerOptions.isLive) return;
            setIsPlaying(!isPlaying);
        },
        next: () => {
            if (playerOptions.isLive) return;
            seekTo(null, currentTime + 10);
        },
        fast_next: () => {
            if (playerOptions.isLive) return;
            seekTo(null, currentTime + 30);
        },
        prev: () => {
            if (playerOptions.isLive) return;
            seekTo(null, currentTime - 10);
        },
        fast_prev: () => {
            if (playerOptions.isLive) return;
            seekTo(null, currentTime - 30);
        },
    });

   

    const PlayerStartTime = () => {

        let start_time = epg.start_unix;

        if (currentTime && currentTime > 0) start_time += (currentTime * 1000);

        return start_time ? FormatDate(start_time, "hh:mm:ss") : "--:--:--";

    }

    const PlayerEndTime = () => {

        let end_time = epg.end_unix;

        if (duration && duration > 0 && duration != Infinity && epg.start_unix) {

            end_time = epg.start_unix + (duration * 1000);

        }

        return end_time ? FormatDate(end_time, "hh:mm:ss") : "--:--:--";

    }

    const clickButton = (keyCode) => () => {

        let data = { bubbles: true, cancelable: true, keyCode: keyCode }

        window.dispatchEvent(new KeyboardEvent("keydown", data));
        window.dispatchEvent(new KeyboardEvent("keyup", data));

    }

    if (!channel) return null;

    const controlesHidden = hidden && isPlaying;

  

    return createPortal(

        <div style={playerStyle} onMouseMove={() => { setHidden(false); }}>

            
                <AudioPopup
                  onBack={hidePopup}
                  audios={audios}
                  setAudios={setAudios}
                  isVisible={playerCurrentBlock == "audioPopup"}
              />
                <VideoPopup
                  onBack={hidePopup}
                  videos={video}
                  setVideo={setVideo}
                  className="live"
                  isVisible={playerCurrentBlock == "videoPopup"}
              />
            

            <AppPlayer ref={playerRef} {...playerConfign} playerstyle={playerStyle} />

            <PlayerError isVisible={error} />

            <div className={"channels-player-controller " + (controlesHidden ? "hidden" : "")}>

                <div className="change-channel-number">{changedChannelNumber}</div>

                {playerOptions.isLive && <div className="channels-player-info" >

                    <div className="container">

                        <div className='number'>{channel.num}</div>

                        <div className='logo'>
                            <ResizeImage
                                format="png"
                                src={channel.logo}
                                placeholder={dotsIcon}
                                aspectRatio={true}
                                quality={0.5}
                            />
                        </div>

                        <div className="body">
                            <div className='name'>{channel.name}</div>
                            <EpgInfo epg={epg} />
                            <EpgInfo epg={next_epg} />
                        </div>

                        <div className="buttons-container">

                        {
                            playerOptions.isFullscreen && audios.length ?  <div className="left-button" onClick={()=>{  setPlayerCurrentBlock('audioPopup') }}>
                                                            <img src={arrowLeftWhite} alt="arrow-left" />
                                                            <div className="text"><Text>audio</Text></div> 
                                                        </div>
                                                        
                                                        : null
                                                        
                        }

{
                            playerOptions.isFullscreen && video.length ?  <div className="left-button" onClick={()=>{  setPlayerCurrentBlock('videoPopup') }}>
                                                          
                                                            <img className="arrow-right" src={arrowLeftWhite} alt="arrow-right" />
                                                            <div className="text"><Text>video</Text></div> 
                                                           
                                                        </div>
                                                        
                                                        : null
                                                        
                        }
                       

                        </div>
                       
                        {
                         
                            playerOptions.isFullscreen &&  <div className="video-resolution">

                                {
                                    video && video.length ?   video.map((video, index) => {
                                    
                                        console.log(video,'video',video.enabled || video.selected);
                                        
                                            if(video.enabled || video.selected){
                                            
                                                
                                                return video.resolution || ""              
                                            }
        
                                    }) : null
                                }   


                                
                            </div>
                               
                        
                        }

                    </div>

                </div>}

                {!playerOptions.isLive && <div className="epg-player-info">

                    <div className="epg-info-header">

                        <div className='name'>
                            {channel.name}
                        </div>

                        <div className="epg-info-programs">
                            <EpgInfo epg={epg} />
                            <EpgInfo epg={next_epg} />
                        </div>

                    </div>

                    <div className="progress-container">

                        <div className="play-pause-btn">
                            {isPaused ? <PlaySVG /> : <PauseSVG />}
                        </div>

                        <div className="time">
                            <PlayerStartTime />
                        </div>

                        <div className="progress-parent">
                            <Progress
                                isActive={current == "progress"}
                                value={currentTime}
                                total={duration}
                                onChange={seekTo}
                                onMouseEnter={() => setCurrent("progress")}
                            />
                        </div>

                        <div className="time">
                            <PlayerEndTime />
                        </div>

                    </div>

                    <div className="epg-info-buttons">

                        <Button
                            isActive={current == "live"}
                            className={"epg-info-button"}
                            onClick={goLive}
                            onMouseEnter={() => setCurrent("live")}
                        >
                            <Text>go live</Text>
                        </Button>

                        {prev_epg && prev_epg.has_archive ? <Button
                            isActive={current == "prev"}
                            className={"epg-info-button"}
                            onMouseEnter={() => setCurrent("prev")}
                            onClick={() => playNextPrevEpg(prev_epg)}
                        >
                            <Text>prev</Text>
                        </Button> : null}

                        {next_epg && next_epg.has_archive ? <Button
                            isActive={current == "next"}
                            className={"epg-info-button"}
                            onMouseEnter={() => setCurrent("next")}
                            onClick={() => playNextPrevEpg(next_epg)}
                        >
                            <Text>next</Text>
                        </Button> : null}

                    </div>

                </div>}

            </div>

        </div>,
        document.getElementById("root")
    );

};

export default memo(Player);