import { memo, useContext } from "react";
import { arrowLeftBlack, arrowLeftWhite, searchBlack, searchWhite } from "../../../../../assets/icons";
import Text from "../../../../../components/text/Text";
import Button from "../../../../../components/button/Button";
import { MoviesPageContext } from "../../../MoviesPage";

import useStore from "@/src/hooks/useStore";

const SearchButton = ({
    isActive = false,
    onMouseEnter = () => { },
}) => {

    const {
        searchIsOpen,
        setSearchIsOpen,
    } = useContext(MoviesPageContext);

    const [rtlMode] = useStore("rtlMode");

    const searchIcon = isActive ? searchBlack : searchWhite;

    const arrowIcon = isActive ? arrowLeftBlack : arrowLeftWhite;

    const icon = searchIsOpen ? arrowIcon : searchIcon;

    const title = searchIsOpen ? "categories" : "search";

    const onClick = () => {
        setSearchIsOpen(!searchIsOpen);
    };

    return (
        <Button
            className="category-search-btn focus-item"
            isActive={isActive}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
        >
            <img className={rtlMode && searchIsOpen ? "rtlIcon" : ""} src={icon} />
            <Text>{title}</Text>
        </Button>
    )

};

export default memo(SearchButton);