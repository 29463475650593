import Storege from "../services/storage/storage";

export const RemToPixel = (rem) => {

    let k = window.innerWidth / 1920;

    return rem * k * 10;

}

export const PixelToRem = (pixel) => {

    let k = window.innerWidth / 1920;

    return pixel / k / 10;

}

export function FormatDate(date, format) {

    try {

        date = new Date(date);

        const map = {
            'dd': date.getDate().toString().padStart(2, '0'),
            'MM': (date.getMonth() + 1).toString().padStart(2, '0'),
            'yyyy': date.getFullYear().toString(),
            'hh': date.getHours().toString().padStart(2, '0'),
            'mm': date.getMinutes().toString().padStart(2, '0'),
            'ss': date.getSeconds().toString().padStart(2, '0')
        };

        return format.replace(/dd|MM|yyyy|hh|mm|ss/g, (match) => map[match]);

    } catch (e) {
        return "";
    }

}

export function DeviceDate() {

    let timeSettings = Storege.getTimeSettings() || { hours: 0, minutes: 0 };

    let date = new Date();

    date.setHours(date.getHours() + timeSettings.hours);
    date.setMinutes(date.getMinutes() + timeSettings.minutes);

    return date;

}

export function isValidHost(url = "") {

    try {

        let [protocol, hostname] = url.split("://");

        if ((protocol != "http" && protocol != "https") || hostname == "") return false;

        return true;

    } catch (e) {
        return false;
    }

}

export function isValidPlaylistUrl(url) {

    try {

        if (!isValidHost(url)) return false;

        let parts = url.split("/").filter((part) => part != "");

        if (parts.length < 3) return false;

        return true;

    } catch (e) {
        return false;
    }

}

export function Base64Decode(str) {
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export function FormatPlayerTime(secunds) {

    if (!secunds) return "00:00:00";

    if (secunds < 0) secunds = 0;

    let hours = Math.floor(secunds / 3600);
    let minutes = Math.floor((secunds - (hours * 3600)) / 60);
    let seconds = Math.floor(secunds - (hours * 3600) - (minutes * 60));

    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    return hours + ":" + minutes + ":" + seconds;

}

export function getYearFromMovieTitle(title) {
    // Regular expression to match a year in square brackets or parentheses
    var yearRegex = /(\[[0-9]{4}\])|(\([0-9]{4}\))/;

    // Use the regex to find the year in the title
    var match = yearRegex.exec(title);

    if (match) {
        // Extract the matched year
        var year = match[0];

        // Remove the matched year from the title
        var cleanedTitle = title.replace(year, '').trim();

        return {
            name: cleanedTitle,
            year: year.slice(1, -1) // Remove the brackets or parentheses
        };

    } else {
        // If no year is found, return the original title
        return {
            name: title,
            year: null
        };
    }
}

export function isAdultTitle(title) {

    if (!title) return false;

    let keyword = ["18+", "porn", "xxx", "adult"];

    title = title.toLowerCase();

    for (let i = 0; i < keyword.length; i++) {
        if (title.indexOf(keyword[i]) > -1) return true;
    }

    return false;

}

export function isUrlRedirected(url) {

    var xhr = new XMLHttpRequest();

    xhr.withCredentials = true;

    xhr.onreadystatechange = function () {

        if (this.readyState == 4) {

            console.log(this.responseURL, url)

            var isRedirected = this.responseURL != "" && url != "" && this.responseURL !== url;

            console.log({
                isRedirected: isRedirected,
                redirectUrl: isRedirected ? this.responseURL : null
            });

        }

    }

    xhr.open('HEAD', url, true);
    xhr.send();

}

export function generateRandomDeviceId() {

    let chars = "abcdefghijklmnopqrstuvwxyz0123456789";

    let id = "";

    for (let i = 0; i < 16; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;

}

export function generateMacAddress(str) {

    var mac_address = "";
  
    var temp = str.replace(/['-]+/g, '');
  
    for (var i = 0; i <= 5; i++) {
  
        mac_address += temp.substr(i * 2, 2);
  
        if (i < 5) mac_address += ":";
            
  
    }

    return mac_address;
}

export function loadScript(src) {

    return new Promise((resolve, reject) => {

        let script = document.createElement("script");

        script.onload = () => {
            resolve();
        }

        script.onerror = () => {
            try { script.remove(); } catch (e) { }
            reject();
        }

        script.src = src;

        document.head.appendChild(script);

    });

}

export function getBundleJsHost() {

    let scripts = document.getElementsByTagName("script");

    for (let i = 0; i < scripts.length; i++) {

        let src = scripts[i].src;

        if (src.indexOf("bundle.js") > -1) return src.split("bundle.js")[0];

    }

    return "";

}

export function animation(cb, duration) {

    let start = performance.now();
    let animationFrame = null;

    let animate = () => {

        let timePassed = performance.now() - start;

        if (timePassed > duration) timePassed = duration;

        cb(timePassed / duration);

        if (timePassed < duration) animationFrame = requestAnimationFrame(animate);

    }

    animate();

    return () => {
        cancelAnimationFrame(animationFrame);
    }

}

export function transformElement(element, x, y, progress = 1) {

    let pastX = parseFloat(element.getAttribute("data-x") || 0);
    let pastY = parseFloat(element.getAttribute("data-y") || 0);

    x = pastX + (x - pastX) * progress;
    y = pastY + (y - pastY) * progress;

    let transform = `translate3d(${x}px, ${y}px, 0)`;

    element.style["-webkit-transform"] = transform;
    element.style["transform"] = transform;
    element.style["-ms-transform"] = transform;

    element.setAttribute("data-x", x);
    element.setAttribute("data-y", y);

}