import { memo, useContext } from "react";
import Button from "../../../../../../components/button/Button";
import { SearchContext } from "../Search";

const KeyboardTypes = () => {

    const {
        isActive,
        types,
        type,
        setType,
        current,
        setCurrent,
        rowIndex,
        setRowIndex,
        parentMouseEnter
    } = useContext(SearchContext);

    const isActiveTypes = isActive && current == "types";

    const onClick = (e, index) => {
        setType(index);
    };

    const mouseEnter = (e, index) => {
        parentMouseEnter();
        setCurrent("types");
        setRowIndex(index);
    };

    return (
        <div className="search-row">
            <div className="movies-search-items">

                {types.map((item, index) => {

                    const isActive = isActiveTypes && rowIndex === index;

                    const isSelected = type === index;

                    return (
                        <Button
                            key={"movies-sort-type-item-" + index}
                            className="focus-item large "
                            isActive={isActive}
                            isSelected={isSelected}
                            data={index}
                            onClick={onClick}
                            onMouseEnter={mouseEnter}
                        >
                            {item.name}
                        </Button>
                    )

                })}

            </div>
        </div>
    )

};

export default memo(KeyboardTypes);