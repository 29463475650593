import { memo, useEffect, useState } from "react";
import Storege from "../../../../services/storage/storage";
import Button from "../../../../components/button/Button";
import useKeydown from "../../../../hooks/useKeydown";
import "./TimeSettings.scss";
import { DeviceDate } from "@/src/utils/util";

import useStore from "@/src/hooks/useStore";

import { arrowUpWhiteIcon, arrowDownWhiteIcon } from "@/src/assets/icons";

const TimeSettings = ({
    isActive = false,
    onBack = () => { },
    onMouseEnter = () => { }
}) => {

    const [settings, setSettings] = useState(Storege.getTimeSettings()); // { hours: 0, minutes: 0 }

    const [date, setDate] = useState(new DeviceDate());

    const rtlMode = Storege.getRtlMode();

    const [activeIndex, setActiveIndex] = useState(rtlMode ? 1 : 0);

    const onClick = (e, index) => {


    }

    const mouseEnter = (e, index) => {
        setActiveIndex(index);
        onMouseEnter();
    }

    useEffect(() => {

        Storege.setTimeSettings(settings);

        setDate(new DeviceDate());

    }, [settings]);

    useEffect(() => {

        let interval = setInterval(() => {
            setDate(new DeviceDate());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useKeydown({
        isActive,
        back: onBack,
        left: onBack,
        left: () => {
            if (rtlMode) {
                if (activeIndex == 0) return setActiveIndex(1);
                onBack();
            } else {
                if (activeIndex == 1) return setActiveIndex(0);
                onBack();
            }
        },
        right: () => {
            if (rtlMode) setActiveIndex(0);
            else setActiveIndex(1);
        },
        up: () => {

            let { hours, minutes } = settings;

            if (activeIndex === 0) {
                hours = (hours + 1) % 24;
            } else {
                minutes++;
                if (minutes > 59) minutes = 0
            }

            setSettings({ hours, minutes });

        },
        down: () => {

            let { hours, minutes } = settings;

            if (activeIndex === 0) {
                hours = (hours + 23) % 24;
            } else {
                minutes--;
                if (minutes < -59) minutes = 0
            }

            setSettings({ hours, minutes });

        }
    })

    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;

    return (
        <div className="settings-content time-settings">

            <Button
                isActive={activeIndex === 0 && isActive}
                onClick={onClick}
                onMouseEnter={mouseEnter}
                data={0}
                className={"focus-item"}
            >
                {(activeIndex === 0 && isActive) && <img className="arrow-up-icon" src={arrowUpWhiteIcon} />}
                {hours}
                {(activeIndex === 0 && isActive) && <img className="arrow-down-icon" src={arrowDownWhiteIcon} />}
            </Button>
            <div className="dots">:</div>
            <Button
                isActive={activeIndex === 1 && isActive}
                onClick={onClick}
                onMouseEnter={mouseEnter}
                data={1}
                className={"focus-item"}
            >
                {(activeIndex === 1 && isActive) && <img className="arrow-up-icon" src={arrowUpWhiteIcon} />}
                {minutes}
                {(activeIndex === 1 && isActive) && <img className="arrow-down-icon" src={arrowDownWhiteIcon} />}
            </Button>

        </div>
    );

};

export default memo(TimeSettings);