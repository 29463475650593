import { memo } from "react";
import "./Rating.scss";

import starDark from "./star-dark.png";
import starLight from "./star-light.png";

const Rating = ({ rating, max = 10 }) => {

    let width = rating * (max == 10 ? 10 : 20);

    return (
        <div className="rating-parent" style={{ backgroundImage: `url(${ starDark })` }}>
            <div className="rating" style={{ width: width + "%", backgroundImage: `url(${ starLight })` }}></div>
        </div>
    )

};

export default memo(Rating);