import "./Toggle.scss";

const Toggle = ({ checked, isActive }) => {
    return (
        <div className={"toggle-parent" + (checked ? " checked" : "") + (isActive ? " active" : "")}>
            <div className="toggle-circle"></div>
        </div>
    )
}

export default Toggle;