import CheckPin from "@/src/components/checkPin/CheckPin";
import { createPortal } from "react-dom";
import "./CheckPinPopup.scss";
import { memo } from "react";

const CheckPinPopup = ({
    isVisiable = false,
    onComplate = () => { },
    onBack = () => { },
}) => {

    if (!isVisiable) return null;

    return (createPortal(
        <div className="check-pin-popup bg-color">
            <CheckPin
                onMouseEnter={() => { }}
                isActive={true}
                onBack={onBack}
                onLeft={() => { }}
                onComplate={onComplate}
                capture={true}
            />
        </div>,
        document.getElementById("root")
    ))

};

export default memo(CheckPinPopup);