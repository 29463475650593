import { WarningSVG } from "../svgIcons";
import Text from "../text/Text";
import "./PlayerError.scss";

const PlayerError = ({ isVisible = false, className = "", errorMessage = "" }) => {

    if (!isVisible) return null;

    const message = errorMessage || "sorry, there was an error loading the player.";

    return (
        <div className={`player-error ${ className }`}>
            <WarningSVG />
            <div className="error-message">
                <Text>{message}</Text>
            </div>
        </div>
    );

};

export default PlayerError;