import { memo } from "react";
import useKeydown from "../../hooks/useKeydown";
import { useEffect } from "react";

const Button = ({
    isActive,
    isSelected,
    className,
    activeClassName,
    selectedClassName,
    onClick,
    onMouseEnter,
    data,
    children,
    style = {},
}) => {

    if (isActive) className += " " + (activeClassName || "active");
    if (isSelected) className += " " + (selectedClassName || "selected");

    if (typeof onClick != "function") onClick = () => { console.warn("Button onClick is not defined") };
    if (typeof onMouseEnter != "function") onMouseEnter = () => { console.warn("Button onMouseEnter is not defined") };

    const click = (e) => {
        e.stopPropagation();
        onClick(e, data);
    }

    const mouseEnter = (e) => {

        if (e.target) {

            const mousemove = (e) => {
                onMouseEnter(e, data);
                e.target.removeEventListener("mousemove", mousemove);
            }

            e.target.addEventListener("mousemove", mousemove);

        }

    }

    if (window.Android) {

        useEffect(() => {

            let pressed = false;
            let pressedTime = 0;

            const handleKeydown = (e) => {

                if (e.keyCode == 13 && !pressed) {

                    pressed = true;
                    pressedTime = Date.now();

                }

            }

            const handleKeyup = (e) => {

                if (e.keyCode == 13 && pressed) {
                    pressed = false;
                    e.duration = Date.now() - pressedTime;
                    pressedTime = 0;
                    click(e);
                }

            }

            if (isActive) {
                window.addEventListener('keydown', handleKeydown);
                window.addEventListener('keyup', handleKeyup);
            } else {
                window.removeEventListener('keydown', handleKeydown);
                window.removeEventListener('keyup', handleKeyup);
            }

            return () => {
                window.removeEventListener('keydown', handleKeydown);
                window.removeEventListener('keyup', handleKeyup);
            }

        }, [isActive, onClick]);

    } else {

        useKeydown({
            isActive,
            ok: click
        });

    }

    return (
        <div
            style={style}
            className={className}
            onClick={click}
            onMouseEnter={mouseEnter}
        >
            {children}
        </div>
    );

};

export default memo(Button);