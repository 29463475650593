import { memo, useState } from "react";
import "./Toast.scss";
import { toastError, toastSuccess, toastWarning } from "../../assets/icons";
import Text from "../text/Text";

const ToastItem = memo(({ type, msg, visible, index, icon }) => {

    return (
        <div
            key={index}
            style={{ top: ((index * 10) + 2) + "rem" }}
            className={"toast " + type + (visible ? " show" : "")}
        >
            <img src={icon} />
            <div className="toast-message">
                <Text>{msg}</Text>
            </div>
        </div>
    )
})

const Toast = () => {

    const [items, setItems] = useState([]);

    Toast.error = (data) => {
        return show("error", data, toastError);
    }

    Toast.warning = (data) => {
        return show("warning", data, toastWarning);
    }

    Toast.success = (data) => {
        return show("success", data, toastSuccess);
    }

    const show = (type, data, icon) => {

        let { msg, id, time = 3000 } = data;

        if (!msg || !type) return;

        if (id && items.find((item) => item.id == id)) return;

        let item = { type, msg, visible: true, icon, id };

        setItems((items) => {
            return [...items, item];
        });

        let timeout = setTimeout(() => {

            item.visible = false;

            setItems((items) => [...items]);

            setTimeout(() => {
                setItems((items) => items.filter((item) => item.visible));
            }, 250);

        }, time);

        return () => {
            clearTimeout(timeout);
            setItems((items) => items.filter((_item) => item != _item));
        }

    }

    return (
        <div className="toast-container">
            {items.map(({ type, msg, visible, icon }, index) => {
                return (
                    <ToastItem
                        key={index}
                        type={type}
                        msg={msg}
                        visible={visible}
                        index={index}
                        icon={icon}
                    />
                )
            })}
        </div>
    )

}

export default Toast;
